import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitOnBoardingForm } from "../../../redux/OnboardingForm/OnboardingFormActions";
import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
import {
  CMO_SUBMIT,
  CMO_DASHBOARD,
} from "../../../constants/route.constants.js/cmo";
import BankInformation from "../../ReviewDetails/components/BankInformation";
import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import { toast, Toaster } from "react-hot-toast";
import {
  rejectApplicationCMO,
  clarifyApplicationCMO,
  approveApplicationCMO,
} from "../../../redux/CMO/CMOJourneyActions";
import { APPLICATION_STATUSES } from "../../../constants/status.constants";
import Clarification from "../../ReviewDetails/components/Clarification/index";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";
import { SELECTED_OPTION } from "../../../constants/status";
import { loginInfo } from "../../../redux/Login/LoginActions";
import ActivityTracker from "../../ReviewDetails/components/ActivityTracker";

const ReviewOverlay = ({
  showOverlay,
  setShowOverlay,
  setRemarkComment,
  setClarificationComment,
  reject,
  handleClarification,
  handleReject,
  handleSubmit,
  selectedOption
}) => {
  const [overlayData, setOverlayData] = useState();
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  const handleAddPartner = () => {
    setShowOverlay(true);
  };
  const handleSubmitOverlay = () => {

    setShowOverlay(false);
    if (selectedOption === "approve") {
      return handleSubmit(overlayData)
    }

    {
      reject
        ? setRemarkComment(overlayData)
        : setClarificationComment(overlayData);
    }
    {
      reject ? handleReject(overlayData) : handleClarification(overlayData);
    }
  };

  return (
    <>
      {reject ? (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Remarks"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType={selectedOption === SELECTED_OPTION.Reject ? "red" : ""}
          buttonSubmitLabel={selectedOption === SELECTED_OPTION.Reject ? "REJECT" : "SUBMIT"}
          handleCloseOverlay={handleCloseOverlay}
          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea placeholder="Please specify the details of the remarks" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      ) : (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title={selectedOption === SELECTED_OPTION.Approve ? "Remarks" : "Clarification"}
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType=""
          buttonSubmitLabel="SUBMIT"
          handleCloseOverlay={handleCloseOverlay}
          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea placeholder="Please specify the details of the clarification required" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      )}
    </>
  );
};

const CMOReviewDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState(false);
  const [routePath, setRoutePath] = useState();
  const [reject, setReject] = useState();
  const [remarkComment, setRemarkComment] = useState();
  const [clarificationComment, setClarificationComment] = useState();
  const [selectedOption, setSelectedOption] = useState("")

  useEffect(() => {
    setRoutePath(location.pathname);
  }, []);

  const userData = dispatch(loginInfo)?.data
  // const selectedData = useSelector((state) => state?.onBoadringForm?.selectedData);
  const selectedApplicantCMO = useSelector(
    (state) => state?.cmoJourney?.selectedApplicantCMO
  );
  const handleBack = () => {
    history.push(CMO_DASHBOARD);
  };

  const makeChange = () => { };

  const handleReject = (text) => {
    const payload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: selectedApplicantCMO?._id,
    };

    const rejectCommentsHistory = {
      applicationId: selectedApplicantCMO?._id,
      ...selectedApplicantCMO,
      status: APPLICATION_STATUSES["Rejected"]["label"],
    };
    rejectCommentsHistory?.reject_remarks?.push(payload);
    dispatch(
      rejectApplicationCMO(rejectCommentsHistory, handleSuccess, handleError)
    );
  };

  const handleClarification = (text) => {
    const payload = {
      clarification: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: selectedApplicantCMO?._id,
    };

    // let formData = new FormData();
    const clarificationCommentsHistory = {
      applicationId: selectedApplicantCMO?._id,
      ...selectedApplicantCMO,
      status: APPLICATION_STATUSES["Awaiting Clarification"]["label"],
    };
    clarificationCommentsHistory?.clarification_history?.push(payload);
    dispatch(
      clarifyApplicationCMO(
        clarificationCommentsHistory,
        handleSuccess,
        handleError
      )
    );
  };

  const handleSuccess = () => {
    history.push(CMO_DASHBOARD);
  };

  const handleApproveSuccess = () => {
    history.push(CMO_SUBMIT);
  };

  const handleError = (message) => {
    toast.error(message);
  };

  const handleSubmit = (text) => {
    const createCMOPayload = {
      ...selectedApplicantCMO,
      active_step: 9,
      status: APPLICATION_STATUSES["Franchise Approved"]["label"],
      stepHistory: [
        ...selectedApplicantCMO?.stepHistory?.map((item) => {
          if (item?.step === selectedApplicantCMO?.active_step) {
            item.status = APPLICATION_STATUSES["Franchise Approved"]["label"];
            item.completed_date = new Date().toDateString();
            if (item?.step == 8 || item?.step == 9) {
              item.completedBy = userData._id;
            }
          }
          return item;
        }),
      ],
    };
    const textPayload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: location?.state?.review_data?._id,
    };
    if (text !== undefined) {
      createCMOPayload?.reject_remarks?.push(textPayload);
    }
    let currentUser = JSON.parse(localStorage.getItem("userInfo"));
    selectedApplicantCMO?.approval_history?.push({
      status: "Approved",
      approved_at: new Date().toDateString(),
      approved_by: currentUser?.profile?.first_name + " "+ currentUser?.profile?.last_name,
      role: currentUser?.role_name
  })
  createCMOPayload.approval_history =  selectedApplicantCMO?.approval_history
    dispatch(
      approveApplicationCMO(createCMOPayload, handleApproveSuccess, handleError)
    );
  };

  const loadingReject = useSelector((state) => {
    return state?.globalReducer?.rejectLoading;
  });
  const loadingClarification = useSelector((state) => {
    return state?.globalReducer?.clarifyLoading;
  });

  const loadingSubmit = useSelector((state) => {
    return state?.globalReducer?.submitLoading;
  });
  const SlidingNavabr = [
    { id: 1, key: "Basic Information", title: "Basic information" },
    { id: 2, key: "Centre Information", title: "Centre Information" },
    { id: 3, key: "Addition Information", title: "Addition Information" },
    { id: 4, key: "Partner Information", title: "Partner Information" },
    { id: 5, key: "Bank Information", title: "Bank Information" },
    {
      id: 6,
      key: "Checklist Requirement Information",
      title: "Checklist Requirement Information",
    },
  ];
  return (
    <section className="review-details__wrapper">
      <Toaster />
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <SlidingNavbar selectedData={selectedApplicantCMO} />
            {/* Array of data UserInformation to be passed 
            Requirements:-
              1.You will get an array only if a partner has been added
              2. To add a partner you need to fill the partnerKYC form
              3. To fill the form you need to get an email (which currently we are not getting)
              4. The email will get triggered when you 
            */}
            <UserInformation
              selectedData={selectedApplicantCMO?.source_data}
              _id={selectedApplicantCMO?._id}
              showAddress={true}
              kycDocs={selectedApplicantCMO?.kycDocs}
            />
            <div style={{ marginTop: "50px" }}>
              {Array.isArray(selectedApplicantCMO?.partner_details) &&
                selectedApplicantCMO?.partner_details?.map((item) => {
                  if (item?.source_data) {
                    return (
                      <UserInformation
                        selectedData={item?.source_data}
                        _id={selectedApplicantCMO?._id}
                        partnerId={item?.id}
                        isPartnerData={true}
                        showAddress={true}
                        kycDocs={selectedApplicantCMO?.kycDocs}
                      />
                    );
                  }
                })}
            </div>

            <CenterInfo selectedData={selectedApplicantCMO?.source_data} />
            <AddtionalDetails selectedData={selectedApplicantCMO?.source_data} />
            <PartnerInformation
              selectedData={selectedApplicantCMO?.source_data}
              fullInfo={selectedApplicantCMO}
            />
            <BankInformation selectedData={selectedApplicantCMO} />
            <OtherInformation selectedData={selectedApplicantCMO} />
            <ChecklistRequirement
              selectedData={selectedApplicantCMO?.checkList}
            />
            <PhleboInformation
              selectedData={selectedApplicantCMO?.phleboList}
              _id={selectedApplicantCMO?._id}
            />
            <BrandingPhotos _id={selectedApplicantCMO?._id} />
            <ServiceTiming selectedData={selectedApplicantCMO} />
            <Remarks selectedData={selectedApplicantCMO?.reject_remarks} />
            <Clarification
              selectedData={selectedApplicantCMO?.clarification_history}
            />

            <ActivityTracker selectedData={selectedApplicantCMO?.approval_history} />
            <div className="layout-button">
              <div>
                <Button type="ghost" label="Back" onClick={handleBack} />
              </div>
              <div>
                {selectedApplicantCMO?.status ===
                  APPLICATION_STATUSES["Rejected"]["label"] ? (
                  <Button label="REJECTED" type="reject" />
                ) : selectedApplicantCMO?.status !==
                  APPLICATION_STATUSES["Franchise Approved"]["label"] ? (
                  <Button
                    label="REJECT"
                    type="reject"
                    loading={loadingReject}
                    onClick={() => {
                      setShowOverlay(true);
                      setReject(true);
                      setSelectedOption("Reject")


                    }}
                  />
                ) : (
                  ""
                )}
                {selectedApplicantCMO?.status !==
                  APPLICATION_STATUSES["Franchise Approved"]["label"] ? (
                  <Button
                    label="CLARIFICATION"
                    type="outline"
                    loading={loadingClarification}
                    onClick={() => {
                      setShowOverlay(true);
                      setReject(false);
                    }}
                    id="clarification"
                  />
                ) : (
                  ""
                )}
                {selectedApplicantCMO?.status ===
                  APPLICATION_STATUSES["Awaiting CMO Approval"]["label"] ||
                  selectedApplicantCMO?.status ===
                  APPLICATION_STATUSES["Awaiting Clarification"]["label"] ? (
                  <Button
                    label="APPROVE"
                    loading={loadingSubmit}
                    onClick={() => {
                      setShowOverlay(true)
                      setSelectedOption("approve")
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            {showOverlay && (
              <ReviewOverlay
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                setRemarkComment={setRemarkComment}
                setClarificationComment={setClarificationComment}
                reject={reject}
                handleReject={handleReject}
                handleClarification={handleClarification}
                handleSubmit={handleSubmit}
                selectedOption={selectedOption}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CMOReviewDetails;
