import "./index.scss";
const DatePicker = ({
  name,
  value,
  label,
  placeholder,
  handleChange,
  error,
  isDisabled=false,
  ...otherProps
}) => {
  const errCls = error?.length !== 0 ? 'lds__date-picker__input--error' : '';
  const style = {
    cursor: isDisabled ? "no-drop": 'auto'
  };
  return (
    <div className="lds__date-picker__wrapper">
      <label className="lds__date-picker__label" for={name}>
        {label}
      </label>
      <input
        className={`lds__date-picker__input ${errCls}`}
        id="lds__date-picker__input"
        type="date"
        value={value}
        placeholder={value ? value: placeholder}
        name={name}
        onChange={handleChange}
        disabled={isDisabled}
        style={style}
        {...otherProps}
      />
      {error?.length !== 0 && (
        <label className="lds-form-group--error-label">{error}</label>
      )}
    </div>
  );
};
export default DatePicker;
