import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import DocumentProgress from "./components/documentProgress";
import "./index.scss";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import imageToBase64 from "image-to-base64/browser";
import { extractDataFromPancard, validateIdentityProof, setFileObjects, setUploadedDocs } from "../../../redux/OnboardingForm/OnboardingFormActions";
import { removeCertificate } from "../../../redux/UploadedDocuments/UploadedDocumentsActions";

const DragAndDrop = ({ label, enums, handleUpload, name, error = '', saveUploadedImages, uploadCertificate, clearFieldError, disabled=false }) => {
  const [uploaded, setUploaded] = useState(false);
  const [fileName, setFileName] = useState();
  const [fileImage, setFileImage] = useState();
  const [fileSize, setFileSize] = useState();
  const [invalidError, setInvalidError] = useState('');
  const selectedData = useSelector(
    (state) => state?.onBoadringForm?.selectedData
  );

  const selectedDocData = useSelector(
    (state) => state?.onBoadringForm?.uploadedDocs
  );
  const enumType = (enums === "excel" ? ".xlsx, .xls, .csv " : ( enums.includes("pdf") ?  "application/pdf" : "image/*" ));
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [fileObject, setFileObject] = useState({});
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      disabled: disabled,
      maxFiles: 1,
      accept: enumType,
      onDrop: (acceptedFiles) => {

        setFiles(
          acceptedFiles.map((file) => {
            setFileObject(file)
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          }
          )
        );
      },
    });
  const acceptedFile = acceptedFiles.map((file) => {
    let fileData = {
      file_path: file.path,
      file_size: file.size,
      file_preview: file.preview,
    };
    return fileData;
  });

  const fileRejectionReason = fileRejections.map(({ errors }) =>
    errors.map((e) => e.message)
  );
  useEffect(() => {
    const uploadedFile = acceptedFile?.[0];
    if (uploadedFile) {
      const { file_path, file_size, file_preview } = uploadedFile;
      setFileName(file_path);
      setFileImage(file_preview);
      setFileSize(file_size);
    }
  }, [acceptedFile]);

  const [cancel, setCancel] = useState(false);

  const removeFiles = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    setFileName(null);
    setFileImage(null);
    setFileSize(null);
    setUploaded(false);
    setFileObject({});
    setInvalidError(false);
    handleUpload(name, '');
    dispatch(setFileObjects(name, null, true));
    dispatch(setUploadedDocs(name, {}, true));
    if(uploadCertificate){
      dispatch(removeCertificate(name));
    }
  }


  const cancelClick = () => {
    removeFiles();
  };

  const removeDynamicFormErr = (fields) => {
    clearFieldError(fields, true);
  }

  useEffect(() => {
    if (fileImage) {
      if (uploadCertificate) {
        dispatch(saveUploadedImages(name, fileObject));
        dispatch(setUploadedDocs(name, { ...fileObject, name: fileObject?.name, size: fileObject?.size }));
      }
      dispatch(setFileObjects(name, fileObject));
      dispatch(setUploadedDocs(name, { ...fileObject, name: fileObject?.name, size: fileObject?.size }));
      handleUpload(name, fileImage);
      imageToBase64(fileImage).then((response) => {

        const data = {
          organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
          reference_id : Math.floor(Date.now()),
          source: response,
          type: "JPEG",
          status: "pending",
          is_editable: true,
          reference_id: "abcd",
          source_type: "base64",
        };
        if (name === "id_proof_front") {
          handleUpload("id_proof_front_base64", response);
        }
        if (name === "id_proof_back") {
          handleUpload("id_proof_back_base64", response);
        }

        if (name === "pan") {
          dispatch(extractDataFromPancard(data, removeDynamicFormErr, () => {
            setInvalidError('Invalid File');
          }));
        }

        if (name === "id_proof_back") {
          const idProofType = selectedData.id_proof
          const imgSrcList = []
          imgSrcList.push(response)
          imgSrcList.push(selectedData.id_proof_front_base64)
          dispatch(validateIdentityProof(imgSrcList, idProofType, () => {
            // setInvalidError('Invalid File');
          }));
        }

        if(name === "tagging_bdc_excel"){
          handleUpload("tagging_bdc_excel_base64", response);
        }
      });
    }
  }, [fileImage, fileObject]);

  useEffect(() => {
    if (name && selectedData?.hasOwnProperty(name) && selectedData[name]?.length !== 0 || (uploadCertificate && Object.keys(selectedDocData)?.length && Object.keys(selectedDocData).includes(name))) {
      setUploaded(true);
    }
  }, []);
  const requiredError = error?.length !== 0 && error?.toLowerCase()?.indexOf('require') !== -1;
  const dropzoneErrCls = requiredError ? 'lds__drag-dropzone--error' : '';
  const disabledCls = disabled ? 'lds__drag-dropzone--disabled' : '';

  return (
    <section className="lds__drag--wrapper">
      {!uploaded && !fileName ? (
        <>
          <span className="lds__drag--label">{label}</span>

          <div {...getRootProps({ className: `lds__drag-dropzone ${dropzoneErrCls} ${disabledCls}` })}>
            <input {...getInputProps()} />
            <p>Drag file here or <span className="lds__drag-dropzone_browse">Browse</span></p>
          </div>
          {requiredError && (
            <span className="lds__drag-error"><small>{error}</small></span>
          )}
        </>
      ) : (
        <Container>
          <Row>
            <Col lg={12}>
              <span className="lds__drag--label">{label}</span>
              <DocumentProgress
                fileName={fileName || selectedDocData[name]?.name}
                fileSize={fileSize || selectedDocData[name]?.size}
                uploaded={uploaded}
                fileRejectionReason={fileRejectionReason}
                cancelClick={cancelClick}
                error={invalidError}
                removeFiles={removeFiles}
              />
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};
export default DragAndDrop;
