import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCheckList } from "../../../redux/PhleboOnboarding/PhleboOnboardingActions";
import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
import "./index.scss";
import {
  CLIENT_DASHBOARD_BASIC,
  CLIENT_PARTNER_DOCMUMENTATION,
} from "../../../constants/route.constants.js/applicant";
import { PHLEBO_CHECKLIST } from "../../../constants/route.constants.js/phlebo";
import { CLIENT_ALLOWED_STEPS } from "../../../constants/app.constants";
import { Link } from "react-router-dom";
import BankInformation from "../../ReviewDetails/components/BankInformation";
import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";
import Clarifications from "../../ReviewDetails/components/Clarification";
import PhleboOnboarding from '../PhleboOnboarding/index';
import { loginInfo } from "../../../redux/Login/LoginActions";

const routeMap = {
  4: CLIENT_PARTNER_DOCMUMENTATION,
  8: PHLEBO_CHECKLIST,
};

const ReviewDetailsPhlebo = () => {
  // const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch()
  // const selectedData = useSelector(
  //   (state) => state?.addPartnerDetails?.selectedData
  // );

  // const selectedApplication = useSelector(
  //   (state) => state?.addPartnerDetails?.selectedApplication
  // );
  const [viewMore, setViewMore] = useState(false);

  const selectedPhleboApplication = useSelector(
    (state) => state?.phleboOnboarding?.selectedPhleboApplication
  );
  const userData = dispatch(loginInfo)?.data

  const { active_step, checkList } = selectedPhleboApplication;
  
  useEffect(() => {
    if (selectedPhleboApplication && Object.keys(selectedPhleboApplication)?.length) {
      if (selectedPhleboApplication?.is_draft) {
        dispatch(updateCheckList(selectedPhleboApplication?.checkList));
      }
    }
  }, [])

  const handleProceed = () => {
    const route = routeMap[selectedPhleboApplication?.active_step];
    history.push(route);
  };

  const handleBack = () => {
    history.push(CLIENT_DASHBOARD_BASIC);
  };


  return (
    <section className="review-details__wrapper">
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            {
              (viewMore || active_step !== 8) ? <SlidingNavbar selectedData={selectedPhleboApplication} /> : ''
            }
            <UserInformation
              selectedData={selectedPhleboApplication?.source_data}
              _id={selectedPhleboApplication?._id}
              kycDocs={selectedPhleboApplication?.kycDocs}
            />

            {viewMore || active_step !== 8 ? (
              <>
                <div style={{ marginTop: "50px" }}>
                  {Array.isArray(selectedPhleboApplication?.partner_details) &&
                    selectedPhleboApplication?.partner_details?.map((item) => {
                      if (item?.source_data) {
                        return (
                          <UserInformation
                            selectedData={item?.source_data}
                            _id={selectedPhleboApplication?._id}
                            partnerId={item?.id}
                            isPartnerData={true}
                            kycDocs={selectedPhleboApplication?.kycDocs}
                          />
                        );
                      }
                    })}
                </div>

                <CenterInfo
                  selectedData={selectedPhleboApplication?.source_data}
                />
                <AddtionalDetails
                  selectedData={selectedPhleboApplication?.source_data}
                />
                {selectedPhleboApplication?.source_data?.partner_profiling ? (
                  <PartnerInformation
                    selectedData={selectedPhleboApplication?.source_data}
                    fullInfo={selectedPhleboApplication}
                  />
                ) : (
                  ""
                )}
                {selectedPhleboApplication?.bank_name ? (
                  <BankInformation selectedData={selectedPhleboApplication} />
                ) : (
                  ""
                )}
                {selectedPhleboApplication?.document_signed ? (
                  <OtherInformation selectedData={selectedPhleboApplication} />
                ) : (
                  ""
                )}
                {Array.isArray(selectedPhleboApplication?.checkList) &&
                  selectedPhleboApplication?.checkList ? (
                  <ChecklistRequirement
                    selectedData={selectedPhleboApplication?.checkList}
                  />
                ) : (
                  ""
                )}
                {Array.isArray(selectedPhleboApplication?.phleboList) &&
                  selectedPhleboApplication?.phleboList?.length ? (
                  <PhleboInformation
                    selectedData={selectedPhleboApplication?.phleboList}
                    _id={selectedPhleboApplication?._id}
                  />
                ) : (
                  ""
                )}
                {selectedPhleboApplication?.weekday_start_time ? (
                  <>
                    {" "}
                    <Link
                      className="review-client-text"
                      to="#"
                      onClick={() => setViewMore(!viewMore)}
                    >
                      {viewMore ? "View less" : "View more"}
                    </Link>
                    <BrandingPhotos _id={selectedPhleboApplication?._id} />
                    <ServiceTiming selectedData={selectedPhleboApplication} />
                  </>
                ) : (
                  ""
                )}
                {Array.isArray(selectedPhleboApplication?.reject_remarks) &&
                  selectedPhleboApplication?.reject_remarks?.length ? (
                  <Remarks
                    role="Business Head"
                    selectedData={selectedPhleboApplication?.reject_remarks}
                  />
                ) : (
                  ""
                )}
                {Array.isArray(
                  selectedPhleboApplication?.clarification_history
                ) &&
                  selectedPhleboApplication?.clarification_history?.length ? (
                  <Clarifications
                    role="Business Head"
                    selectedData={
                      selectedPhleboApplication?.clarification_history
                    }
                    userData={userData}
                  />
                ) : (
                  ""
                )}
              </>
            ) : <div onClick={() => setViewMore(true)} className="review-details__wrapper--view-more__btn">View more</div>}
            {active_step === 8 && (
              <p className="review-details__wrapper--view-more__text">
                Your application has almost reached the final stage of onboarding
                procedure. To proceed further we need few more information from
                your end. Please keep the details and documents related to the
                Phlebotomist and the centre branding related information.
                <br />
              </p>
            )}
            <div className="layout-button">
              <Button label="BACK" type="ghost" onClick={handleBack} />
              {CLIENT_ALLOWED_STEPS.includes(
                selectedPhleboApplication?.active_step
              ) ? (
                <Button label="PROCEED" onClick={handleProceed} />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReviewDetailsPhlebo;
