import { APPLICATION_STATUSES } from '../../../constants/status.constants';

export const getStatusBasedBtn = (type, step, status) => {
    if (type === "reject" && status === APPLICATION_STATUSES['Awaiting BH Approval']['label']) {
        if (step === 3) return true
    }
    if (type === 'clarification' && status !== APPLICATION_STATUSES['Rejected']['label']) {
        return true
    }
    if (type === 'approve' && status === APPLICATION_STATUSES['Awaiting BH Approval']['label']) {
        if (step === 3) return true
    }
    if (type === 'rejected') {
        if (step === 3 && status === APPLICATION_STATUSES['Rejected']['label']) return true
    }
}