export const APPLICATION_STATUSES = {
    "Awaiting CH Approval": { label: "Awaiting CH Approval", roles: [], color: "#DEEFFF", listAction: { "Cluster Head": "Proceed" } },
    "Awaiting BH Approval": { label: "Awaiting BH Approval", roles: [], color: "#DEEFFF", listAction: { "Business Head": "Proceed" } },
    "Awaiting Clarification": { label: "Awaiting Clarification", roles: [], color: "#FFECD7", listAction: {} },
    "Awaiting KYC": { label: "Awaiting KYC", roles: [], color: "#EDD5FF", listAction: {} },
    "Awaiting Payment": { label: "Awaiting Payment", roles: [], color: "#EDD5FF", listAction: {} },
    "Awaiting Additional Info": { label: "Awaiting Additional Info", roles: [], color: "#EDD5FF", listAction: {} },
    "Awaiting Payment Confirmation": { label: "Awaiting Payment Confirmation", roles: [], color: "#DEEFFF", listAction: { "Channel Team": "Proceed" } },
    "Awaiting Partner KYC": { label: "Awaiting Partner KYC", roles: [], color: "#DEEFFF", listAction: {} },
    "Out For Signature": { label: "Out For Signature", roles: [], color: "#CDFEFF", listAction: {} },
    "Awaiting Legal Approval": { label: "Awaiting Legal Approval", roles: [], color: "#EDD5FF", listAction: { "Legal Team": "Proceed"} },
    "Approved - Legal Team": { label: "Approved - Legal Team", roles: [], color: "#D9F9EC", listAction: { } },
    "Awaiting Client Code": { label: "Awaiting Client Code", roles: [], color: "#DEEFFF", listAction: { "IT Team": "Proceed" } },
    "Awaiting CMO Approval": { label: "Awaiting CMO Approval", roles: [], color: "#DEEFFF", listAction: { "CMO": "Proceed" } },
    "Franchise Approved": { label: "Franchise Approved", roles: [], color: "#D9F9EC", listAction: {} },
    "Rejected": { label: "Rejected", roles: [], color: "#FFD3D7", listAction: {} },
    "Terminated": { label: "Terminated", roles: [], color: "#FFD3D7", listAction: {} }
};
