import {
  SET_REJECT_LOADING,
  SET_SUBMIT_LOADING,
  SET_CLEARIFICATION_LOADING,
  LOGIN_LOADING,
  PAGE_LOADING,
  SET_DISABLED_FIELDS,
  SET_OTP_LOADING,
  SET_APP_ERROR,
} from "./Types";

export const setSubmitLoading = (val) => {
  return {
    type: SET_SUBMIT_LOADING,
    payload: val,
  };
};
export const setRejectLoading = (val) => {
  return {
    type: SET_REJECT_LOADING,
    payload: val,
  };
};
export const setClarificationLoading = (val) => {
  return {
    type: SET_CLEARIFICATION_LOADING,
    payload: val,
  };
};

export const setLoginLoading = (val) => {
  return {
    type: LOGIN_LOADING,
    payload: val,
  };
};
export const setPageLoader = (val) => {
  return {
    type: PAGE_LOADING,
    payload: val,
  };
};

export const setOtpLoader=(val)=>{
  return{
    type:SET_OTP_LOADING,
    payload:val
  }
}

export const setOnBoardingDisabledFields = (data, action_type) => {
  return {
    type: SET_DISABLED_FIELDS,
    payload: data,
    action_type
  }
}

export const setAppError=(val)=>{
    return {
      type:SET_APP_ERROR,
      payload:val
    }
}