import {
    APPLICANT_LIST_REQUEST,
    APPLICANT_LIST_SUCCESS,
    APPLICANT_LIST_FAILURE,
    LEGAL_TEAM_REJECTION_REQUEST,
    LEGAL_TEAM_REJECTION_SUCCESS,
    LEGAL_TEAM_REJECTION_FAILURE,
    LEGAL_TEAM_APPROVAL_REQUEST,
    LEGAL_TEAM_APPROVAL_SUCCESS,
    LEGAL_TEAM_APPROVAL_FAILURE,
    GET_GEO_LOCATION_REQUEST,
    GET_GEO_LOCATION_SUCCESS,
    GET_GEO_LOCATION_FAILURE,
    SELECTED_APPLICANT_CHANNEL
  } from "./LegalTeamTypes";
  const initialState = {
    loading: false,
    applicantList: [],
    itTeamApproval: {},
    itTeamRejection: {},
    error: "",
    selectedApplicantLegal: {}
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case APPLICANT_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case APPLICANT_LIST_SUCCESS: {
        return {
          ...state,
          applicantList: action.payload,
        };
      }
      case APPLICANT_LIST_FAILURE: {
        return {
          ...state,
          error: action.payload,
        };
      }
      case LEGAL_TEAM_REJECTION_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case LEGAL_TEAM_REJECTION_SUCCESS: {
        return {
          ...state,
          LegalTeamRejection: action.payload,
        };
      }
      case LEGAL_TEAM_REJECTION_FAILURE: {
        return {
          ...state,
          error: action.payload,
        };
      }
      case LEGAL_TEAM_APPROVAL_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case LEGAL_TEAM_APPROVAL_SUCCESS: {
        return {
          ...state,
          LegalTeamApproval: action.payload,
        };
      }
      case LEGAL_TEAM_APPROVAL_FAILURE: {
        return {
          ...state,
          error: action.payload,
        };
      }
      case GET_GEO_LOCATION_REQUEST: {
        return {
          ...state,
          loading: true,
        };
      }
      case GET_GEO_LOCATION_SUCCESS: {
        return {
          ...state,
          ipData: action.payload,
        };
      }
      case GET_GEO_LOCATION_FAILURE: {
        return {
          ...state,
          error: action.payload,
        };
      }
      case SELECTED_APPLICANT_CHANNEL: {
        return {
          ...state,
          selectedApplicantLegal: action.payload,
        };
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  
