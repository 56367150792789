import { Col } from "react-bootstrap";
import Input from "../../../lib/components/Input";
import Button from "../../../lib/components/Button";
import "../index.scss";
import { Link } from "react-router-dom";
import { CLIENT_LOGIN } from "../../../constants/route.constants.js/applicant";
const PasswordLogin = ({ handleSubmitLogin, user, loading, handleChange }) => {



  return (
    <Col lg={5}>
      <div className="login__content-right">
        <p>Sign In</p>
        <Input
          label="User Name"
          name="username"
          value={user?.username}
          onChange={(e) => handleChange(e)}
        />
        <Input
          label="Password"
          navLink={true}
          name="password"
          type="password"
          value={user?.password}
          onChange={(e) => handleChange(e)}
        />
        <div style={{ width: "100%" }}>
          <Button
            label="Login"
            onClick={handleSubmitLogin}
            style={{ width: "100%" }}
            loading={loading}
          />
        </div>

        <div className="login__content-clickhere">
          To check the application status <Link to={CLIENT_LOGIN}>click here</Link>
        </div>
      </div>
    </Col>
  );
};
export default PasswordLogin;
