import { Button, Spinner } from "react-bootstrap";
import "./index.scss";
const Buttonv1 = (props) => {
  return (
    <>
      <Button
        {...props}
        className={props.className}
        style={props.style}
        onClick={props.onClick}
        disabled={ props.loading ?true :false}
      >
        {props.loading ? (
          <Spinner animation="border" />
        ) : (
          <>{props.label}</>
        )}
      </Button>
    </>
  );
};
export default Buttonv1;
