import Button from "../../lib/components/Button";
import Input from "../../lib/components/Input";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const ComplexComponent = props => {
  const dispatch = useDispatch();
  const otpValidationInProgress = useSelector(
    state => state?.onBoadringForm?.otpValidationInProgress
  );
  const otpValidationSucceeded = useSelector(
    state => state?.onBoadringForm?.isOtpValidated
  );
  const otpGenerated = useSelector(
    state => state?.onBoadringForm?.isOtpGenerated
  );
  const { active = 0, components = [], onChange, validateOtp, clearFieldError } = props;
  const [icon, setIcons] = useState("tick");
  const [otpError, setError] = useState();
  const isOtpGenerated = otpGenerated && otpGenerated !== "init_state";
  let activeComp = components[isOtpGenerated ? 1 : 0];
  useEffect(() => {
    if (
      otpValidationSucceeded !== "init_state" &&
      otpValidationSucceeded !== undefined
    ) {
      if (otpValidationSucceeded) {
        setIcons("tick");
      } else {
        setError("Invalid otp");
        setIcons("");
      }
      // dispatch(resetOtpSuccess());
    }
  }, [otpValidationSucceeded]);

  useEffect(() => {
    if (
      otpValidationInProgress !== "init_state" &&
      otpValidationInProgress !== undefined
    ) {
      if (otpValidationInProgress) {
        setIcons("load");
      } else {
        setIcons("");
      }
    }
  }, [otpValidationInProgress]);

  const handleChange = e => {
    setError("");
    activeComp = { ...activeComp, value: e.target.value };
    if (activeComp.value.length === 6) {
      validateOtp(activeComp.value);
      setIcons("load");
    }
  };
  const onClickImage = e => {};

  activeComp = {
    ...activeComp.field_attributes,
    type: activeComp.type,
    value: activeComp.value,
    handleChange: handleChange,
  };
  const otp_verified =
    otpValidationSucceeded && otpValidationSucceeded !== "init_state";
  if (otp_verified) {
    return "";
  }
  return (
    <>
      {activeComp?.type === "button" ? (
        <div className="lds-form-group">
          <label className="lds-form-group__label"></label>
          <Button
            className='lds-complexcomp-btn lds-complexcomp-btn--safari-only'
            onClick={() => {
              onChange({ ...props, active: 1 });
              clearFieldError("mobile_number");
            }}
            {...activeComp}
            style={{ border: "1px solid #0076bc", width: '100%' }}
          />
        </div>
      ) : (
        <div className="lds-form-group">
          <Input
            {...activeComp}
            icon={icon}
            error={otpError}
            onClickImage={onClickImage}
            custom_action={{
              label: "Re-send OTP",
              handlerFunc: () => {
                onChange(null, true);
              },
              style: { color: "#0076BC" },
              footer: true,
            }}
          />
        </div>
      )}
    </>
  );
};

export default ComplexComponent;
