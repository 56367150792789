import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./index.scss";
import loader from "../../../assets/images/loader.svg";
import greentick from "../../../assets/images/green tick.svg";
import eye from '../../../assets/images/eye.svg'
import eyeHide from '../../../assets/images/eyeHide.svg'

import { useState } from "react";
import { FORGET_PASSWORD } from "../../../constants/route.constants.js/forget_password";

const Input = ({
  label,
  value,
  type,
  fieldName,
  name,
  navLink,
  handleChange,
  onPinBlur,
  placeholder,
  onClickImage,
  error,
  isDisabled = false,
  custom_action = undefined,
  verified = false,
  ...otherProps
}) => {
  const [viewPassword, setViewPassword] = useState(false)
  const [bgColor, setBgColor] = useState("")

  const errActive = error && error?.length !== 0;
  const style = {
    border: errActive ? "1px solid red" : "",
    cursor: isDisabled ? "no-drop" : 'auto',
    backgroundColor: bgColor.backgroundColor
  };
  const handleFocus = () => {
    if (value) {
      setBgColor({ backgroundColor: "#e8eefe" })
    }
  }
  const handleBlur = () => {
    if (value) {
      setBgColor({ backgroundColor: "#f5f8fa" })
    }
  }
  return (
    <div className="lds-form-group">
      <label className="lds-form-group__label" htmlFor={fieldName}>
        {label}
        {!custom_action?.footer && <span onClick={custom_action?.handlerFunc} className="lds-form-group__input-custom_action" style={custom_action?.style}>{custom_action?.label}</span>}
      </label>
      <input
        maxLength={name === "otp" ? 6 : 500}
        style={style}
        className={`lds-form-group__input ${name === "otp" ? "abc" : ""}`}
        type={viewPassword && type === "password" ? "text" : type}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={name === "pin_code" ? onPinBlur : null}
        value={value}
        disabled={isDisabled}
        {...otherProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {type === "password" &&
        <div className="eye">
          {value.length !== 0 &&
            <>
              {
                viewPassword ?
                  <img src={eyeHide} className="eye-input-toggle" onClick={(e) => setViewPassword(!viewPassword)} />
                  :
                  <img src={eye} className="eye-input-toggle" onClick={() => setViewPassword(!viewPassword)} />
              }
            </>
          }
        </div>
      }
      {
        verified && (
          <img className="otp-input-loader" src={greentick} />
        )
      }
      {
        custom_action?.footer &&
        <span onClick={custom_action?.handlerFunc} className="lds-form-group__input-footer_custom_action" style={custom_action?.style}>
          {custom_action?.label}
        </span>
      }
      {errActive && (
        <label className="lds-form-group--error-label">{error}</label>
      )}
      {/* incase we need tick mark in the opt verification fields */}
      {/* {otherProps.icon === "load" ? (
        // <img className="otp-input-loader" src={loader} onClick={onClickImage} />
      ) : otherProps.icon === "tick" ? (
        <img className="otp-input-loader" src={greentick} />
      ) : null} */}

      {navLink && (
        <Link className="lds-form-group__link" to={FORGET_PASSWORD}>
          Forgot Password ?
        </Link>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
};

export default Input;
