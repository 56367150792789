import { useState } from "react";
import { Col } from "react-bootstrap";
import Input from "../../../lib/components/Input";
import Button from "../../../lib/components/Button";
import { Link } from "react-router-dom";
import "../index.scss";
import { GLOBAL_LOGIN_ROUTE } from "../../../constants/route.constants.js";

import { toast, Toaster } from "react-hot-toast";
const OTPLogin = (props) => {
  const [active, setActive] = useState(false);
  const [mob, setMob] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const { sendOtp, verifyOtp } = props;
  const otpSubmit = async (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      setError("");
      verifyOtp(otp, mob);
    } else {
      setError("Invalid Otp");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  const loginSubmit = async () => {
    if (mob.length == 10 || mob.length == 12) {
      setError("");
      sendOtp(mob, () => {
        setActive(true);
      });
    } else {
      setError("Invalid phone number");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  const handleNumberChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      return toast.error("Please enter only number");
    }
    if (value) {
      setMob(value);
      setError("");
    } else {
      setMob("");
      setError("Invalid phone number");
    }
  };
  const handleOtpChange = (e) => {
    let value = e.target.value;
    if (value) {
      setOtp(value);
      setError("");
    } else {
      setOtp("");
      setError("Invalid otp");
    }
  };
  return (
    <>
      <Toaster />
      {active ? (
        <Col lg={5}>
          <div className="login__content-right">
            <p>Log In </p>
            <Input
              label="Verify OTP"
              type="text"
              placeholder="Enter OTP number"
              onChange={(e) => handleOtpChange(e)}
              value={otp}
              max="6"
            />
            <small style={{ color: "red" }}>{error}</small>
            <Button
              label="Login"
              loading={props.loading}
              onClick={otpSubmit}
              style={{ width: "100%" }}
            />
          </div>
        </Col>
      ) : (
        <Col lg={6}>
          <div className="login__content-right">
            <p>Log In</p>
            <Input
              label="Registered phone number"
              type="text"
              placeholder="Enter phone number"
              onChange={(e) => handleNumberChange(e)}
              value={mob}
              max="12"
            />
            <small style={{ color: "red" }}>{error}</small>
            <Button
              loading={props.loading}
              label="Submit"
              onClick={loginSubmit}
              style={{ width: "100%" }}
            />
            <div className="login__content-clickhere">
              <Link to={GLOBAL_LOGIN_ROUTE}>Click here</Link> to login as a Agilus user
            </div>
          </div>
        </Col>
      )}
    </>
  );
};
export default OTPLogin;
