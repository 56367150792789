import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitOnBoardingForm } from "../../../redux/OnboardingForm/OnboardingFormActions";
import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
import { setApplicantSecondJourneySelectedData, clearApplicantSecondJourneySelectedData, savePartnerList } from '../../../redux/AddPartnerDetails/AddPartnerDetailsActions'
import "./index.scss";
import {
  CLIENT_DASHBOARD_BASIC,
  CLIENT_PARTNER_DOCMUMENTATION,
  PAYMENT_SCREEN,
} from "../../../constants/route.constants.js/applicant";
import { PHLEBO_CHECKLIST } from "../../../constants/route.constants.js/phlebo";
import { CLIENT_ALLOWED_STEPS } from "../../../constants/app.constants";
import { APPLICATION_STATUSES } from '../../../constants/status.constants';
import { Link } from "react-router-dom";
import { SUCCESS_RESPONSE_LIST } from "../../../constants/status";
import { axisPayment, axisPaymentResult } from '../../../lib/components/api/api.service';

const routeMap = {
  4: CLIENT_PARTNER_DOCMUMENTATION,
  8: PHLEBO_CHECKLIST
};

const ReviewDetailsClient = () => {
  // const location = useLocation();
  let history = useHistory();
  const [applicationData, setApplicationData] = useState()
  const [viewMore, setViewMore] = useState(false)
  const [showPayment, setShowPayment] = useState(true)

  // const selectedData = useSelector(
  //   (state) => state?.addPartnerDetails?.selectedData
  // );

  const selectedApplication = useSelector(
    (state) => state?.addPartnerDetails?.selectedApplication
  );
  const dispatch = useDispatch();
  const { active_step, is_draft } = selectedApplication;
  useEffect(() => {
    setApplicationData(selectedApplication)
  }, [])

  useEffect(() => {
    if (selectedApplication && Object.keys(selectedApplication)?.length) {
      if (is_draft) {
        dispatch(setApplicantSecondJourneySelectedData("bank_name", selectedApplication?.bank_name))
        dispatch(setApplicantSecondJourneySelectedData("ifsc_code", selectedApplication?.ifsc_code))
        dispatch(setApplicantSecondJourneySelectedData("beneficiary_name", selectedApplication?.beneficiary_name))
        dispatch(setApplicantSecondJourneySelectedData("account_number", selectedApplication?.account_number))
        dispatch(setApplicantSecondJourneySelectedData("ownership", selectedApplication?.ownership))
        dispatch(savePartnerList(selectedApplication?.partner_details))
      } else {
        dispatch(clearApplicantSecondJourneySelectedData())
      }
    }
  }, [])

  const handleProceed = () => {
    const route = routeMap[applicationData?.active_step];
    history.push(route);
  };

  const handleBack = () => {
    history.push(CLIENT_DASHBOARD_BASIC);
  };
  const responseSubmit = async() => {
    history.push(PAYMENT_SCREEN)
  //   console.log(selectedApplication)
  //   const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
  //   console.log(res)
  //   if (!res) {
  //     alert('Razorpay SDK failed to load.')
  //     return
  //   }else{
  //   console.log(selectedApplication)
  //   let params = {
  //     id: selectedApplication?._id,
  //     security_deposit: selectedApplication?.source_data?.security_deposit
  //   }
  //   let response = axisPayment(params)
  //   response.then((data) => {
  //     const options = {
  //       key: process.env.REACT_APP_PAYMENT_KEY,
  //       currency: "INR",
  //       amount: params.security_deposit + "00",
  //       order_id: data.data?.result?.order_id,
  //       name: 'SRL LIMITED',
  //       description: '',
  //       handler: function (response) {
  //         let params = {
  //           id: selectedApplication?._id,
  //           org_name: response.org_name,
  //           initiated_at: data.data?.result?.initiated_at,
  //           gateway_response: response
  //         }
  //         // razorpay_order_id: response.razorpay_order_id,
  //         // razorpay_signature: response.razorpay_signature,
  //         axisPaymentResult(params);
  //         console.log({ response })
  //         if (SUCCESS_RESPONSE_LIST?.includes(data?.status)) {
  //          selectedApplication.status = APPLICATION_STATUSES['Awaiting Payment']['label'] 
  //          setShowPayment(false)
  //        }
  //       },
  //       prefill: {
  //         name: selectedApplication?.source_data?.first_name + " " + selectedApplication?.source_data?.last_name,
  //         email: selectedApplication?.source_data?.email,
  //         contact: selectedApplication?.source_data?.mobile_number
  //       },
  //       image: "/static/media/SRL_Diagnostics_Logo.eb828ce1.png",
  //       callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
  //       notes: {
  //         "address": "Razorpay Corporate Office"
  //       },
  //       theme: {
  //         "color": "#3399cc"
  //       }
  //     }

  //     const paymentObject = new window.Razorpay(options)
  //     paymentObject.open()
  //   })
  // }
    // handleSubmit()
  }

  return (
    <section className="review-details__wrapper">
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <UserInformation selectedData={selectedApplication?.source_data} _id={selectedApplication?._id}  kycDocs={selectedApplication?.kycDocs}/>
            {active_step === 4 && (
              <div style={{ marginTop: "40px", marginLeft: "25px" }}>
                <Link to="#" onClick={() => setViewMore(!viewMore)}> {viewMore ? "" : "View More"}</Link>
              </div>
            )}
            {viewMore || active_step !== 4 ?
              <>
                <CenterInfo selectedData={selectedApplication?.source_data} />
                <AddtionalDetails selectedData={selectedApplication?.source_data} />
                <PartnerInformation selectedData={selectedApplication?.source_data} fullInfo={selectedApplication}/>
              </>
              : null}

            {(active_step === 4 && selectedApplication.status == "Awaiting KYC" ) && (
              <p className="review-client-text-second">
                Your application has been approved by the Business Head. To
                proceed further we need few more information from your end. Please
                keep your Aadhaar and banking information ready before proceeding.
              </p>
            )}
             {(active_step === 4 && selectedApplication.status == "Awaiting Payment" ) && (
              <p className="review-client-text-second">
               KYC completed successfully! Please make the deposit to move further.
              </p>
            )}
            <div className="layout-button">
              <Button label="BACK" type="ghost" onClick={handleBack} />
              {/* {
                CLIENT_ALLOWED_STEPS.includes(selectedApplication?.active_step) ?
                  <Button label="PROCEED" onClick={handleProceed} /> : ''
              } */}
              {
                selectedApplication?.status === APPLICATION_STATUSES['Awaiting KYC']['label'] ?
                  <Button label="PROCEED" onClick={handleProceed} /> : ''
              }
              {
                selectedApplication?.status === APPLICATION_STATUSES['Awaiting Payment']['label'] && showPayment  ?
                  <Button label="Make Payment" onClick={responseSubmit} /> : ''
              }
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}
export default ReviewDetailsClient;
