import {
  APPLICANT_LIST_REQUEST,
  APPLICANT_LIST_SUCCESS,
  APPLICANT_LIST_FAILURE,
  BUSINESS_HEAD_REJECTION_REQUEST,
  BUSINESS_HEAD_REJECTION_SUCCESS,
  BUSINESS_HEAD_REJECTION_FAILURE,
  BUSINESS_HEAD_APPROVAL_REQUEST,
  BUSINESS_HEAD_APPROVAL_SUCCESS,
  BUSINESS_HEAD_APPROVAL_FAILURE,
} from "./BusinessHeadTypes";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status.js";
import config_mock from "./config_mock.json";
import { toast } from "react-hot-toast";
import { APPLICATION_STATUSES } from "../../constants/status.constants";
import { setClarificationLoading, setRejectLoading, setSubmitLoading } from "../App/Action.js";
import requestV1 from "../../utils/requestV1.js";

export const fetchApplicantsList = (businessHeadId) => async (dispatch) => {
  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ "assigned_to": businessHeadId, "active_step": 3 , organisation_id: process.env.REACT_APP_ORGANIZATION_ID }),
  };
  try {
    dispatch(fetchApplicantsListRequest());
    const response = await requestV1(reqUrl, options)
    const { data, status, message } = response
    if (response !== undefined && Object.keys(response).length !== 0) {
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        dispatch(fetchApplicantsListSuccess(data));
        // toast.success(message)
      }
      else {
        dispatch(fetchApplicantsListSuccess([]));;
        dispatch(fetchApplicantsListFailure(data?.message))
        toast.error(message)
      }
    }
  }
  catch (err) {
    dispatch(fetchApplicantsListSuccess(config_mock));
    toast.error("Network Error")
  }
};

export const fetchApplicantsListRequest = () => {
  return {
    type: APPLICANT_LIST_REQUEST,
  };
};

export const fetchApplicantsListSuccess = (data) => {
  return {
    type: APPLICANT_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchApplicantsListFailure = () => {
  return {
    type: APPLICANT_LIST_FAILURE,
  };
};

export const rejectApplication =
  (document, userData, handleSuccess, handleError) =>
    async (dispatch, getState) => {
      const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`;

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(document),
      }

      try {
        dispatch(setRejectLoading(true));

        const response = await requestV1(reqUri, options)
        const { data, status, message } = response
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
          dispatch(setRejectLoading(false));
          toast.success("Application rejected successfully");
          dispatch(rejectApplicationSuccess(data));
          handleSuccess("rejected")
          dispatch(fetchApplicantsList(userData?._id));
        }
        else {
          handleError(data?.message);
          dispatch(rejectApplicationSuccess([]));
          dispatch(setRejectLoading(false));
          dispatch(rejectApplicationFailure(data?.message));
        }
      }
      catch (err) {
        dispatch(setRejectLoading(false));
        handleError("Network Error")
      }
    };
export const rejectApplicationRequest = () => {
  return {
    type: BUSINESS_HEAD_REJECTION_REQUEST,
  };
};

export const rejectApplicationSuccess = (data) => {
  return {
    type: BUSINESS_HEAD_REJECTION_SUCCESS,
    payload: data,
  };
};

export const rejectApplicationFailure = () => {
  return {
    type: BUSINESS_HEAD_REJECTION_FAILURE,
  };
};

//APPROVAL
export const approveApplication =
  (document, logged_User_Id, handleSuccess) => async (dispatch) => {
    const reqUri =
      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/approve-applicant`;
      let currentUser = JSON.parse(localStorage.getItem("userInfo"))
      document?.approval_history?.push({
        status: "Approved",
        approved_at: new Date().toDateString(),
        approved_by: currentUser?.profile?.first_name + " "+ currentUser?.profile?.last_name,
        role: currentUser?.role_name

    })
    let createPayload = {
      ...document,
      applicationId: document._id,
      source_data: { ...document?.source_data },
      active_step: 4,
      status: APPLICATION_STATUSES["Awaiting KYC"]["label"],
      stepHistory: [
        ...document?.stepHistory?.map((item) => {
          if (item?.step === document?.active_step) {
            item.status = "COMPLETED";
            item.completed_date = new Date().toDateString();
            if (item?.step == 3) {
              item.completedBy = logged_User_Id
            }
          }
          return item;
        }),
        ...[
          {
            step: 4,
            status: APPLICATION_STATUSES["Awaiting KYC"]["label"],
            initated_date: new Date().toDateString(),
          },
        ],
      ],
    };
    dispatch(setSubmitLoading(true));
    dispatch(approveApplicationRequest());
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(createPayload),
    }
    try {
      const response = await requestV1(reqUri, options)
      const { data, status, message } = response
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        toast.success("Application approved successfully");
        dispatch(approveApplicationSuccess(data));
        dispatch(setSubmitLoading(false));
        handleSuccess();
      }
      else {
        toast.message(data?.message)
        dispatch(setSubmitLoading(false));
        dispatch(approveApplicationSuccess([]));
      }
    }
    catch (err) {
      toast.error("Network Error")
      dispatch(approveApplicationFailure(err.message));
      dispatch(setSubmitLoading(false));
    }
  };

export const approveApplicationRequest = () => {
  return {
    type: BUSINESS_HEAD_APPROVAL_REQUEST,
  };
};

export const approveApplicationSuccess = (data) => {
  return {
    type: BUSINESS_HEAD_APPROVAL_SUCCESS,
    payload: data,
  };
};

export const approveApplicationFailure = () => {
  return {
    type: BUSINESS_HEAD_APPROVAL_FAILURE,
  };
};

export const clarifyApplication =
  (document, userData, message, handleSuccess) => async (dispatch, getState) => {
    const reqUri =
      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`;
    const payload = {
      clarification: message,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: document?._id,
    };

    const clarificationCommentsHistory = {
      applicationId: document?._id,
      ...document,
      status: APPLICATION_STATUSES["Awaiting Clarification"]["label"],
    };
    clarificationCommentsHistory?.clarification_history?.push(payload);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clarificationCommentsHistory),
    }

    try {
      dispatch(clarifyApplicationRequest());
      dispatch(setClarificationLoading(true))
      const response = await requestV1(reqUri, options)
      const { status, data, message } = response

      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        dispatch(setClarificationLoading(false))
        toast.success("Clarification sent successfully");
        handleSuccess();
        dispatch(clarifyApplicationSuccess(data));
      } else {
        toast.error(data?.message)
        dispatch(setClarificationLoading(false))
        dispatch(clarifyApplicationSuccess([]));
      }
    }
    catch (err) {
      toast.error("Network Error")
      dispatch(setClarificationLoading(false))
      dispatch(clarifyApplicationFailure(err.message));
    }
  };

export const clarifyApplicationRequest = () => {
  return {
    type: BUSINESS_HEAD_REJECTION_REQUEST,
  };
};

export const clarifyApplicationSuccess = (data) => {
  return {
    type: BUSINESS_HEAD_REJECTION_SUCCESS,
    payload: data,
  };
};

export const clarifyApplicationFailure = () => {
  return {
    type: BUSINESS_HEAD_REJECTION_FAILURE,
  };
};
