import Button from "../Button";
import success from "../../../assets/images/success.svg";
import paymentsuccess from "../../../assets/images/payment success.svg";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { PAYMENT_SCREEN } from "../../../constants/route.constants.js/applicant";
const SubmitResponse = ({ text, tq, secondText, btnLabel, btnStatus, payment, responseSubmit }) => {
  let history = useHistory()
  const handleDone = () => {
    responseSubmit()
  };

  return (
    <div className="lds-response">
      <div className="lds-response-success">
        {payment ? <img src={success} /> : <img src={success} />}
      </div>
      <span className={payment ? "lds-response-ptext" : "lds-response-text"}>
        {tq}
      </span>
      {payment && (
        <span className="lds-response-text">Payment successful</span>
      )}
      <p className={payment ? "lds-response-ptext" : "lds-response-text"}>
        {text}
      </p>
      <p className={payment ? "lds-response-ptext" : "lds-response-secondText"}>
        {secondText}
      </p>
      {btnStatus && (
        <Button label={btnLabel ? btnLabel : "Done"} onClick={handleDone} variant={"primary"} />
      )}
    </div>
  );
};
export default SubmitResponse;
