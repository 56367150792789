
import { Modal } from 'react-bootstrap'
import profile from "../../assets/images/dummyProfile3.jpg"
import './index.scss'

const ViewImage=({handleClose,show,label,src})=>{

    return(
        <div>
            <Modal className="my-modal"
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  size="lg"
               >
                 <Modal.Header closeButton/>
                <Modal.Body className="my-body">
                 { src ?
                   <img
                     style={{ width: "100%", height: "60vh", objectFit: "contain" }}
                     src={src}
                  />
                  :
                  null 
                }
                </Modal.Body>
              </Modal>
        </div>
    )
}
export default ViewImage