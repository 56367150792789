import "./index.scss";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#f5f8fa"
  })
}

const Dropdown = ({
  defaultOption,
  label,
  onChange,
  options,
  name,
  value,
  error='',
  ...otherProps
}) => {
  const errCls = error?.length !== 0 ? 'lds-dropdown__select-error' : '';
  const labelHidden = otherProps?.labelHidden ? true : false;
  options = options?.map((item) => {
    return { ...item, value: item?.label }
  })
  const labelCls = labelHidden ? 'lds-dropdown__label--hidden' : '';
  return (
    <div className="lds-dropdown-wrapper">
      <Row>
        <Col lg={12}>
          {label?.length !== 0 && ( 
            <label className={`lds-dropdown__label ${labelCls}`}>{label}</label>
          )}
          
          <Select
            styles={customStyles}
            options={options}
            onChange={(v) => onChange(name, v.label)}
            defaultValue={value}
            className={`lds-dropdown__select ${errCls}`}
          />
          {error?.length !== 0 && (
            <span className="lds-dropdown__error">{error}</span>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Dropdown;
