import { SET_CURRENT_PAGE,SET_CURRENT_TAB } from "./Types";

export const setCurrentPageAction = (pageNum) => {
  return {
    type: SET_CURRENT_PAGE,
    payload: pageNum,
  };
};

export const setCurrentTab=(tab)=>{
  return {
    type:SET_CURRENT_TAB,
    payload:tab
  }
}

