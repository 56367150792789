
import "./index.scss";
import KYCOTPImage from "../../assets/images/otp.svg";
import OtpNumber from "./OtpNumber";
import { aadharGenerateOTP, aadharVerifyOTP, submitApplicantSecondJourney } from '../../redux/AddPartnerDetails/AddPartnerDetailsActions';
import { CLIENT_MAKE_PAYMENT, KYC_ERROR_HANDLE } from "../../constants/route.constants.js/applicant";
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast'
import { useSelector, useDispatch } from "react-redux";
import { APPLICATION_STATUSES } from '../../constants/status.constants';
import { subStringHelper } from '../../utils/subStringHelper'
import requestV1 from "../../utils/requestV1.js";
const KYCOTP = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedData = useSelector(state => state?.addPartnerDetails?.selectedData)
  const partnerData = useSelector(state => state?.addPartnerDetails?.partnerList)
  const selectedApplication = useSelector(state => state?.addPartnerDetails?.selectedApplication)
  const kycFile = useSelector(state => state?.onBoadringForm?.kycFile)

  const handleSubmitApplicantSecondJourneySuccess = () => {
    history.push(CLIENT_MAKE_PAYMENT);
  }

  const handleSuccess = async() => {
    if (selectedData) {
      let formData = new FormData()
      for (let file in kycFile) {
        formData.append(file, kycFile[file])
      }
      delete selectedData?.cheque;
      delete selectedData?.address_proof;
      delete selectedApplication?.is_draft
      const secondJourneyPayload = {
        ...selectedApplication,
        ...selectedData,
        source_data: {
          ...selectedApplication.source_data,
          current_address: selectedData.current_address,
          permanent_address: selectedData.permanent_address,
          aadhaar_number: selectedData ? subStringHelper(selectedData.aadhaar_number, 8, 12) : "",
          name_as_per_aadhar: localStorage.getItem('name_as_per_aadhar_applicant') ? localStorage.getItem('name_as_per_aadhar_applicant') : "",
          gender: localStorage.getItem('gender') ? localStorage.getItem('gender') : "",
        },
        is_draft: false,
        partner_details: partnerData,
        notify_partners: true,
        aadhaar_number: selectedData ? subStringHelper(selectedData.aadhaar_number, 8, 12) : "",
        payment_approved: false,
        status: "Awaiting Payment",
        stepHistory: [...selectedApplication?.stepHistory?.map((item) => {
          if (item?.step === selectedApplication?.active_step) {
            item.status = "COMPLETED";
            item.completed_date = new Date().toDateString()
          }
          return item
        }), ...[{
          step: 5,
          status: APPLICATION_STATUSES['Awaiting Payment Confirmation']['label'],
          initated_date: new Date().toDateString(),
        }]]
      }
      let params = {
        body: JSON.stringify(secondJourneyPayload)
      }
      const encryptedBodyRes = await requestV1('',params,true,true);
      // console.log(encryptedBodyRes)
      formData.append('data', JSON.stringify(encryptedBodyRes))
      // formData.append("data", JSON.stringify(secondJourneyPayload))
      // if(Object.keys(formData).length !==0){ TODO: form data is not getting generated but secondJourneyPayload is generating
        dispatch(submitApplicantSecondJourney(formData, handleSubmitApplicantSecondJourneySuccess, handleError))
    }
  }
  const handleError = (message) => {
    toast.error(message)
    history.push(KYC_ERROR_HANDLE);
  }

  const resendOtp = () => {
    const aadhaar = selectedData.aadhaar_number;
    dispatch(aadharGenerateOTP(aadhaar));
  }

  return (
    <div className="kyc__wrapper">
      <Toaster />
      <div>
        <img src={KYCOTPImage} alt="optimg" />
      </div>
      <div>
        <p className="kyc__header">OTP Verification</p>
        <p className="kyc__sub-header">
          Enter the OTP sent to your phone number registered with Aadhaar
        </p>
        <OtpNumber
          aadharVerifyOTP={aadharVerifyOTP}
          handleSuccess={handleSuccess}
          handleError={handleError}
          resendOtp={resendOtp}
        />
      </div>
    </div>
  );
};

export default KYCOTP;
