import React from "react";
import { useState, useEffect } from "react";
import "./index.scss";
const Timer = ({onTimerEnd=()=>{}}) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        onTimerEnd();
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <span>
      {/* {minutes === 0 && seconds === 0 ? null : ( */}
        <span className="timer-wrapper">
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      {/* )} */}
    </span>
  );
};

export default Timer;
