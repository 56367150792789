export const FETCH_PARTNER_DETAILS_REQUEST = "FETCH_PARTNER_FORM_REQUEST";
export const FETCH_PARTNER_DETAILS_SUCCESS = "FETCH_PARTNER_FORM_SUCCESS";
export const FETCH_PARTNER_DETAILS_FAILURE = "FETCH_PARTNER_FORM_FAILURE";
export const SET_APPLICANT_SECOND_JOURNEY_SELECTED_DATA = "SET_APPLICANT_SECOND_JOURNEY_SELECTED_DATA";
export const STORE_PARTNER_LIST = "STORE_PARTNER_LIST";
export const STORE_AADHAR_CLIENT_ID = "STORE_AADHAR_CLIENT_ID";
export const SAVE_SECOND_JOURNEY_DRAFT_APPLICATIONS = "SAVE_SECOND_JOURNEY_DRAFT_APPLICATIONS"

export const FETCH_APPLICATIONS_REQUEST = "FETCH_APPLICATIONS_REQUEST"
export const FETCH_APPLICATIONS_SUCCESS = "FETCH_APPLICATIONS_SUCCESS"
export const FETCH_APPLICATIONS_FAILURE = "FETCH_APPLICATIONS_FAILURE"

export const SET_APPLICANT_DATA = "SET_APPLICANT_DATA"
export const SET_SELECTED_APPLICATION = "SET_SELECTED_APPLICATION"


export const FETCH_KYC_INVITATION_REQUEST = "FETCH_KYC_INVITATION_REQUEST";
export const FETCH_KYC_INVITATION_SUCCESS = "FETCH_KYC_INVITATION_SUCCESS";
export const FETCH_KYC_INVITATION_FAILURE = "FETCH_KYC_INVITATION_FAILURE";

export const AADHAR_KYC_GENERATE_OTP = "AADHAR_KYC_GENERATE_OTP";
export const FETCH_APPLICATIONS_ENDPOINT = "FETCH_APPLICATIONS_ENDPOINT";
export const GET_APPLICANT_PARTNER_DOCUMENTATION_FORM = "GET_APPLICANT_PARTNER_DOCUMENTATION_FORM";
// KYC Form Fields

export const FETCH_KYC_FORM_REQUEST = "FETCH_KYC_FORM_REQUEST"
export const FETCH_KYC_FORM_SUCCESS = "FETCH_KYC_FORM_SUCCESS"
export const FETCH_KYC_FORM_FAILURE = "FETCH_KYC_FORM_FAILURE"


