import AddListContainer from "./AddListContainer";

const EditPartnerList = ({
  overlayHeader,
  showOverlay,
  handleCloseOverlay,
  editPartnerList,
  id,
  name,
  email,
  mobile,

  first_name,
  last_name,
  mobile_number,
  qualification,
  date_of_birth,
  phlebotomy_experience,
  phleboOnboarding,
  saveUploadedCertificates
}) => {
  const addPartnerItem = (formData) => {

    editPartnerList(formData);
  };
  return (
    <div>
      <AddListContainer
        overlayHeader={overlayHeader}
        showOverlay={showOverlay}
        handleCloseOverlay={handleCloseOverlay}
        addPartnerItem={addPartnerItem}
        id={id}
        name={name}
        email={email}
        mobile={mobile}
        first_name={first_name}
        last_name={last_name}
        mobile_number={mobile_number}
        qualification={qualification}
        date_of_birth={date_of_birth}
        phlebotomy_experience={phlebotomy_experience}
        phleboOnboarding={phleboOnboarding}
        saveUploadedCertificates={saveUploadedCertificates}
      />
    </div>
  );
};
export default EditPartnerList;
