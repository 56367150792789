import "./index.scss";
import Helptext from "../Helptext";
const Textarea = ({
  label,
  handleChange,
  type,
  fieldName,
  value,
  placeholder,
  error,
  helpText,
  custom_action=undefined,
  ...otherProps
}) => {
  const errActive = error && error?.length !== 0;
  const errCls = errActive ? 'lds-textarea-wrapper__error' : '';
  return (
    <div className="lds-textarea-wrapper">
      <label className="lds-textarea-wrapper__label" htmlFor="box">
        {label}
        {custom_action && <span onClick={custom_action?.handlerFunc} className="lds-textarea-wrapper__custom_action" style={custom_action?.style}>{custom_action?.label}</span>}
      </label>
      <textarea
        className={`lds-textarea-wrapper__textarea ${errCls}`}
        rows={4}
        cols={10}
        type={type}
        name={fieldName}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        {...otherProps}
      ></textarea>
    {  helpText && 
      <Helptext text={helpText}></Helptext>}
      {errActive && (
        <label className="lds-form-group--error-label">{error}</label>
      )}
    </div>
  );
};
export default Textarea;
