const getFinalEndpoint = (api) => {
  return process.env.REACT_APP_API_BASE_ENDPOINT + api;
};

const getKycFinalEndpoint = (api) => {
  return process.env.REACT_APP_KYC_ENDPOINT + api;
};

const getOtpFinalEndpoint = (api) => {
  return process.env.REACT_APP_OTP_ENDPOINT + api;
};

const getSendMailKycInvitationEndpoint = (api) => {
  return process.env.REACT_APP_OTP_ENDPOINT + api;
};


export const LOGIN_ENDPOINT = getFinalEndpoint("/swl/client-login");

export const GET_WORKFLOW_CONFIG_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getInitialForm`

export const GET_APPLICANT_PARTNER_DOCUMENTATION_FORM = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/get-second-journey-form`

export const UPLOAD_PAN_ENDPOINT = getFinalEndpoint(
  "/SDBI/pan-data-extraction"
);

// export const UPLOAD_KYC_ENDPOINT = getKycFinalEndpoint(
//   "/digitalkyc/validateUploadedPANDocument"
// );

export const UPLOAD_DOCUMENT_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/verifyuploadedpandocument`


export const OTP_GENERATE_ENDPOINT = getOtpFinalEndpoint(
  "/api/users/generateOTPToMobile"
);

export const OTP_VALIDATE_ENDPOINT = getOtpFinalEndpoint(
  "/api/users/validateMobileOTP"
);

export const UPDATE_WORKFLOW_ENDPOINT = getFinalEndpoint("/sw/updateWorkflow");

export const SUBMIT_ONBOARD_FRANCHISE_USER = getOtpFinalEndpoint(
  "/api/users/onBoardFranchiseUser"
);

//Replace the endpoint with a valid one once the API is ready
export const REJECT_APPLICANT_ENDPOINT = getFinalEndpoint(
  "/digitalkyc/validateUploadedPANDocument"
);
export const PINCODE_ALLOCATION_ENDPOINT = getOtpFinalEndpoint(
  "/api/users/assignUserBasedOnPincode"
);

export const VALIDATE_DRIVING_LICENSE_ENDPOINT = getKycFinalEndpoint(
  "/digitalkyc/validateUploadedDrivingLicense"
);

export const VALIDATE_VOTERID_ENDPOINT = getKycFinalEndpoint(
  "/digitalkyc/validateUploadedVoterID"
);

export const VALIDATE_PASSPORT_ENDPOINT = getKycFinalEndpoint(
  "/digitalkyc/validateUploadedPassport"
);

export const VALIDATE_AADHAR_ENDPOINT = getKycFinalEndpoint(
  "/digitalkyc/validateUploadedAadhar"
);

export const BUSINESSHEAD_ENDPOINT = getFinalEndpoint(
  "/api/SD_APPLICANT/get-new-applications?active_step=3"
);

export const BUSINESSHEAD_REJECT_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/reject-applicant`

export const BUSINESSHEAD_CLARIFY_ENDPOINT = getFinalEndpoint(
  "/api/retailWorkflow/clarification"
);
export const BUSINESSHEAD_APPROVE_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/approve-applicant`

export const FETCH_PIN_CODE_ENDPOINT = getOtpFinalEndpoint(
  "/api/users/assignUserBasedOnPincode"
)

export const CLUSTER_HEAD_SUBMIT_PAYLOAD = getOtpFinalEndpoint(
  '/api/SD_APPLICANT/update-application'
)

//Same API for generate and verify
export const AADHAR_KYC_GENERATE_OTP = getKycFinalEndpoint(
  "/digitalkyc/validateAadharNumber"
)

export const FETCH_APPLICATIONS_ENDPOINT = getFinalEndpoint(
  '/api/SD_APPLICANT/get-all-applications'
)
export const FETCH_KYC_INVITATION_ENDPOINT = getSendMailKycInvitationEndpoint(
  "/retailWorkflow/kycInvitation"
)
export const IT_TEAM_ENDPOINT = getFinalEndpoint(
  "/api/SD_APPLICANT/get-new-applications?active_step=3" //change step accordingly
);

export const IT_TEAM_REJECT_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/reject-applicant`

export const IT_TEAM_APPROVE_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/approve-applicant`

export const CHANNEL_TEAM_ENDPOINT = getFinalEndpoint(
  "/api/SD_APPLICANT/get-new-applications?active_step=5" //change step accordingly
);

export const CHANNEL_TEAM_REJECT_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/reject-applicant`

export const CHANNEL_TEAM_APPROVE_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/approve-applicant`

export const LEGAL_TEAM_ENDPOINT = getFinalEndpoint(
  "/api/SD_APPLICANT/get-new-applications?active_step=5" //change step accordingly
);

export const LEGAL_TEAM_REJECT_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/reject-applicant`

export const LEGAL_TEAM_APPROVE_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/approve-applicant`

export const GET_PHLEBO_CHECKLIST_ENDPOINT = getOtpFinalEndpoint(
  "/api/retailWorkflow/get-phlebo-checklist"
);

export const FORGOT_PASSWORD_ENDPOINT = getOtpFinalEndpoint(
  "/api/users/forgot-password"
);

export const RESET_PASSWORD_ENDPOINT = getOtpFinalEndpoint(
  "/api/users/resetPassword"
);
export const GET_GEO_LOCATION_ENDPOINT = "https://geolocation-db.com/json/";
