import { combineReducers } from "redux";
import onboardingFromReducer from "./OnboardingForm/OnboardingFormReducer";
import partnerDetailsReducer from "./PartnerFormDetails/PartnerFormDetailsReducer";
import addPartnerDetailsReducer from "./AddPartnerDetails/AddPartnerDetailsReducer";
import signIn from "./Login/LoginReducer";
import clusterHead from "./Dashboard/DashboardReducer";
import businessHead from "../redux/BusinessHead/BusinessHeadReducer";
import itTeam from "../redux/ITTeam/ITTeamReducer";
import partnerKYCReducer from "../redux/PartnerKYC/partnerKYCReducer";

import channelTeam from "../redux/ChannelTeam/ChannelTeamReducer";
import legalTeam from "../redux/LegalTeam/LegalTeamReducer";
import phleboOnboarding from "./PhleboOnboarding/PhleboOnboardingReducer";
import cmoreducer from "./CMO/CMOJourneyReducer";
import uploadedDocument from "./UploadedDocuments/UploadedDocumentsReducer";
import globalReducer from "./App/Reducer";
import pageReducers from "./Pagination/Reducer";
import superAdmin from "./Admin/DashboardReducer";
import { getItemFromLocal, setItemToLocal } from "../utils/storageHelper/localStorage";

const appReducer = combineReducers({
  globalReducer: globalReducer,
  onBoadringForm: onboardingFromReducer,
  partnerDetailsForm: partnerDetailsReducer,
  addPartnerDetails: addPartnerDetailsReducer,
  signIn: signIn,
  clusterHead: clusterHead,
  superAdmin: superAdmin,
  businessHead: businessHead,
  partnerKyc: partnerKYCReducer,
  itTeam: itTeam,
  channelTeam: channelTeam,
  phleboOnboarding: phleboOnboarding,
  cmoJourney: cmoreducer,
  uploadedDocument: uploadedDocument,
  channelTeam: channelTeam,
  legalTeam: legalTeam,
  pageReducers: pageReducers,
});

//To clear the redux on logout
export const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    setItemToLocal("userInfo",undefined);
    setItemToLocal('token',undefined);
    setItemToLocal('userInfoEnc', undefined)
    // console.log("reset", getItemFromLocal('userInfo'))
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
