import { Container, Row, Col } from "react-bootstrap";
import ImageUpload from "../../../../assets/images/image_uploaded.svg";

const DocumentUploaded = ({
  fileName,
  fileSize,
  error,
  removeUploadedFile,
}) => {
  var sizeInMB = fileSize !== null ? (fileSize / (1024 * 1024)).toFixed(2) : "";
  return (
    <section className="document-uploaded__wrapper">
      <Container>
        <Row className="align-items-center">
          <Col xs={1}>
            <div className="document-uploaded__image">
              <img src={ImageUpload} alt="" />
            </div>
          </Col>
          <Col xs={11} md={11}>
            <div className="document-uploaded__content">
              <span className="document-uploaded__filename">{fileName}</span>
              <span className="document-uploaded__filesize">
                {sizeInMB && `${sizeInMB}MB`}
              </span>
              {error?.length !== 0 && (
                <span className="document-uploaded__error">{error}</span>
              )}
              <button
                className="document-uploaded__remove-btn"
                onClick={removeUploadedFile}
              >
                Remove
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DocumentUploaded;
