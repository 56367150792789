import "./index.scss";
import profile from "../../assets/images/dummyProfile3.jpg";

const ModalViewImage = ({ handleClose, show, src, id }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="modal-data" onClick={handleClose}>
      <div
        className={"modal-data-contents"}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={"modal-data-bodys"}>
          {src ? (
            <img src={src} alt="not found" />
          ) : (
            <img src={profile} alt="not found" />
          )}
          <span onClick={handleClose}>&#x2715;</span>
        </div>
      </div>
    </div>
  );
};
export default ModalViewImage;
