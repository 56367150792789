import { Container, Row, Col } from "react-bootstrap";
import profile from "../../../../assets/images/dummyProfile.jpg";
import "./index.scss";
import { Link } from 'react-router-dom';
import  HorizontalLine  from "../HorizontalLine/index.js"

const Clarifications = ({ clarificationComment, selectedData, role, userData }) => {

  const replyToClarification = () => {
    const clarificationEl = document.getElementById("clarification");
    if(clarificationEl){
      clarificationEl.click();
    }
  }
  var clarificaitonComments = [];
  selectedData && selectedData?.map((comments) => {
    var clarificaitonComment = {
      id: 1,
      name: comments.name,
      role: comments.role,
      clarification: comments?.clarification || comments?.message
    }
    clarificaitonComments.push(clarificaitonComment);
  })

  return (
    <section className="clarification-info" id="clarification-section">
      <HorizontalLine />
      <Container fluid={true}>
        {
          clarificaitonComments && clarificaitonComments?.length ?
            <Row>
              <p className="widget-info__title">Clarifications</p>
            </Row> : ''
        }
        {clarificaitonComments.map((item) => (
          <Row>
            <Col lg={12}>
              <div className="clarification-info__profile" style={{ backgroundColor: "#f0f1f1", borderRadius: '10px' }}>
                <div className="clarification-info__profile-name">
                  <span>{item.name}</span>
                  {/* <span style = {{textAlign : 'right'}}>{new Date().toLocaleString()}</span> */}
                  <span>{item.role}</span>
                </div>
                <div className="clarification-info__data" style={{ marginLeft: '12px', backgroundColor: "#f0f1f1", paddingTop: '10px' }}>{item.clarification}</div>
              </div>
            </Col>
            <span className="clarification-info__reply" onClick={replyToClarification}>Reply</span>
          </Row>
        ))}
        {/* <Row>
          <Col lg={12}>
            <div className="clarification-info__profile d-flex">
              <div className="clarification-info__image">
                <img src={profile} />
              </div>
              <div className="clarification-info__profile-name">
                <span>NAME :{userData?.first_name}</span>
                {selectedData && selectedData.map((comments) => {
                  <div style={{ marginLeft: '50px' }}>{comments.clarification}</div>
                })}
                <span>{role}</span>
                 </div>
            </div>
            <div className="clarification-info__data">{clarificationComment}</div>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default Clarifications;
