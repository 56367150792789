import GlobalLogin from "../pages/GlobalLogin";
import ErrorPage from "../pages/ErrorPage";

/*APPLICANT ONBOARDING JOURNEY*/
import FranchiseOnboarding from "../pages/ApplicantOnboarding/FranchiseOnboarding";
import RedirectUser from "../pages/ApplicantOnboarding/RedirectUser";
import ReviewDetailsApplicant from "../pages/ApplicantOnboarding/ReviewDetails";
import SubmitOnboardingForm from "../pages/ApplicantOnboarding/Submit";

/* CLUSTER HEAD JOURNEY */
import DashboardCH from "../pages/ClusterHead/Dashboard";
import PartnerDetailsForm from "../pages/ClusterHead/PartnerDetailsForm/PartnerDetails";
import ReviewDetailsCH from "../pages/ClusterHead/ReviewDetails";
import SubmitClusterHead from "../pages/ClusterHead/Submit";

/* Admin  JOURNEY */
import DashboardAdmin from "../pages/Admin/Dashboard";
import ReviewDetailsAdmin from "../pages/Admin/ReviewDetails";
import DashboardEdit from "../pages/Admin/DashboardEdit";
import dummy from "../pages/Admin/DashboarDummy";

/* BUSINESS HEAD JOURNEY */
import DashboardBH from "../pages/BusinessHead/Dashboard";
import ReviewBH from "../pages/BusinessHead/ReviewDetails";
import SubmitBusinesHead from "../pages/BusinessHead/Submit";

/* APPLICANT SECOND JOURNEY*/
import LoginClient from "../pages/ApplicantBanking/Login";
import DashboardClient from "../pages/ApplicantBanking/Dashboard";
import ReviewDetailsClient from "../pages/ApplicantBanking/ReviewDetails";
import PartnerDocumentation from "../pages/ApplicantBanking/PartnerDocumentation";
import ClientKYC from "../pages/ApplicantBanking/KYC";
import KYCOTP from "../components/KYC/KYCOtp";
import PartnerKYCOTP from "../components/PartnerKYC/KYCOtp";

/* APPLICANT SECOND JOURNEY*/
import PartnerKYCForm from "../pages/PartnersKYC/PartnerKYCForm";
import PartnerKYC from "../pages/PartnersKYC/KYC";

/* CHANNEL TEAM JOURNEY */
import ChannelTeamDashboard from "../pages/ChannelTeam/Dashboard";
import ChannelTeamReview from "../pages/ChannelTeam/ReviewDetails";
import SubmitChannelTeam from "../pages/ChannelTeam/Submit";

/* CHANNEL TEAM JOURNEY */
import LegalTeamDashboard from "../pages/LegalTeam/Dashboard";
import LegalTeamReview from "../pages/LegalTeam/ReviewDetails";
import SubmitLegalTeam from "../pages/LegalTeam/Submit";

/* IT TEAM JOURNEY */
import ItTeamDashboard from "../pages/ITTeam/Dashboard";
import ItTeamReviewDetails from "../pages/ITTeam/ReviewDetails";
import ItTeamSubmit from "../pages/ITTeam/Submit";

/* CMO JOURNEY */
import CMODashboard from "../pages/CMO/Dashboard";
import CMOReviewDetails from "../pages/CMO/ReviewDetails";
import SubmitDetails from "../pages/ApplicantBanking/Submit/Index";
import PaymentScreen from "../pages/PaymentScreen";
// import ItTeamSubmit from "../pages/ITTeam/Submit";

// PHLEBO ONBOARDING
import PhleboOnboarding from "../pages/PhleboOnboard/PhleboOnboarding";
import RequirementChecklist from "../pages/PhleboOnboard/RequirementChecklist";
import SubmitPhlebo from "../pages/PhleboOnboard/Submit";
import ReviewDetailsPhlebo from "../pages/PhleboOnboard/ReviewDetails";
import SubmitCMO from "../pages/CMO/Submit";
import DemoPage from "../pages/Demopage";
import ResetPassword from "../components/ResetPassword";
import ForgotPassword from "../components/ForgotPassword/Index";
import SubmitPartnerKYC from "../pages/PartnersKYC/Submit";
import KYCHandle from "../pages/KYC/index";
import { RoleConstants } from "../constants/app.constants";
import { SUPER_ADMIN_REVIEW } from "../constants/route.constants.js/admin";

const {
  CLUSTER_HEAD,
  BUSINESS_HEAD,
  CHANNEL_TEAM,
  LEGAL_TEAM,
  IT_TEAM,
  CMO,
  CLIENT,
  SUPER_ADMIN,
  RMM
} = RoleConstants;
export default [
  /*APPLICANT ONBOARDING JOURNEY*/
  {
    path: ["/"],
    exact: true,
    private: false,
    component: RedirectUser,
  },
  {
    path: ["/client-onboarding/:id"],
    exact: true,
    private: false,
    component: FranchiseOnboarding,
  },
  {
    path: "/review/client",
    exact: true,
    private: false,
    component: ReviewDetailsApplicant,
  },
  {
    path: "/submit/onboarding",
    exact: true,
    private: false,
    component: SubmitOnboardingForm,
  },
  /* CLUSTER HEAD JOURNEY */
  {
    path: "/dashboard/cluster-head",
    exact: true,
    private: true,
    roles: [CLUSTER_HEAD],
    component: DashboardCH,
  },
  {
    path: "/review/cluster-head",
    exact: true,
    private: true,
    roles: [CLUSTER_HEAD],
    component: ReviewDetailsCH,
    selectorKey: "clusterHead",
    dataKey: "selectedApplicant",
  },
  {
    path: "/partner-details-form",
    exact: true,
    private: true,
    roles: [CLUSTER_HEAD],
    component: PartnerDetailsForm,
    selectorKey: "clusterHead",
    dataKey: "selectedApplicant",
  },
  {
    path: "/review/cluster-head-form",
    exact: true,
    private: true,
    roles: [CLUSTER_HEAD],
    component: ReviewDetailsCH,
    selectorKey: "clusterHead",
    dataKey: "selectedApplicant",
  },
  {
    path: "/submit/cluster-head",
    exact: true,
    private: true,
    roles: [CLUSTER_HEAD],
    component: SubmitClusterHead,
    selectorKey: "clusterHead",
    dataKey: "selectedApplicant",
  },

  /* BUSINESS HEAD JOURNEY */
  {
    path: "/dashboard/business-head",
    exact: true,
    private: true,
    roles: [BUSINESS_HEAD],
    component: DashboardBH,
  },
  {
    path: "/review/business-head",
    exact: true,
    private: true,
    roles: [BUSINESS_HEAD],
    component: ReviewBH,
    reduxCheck: false,
  },
  {
    path: "/submit/business-head",
    exact: true,
    private: true,
    roles: [BUSINESS_HEAD],
    component: SubmitBusinesHead,
    reduxCheck: false,
  },
  /* APPLICANT SECOND JOURNEY*/
  {
    path: "/login/client",
    exact: true,
    private: false,
    component: LoginClient,
  },
  {
    path: "/dashboard/client-basic",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: DashboardClient,
  },
  {
    path: "/review/client-basic",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: ReviewDetailsClient,
    selectorKey: "addPartnerDetails",
    dataKey: "selectedApplication",
  },
  {
    path: "/partner-documentation",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: PartnerDocumentation,
    selectorKey: "addPartnerDetails",
    dataKey: "selectedApplication",
  },
  {
    path: "/client/kyc",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: ClientKYC,
    selectorKey: "addPartnerDetails",
    dataKey: "selectedApplication",
  },
  {
    path: "/client/kyc-otp",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: KYCOTP,
    selectorKey: "addPartnerDetails",
    dataKey: "selectedApplication",
  },
  {
    path: "/submit/second-client",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: SubmitDetails,
    selectorKey: "addPartnerDetails",
    dataKey: "selectedApplication",
  },
  {
    path: "/submit/payment-screen",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: PaymentScreen,
    selectorKey: "addPartnerDetails",
    dataKey: "selectedApplication",
  },
  /* PARTNER KYC JOURNEY*/
  {
    path: "/partner-info/:workflowId/:partnerId",
    exact: true,
    private: false,
    component: PartnerKYCForm,
    reduxCheck: false,
  },
  {
    path: "/partner-kyc",
    exact: true,
    private: false,
    component: PartnerKYC,
    selectorKey: "partnerKyc",
    dataKey: "partnerOtherInfo",
  },
  {
    path: "/partner/kyc-otp",
    exact: true,
    private: false,
    component: PartnerKYCOTP,
    selectorKey: "partnerKyc",
    dataKey: "partnerOtherInfo",
  },
  {
    path: "/submit/partner-kyc",
    exact: true,
    component: SubmitDetails,
    selectorKey: "partnerKyc",
    dataKey: "partnerOtherInfo",
  },
  {
    path: "/partner-kyc/success",
    exact: true,
    component: SubmitPartnerKYC,
    selectorKey: "partnerKyc",
    dataKey: "partnerOtherInfo",
  },
  /* CHANNEL TEAM JOURNEY */
  {
    path: "/dashboard/channel-team",
    exact: true,
    private: true,
    roles: [CHANNEL_TEAM],
    component: ChannelTeamDashboard,
  },
  {
    path: "/review/channel-team",
    exact: true,
    private: true,
    roles: [CHANNEL_TEAM],
    component: ChannelTeamReview,
    selectorKey: "channelTeam",
    dataKey: "selectedApplicantChannel",
  },
  {
    path: "/submit/channel-team",
    exact: true,
    private: true,
    roles: [CHANNEL_TEAM],
    component: SubmitChannelTeam,
    selectorKey: "channelTeam",
    dataKey: "selectedApplicantChannel",
  },
  /* CHANNEL TEAM JOURNEY */
  {
    path: "/dashboard/legal-team",
    exact: true,
    private: true,
    roles: [LEGAL_TEAM],
    component: LegalTeamDashboard,
  },
  {
    path: "/review/legal-team",
    exact: true,
    private: true,
    roles: [LEGAL_TEAM],
    component: LegalTeamReview,
    selectorKey: "legalTeam",
    dataKey: "selectedApplicantLegal",
  },
  {
    path: "/submit/legal-team",
    exact: true,
    private: true,
    roles: [LEGAL_TEAM],
    component: SubmitLegalTeam,
    selectorKey: "legalTeam",
    dataKey: "selectedApplicantLegal",
  },
  /* IT TEAM JOURNEY */
  {
    path: "/dashboard/it-team",
    exact: true,
    private: true,
    roles: [IT_TEAM],
    component: ItTeamDashboard,
  },
  {
    path: "/review/it-team",
    exact: true,
    private: true,
    roles: [IT_TEAM],
    component: ItTeamReviewDetails,
    reduxCheck: false,
  },
  {
    path: "/submit/it-team",
    exact: true,
    private: true,
    roles: [IT_TEAM],
    component: ItTeamSubmit,
    reduxCheck: false,
  },
  /* CMO JOURNEY */
  {
    path: "/dashboard/cmo",
    exact: true,
    private: true,
    roles: [CMO],
    component: CMODashboard,
  },
  {
    path: "/review/cmo",
    exact: true,
    private: true,
    roles: [CMO],
    component: CMOReviewDetails,
    selectorKey: "cmoJourney",
    dataKey: "selectedApplicantCMO",
  },
  {
    path: "/submit/cmo",
    exact: true,
    private: true,
    roles: [CMO],
    component: SubmitCMO,
    selectorKey: "cmoJourney",
    dataKey: "selectedApplicantCMO",
  },
  //Phlebo onboarding
  {
    path: "/phlebo/onboarding",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: PhleboOnboarding,
    selectorKey: "phleboOnboarding",
    dataKey: "selectedPhleboApplication",
  },
  {
    path: "/phlebo/checklist",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: RequirementChecklist,
    selectorKey: "phleboOnboarding",
    dataKey: "selectedPhleboApplication",
  },
  {
    path: "/phlebo/review",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: ReviewDetailsPhlebo,
    selectorKey: "phleboOnboarding",
    dataKey: "selectedPhleboApplication",
  },
  {
    path: "/phlebo/submit",
    exact: true,
    private: true,
    roles: [CLIENT],
    component: SubmitPhlebo,
    selectorKey: "phleboOnboarding",
    dataKey: "selectedPhleboApplication",
  },
  /* Admin HEAD JOURNEY */
  {
    path: "/dashboard/super-admin-edit",
    exact: true,
    private: true,
    roles: [SUPER_ADMIN],
    component: DashboardEdit,
  },
  {
    path: "/dashboard/super-admin",
    exact: true,
    private: true,
    roles: [SUPER_ADMIN],
    component: DashboardAdmin,
  },
  {
    path: "/dashboard/rmm",
    exact: true,
    private: true,
    roles: [RMM],
    component: DashboardEdit,
  },
 
  {
    path: "/review/rmm",
    exact: true,
    private: true,
    roles: [RMM],
    component: ReviewDetailsAdmin,
    selectorKey: "superAdmin",
    dataKey: "selectedApplicant",
  },
  {
    path: "/review/cluster-head-edit",
    exact: true,
    private: true,
    roles: [CLUSTER_HEAD],
    component: ReviewDetailsAdmin,
    selectorKey: "superAdmin",
    dataKey: "selectedApplicant",
  },
  {
    path: "/dashboard/dummy",
    exact: true,
    private: true,
    roles: [SUPER_ADMIN],
    component: dummy,
  },

  {
    path: "/review/super-admin",
    exact: true,
    private: true,
    roles: [SUPER_ADMIN],
    component: ReviewDetailsAdmin,
    selectorKey: "superAdmin",
    dataKey: "selectedApplicant",
  },
  {
    path: "/reset-password/:userId/:token",
    exact: true,
    private: false,
    component: ResetPassword,
  },
  {
    path: "/forgot-password",
    exact: true,
    private: false,
    component: ForgotPassword,
  },
  {
    path: "/login",
    exact: true,
    private: false,
    component: GlobalLogin,
  },
  {
    path: "/KYC-server-error",
    exact: true,
    private: false,
    component: KYCHandle,
  },
  {
    path: "/*",
    exact: true,
    private: false,
    component: ErrorPage,
  },
];
