import { Container, Row, Col } from "react-bootstrap";
import { getBooleanImg } from "../../../../utils/booleanImgHelper";
import "./index.scss";
import HorizontalLine from "../HorizontalLine/index.js";
import editIcon from "../../../../assets/images/Edit_icon.svg";
import { useState } from "react";
import InputModal from "../../../../lib/components/InputModal";
import { SUCCESS_RESPONSE_LIST } from "../../../../constants/status";
import requestV1 from "../../../../utils/requestV1";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

const basicInfoHeaders = {
  header: "Center Information",
  columnOne: {
    titleOne: "Centre location",
    titleTwo: "Washroom Available",
  },
  columnTwo: {
    titleOne: "Rented Place",
    titleTwo: "Parking Available",
  },
  columnThree: {
    titleOne: "Floor",
    titleTwo: "Lift Available",
  },
  subText: "Center Location",
};

const shop_finalized_yes_fields = [
  {
    label: "Center Name",
    dataIndex: "store_name",
  },
  {
    label: "Center Location Finalized",
    dataIndex: "shop_finalized",
  },
  {
    label: "Capacity",
    dataIndex: "capacity",
  },
  {
    label: "Floor",
    dataIndex: "floor",
  },
  {
    label: "Lift Available",
    dataIndex: "lift_available",
    isBool: true,
  },
  {
    label: "Monthly Rental",
    dataIndex: "monthly_rental",
  },
  {
    label: "Parking Available",
    dataIndex: "parking_available",
    isBool: true,
  },
  {
    label: "Place Rented",
    dataIndex: "place_rented",
    isBool: true,
  },
  {
    label: "Size in square feet",
    dataIndex: "size_in_sqft",
  },
  {
    label: "Washroom Available",
    dataIndex: "washroom_available",
    isBool: true,
  },
  {
    label: "Centre Location",
    dataIndex: "complete_address",
  },
  {
    label: "Centre Pin Code",
    dataIndex: "pin_code",
  },
];

const shop_finalized_no_fields = [
  {
    label: "Center Name",
    dataIndex: "store_name",
  },
  {
    label: "Center Location Finalized",
    dataIndex: "shop_finalized",
  },
  {
    label: "Pin Code",
    dataIndex: "pin_code",
  },
  {
    label: "City",
    dataIndex: "district",
  },
  {
    label: "Area Locality",
    dataIndex: "area_locality",
  },
];

const CenterInformation = ({ selectedData, id }) => {
  const { header } = basicInfoHeaders;
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalDetails, setEditModalDetails] = useState({});
  const currentActiveStep = useSelector((state)=>state?.clusterHead?.selectedApplicant?.active_step)

  let adminView = false;
  const url = window.location.href;
  if (url.includes("super-admin"))
    adminView = true;

  const handleEdit = (fieldName,field_id, fieldValue ) => {
    // console.log(fieldName);
    // console.log("field name", fieldName, field_id)
    setShowEditModal(true);
    setEditModalDetails({
      title: fieldName,
      inputText: fieldValue,
      field: field_id
    });
  };

  const updateSelectedData = (title, field, inputText ) => {
  //  console.log("values in data", title, field, inputText)  
    setEditModalDetails({
      title: title,
      inputText: inputText,
      field: field
    });
  };
  const handleEditSubmit = async(e,field, input_data) => {
    // console.log(input_data, field)
    e.preventDefault();
    const reqUri =
    `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateApplicantInfo`;
    // console.log()
    let _id = ""
    if(id)
    {
      _id = id
    }
// console.log(id)
const payload = {
  _id: _id,
    field: editModalDetails?.field
};
payload[editModalDetails.field] = editModalDetails?.inputText

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  
      try {
       
        const response = await requestV1(reqUri, options)
        const { status, data, message } = response
        // console.log(response)
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
          selectedData[editModalDetails.field] = editModalDetails?.inputText
          // console.log(selectedData)
          toast.success("Data updated successfully");
        
        } else {
          toast.error(data?.message)
         
        }
      }
      catch (err) {
        toast.error("Network Error")
       
      }
    setShowEditModal(false);
  };
  // console.log(selectedData);
  const showShopFinalizedYesFields = () => {   
    return shop_finalized_yes_fields.map((item) => {
      if (selectedData[item?.dataIndex]) {
        return (
          <Col lg={4}>
            <div>
              {item.label === "Centre Location" || item.label === "Center Name" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                  }}
                >
                  <p className="widget-info__sub-title">{item.label}</p>
                  {adminView && currentActiveStep < 6 &&
                  <img
                    className="ml-2"
                    src={editIcon}
                    alt="edit"
                    style={{ cursor: "pointer", width: "14px" }}
                    onClick={() =>
                      handleEdit(
                        item.label === "Centre Location" ? "Centre Location" :item.label === "Center Name" ? "Center Name" : "",
                        item.label === "Centre Location" ? "complete_address" :item.label === "Center Name" ? "store_name" : "",
                        selectedData[item?.dataIndex]
                      )
                    }
                  />
      }
                </div>
              ) : (
                <p className="widget-info__sub-title">{item.label}</p>
              )} 
              <p className="widget-info__sub-text">
                {item?.isBool
                  ? getBooleanImg(selectedData[item?.dataIndex])
                  : selectedData[item?.dataIndex]
                  ? selectedData[item?.dataIndex]
                  : "-"}
              </p>
            </div>
          </Col>
        );
      } else return;
    });
  };

  const showShopFinalizedNoFields = () => {
    return shop_finalized_no_fields.map((item, i) => {
      return (
        <Col lg={4} key={i}>
          <div>
            <p className="widget-info__sub-title">{item.label}</p>
            <p className="widget-info__sub-text">
              {item?.isBool
                ? getBooleanImg(selectedData[item?.dataIndex])
                : selectedData[item?.dataIndex]
                ? selectedData[item?.dataIndex]
                : "-"}
            </p>
          </div>
        </Col>
      );
    });
  };

  return (
    <section className="widget-info" id="center-info">
      <div className="widget-info__container">
        <HorizontalLine />
        <p
          className="widget-info__title"
          style={{ color: "#$color-secondary-black" }}
        >
          {header}
        </p>
        <Row>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {selectedData?.shop_finalized?.toLowerCase() === "yes"
              ? showShopFinalizedYesFields()
              : showShopFinalizedNoFields()}
          </div>
        </Row>
      </div>
      {showEditModal && (
     <InputModal
     showModal={showEditModal}
     hideModal={() => setShowEditModal(false)}
     title={editModalDetails?.title}
     inputText={editModalDetails?.inputText}
     setEditModalDetails={updateSelectedData}
     field = {editModalDetails?.field}
     handleSubmit={(e)=>handleEditSubmit(e)}
     buttonSubmitType={"primary"}
     buttonSubmitLabel={"Submit"}
     buttonCloseType="outline"
     buttonCloseLabel="Cancel"
   />
      )}
    </section>
  );
};

export default CenterInformation;
