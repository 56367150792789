import { Container, Row, Col, Table } from "react-bootstrap";
import profile from "../../../../assets/images/dummyProfile.jpg";
import "./index.scss";
import { Link } from 'react-router-dom';
import  HorizontalLine  from "../HorizontalLine/index.js"

const ActivityTracker = ({  selectedData }) => {
// console.log("activiry here", selectedData)
 
  // var selectedData = [];


  return (
    selectedData?.length>0 &&
      <Container fluid={true}>
        {
          selectedData && selectedData?.length ?
            <Row>
              <p className="widget-info__title">Activity Tracker</p>
            </Row> : ''
        }
        
          <Row>
            <Col lg={12}>
            <div className="table-responsive">
                <table className="table">
                {selectedData.map((item, index) => (
                    <tr key={index}>
                        <td className="valign-middle position-sticky bg-white" style={{minWidth: "180px",left: 0}}>
                            <div className="d-flex align-items-center">
                              
                                <div className="mx-2">
                                    <div className="fs-14px">{item?.approved_by}</div>
                                    <div className="fs-12px text-muted">{item?.role}</div>
                                </div>
                            </div>
                        </td>
                        <td className="valign-middle fs-14px" style={{minWidth: "180px"}}>
                            <div className="d-flex justify-content-center">
                                {item?.status}
                            </div>
                        </td>
                        <td className="valign-middle fs-14px" style={{minWidth: "180px"}}>
                             <div className="d-flex justify-content-end">{item?.approved_at}</div>
                        </td>
                    </tr>
                       ))}
                </table>
            </div> 
            </Col>
          </Row>
     
        {/* <Row>
          <Col lg={12}>
            <div className="clarification-info__profile d-flex">
              <div className="clarification-info__image">
                <img src={profile} />
              </div>
              <div className="clarification-info__profile-name">
                <span>NAME :{userData?.first_name}</span>
                {selectedData && selectedData.map((comments) => {
                  <div style={{ marginLeft: '50px' }}>{comments.clarification}</div>
                })}
                <span>{role}</span>
                 </div>
            </div>
            <div className="clarification-info__data">{clarificationComment}</div>
          </Col>
        </Row> */}
      </Container>
    // </section>
  );
};

export default ActivityTracker;
