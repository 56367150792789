import { createStore, applyMiddleware } from "redux";
import { persistStore } from 'redux-persist'
import thunk from "redux-thunk";
import { createLogger } from "redux-logger"
import rootReducer from '../redux/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension';
import { reduxStoreListener } from "../redux/subscribe/storeSubscribe";
const composeEnhancers = composeWithDevTools({
  features: {
    pause: true, // start/pause recording of dispatched actions
    lock: true, // lock/unlock dispatching actions and side effects    
    persist: true, // persist states on page reloading
    export: true, // export history of actions in a file
    import: 'custom', // import history of actions from a file
    jump: true, // jump back and forth (time travelling)
    skip: true, // skip (cancel) actions
    reorder: true, // drag and drop actions in the history list 
    dispatch: true, // dispatch custom actions or action creators
    test: true // generate tests for the selected actions
  },
  // other options like actionSanitizer, stateSanitizer
})

let middlewares = [thunk];
if(process.env.NODE_ENV === "development"){
  middlewares.push(createLogger())
}
let store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(...middlewares)
))
let persistor = persistStore(store)
store.subscribe(() => {
  reduxStoreListener(store);
})

export default { store, persistor }