import { useHistory } from "react-router";
import { CMO_DASHBOARD } from "../../../constants/route.constants.js/cmo";
import SubmitResponse from "../../../lib/components/SubmitResponse";
// import Loginimage from "../../../assets/images/AdminLogin.svg";
// import { Row, Col, Container } from "react-bootstrap";
import "./index.scss";

const SubmitCMO = () => {
    const history=useHistory() 
    const responseSubmit=()=>{
        history.push(CMO_DASHBOARD)
    }
    
  return (
        <SubmitResponse
            text="Franchise request has been approved successfully"
            btnStatus={true}
            responseSubmit={responseSubmit}
        />
        );
};
export default SubmitCMO;
