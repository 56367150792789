import { Container, Row, Col } from "react-bootstrap";
import "./index.scss";
import HorizontalLine from "../HorizontalLine/index.js"
import { sendReminderToPartner } from "../../../../lib/components/api/api.service";
import { useDispatch, useSelector } from "react-redux";
import Button from '../../../../lib/components/Button'
import { Toaster } from "react-hot-toast";
const OtherPartnerDetails = ({ currentApplicant }) => {
  const dispatch = useDispatch()
  const setReminder = (email, name) => {
    dispatch(sendReminderToPartner(email, name))
  }

  const loading = useSelector((state) => {
    return state?.globalReducer?.submitLoading
  })
  return (
    <section className="other-partner">
      <Toaster />
      <HorizontalLine />
      <Container fluid={true}>
        <p className="other-partner__header">Other partner Details</p>
        <HorizontalLine />
        <div className="other-partner__content">
          {Array.isArray(currentApplicant?.partner_details) && currentApplicant?.partner_details && currentApplicant?.partner_details?.map(item => (
            <Row className="other-partner__row">
              <Col>
                <span className="other-partner__text">
                  {item.name}
                </span>
              </Col>
              <Col>
                <span className="other-partner__text">{item.email}</span>
              </Col>
              <Col>
                <span className="other-partner__text">{item.mobile}</span>
              </Col>
              <Col>
                <span className="other-partner__tag">Awaiting KYC</span>
              </Col>
              <Col>
                <Button className="other-partner__action" loading={loading} onClick={() => { setReminder(item.email, item.name) }} label={"SEND REMINDER"} />
              </Col>
            </Row>
          ))}

        </div>
      </Container>
    </section>
  );
};

export default OtherPartnerDetails;
