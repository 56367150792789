import { Col, Container, Row } from "react-bootstrap";
import "./index.scss";
import profile from "../../../../assets/images/dummyProfile3.jpg";
import DOB from "../../../../assets/images/Date of birth.svg";
import emailImage from "../../../../assets/images/Email.svg";
import phone from "../../../../assets/images/Phone.svg";
import idCard from "../../../../assets/images/ID card.svg";
import address from "../../../../assets/images/Address.svg";
import { toast } from "react-hot-toast";
import { SUCCESS_RESPONSE_LIST } from "../../../../constants/status";
import requestV1 from "../../../../utils/requestV1";

import { useState, useRef, useEffect } from "react";
import uploadImg from "../../../../assets/images/cloud_upload.svg";
import documentIcon from "../../../../assets/images/Document icon.svg";
import editIcon from "../../../../assets/images/Edit_icon.svg";
import ViewImage from "../../../../components/ViewImage";
import ViewPDF from "../../../../components/ViewPDF";
import Image from "../../../../components/Image";
import ModalViewImage from "../../../../components/ModalViewImage";
import { getSimilarity } from "../../../../utils/stringSimilarityHelper";
import { useLocation } from "react-router-dom";
import { CLIENT_REVIEW } from "../../../../constants/route.constants.js/applicant";
import InputModal from "../../../../lib/components/InputModal";
import { forEach } from "underscore";
import { selectedApplicantData } from "../../../../redux/Admin/DashboardActions";
import { useSelector } from "react-redux";
const UserInfoProfilePhoto = ({
  selectedData,
  _id,
  isPartnerData,
  partnerId,
  currentActiveStep,
}) => {
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState("");

  const location = useLocation();
  const handleClose = () => {
    setSrc("");
    setShow(false);
  };
  const handleShow = (src) => {
    setSrc(src);
    setShow(true);
  };

  const cls = location?.pathname === CLIENT_REVIEW ? "" : "lds-clickable-item";
  return (
    <Col lg={2}>
      <div className={`user-info__profile-image ${cls}`}>
        {isPartnerData ? (
          <Image
            onClick={() =>
              handleShow(
                `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/applicant_photo`
              )
            }
            src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/applicant_photo`}
          />
        ) : _id !== undefined ? (
          <Image
            onClick={() =>
              handleShow(
                `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/applicant_photo`
              )
            }
            src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/applicant_photo`}
          />
        ) : (
          <img
            src={
              selectedData?.applicant_photo
                ? selectedData?.applicant_photo
                : profile
            }
          />
        )}
        <span></span>
      </div>
      <ViewImage src={src} handleClose={handleClose} show={show} />
    </Col>
  );
};

const UserInfoDOB = ({ selectedData, currentApplicant, currentActiveStep, _id }) => {
  // const { mobile_number, date_of_birth, email } = currentApplicant?.source_data;

  let adminView = false;


  const url = window.location.href;
  if (url.includes("super-admin"))
  adminView = true;

  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalDetails, setEditModalDetails] = useState({
    title: "",
    field: "",
    inputText: "",
  });
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if(error){
      setErrorMsg('Phone number entered is invalid. kindly check!')
    }
  }, [error])


  const handleEdit = (fieldName, field_id, fieldValue) => {
    // console.log(fieldName);
    // console.log("field name", fieldName, field_id)
    setShowEditModal(true);
    setEditModalDetails({
      title: fieldName,
      inputText: fieldValue,
      field: field_id,
    });
  };

  const updateSelectedData = (title, field, inputText) => {
    //  console.log("values in data", title, field, inputText)
    if(field === 'mobile_number'){
      if(inputText.length === 10){
        setError(false)
      }
    }
    setEditModalDetails({
      title: title,
      inputText: inputText,
      field: field,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateApplicantInfo`;
    // console.log(selectedData)
    const payload = {
      _id: _id,
      field: editModalDetails?.field,
    };
    payload[editModalDetails.field] = editModalDetails?.inputText;
    if(payload?.mobile_number && (payload?.mobile_number.length < 10 || payload?.mobile_number.length > 10)) {
      setError(true)
    } else {
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
  
      try {
        const response = await requestV1(reqUri, options);
        const { status, data, message } = response;
        // console.log(response)
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
          selectedData[editModalDetails.field] = editModalDetails?.inputText;
          // selectedData[field] = input_data
          toast.success("Data updated successfully");
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        toast.error("Network Error");
      }
      setShowEditModal(false);
    }
  };

  // const [adminView , setAdminView] = useState(false);

  return (
    <Col lg={3}>
      <div className="d-flex" style={{ height: "100%", marginTop: "20px" }}>
        <div className="user-info__icon-column"></div>
        <div className="user-info__content-wrapper">
          <div className="user-info__content">
            <img className="user-info__icon-image" src={DOB} />
            <span className="user-info__sub-title">Date of birth</span>
            <p className="user-info__title-text">
              {currentApplicant && currentApplicant?.source_data ? (
                <>{currentApplicant?.source_data?.date_of_birth}</>
              ) : (
                <>{selectedData?.date_of_birth ?? ""}</>
              )}
            </p>
          </div>
          <div className="user-info__content">
            <img className="user-info__icon-image" src={emailImage} />
            <span className="user-info__sub-title">Email</span>
            {adminView && currentActiveStep < 6 && (
              <img
                className="ml-2"
                src={editIcon}
                alt="edit"
                style={{ cursor: "pointer", width: "14px" }}
                onClick={() =>
                  handleEdit(
                    "Email",
                    "email",
                    selectedData?.email
                  )
                }
              />
            )}
            <p className="user-info__title-text">
              {currentApplicant && currentApplicant?.source_data ? (
                <>{currentApplicant?.source_data?.email}</>
              ) : (
                <>{selectedData?.email ?? ""}</>
              )}
            </p>
          </div>
          <div className="user-info__content">
            <img className="user-info__icon-image" src={phone} />
            <span className="user-info__sub-title">Phone</span>
            {adminView && currentActiveStep < 6 && (
              <img
                className="ml-2"
                src={editIcon}
                alt="edit"
                style={{ cursor: "pointer", width: "14px" }}
                onClick={() =>
                  handleEdit(
                    "Phone",
                    "mobile_number",
                    selectedData?.mobile_number
                  )
                }
              />
            )}
            <p className="user-info__title-text">
              {currentApplicant && currentApplicant?.source_data ? (
                <>{currentApplicant?.source_data?.mobile_number}</>
              ) : (
                <>{selectedData?.mobile_number ?? ""}</>
              )}
            </p>
          </div>
        </div>
      </div>
      {showEditModal && (
        <InputModal
          showModal={showEditModal}
          hideModal={() => setShowEditModal(false)}
          title={editModalDetails?.title}
          inputText={editModalDetails?.inputText}
          setEditModalDetails={updateSelectedData}
          field={editModalDetails?.field}
          errorMsg={error ? errorMsg : ''}
          handleSubmit={(e) => handleEditSubmit(e)}
          buttonSubmitType={"primary"}
          buttonSubmitLabel={"Submit"}
          buttonCloseType="outline"
          buttonCloseLabel="Cancel"
        />
      )}
    </Col>
  );
};
const handleFileChange = async (event, _id, selectedData, docType) => {
  // console.log("type-----", d)
  event.preventDefault();
  let fileObj = event?.target?.files && event?.target?.files[0];
  let formData = new FormData();
  // TODO: Hit API here
  if (!fileObj) {
    return;
  }
  // console.log(selectedData)
  // fileObj["fieldname"] = docType;
  // fileObj.filetype = type
  // console.log(fileObj)
  // for (let file in fileObj) {
  //   // formData.append(file, kycFiles[file])
  // }
  // console.log(selectedApplicantData())
  formData.append("file", fileObj);
  let payload = {
    organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
    workflow_id: _id,
    field: docType,
  };
  let params = {
    body: JSON.stringify(payload),
  };
  // console.log(params)
  const encryptedBodyRes = await requestV1("", params, true, true);

  formData.append("data", JSON.stringify(encryptedBodyRes));
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateDocuments`;
  const options = {
    method: "POST",
    body: formData,
  };

  try {
    const response = await requestV1(reqUri, options, false, true);
    const { status, message } = response;
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      // handleSuccess()
    } else {
      toast.error(message);
      // handleError(message)
    }
    // await axios.post(reqUri, payload)
    //   .then((response) => {
    //     if (SUCCESS_RESPONSE_LIST.includes(response.data.status)) {
    //       handleSuccess()
    //     } else {
    //       handleError(response?.data?.message)
    //     }
    //   })
  } catch (error) {
    // handleError("Network Error")
  }
};
const UserInfoID = ({
  selectedData,
  _id,
  partnerId,
  isPartnerData,
  currentActiveStep,
}) => {
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState("");
  let adminView = false;
  const url = window.location.href;
  if (url.includes("super-admin") || url.includes("cluster-head-edit"))
    adminView = true;
  // const [adminView , setAdminView] = useState(false);
  // if (url.includes('super-admin'))
  //   setAdminView(true)
  const handleClose = () => {
    setSrc("");
    setShow(false);
  };
  const handleShow = (src) => {
    setSrc(src);
    setShow(true);
  };
  const location = useLocation();
  const cls = location?.pathname === CLIENT_REVIEW ? "" : "lds-clickable-item";
  const inputRef = useRef(null);
  const inputRefAadhar = useRef(null);
  const handleClick = (type) => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  const handleClickAadhar = () => {
    // 👇️ open file input box on click of other element
    inputRefAadhar.current.click();
  };

  const handleFileChangeAadhar = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
  };
  return (
    <Col lg={3}>
      <div className="d-flex" style={{ height: "100%", marginTop: "20px" }}>
        <div className="user-info__icon-column"></div>
        <div className="user-info__content-wrapper">
          <div className="user-info__content">
            <img className="user-info__icon-image" src={idCard} />
            <span className="user-info__sub-title">
              PAN{" "}
              <span>
                {selectedData?.pan_number}
                {adminView && (
                  <>
                    <input
                      ref={inputRef}
                      type="file"
                      // onChange={(e) => handleFileChange(e, _id, selectedData, "pan")}
                      accept="img/*"
                      style={{ display: "none" }}
                    ></input>
                    {currentActiveStep < 6 && (
                      <button
                        onClick={handleClick}
                        style={{ borderWidth: "0px", background: "none" }}
                      >
                        <img src={uploadImg} style={{ height: "15px" }} />
                      </button>
                    )}
                  </>
                )}
              </span>
            </span>
            <div className={`user-info__id-image ${cls}`}>
              {/*Pan */}
              {isPartnerData ? (
                <Image
                  onClick={() =>
                    handleShow(
                      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/pan`,
                      ""
                    )
                  }
                  src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/pan`}
                />
              ) : _id !== undefined ? (
                <Image
                  onClick={() =>
                    handleShow(
                      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/pan`,
                      ""
                    )
                  }
                  src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/pan`}
                />
              ) : (
                <img src={selectedData?.pan ? selectedData?.pan : profile} />
              )}
              <ModalViewImage src={src} handleClose={handleClose} show={show} />
            </div>
          </div>
          <div className="user-info__content">
            <img className="user-info__icon-image" src={idCard} />
            <span className="user-info__sub-title">
              {selectedData?.id_proof}
              {/* <input
                ref={inputRefAadhar}
                type="file"
                onChange={handleFileChangeAadhar}
                accept="application/pdf"
                style={{ display: "none" }}
              ></input>
              <button
                onClick={handleClickAadhar}
                style={{ borderWidth: "0px", background: "none" }}
              >
                <img src={uploadImg} style={{ height: "15px" }} />
              </button> */}
            </span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className={`user-info__id-image ${cls}`}>
                {isPartnerData ? (
                  <span>
                    <Image
                      onClick={() =>
                        handleShow(
                          `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/id_proof_front`
                        )
                      }
                      src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/id_proof_front`}
                    />
                  </span>
                ) : _id !== undefined ? (
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className="py-1"
                  >
                    <Image
                      style={{ width: "100px" }}
                      onClick={() =>
                        handleShow(
                          `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/id_proof_front`
                        )
                      }
                      src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/id_proof_front`}
                    />
                    <div>
                      {/* <input
                      ref={inputRefAadhar}
                      type="file"
                      onChange={handleFileChangeAadhar}
                      accept="application/pdf"
                      style={{ display: "none" }}
                    ></input>
                    <button
                      onClick={handleClickAadhar}
                      style={{ borderWidth: "0px", background: "none" }}
                    >
                      <img src={uploadImg} style={{ height: "30px" ,width : "15px" }} />
                      </button> */}
                      {adminView && (
                        <>
                          <input
                            ref={inputRefAadhar}
                            type="file"
                            id="id_proof_front_upload"
                            // onChange={(e) =>
                            //   handleFileChange(e, _id, selectedData, "id_proof_front")
                            // }
                            accept="image/*"
                            style={{ display: "none" }}
                          ></input>
                          {currentActiveStep < 6 && (
                            <button
                              id="id_proof_front"
                              onClick={handleClick}
                              style={{ borderWidth: "0px", background: "none" }}
                            >
                              <img
                                src={uploadImg}
                                style={{ height: "30px", width: "15px" }}
                              />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <img
                      style={{ width: "40px" }}
                      src={
                        selectedData?.id_proof_front
                          ? selectedData?.id_proof_front
                          : profile
                      }
                    />
                    <div>
                      {adminView && (
                        <>
                          {" "}
                          <input
                            ref={inputRefAadhar}
                            type="file"
                            // onChange={(e) =>
                            //   handleFileChange(e, _id, selectedData, "id_proof")
                            // }
                            accept="image/*"
                            style={{ display: "none" }}
                          ></input>
                          {currentActiveStep < 6 && (
                            <button
                              onClick={handleClickAadhar}
                              style={{ borderWidth: "0px", background: "none" }}
                            >
                              <img
                                src={uploadImg}
                                style={{ height: "30px", width: "20px" }}
                              />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {/* <ViewImage
                  src={src}
                  handleClose={handleClose}
                  show={show}
                /> */}
              </div>
              <div className={`user-info__id-image ${cls}`}>
                {/* addar back for both */}
                {isPartnerData ? (
                  <Image
                    onClick={() =>
                      handleShow(
                        `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/id_proof_back`
                      )
                    }
                    src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/id_proof_back`}
                  />
                ) : _id !== undefined ? (
                  <div style={{ display: "flex" }}>
                    <Image
                      style={{ width: "100px" }}
                      onClick={() =>
                        handleShow(
                          `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/id_proof_back`
                        )
                      }
                      src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/id_proof_back`}
                    />
                    <div>
                      {adminView && (
                        <>
                          <input
                            ref={inputRefAadhar}
                            type="file"
                            id="id_proof_back_upload"
                            // onChange={(e) =>
                            //   handleFileChange(e, _id, selectedData, "id_proof_back")
                            // }
                            accept="image/*"
                            style={{ display: "none" }}
                          ></input>
                          {currentActiveStep < 6 && (
                            <button
                              id="id_proof_back"
                              onClick={handleClick}
                              style={{ borderWidth: "0px", background: "none" }}
                            >
                              <img
                                src={uploadImg}
                                style={{ height: "30px", width: "15px" }}
                              />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <img
                      style={{ width: "20px" }}
                      src={
                        selectedData?.id_proof_front
                          ? selectedData?.id_proof_back
                          : profile
                      }
                    />
                    <div>
                      {adminView && (
                        <>
                          <input
                            ref={inputRef}
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                          ></input>
                          {currentActiveStep < 6 && (
                            <button
                              onClick={handleClick}
                              style={{ borderWidth: "0px", background: "none" }}
                            >
                              <img
                                src={uploadImg}
                                style={{ height: "15px", width: "20px" }}
                              />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {/* <ViewImage
                  src={src}
                  handleClose={handleClose}
                  show={show}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

const UserInfoAddress = ({
  selectedData,
  _id,
  partnerId,
  isPartnerData,
  addressData,
  currentActiveStep,
}) => {
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalDetails, setEditModalDetails] = useState({
    title: "",
    field: "",
    inputText: "",
  });

  // const [adminView , setAdminView] = useState(false);
  let adminView = false;
  const url = window.location.href;
  if (url.includes("super-admin") || url.includes("cluster-head-edit"))
    adminView = true;
  // setAdminView(true)

  const handleClose = () => {
    setSrc("");
    setShow(false);
  };
  const handleShow = (src) => {
    if (addressData && addressData[0]?.file_type == ".pdf") {
      //Set embedded url to src variable here
      setSrc(src);
    } else {
      setSrc(src);
    }
    setShow(true);
  };
  const inputRef = useRef(null);
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };
  // const handleFileChange = (event, type) => {
  //   console.log(type)
  //   const fileObj = event?.target?.files && event?.target?.files[0];
  //   if (!fileObj) {
  //     return;
  //   }
  //   console.log(fileObj)
  // };

  const handleEdit = (fieldName, field_id, fieldValue) => {
    // console.log(fieldName);
    // console.log("field name", fieldName, field_id)
    setShowEditModal(true);
    setEditModalDetails({
      title: fieldName,
      inputText: fieldValue,
      field: field_id,
    });
  };

  const updateSelectedData = (title, field, inputText) => {
    //  console.log("values in data", title, field, inputText)
    setEditModalDetails({
      title: title,
      inputText: inputText,
      field: field,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    // console.log(selectedData)
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateApplicantInfo`;
    // console.log(selectedData)
    const payload = {
      _id: _id,
      field: editModalDetails?.field,
    };
    payload[editModalDetails.field] = editModalDetails?.inputText;
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await requestV1(reqUri, options);
      const { status, data, message } = response;
      // console.log(response)
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        selectedData[editModalDetails.field] = editModalDetails?.inputText;

        // selectedData[field] = input_data
        toast.success("Data updated successfully");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      toast.error("Network Error");
    }
    setShowEditModal(false);
  };

  return (
    <Col lg={4}>
      <div className="d-flex" style={{ height: "100%", marginTop: "20px" }}>
        <div className="user-info__icon-column"></div>
        <div className="user-info__content-wrapper">
          <div className="user-info__content">
            <img className="user-info__icon-image" src={idCard} />
            <span className="user-info__sub-title">
              Address Proof
              {adminView && (
                <>
                  <input
                    ref={inputRef}
                    type="file"
                    onChange={(e) =>
                      handleFileChange(e, _id, selectedData, "address_proof")
                    }
                    accept="application/pdf"
                    style={{ display: "none" }}
                  ></input>
                  {currentActiveStep < 6 && (
                    <button
                      onClick={handleClick}
                      style={{ borderWidth: "0px", background: "none" }}
                    >
                      <img src={uploadImg} style={{ height: "15px" }} />
                    </button>
                  )}
                </>
              )}
            </span>
            <div className="user-info__document-image">
              {isPartnerData ? (
                <Image
                  onClick={() =>
                    handleShow(
                      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/partners_docs/${partnerId}/address_proof`
                    )
                  }
                  src={documentIcon}
                />
              ) : (
                <Image
                  onClick={() =>
                    handleShow(
                      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/address_proof`
                    )
                  }
                  src={documentIcon}
                />
              )}
            </div>
          </div>
          <div className="user-info__content">
            <img className="user-info__icon-image" src={address} />
            <span className="user-info__sub-title">Current Address</span>
            {adminView && currentActiveStep < 6 && (
              <img
                className="ml-2"
                src={editIcon}
                alt="edit"
                style={{ cursor: "pointer", width: "14px" }}
                onClick={() =>
                  handleEdit(
                    "Current Address",
                    "current_address",
                    selectedData?.current_address
                  )
                }
              />
            )}
            <p className="user-info__title-text">
              {selectedData?.current_address}
            </p>
          </div>
          <div className="user-info__content">
            <img className="user-info__icon-image" src={address} />
            <span className="user-info__sub-title">Permanent Address</span>
            {adminView && currentActiveStep < 6 && (
              <img
                className="ml-2"
                src={editIcon}
                alt="edit"
                style={{ cursor: "pointer", width: "14px" }}
                onClick={() =>
                  handleEdit(
                    "Permanent Address",
                    "permanent_address",
                    selectedData?.permanent_address
                  )
                }
              />
            )}
            <p className="user-info__title-text">
              {selectedData?.permanent_address}
            </p>
          </div>
        </div>
      </div>
      {addressData && addressData[0]?.file_type == ".pdf" ? (
        <ViewPDF
          src={src}
          handleClose={handleClose}
          show={show}
          fullscreen={true}
        />
      ) : (
        <ViewImage src={src} handleClose={handleClose} show={show} />
      )}
      {showEditModal && (
        <InputModal
          showModal={showEditModal}
          hideModal={() => setShowEditModal(false)}
          title={editModalDetails?.title}
          inputText={editModalDetails?.inputText}
          setEditModalDetails={updateSelectedData}
          field={editModalDetails?.field}
          handleSubmit={(e) => handleEditSubmit(e)}
          buttonSubmitType={"primary"}
          buttonSubmitLabel={"Submit"}
          buttonCloseType="outline"
          buttonCloseLabel="Cancel"
        />
      )}
    </Col>
  );
};

const UserInfo = ({
  selectedData = {},
  currentApplicant,
  _id,
  isPartnerData,
  partnerId,
  kycDocs,
}) => {
  var { name_as_per_aadhar, first_name, last_name } = selectedData;
  const aadharName = name_as_per_aadhar || "";
  const applicantFullName = `${first_name || ""} ${last_name || ""}`;
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalDetails, setEditModalDetails] = useState({
    title: "",
    field: "",
    inputText: "",
  });
  const updateSelectedData = (title, field, inputText) => {
    //  console.log("values in data", title, field, inputText)
    setEditModalDetails({
      title: title,
      inputText: inputText,
      field: field,
    });
  };

  const currentActiveStep = useSelector(
    (state) => state?.clusterHead?.selectedApplicant?.active_step
  );
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    // console.log(editModalDetails)
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateApplicantInfo`;
    // console.log(selectedData)
    // console.log(editModalDetails)

    const payload = {
      _id: _id,
      field: editModalDetails?.field,
    };

    payload[editModalDetails.field] = editModalDetails?.inputText;
    if (editModalDetails?.field == "full_name") {
      let arr = editModalDetails?.inputText.split(" ");
      let firstName = arr[0];
      arr[0] = "";
      let lastName = arr.join(" ");
      payload["first_name"] = firstName;
      first_name = firstName;
      last_name = lastName;
      payload["last_name"] = lastName.trim();
    }
    // console.log(payload)
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await requestV1(reqUri, options);
      const { status, data, message } = response;
      // console.log(response)
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        selectedData[editModalDetails.field] = editModalDetails?.inputText;
        if (editModalDetails?.field == "full_name") {
          selectedData["first_name"] = first_name;
          selectedData["last_name"] = last_name;
        }
        // selectedData[field] = input_data
        toast.success("Data updated successfully");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      toast.error("Network Error");
    }
    setShowEditModal(false);
  };
  const handleEdit = (fieldName, field_id, fieldValue) => {
    // console.log(fieldName);
    // console.log("field name", fieldName, field_id)
    setShowEditModal(true);
    setEditModalDetails({
      title: fieldName,
      inputText: fieldValue,
      field: field_id,
    });
  };
  let adminView = false;
  const url = window.location.href;
  if (url.includes("super-admin") || url.includes("cluster-head-edit"))
    adminView = true;

  const addressData = kycDocs?.filter(function (data) {
    return data?.document_type == "address_proof";
  });
  return (
    <Container fluid={true}>
      <Row>
        <div id="basic-info">
          {adminView && currentActiveStep < 6 && (
            <img
              className="ml-2"
              src={editIcon}
              alt="edit"
              style={{ cursor: "pointer", width: "14px" }}
              onClick={() => handleEdit("Name", "full_name", applicantFullName)}
            />
          )}
          <span className="user-info__name">
            {currentApplicant && currentApplicant?.source_data ? (
              <>
                {currentApplicant?.source_data?.first_name}{" "}
                {currentApplicant?.source_data?.last_name}
              </>
            ) : (
              <>
                {selectedData?.first_name} {selectedData?.last_name}
              </>
            )}
          </span>
          {aadharName?.length !== 0 && applicantFullName?.length !== 0 && (
            <span className="user-info__sub-name">
              <span>{getSimilarity(aadharName, applicantFullName)}</span> &nbsp;
              Name match
            </span>
          )}
        </div>
        {showEditModal && (
          <InputModal
            showModal={showEditModal}
            hideModal={() => setShowEditModal(false)}
            title={editModalDetails?.title}
            inputText={editModalDetails?.inputText}
            setEditModalDetails={updateSelectedData}
            field={editModalDetails?.field}
            handleSubmit={(e) => handleEditSubmit(e)}
            buttonSubmitType={"primary"}
            buttonSubmitLabel={"Submit"}
            buttonCloseType="outline"
            buttonCloseLabel="Cancel"
          />
        )}
      </Row>
      <Row>
        <UserInfoProfilePhoto
          selectedData={selectedData}
          currentApplicant={currentApplicant}
          _id={_id}
          isPartnerData={isPartnerData}
          partnerId={partnerId}
          currentActiveStep={currentActiveStep}
        />
        <UserInfoDOB
          selectedData={selectedData}
          _id={_id}
          currentApplicant={currentApplicant}
          isPartnerData={isPartnerData}
          partnerId={partnerId}
          currentActiveStep={currentActiveStep}
        />
        <UserInfoID
          selectedData={selectedData}
          _id={_id}
          isPartnerData={isPartnerData}
          partnerId={partnerId}
          currentActiveStep={currentActiveStep}
        />
        {selectedData?.current_address ? (
          <UserInfoAddress
            selectedData={selectedData}
            _id={_id}
            isPartnerData={isPartnerData}
            partnerId={partnerId}
            addressData={addressData}
            currentActiveStep={currentActiveStep}
          />
        ) : (
          ""
        )}
      </Row>
    </Container>
  );
};

export default UserInfo;
