import { useHistory,useLocation } from "react-router";
import { BUSINESS_HEAD_DASHBOARD } from "../../../constants/route.constants.js/business_head";
import SubmitResponse from "../../../lib/components/SubmitResponse";

const SubmitBusinesHead = () => {
  const location = useLocation();
  const rejected = location?.state
  const history=useHistory()
  const responseSubmit=()=>{
    history.push(BUSINESS_HEAD_DASHBOARD)
  }
  return (
    <>
    {rejected == "rejected" ?
      <SubmitResponse text="Franchise request has been rejected" btnStatus={true} responseSubmit={responseSubmit} />
     : 
     <SubmitResponse text="Franchise request has been Approved Successfully" btnStatus={true} responseSubmit={responseSubmit} />
    }
  </> );
};

export default SubmitBusinesHead;
