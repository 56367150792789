import { toast } from 'react-hot-toast'
import { getItemFromLocal, setItemToLocal } from "../../utils/storageHelper/localStorage";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_INFO,
  RESET_STORE
} from "./LoginActionTypes";
import { Apis_of_step_two } from '../../utils/Apis/step2.apis'
import { setLoginLoading } from "../App/Action";
import CryptoJS from "crypto-js";


let { auth_user_login } = Apis_of_step_two

export const signIn = (cb, data) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    dispatch(setLoginLoading(true))
    let response_from_server = await auth_user_login(data);
    if (response_from_server.status === "success") {
      dispatch(setLoginLoading(false))
      dispatch(loginSuccess(response_from_server.user))
      cb(response_from_server.user);
    } else {

      dispatch(setLoginLoading(false))
      toast.error("Email Address or Password is incorrect")
    }
  } catch (error) {
    let errors = { error }
    dispatch(setLoginLoading(false))
    toast.error("Email Address or Password is incorrect")
    dispatch(loginFailure(errors))
  }
};

const loginRequest = (user) => {
  return { type: LOGIN_REQUEST, user };
}

export const loginSuccess = (data) => {
  const { token,_id } = data;
  setItemToLocal('token', token);
  setItemToLocal("userId", _id);
  setItemToLocal("userInfo",data);
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
  setItemToLocal("userInfoEnc",ciphertext)
  var ciphertext = getItemFromLocal('userInfoEnc');
  var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
  var userInfoutf = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  // console.log("user i", userInfoutf)
  return { type: LOGIN_SUCCESS, data };
}
const loginFailure = (error) => {
  return { type: LOGIN_FAILURE, error };
}

export const loginInfo = () => {
  // let userInfo = getItemFromLocal('userInfo')
  
  var ciphertext = getItemFromLocal('userInfoEnc');
  var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
  var userInfo = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return {
    type : {},
    data: userInfo
  }
}

export const resetStore = () => {
  return {
    type: RESET_STORE,
  };
}