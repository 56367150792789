import { toast } from 'react-hot-toast';
import { SUCCESS_RESPONSE_LIST } from '../../constants/status'
import { APPLICANT_REJECTED_FAILURE, APPLICANT_REJECTED_SUCCESS, SELECTED_APPLICANT_FROM_DASHBOARD, SAVE_DRAFT_JOBS, CLUSTER_HEAD_CLARIFICATION_REQUEST, CLUSTER_HEAD_CLARIFICATION_SUCCESS, CLUSTER_HEAD_CLARIFICATION_FAILURE } from './ClusterHeadTypes'
import { fetchClusteHeadApplicants } from "../Dashboard/DashboardActions.js";
import { APPLICATION_STATUSES } from "../../constants/status.constants.js";
import { setClarificationLoading, setRejectLoading, setSubmitLoading } from "../App/Action.js";
import requestV1 from "../../utils/requestV1.js";
import { secondJourneyDrafts } from '../AddPartnerDetails/AddPartnerDetailsActions'

export const startRejectApplicant = (document, userData, handleSuccess, handleError) => async (dispatch, getState) => {
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`;
    const user_id = userData?._id
    delete document?.source_data?.id_proof_front
    const options = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(document)
    };
    try {
        dispatch(setRejectLoading(true))
        const response = await requestV1(reqUri, options)
        const { data, message, status } = response
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
            dispatch(setRejectLoading(false))
            changeStatusApplicateSuccess()
            dispatch(fetchClusteHeadApplicants(user_id))
            handleSuccess("rejected")
        }
        else {
            handleError(data.message)
            dispatch(setRejectLoading(false))
        }
    }
    catch (err) {
        dispatch(setRejectLoading(false))
        handleError("Network Error")
        dispatch(changeStatusApplicateFailure(err?.message));
    }
};


export const submitSecondStep = (document, selectedData, kycFiles, userData, errorCB, successCB) => async (dispatch, getState) => {
    let formData = new FormData();
    for (let file in kycFiles) {
        formData.append(file, kycFiles[file])
    }
    let pincode = document?.source_data?.pin_code
    const payload = {
        remark: selectedData?.remarks,
        name: userData?.profile?.first_name,
        role: userData?.role_name,
        _id: document?._id,
    };
    let currentUser = JSON.parse(localStorage.getItem("userInfo"))
    document.approval_history = [{
        status: "Approved",
        approved_at: new Date().toDateString(),
        approved_by: currentUser?.profile?.first_name + " "+ currentUser?.profile?.last_name,
        role: currentUser?.role_name

    }]
    let createPayload = {
        ...document,
        applicationId: document._id,
        source_data: { ...document?.source_data, ...selectedData },
        active_step: 3,
        organisation_id: document.organisation_id,
        is_draft: false,
        stepHistory: [...document?.stepHistory?.map((item) => {
            if (item?.step === document?.active_step) {
                item.status = "COMPLETED";
                item.completed_date = new Date().toDateString()
                if (item?.step === 2) {
                    item.completed_by = userData?._id;
                }
            }
            return item
        }), ...[{
            step: 3,
            status: APPLICATION_STATUSES['Awaiting BH Approval']['label'],
            initated_date: new Date().toDateString(),
        }]],
    }
    createPayload?.reject_remarks?.push(payload);
    delete createPayload?.source_data?.id_proof_front
    delete createPayload?.source_data?.tagging_bdc_excel
    delete createPayload?.source_data?.tagging_bdc_excel_base64
    let params = {
      body: JSON.stringify(createPayload)
    }
    const encryptedBodyRes = await requestV1('', params, true, true);
    formData.append('data', JSON.stringify(encryptedBodyRes))

    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`;
    const options = {
        method: "POST",
        body: formData
    }
    try {
        dispatch(setSubmitLoading(true))
        let response = await requestV1(reqUri, options, false, true);
        const { data, message, status } = response
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
            successCB("Approve")
            dispatch(setSubmitLoading(false))
        }
        else {
            dispatch(setSubmitLoading(false))
            errorCB(data.message)
        }
    }
    catch (error) {
        dispatch(setSubmitLoading(false))
        errorCB("Network Error")
    }
};


export const saveDraft = (payload, handleSuccess, handleError) => async () => {
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    }
    try {
        const response = await requestV1(reqUri, options)
        const { data, message, status } = response

        if (SUCCESS_RESPONSE_LIST.includes(status)) {
            handleSuccess("Application drafted successfully")
        }
        else {
            handleError(data?.message)
        }
    }
    catch (err) {
        toast.error("Network Error")
    }
}

export const getDraftJobs = (user_id, isUser = false, module) => async (dispatch) => {
    const paramsForHeads = {
        assigned_to: user_id,
        is_draft: true, 
        organisation_id: process.env.REACT_APP_ORGANIZATION_ID
    }
    const paramsForUsers = {
        user_id: user_id,
        is_draft: true,
         organisation_id: process.env.REACT_APP_ORGANIZATION_ID
    }
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`

    const options = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: isUser ? JSON.stringify(paramsForUsers) : JSON.stringify(paramsForHeads)
    };

    try {
        const response = await requestV1(reqUri, options)
        const { status, message, data } = response
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
            if (module === "CLUSTER HEAD") {
                // toast.success(message)
                dispatch(saveDraftJobs(data))
            } else if (module === "APPLICANT SECOND JOURNEY") {
                // toast.success(message)
                dispatch(secondJourneyDrafts(data))
            }
        }
    }
    catch (error) {
        toast.error("Network Error")
    }
}

const changeStatusApplicateFailure = (error) => {
    return {
        type: APPLICANT_REJECTED_FAILURE,
        payload: error
    }
}
const changeStatusApplicateSuccess = (data) => {
    return {
        type: APPLICANT_REJECTED_SUCCESS,
        payload: data
    }
}

export const saveDraftJobs = (data) => {
    return {
        type: SAVE_DRAFT_JOBS,
        payload: data
    }
}

export const selectedApplicantData = (data) => {
    return {
        type: SELECTED_APPLICANT_FROM_DASHBOARD,
        payload: data
    }
}

export const clarifyApplication = (document, userData, message, handleSuccess) => async (dispatch, getState) => {

    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`;
    const payload = {
        clarification: message,
        name: userData?.profile?.first_name,
        role: userData?.role_name,
        _id: document?._id,
    };
    const clarificationCommentsHistory = {
        applicationId: document?._id,
        ...document,
        status: APPLICATION_STATUSES['Awaiting Clarification']['label']
    };

    clarificationCommentsHistory?.clarification_history?.push(payload);
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(clarificationCommentsHistory),
    };

    dispatch(setClarificationLoading(true))

    try {
        const response = await requestV1(reqUri, options)
        const { data, status, message } = response
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
            toast.success("Clarification sent successfully")
            dispatch(setClarificationLoading(false))
            handleSuccess()
            dispatch(clarifyApplicationSuccess(data));
            dispatch(fetchClusteHeadApplicants(userData?._id));
        } else {
            toast.error(data?.message)
            dispatch(setClarificationLoading(false))
            dispatch(clarifyApplicationSuccess([]));
        }
    }
    catch (err) {
        dispatch(setClarificationLoading(false))
        dispatch(clarifyApplicationFailure("Network Error"));
        toast.error("Network Error")
    }
};


export const clarifyApplicationRequest = () => {
    return {
        type: CLUSTER_HEAD_CLARIFICATION_REQUEST,
    };
};

export const clarifyApplicationSuccess = (data) => {
    return {
        type: CLUSTER_HEAD_CLARIFICATION_SUCCESS,
        payload: data,
    };
};

export const clarifyApplicationFailure = () => {
    return {
        type: CLUSTER_HEAD_CLARIFICATION_FAILURE,
    };
};
