import {
  APPLICANT_LIST_REQUEST,
  APPLICANT_LIST_SUCCESS,
  APPLICANT_LIST_FAILURE,
  BUSINESS_HEAD_REJECTION_REQUEST,
  BUSINESS_HEAD_REJECTION_SUCCESS,
  BUSINESS_HEAD_REJECTION_FAILURE,
  BUSINESS_HEAD_APPROVAL_REQUEST,
  BUSINESS_HEAD_APPROVAL_SUCCESS,
  BUSINESS_HEAD_APPROVAL_FAILURE,
} from "./BusinessHeadTypes";

const initialState = {
  loading: false,
  applicantList: [],
  businessHeadApproval: {},
  businessHeadRejection: {},
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICANT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case APPLICANT_LIST_SUCCESS: {
      return {
        ...state,
        applicantList: action.payload,
      };
    }
    case APPLICANT_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case BUSINESS_HEAD_REJECTION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BUSINESS_HEAD_REJECTION_SUCCESS: {
      return {
        ...state,
        businessHeadRejection: action.payload,
      };
    }
    case BUSINESS_HEAD_REJECTION_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case BUSINESS_HEAD_APPROVAL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BUSINESS_HEAD_APPROVAL_SUCCESS: {
      return {
        ...state,
        businessHeadApproval: action.payload,
      };
    }
    case BUSINESS_HEAD_APPROVAL_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
