import "./index.scss";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAddPartnerDetailsFormFields } from "../../../redux/AddPartnerDetails/AddPartnerDetailsActions";
import DynamicForm from "../../../components/DynamicForm";
import AddList from "../../../components/AddList";
import Button from "../../../lib/components/Button";
import { CLIENT_DASHBOARD_BASIC, CLIENT_KYC_FORM } from "../../../constants/route.constants.js/applicant";
import { useHistory } from "react-router-dom";
import { setApplicantSecondJourneySelectedData } from '../../../redux/AddPartnerDetails/AddPartnerDetailsActions'
import { APPLICANT_DETAIL_REVIEW } from "../../../constants/route.constants.js/business_head";
import { Toaster, toast } from 'react-hot-toast'
import { saveDraft } from "../../../redux/ClusterHead";
import { validate } from "../../../components/DynamicForm/validate";
import { scrollToTop } from "../../../utils";

const PartnerDocumentation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formFields = useSelector(
    (state) =>
      state?.addPartnerDetails?.addPartnerDetailsFields?.configuration?.step_1
  );
  const selectedData = useSelector(
    (state) => state?.addPartnerDetails?.selectedData
  );
  const selectedApplication = useSelector(
    (state) => state?.addPartnerDetails?.selectedApplication
  );

  const partnerList = useSelector(
    (state) => state?.addPartnerDetails?.partnerList
  );

  const [showAddPartnerButton, setShowAddPartnerButton] = useState(false)
  const [formErr, setFormErr] = useState({});
  const [otherProps, setOtherProps] = useState([]);

  const addPartnerAllowed = (data) => {
    return ["partnership","trust","company","LLP"].includes(data);
  }
  useEffect(() => {
    if (selectedData && addPartnerAllowed(selectedData?.ownership)) {
      setShowAddPartnerButton(true)
    } else {
      setShowAddPartnerButton(false)
    }
  }, [selectedData])

  useEffect(() => {
    // if (selectedData && Object.keys(selectedData)?.length === 0) {
    dispatch(fetchAddPartnerDetailsFormFields());
    // }
    if (selectedApplication && Object.keys(selectedApplication)?.length) {
      setOtherProps([...otherProps, { field: "ownership", value: selectedApplication?.source_data?.ownership }]);
      if (selectedApplication?.source_data?.ownership && addPartnerAllowed(selectedApplication?.source_data?.ownership)) {
        setShowAddPartnerButton(true);
      }
    }
  }, []);

  const handleNext = () => {
    const errors = validate(formFields?.fields, selectedData);
    const errKeys = Object.keys(errors);

    if (showAddPartnerButton) {
      if (errKeys?.length === 0 && partnerList?.length) {
        history.push(CLIENT_KYC_FORM);
        return;
      } else if (!partnerList?.length) {
        toast.error("Please add partner details")
      }
      else {
        setFormErr(errors);
        scrollToTop();
        if (errKeys?.length <= 5) {
          toast.error(`These fields are required:- ${errKeys.map(item => item)}`)
        }
      }
    } else if (!showAddPartnerButton) {
      if (errKeys?.length === 0) {
        history.push(CLIENT_KYC_FORM);
        return;
      } else {
        setFormErr(errors);
        scrollToTop();
        if (errKeys?.length <= 5) {
          toast.error(`These fields are required:- ${errKeys.map(item => item)}`)
        }
      }
    }

  };

  const handleBack = () => {
    history.push(APPLICANT_DETAIL_REVIEW);
  }

  const handleSuccess = (message) => {
    history.push(CLIENT_DASHBOARD_BASIC)
    toast.success(message)
  }

  const handleError = (message) => {
    toast.error(message)
  }
  const handleSaveDraft = () => {
    const payload = {
      ...selectedData,
      ...selectedApplication,
      partner_details: partnerList,
      is_draft: true
    }
    dispatch(saveDraft(payload, handleSuccess, handleError))
  }
  return (
    <div className="partner_documentation--wrapper">
      <Toaster />
      <DynamicForm
        formFields={formFields}
        selectedData={selectedData}
        setSelectedData={setApplicantSecondJourneySelectedData}
        partner_documentation={true}
        handleNextClick={handleNext}
        handleCancelClick={handleBack}
        handleSaveDraft={handleSaveDraft}
        otherProps={otherProps}
      >
        {
          showAddPartnerButton ?
            <>
              <AddList buttonText="ADD PARTNER" showAddPartnerButton={showAddPartnerButton} overlayHeader="Add Partner Details" listData={partnerList} />
            </>
            : ''
        }
      </DynamicForm>
    </div>
  );
};

export default PartnerDocumentation;
