import { useState } from "react";
import Dashboard from "../../Dashboard";
import { useHistory, useLocation } from "react-router";
import { RMM_REVIEW, SUPER_ADMIN_REVIEW } from "../../../constants/route.constants.js/admin";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminApplicants,
  selectedApplicantData,
} from "../../../redux/Admin/DashboardActions";
// import {
//   selectedApplicantData,
//   getDraftJobs,
// } from "../../../redux/ClusterHead/index";
import "./index.scss";
import ListPagination from "../../../components/ListPagination";
import { Toaster, toast } from "react-hot-toast";
import {
  setCurrentPageAction,
  setCurrentTab,
} from "../../../redux/Pagination/actions";
import { loginInfo } from "../../../redux/Login/LoginActions";
import NoResult from "../../../lib/components/NoResult/Index";
import {
  terminateApplication
} from "../../../redux/Admin/DashboardActions";
import { APPLICATION_STATUSES } from "../../../constants/status.constants";
import { RoleConstants } from "../../../constants/app.constants";

const { Terminated } = APPLICATION_STATUSES;
const { SUPER_ADMIN } = RoleConstants;

const DashboardEdit = () => {
  const history = useHistory();
  const applicants = useSelector((state) => state?.superAdmin?.applicantsList);
  const saveDraftApp = useSelector((state) => state?.superAdmin?.saveDraftJobs);
  const storePage = useSelector(
    (state) => state?.pageReducers?.currentPageStore
  );
  const currentTab = useSelector((state) => state?.pageReducers?.tab);

  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage, setApplicantsPerPage] = useState(5);
  const [go, setGo] = useState();

  const [applicationsList, setApplicationsList] = useState();
  const [draftApp, setDraftApp] = useState(false);

  const dispatch = useDispatch();
  const user = dispatch(loginInfo)?.data;

  const showActiveApplicants = () => {
    if (applicants?.length) {
      return (
        applicants &&
        applicants?.filter(
          (item) =>
            item?.status !== "Rejected" && item?.status !== "Franchise Approved"
        )
      );
    }
  };

  const showClosedApplicants = () => {
    if (applicants?.length) {
      return (
        applicants &&
        applicants?.filter(
          (item) =>
            item?.status === "Rejected" || item?.status === "Franchise Approved"
        )
      );
    }
  };

  const showCHApplication = () => {
    if (applicants?.length) {
      return applicants && applicants?.filter((item) => item?.status === "Awaiting CH Approval")
    }
  }

  const showBHApplication = () => {
    if (applicants?.length) {
      return applicants && applicants?.filter((item) => item?.status === "Awaiting BH Approval")
    }
  }

  const showPaymentConfirmation = () => {
    if (applicants?.length) {
      return applicants && applicants?.filter((item) => item?.status === "Awaiting Payment Confirmation")
    }
  }

  const showLegalApplication = () => {
    if (applicants?.length) {
      return applicants && applicants?.filter((item) => item?.status === "Awaiting Legal Approval")
    }
  }

  const showPendingPayment = () => {
    if (applicants?.length) {
      return applicants && applicants?.filter((item) => item?.status === "Awaiting Payment")
    }
  }

  const showAllActive = () => {
    if (applicants?.length) {
      return (
        applicants &&
        applicants?.filter(
          (item) =>
            item?.status !== "Rejected" && item?.status !== "Franchise Approved"
        )
      );
    }
  };

  useEffect(() => {
    console.log("heree")
    dispatch(fetchAdminApplicants());
  }, []);
  useEffect(() => {
    if (applicants?.length && currentTab === "Active") {
      let activeApplication = showActiveApplicants();
      setApplicationsList(activeApplication);
    }

    if (applicants?.length && currentTab === "Closed") {
      let closedApplications = showClosedApplicants();
      setApplicationsList(closedApplications);
    }

    if (applicants?.length && currentTab === "Awaiting CH Approval") {
      let closedApplications = showCHApplication()
      setApplicationsList(closedApplications)
    }

    if (applicants?.length && currentTab === "Awaiting BH Approval") {
      let closedApplications = showBHApplication()
      setApplicationsList(closedApplications)
    }

    if (applicants?.length && currentTab === "Awaiting Legal Approval") {
      let closedApplications = showLegalApplication()
      setApplicationsList(closedApplications)
    }

    if (applicants?.length && currentTab === "Awaiting Payment") {
      let closedApplications = showPendingPayment()
      setApplicationsList(closedApplications)
    }

    if (applicants?.length && currentTab === "Awaiting Payment Confirmation") {
      let closedApplications = showPaymentConfirmation()
      setApplicationsList(closedApplications)
    }

    if (applicants?.length && currentTab === "Active") {
      let activeApplication = showAllActive();
      setApplicationsList(activeApplication);
    }
  }, [applicants]);

  useEffect(() => {
    if (saveDraftApp?.length && currentTab === "Draft") {
      let activeApplication =
        saveDraftApp &&
        saveDraftApp?.filter(
          (item) =>
            item?.status !== "Rejected" && item?.status !== "Franchise Approved"
        );
      setApplicationsList(activeApplication);
    }
  }, [saveDraftApp]);

  const handleAction = (_id) => {
    // console.log("id of selected app", _id);
    const result = applicants.find((items) => {
      return items?._id === _id;
    });
    dispatch(selectedApplicantData(result));
    if(window.location.href.includes("/rmm")){
      history.push(RMM_REVIEW, {_id: _id})
    }else
    history.push(SUPER_ADMIN_REVIEW, { _id: _id });
  };

  const handleGoChange = (e) => {
    const val = e.target.value;
    setGo(val);
    setCurrentPage(Number(val));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPageAction(pageNumber));
    setCurrentPage(pageNumber);
  };

  const handleGetDraftApplication = () => {
    setDraftApp(!draftApp);
    dispatch(setCurrentTab("Draft"));
    // dispatch(getDraftJobs(user?.result?._id, false, 'ADMIN'));
    dispatch(setCurrentPageAction(1));
  };

  const handleActiveApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"));
    const result = showActiveApplicants();
    setApplicationsList(result);
    dispatch(setCurrentPageAction(1));
  };

  const handleClosedApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Closed"));
    let closedApplications = showClosedApplicants();
    setApplicationsList(closedApplications);
    dispatch(setCurrentPageAction(1));
  };

  const handleCHApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting CH Approval"))
    let closedApplications = showCHApplication()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleBHApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting BH Approval"))
    let closedApplications = showBHApplication()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleLegal = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Legal Approval"))
    let closedApplications = showLegalApplication()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handlePayment = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Payment"))
    let closedApplications = showPendingPayment()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handlePaymentConfirmation = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Payment Confirmation"))
    let closedApplications = showPaymentConfirmation()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleActive = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"));
    const result = showAllActive();
    setApplicationsList(result);
    dispatch(setCurrentPageAction(1));
  };

  const handleTerminate = (id) => {
    let terminateParams = {
      _id: id,
      role: SUPER_ADMIN,
      status: Terminated?.label
    }
    dispatch(terminateApplication(terminateParams));
  };

  const indexOfLastApplicant = storePage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = applicationsList?.slice(
    indexOfFirstApplicant,
    indexOfLastApplicant
  );

  const filterFunction = (filterItem) => {
    if (filterItem === "name") {
      return currentApplicants.sort(function (a, b) {
        if (a.source_data?.first_name < b.source_data?.first_name) {
          return -1;
        }
        if (a.source_data?.first_name > b.source_data?.first_name) {
          return 1;
        }
        return 0;
      });
    }
  };

  return (

    <div>
      <Toaster />

      {applicants?.length === 0 || applicants === undefined ? (

        <NoResult text="Please wait...!" showImage={false} />
      ) : (
        <>
                <Dashboard
                  handleAction={handleAction}
                  filterFunction={filterFunction}
                  dashboardData={currentApplicants}
                  handleGetDraftApplication={handleGetDraftApplication}
                  handleActiveApplication={handleActiveApplication}
                  handleClosedApplication={handleClosedApplication}
                  handleCH={handleCHApplication}
                  handleBH={handleBHApplication}
                  handleLegal={handleLegal}
                  handlePayment={handlePayment}
                  handlePaymentConfirmation={handlePaymentConfirmation}
                  handleActive={handleActive}
                  handleTerminate={handleTerminate}
                  draftJobsActive={draftApp}
                  currentTab={currentTab}
                />

              {
              currentApplicants?.length !==0 &&
                <ListPagination
                  applicantsPerPage={applicantsPerPage}
                  setApplicantsPerPage={setApplicantsPerPage}
                  currentPage={storePage ? storePage : currentPage}
                  totalCount={applicationsList?.length !== 0 ? applicationsList?.length : applicants?.length}
                  handleGoChange={handleGoChange}
                  go={go}
                  handlePageChange={handlePageChange}
                />
               }
        </>
      )}
    </div>
  );
};
export default DashboardEdit;
