import { Container, Row, Col } from "react-bootstrap";
import "./index.scss";
import HorizontalLine from "../HorizontalLine/index.js";

const ServiceTiming = ({ selectedData }) => {
  const { weekday_start_time, weekday_end_time, weekend_start_time, weekend_end_time, latitude, longitude } = selectedData
  return (
    <section className="service-info" id="timings-info">
      <HorizontalLine />
      <Container fluid={true}>
        <Row>
          <p className="widget-info__title">
            Service Timings and Location Information
          </p>
        </Row>
        <Row>
          <Col lg={4}>
            <div>
              <p className="widget-info__sub-title">Weekdays</p>
              <p className="widget-info__sub-text">{weekday_start_time} to {weekday_end_time}</p>
            </div>
          </Col>
          <Col lg={4}>
            <div>
              <p className="widget-info__sub-title">Weekend</p>
              <p className="widget-info__sub-text">{weekend_start_time} to {weekend_end_time}</p>
            </div>
          </Col>
        </Row>
        <Row>
        <Col lg={4}>
            <div>
              <p className="widget-info__sub-title">Latitude</p>
              <p className="widget-info__sub-text">{latitude}</p>
            </div>
          </Col>
          <Col lg={4}>
            <div>
              <p className="widget-info__sub-title">Longitude</p>
              <p className="widget-info__sub-text">{longitude}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default ServiceTiming;
