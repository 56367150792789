import { loadState } from "../subscribe/persistReduxStore";
import { SET_CURRENT_PAGE ,SET_CURRENT_TAB} from "./Types";

const { pageReducers : storagePageReducer } = loadState();
const initialState = {
  currentPageStore: storagePageReducer?.currentPageStore || 1,
  tab: storagePageReducer?.tab || "Active"
};

const pageReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPageStore: action.payload,
      };
    case SET_CURRENT_TAB:
        return{
          ...state,
          tab:action.payload
       }
    default: {
      return state;
    }
  }
};
export default pageReducers;
