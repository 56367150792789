import axios from "axios";
import { useEffect, useState } from "react";
import placeholderImg from '../../assets/images/placeholder.jpeg';
import { SUCCESS_RESPONSE_LIST } from "../../constants/status";

const Image = (props) => {
    const { src, onClick, style, className } = props;
    const [ imgSrc, setImgSrc ] = useState(placeholderImg);

    useEffect(()=>{
        async function validateSrc(){
            try {
                /**Using axios as request is not returning required response status for img type */
               const res = await axios.get(src);
               if(SUCCESS_RESPONSE_LIST.includes(res.status)){
                   setImgSrc(src);
               }
            } catch (error) {
            }
        }
        validateSrc();
    },[src]);
    const onImgLoadErr = () => {
        setImgSrc(placeholderImg);
    }
    return (
        <img src={imgSrc} onError={onImgLoadErr} onClick={onClick} style={style} className={className} alt="srciamgs" />
    )
}

export default Image;