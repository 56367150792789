import { Container, Row, Col } from "react-bootstrap";
import pan from "../../../../assets/images/PANDummy.png";
import "./index.scss";
import Image from "../../../../components/Image";
import HorizontalLine from "../HorizontalLine/index.js";
import ModalViewImage from "../../../../components/ModalViewImage";
import { useState } from "react";
import ViewImage from "../../../../components/ViewImage";

const BrandingPhotos = ({ _id }) => {
  const [show, setShow] = useState(false)
  const [src, setSrc] = useState("")
  const [title, setTitle] = useState("")
  const handleShow = (src, title) => {
    setShow(true)
    setSrc(src)
    setTitle(title)
  }
  const handleClose = () => {
    setShow(false)
  }

  return (
    <section className="branding-photos" id="branding-photos">
      <HorizontalLine />
      <Container fluid={true}>
        <Row>
          <p className="widget-info__title">Branding Photos</p>
        </Row>
        <Row>
          <Col lg={3}>
            <p className="widget-info__sub-title">Front Display</p>
            <div className="branding-photos__image">
              <Image onClick={() => handleShow(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/front_display`, "Front Display")} src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/front_display`} />
            </div>
          </Col>
          <Col lg={3}>
            <p className="widget-info__sub-title">Phlebotomy Room</p>
            <div className="branding-photos__image">
              <Image onClick={() => handleShow(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/phlebotomy_room`, "Phlebotomy Room")} src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/phlebotomy_room`} />
            </div>
          </Col>
          <Col lg={3}>
            <p className="widget-info__sub-title">Reception</p>
            <div className="branding-photos__image">
              <Image onClick={() => handleShow(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/reception`, "Reception")} src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/reception`} />
            </div>
          </Col>
          <Col lg={3}>
            <p className="widget-info__sub-title">Waiting Area</p>
            <div className="branding-photos__image">
              <Image onClick={() => handleShow(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/waiting_area`, "Waiting Area")} src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/branding_docs/waiting_area`} />
            </div>
          </Col>
        </Row>
        <ViewImage show={show} src={src} title={title} handleClose={handleClose} />
      </Container>
    </section>
  );
};

export default BrandingPhotos;
