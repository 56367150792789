import {
  FETCH_PIN_CODE_FAILURE,
  FETCH_STEP_TWO_APPLICANTS,
  FETCH_PIN_CODE_SUCCESS,
  ADMIN_SELECTED_DATA,
  DOWNLOAD_WORKFLOW_CSV_SUCCESS,
  SELECTED_APPLICANT_FROM_DASHBOARD,
} from "./DashboardActionTypes";
import { toast } from "react-hot-toast";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status";
import requestV1 from "../../utils/requestV1";

export const fetchAdminApplicants = () => async (dispatch) => {
  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`;
  let param = { active_step: 2, organisation_id: process.env.REACT_APP_ORGANIZATION_ID }
  if(window.location.href.includes('/rmm')){
    param.assigned_to = JSON.parse(localStorage.getItem("userInfo"))._id
  }
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(param),
  };
  try {
    const response = await requestV1(reqUrl, options);
    if (response !== undefined && Object.keys(response).length !== 0) {
      const { status, data, message } = response;
      // console.log("response--------", response);
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        dispatch(fetchStepApplicatsData(data));
      } else {
        toast.error(message);
      }
    }
  } catch (Err) {
    toast.error("Network Error");
  }
};
export const fetchStepApplicatsData = (payload) => {
  return {
    type: FETCH_STEP_TWO_APPLICANTS,
    payload: payload,
  };
};

export const downloadWorkflowCSV = (payload) => async (dispatch) => {
  payload.organisation_id = process.env.REACT_APP_ORGANIZATION_ID

  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/downloadWorkflowReport`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  try {
    localStorage.setItem("resp", JSON.stringify(payload))

    // console.log("form data sending", options);
    const response = await requestV1(reqUrl, options);
    const { status, buffer, data, message } = response;
    if (response.status.includes(status)) {
      // dispatch( downloadWorkflowCSV_Success(buffer.data))
      // console.log(buffer)
      var byteArray = new Uint8Array(buffer.data);
      var blob = new Blob([byteArray], { type: "text/csv" });
      if (navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, "test.csv");
      } else {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.target = "_blank";
        a.download = "test.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      toast.error(message);
    }
  } catch (err) {
    toast.error("No Data found in this date range");
  }
};

export const fetchPin_codeDetails = (body) => async (dispatch) => {
  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/users/assignUserBasedOnPincode`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  try {
    const response = await requestV1(reqUrl, options, false);
    const { status, data, message } = response;
    if (response.status.includes(status)) {
      dispatch(fetchPin_code_success(data));
    } else {
      toast.error(message);
    }
  } catch (err) {
    dispatch(fetchPin_code_failure(err));
    toast.error("Network Error");
  }
};

const fetchPin_code_success = (data) => {
  return {
    type: FETCH_PIN_CODE_SUCCESS,
    payload: data,
  };
};
const fetchPin_code_failure = (error) => {
  return {
    type: FETCH_PIN_CODE_FAILURE,
    payload: error,
  };
};
const downloadWorkflowCSV_Success = (data) => {
  return {
    type: DOWNLOAD_WORKFLOW_CSV_SUCCESS,
    payload: data,
  };
};

export const setAdminSelectedData = (key, value) => {
  return {
    type: ADMIN_SELECTED_DATA,
    key,
    payload: value,
  };
};
export const clearAdminSelectedData = () => {
  return {
    type: "CLEAR_SELECTED_DATA",
  };
};
export const selectedApplicantData = (data) => {
  // console.log("data incoming", data);
  return {
    type: SELECTED_APPLICANT_FROM_DASHBOARD,
    payload: data,
  };
};

//TERMINATE
export const terminateApplication =
  (document, userData, handleSuccess, handleError) =>
    async (dispatch, getState) => {
      const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(document),
      }
      try {
        const response = await requestV1(reqUri, options)
        const { data, status, message } = response
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
          dispatch(fetchAdminApplicants());
          toast.success("Application terminated successfully");
        }
        else {
          handleError("Something went wrong")
        }
      }
      catch (err) {
        handleError("Network Error")
      }
    };