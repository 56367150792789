import { Fragment, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import NoResultImg from "../../assets/images/No_result.svg";
import { useHistory } from "react-router-dom";
import Button from "../../lib/components/Button";
import "./index.scss";

const ErrorPage = ({ text = "404 - Page Not Found", showImage = true }) => {
  const history = useHistory();

  const navigateToLogin = () => {
    let route = "/login";

    history.push(route);
  };
  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <div className="page-not-found">
            {showImage ? (
              <>
                <div className="page-not-found-img">
                  <img src={NoResultImg} className="page-not-found-img-cs" />
                  <div className="page-not-found-placeholder-text">{text}</div>
                  <Button
                    className="page-not-found-btn"
                    label="BACK TO HOME"
                    onClick={navigateToLogin}
                  />
                </div>
              </>
            ) : (
              <div>
                <p className="page-not-found-loader-text">{text}</p>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
