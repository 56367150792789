import Overlay from "../../lib/components/Overlay";
import { useEffect, useState } from "react";
import "./index.scss";
import Input from "../../lib/components/Input";
import DragAndDrop from "../../lib/components/DragAndDrop";
import { Row, Col } from "react-bootstrap";
import { PHLEBO_MODAL_HEADER_CONSTANT } from "../../constants/app.constants";
import toast, { Toaster } from "react-hot-toast";

const AddListContainer = ({
  addPartnerItem,
  formFields,
  showOverlay,
  handleCloseOverlay,
  overlayHeader,
  id: Eid,
  name: Ename,
  mobile: Emobile,
  email: Eemail,
  first_name,
  last_name,
  mobile_number,
  qualification: Equalification,
  date_of_birth,
  phlebotomy_experience,
  phleboOnboarding,
  saveUploadedCertificates,
}) => {
  const [id, setId] = useState(Eid ? Eid : Number(new Date()));
  const [name, setName] = useState(Ename ? Ename : "");
  const [email, setEmail] = useState(Eemail ? Eemail : "");
  const [mobile, setMobile] = useState(Emobile ? Emobile : "");

  const [firstName, setFirstName] = useState(first_name ? first_name : "");
  const [lastName, setLastName] = useState(last_name ? last_name : "");
  const [mobileNumber, setMobileNumber] = useState(mobile_number ? mobile_number : "");
  const [dateOfBirth, setDateOfBirth] = useState(date_of_birth ? date_of_birth : "");
  const [experience, setExperience] = useState(phlebotomy_experience ? phlebotomy_experience : "");
  const [certificate, setCertificate] = useState(Emobile ? Emobile : "");
  const [qualification, setQualification] = useState(Equalification ? Equalification : "");

  const handleChange = (e) => {
    const attr = e.target.name;
    if (attr === "name") {
      setName(e.target.value);
    } else if (attr == "email") {
      setEmail(e.target.value);
    } else {
      setMobile(e.target.value);
    }
  };

  const handlePhleboChange = (e) => {
    const attr = e.target.name;
    if (attr === "firstName") {
      setFirstName(e.target.value);
    } else if (attr === "lastName") {
      setLastName(e.target.value);
    } else if (attr == "mobileNumber") {
      setMobileNumber(e.target.value);
    } else if (attr == "dateOfBirth") {
      setDateOfBirth(e.target.value);
    } else if (attr == "experience") {
      setExperience(e.target.value);
    } else if (attr == "qualification") {
      setQualification(e.target.value);
    }
  }

  const handleUpload = (name, value) => {
    setCertificate(value)
  }

  const handleSubmitOverlay = (e) => {
    e.preventDefault();
    const formData = {
      id: id.toString(),
      name: name,
      mobile: mobile,
      email: email,
    };


    if (phleboOnboarding) {

    const formDataPhlebo = {
      id: id.toString(),
      first_name: firstName,
      last_name: lastName,
      qualification: qualification,
      date_of_birth: dateOfBirth,
      phlebotomy_experience: experience,
      mobile_number: mobileNumber,
    }
      let error = false;
      Object.values(formDataPhlebo).map(item => {
        if (item === "") return error = true
      })
      if (error) {
        toast.error("All fields are required")
      } else if(!certificate && !Eid){
        toast.error("All fields are required");
      }else {
        addPartnerItem(formDataPhlebo);
        handleCloseOverlay();
      }
    } else {
      let error = false
      Object.values(formData).map(item => {
        if (item === "") return error = true
      })
      if (error) {
        toast.error("All fields are required")
      } else {
        addPartnerItem(formData);
        handleCloseOverlay();
      }
    }
    setId(Number(new Date()));
  };

  useEffect(() => {
    if (overlayHeader === PHLEBO_MODAL_HEADER_CONSTANT) {
      const tabHandler = (event) => {
        if (event?.keyCode == 9) {
          event?.preventDefault();
          const currentActive = document?.activeElement;
          let nextElId = currentActive?.id?.split('-')?.[1];
          nextElId = parseInt(nextElId) + 1;
          nextElId = nextElId === 7 ? 1 : nextElId;
          let nextEl = document?.getElementById(`addphlebo-${nextElId}`);
          if (currentActive?.class?.indexOf('modal') === -1 || currentActive?.id.indexOf('addphlebo') !== -1) {
            nextEl?.focus();
          } else {
            nextEl = document.getElementById(`addphlebo-1`);
            nextEl?.focus();
          }
        }
      }

      document.addEventListener('keydown', tabHandler);

      return () => {
        tabHandler();
      };
    }
  }, []);

  return (
    <>
      <Overlay
        showOverlay={showOverlay}
        title={overlayHeader}
        buttonCloseType="outline"
        buttonCloseLabel="Cancel"
        buttonSubmitType=""
        buttonSubmitLabel={Eid ? "Update" : "Add"}
        handleCloseOverlay={handleCloseOverlay}
        handleSubmitOverlay={handleSubmitOverlay}
      >
        <Toaster />
        {
          phleboOnboarding ?
            <>
              <Row>
                <Col lg="6">
                  <Input
                    type="text"
                    value={firstName}
                    onChange={handlePhleboChange}
                    name="firstName"
                    label="Name"
                    placeholder="Enter First name"
                    id="addphlebo-1"
                  />
                  <Input
                    type="text"
                    value={qualification}
                    onChange={handlePhleboChange}
                    name="qualification"
                    label="Qualification"
                    placeholder="Enter Qualification"
                    id="addphlebo-3"
                  />
                </Col>
                <Col lg="6">
                  <Input
                    type="text"
                    value={lastName}
                    onChange={handlePhleboChange}
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter Last Name"
                    id="addphlebo-2"
                  />

                  <Input
                    type="date"
                    value={dateOfBirth}
                    name="dateOfBirth"
                    label="Date of Birth"
                    onChange={handlePhleboChange}
                    id="addphlebo-4"
                  />
                </Col>
                <Col lg="6">
                  <Input
                    type="text"
                    value={experience}
                    name="experience"
                    label="Phlebotomy Experience"
                    onChange={handlePhleboChange}
                    placeholder="Enter Phlebotomy Experience"
                    id="addphlebo-5"
                  />
                </Col>
                <Col lg="6">
                  <Input
                    type="text"
                    value={mobileNumber}
                    name="mobileNumber"
                    label="Mobile Number"
                    onChange={handlePhleboChange}
                    placeholder="Enter Mobile Number"
                    id="addphlebo-6"
                  />
                </Col>
              </Row>

              <DragAndDrop
                label="Upload certificate"
                handleUpload={handleUpload}
                enums ={["*"]}
                name={`phlebo_certificate_${id}`}
                saveUploadedImages={saveUploadedCertificates}
                uploadCertificate={true}
              />
            </>
            :
            <>
              <Input
                type="text"
                value={name}
                onChange={handleChange}
                name="name"
                label="Name"
                placeholder="Enter name"
              />
              <Input
                type="text"
                value={email}
                onChange={handleChange}
                name="email"
                label="Email Address"
                placeholder="Enter Email Address"
              />
              <Input
                type="text"
                value={mobile}
                name="mobile"
                label="Mobile Number"
                onChange={handleChange}
                placeholder="Enter Mobile Number"
              />
            </>
        }
      </Overlay>
    </>
  );
};
export default AddListContainer;
