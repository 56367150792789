import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApplications,
  setSelectedApplication,
} from "../../../redux/AddPartnerDetails/AddPartnerDetailsActions";
import { setSelectedApplicationPhlebo } from "../../../redux/PhleboOnboarding/PhleboOnboardingActions";
import { APPLICANT_DETAIL_REVIEW } from "../../../constants/route.constants.js/business_head";
import Dashboard from "../../Dashboard";

import ListPagination from "../../../components/ListPagination";
import { setCurrentPageAction, setCurrentTab } from "../../../redux/Pagination/actions";
import { getDraftJobs } from "../../../redux/ClusterHead";
import NoResult from "../../../lib/components/NoResult/Index";
import { Toaster } from "react-hot-toast";
import { loginInfo } from "../../../redux/Login/LoginActions";

const DashboardClient = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const applicantData = useSelector((state) => state?.addPartnerDetails?.applications);
  const saveDraftApp = useSelector((state) => state?.addPartnerDetails?.draftApplications);
  const storePage = useSelector((state) => state?.pageReducers?.currentPageStore);
  const currentTab = useSelector((state) => state?.pageReducers?.tab)
  const user = dispatch(loginInfo)?.data;
  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage, setApplicantsPerPage] = useState(5);
  const [filterData, setFilterData] = useState([]);
  const [go, setGo] = useState();
  const [draftApp, setDraftApp] = useState(false);


  useEffect(() => {
    dispatch(fetchApplications());
  }, []);

  const showActiveApplicants = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status !== "Rejected" && item?.status !== "Franchise Approved")
    }
  }

  const showClosedApplicants = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status === "Rejected" || item?.status === "Franchise Approved")
    }
  }

  const showCHApplication = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status === "Awaiting CH Approval")
    }
  }

  const showBHApplication = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status === "Awaiting BH Approval")
    }
  }

  const showPaymentConfirmation = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status === "Awaiting Payment Confirmation")
    }
  }

  const showLegalApplication = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status === "Awaiting Legal Approval")
    }
  }

  const showPendingPayment = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status === "Awaiting Payment")
    }
  }

  const showAllActive = () => {
    if (applicantData?.length) {
      return applicantData && applicantData?.filter((item) => item?.status !== "Rejected" && item?.status !== "Franchise Approved")
    }
  }


  useEffect(() => {
    if (applicantData?.length && currentTab === "Active") {
      let activeApplication = showActiveApplicants()
      setFilterData(activeApplication)
    }

    if (applicantData?.length && currentTab === "Closed") {
      let closedApplications = showClosedApplicants()
      setFilterData(closedApplications)
    }

    if (applicantData?.length && currentTab === "Awaiting CH Approval") {
      let closedApplications = showCHApplication()
      setFilterData(closedApplications)
    }

    if (applicantData?.length && currentTab === "Awaiting BH Approval") {
      let closedApplications = showBHApplication()
      setFilterData(closedApplications)
    }

    if (applicantData?.length && currentTab === "Awaiting Legal Approval") {
      let closedApplications = showLegalApplication()
      setFilterData(closedApplications)
    }

    if (applicantData?.length && currentTab === "Awaiting Payment") {
      let closedApplications = showPendingPayment()
      setFilterData(closedApplications)
    }

    if (applicantData?.length && currentTab === "Awaiting Payment Confirmation") {
      let closedApplications = showPaymentConfirmation()
      setFilterData(closedApplications)
    }
    if (applicantData?.length && currentTab === "Active") {
      let activeApplication = showAllActive()
      setFilterData(activeApplication)
    }
  }, [applicantData]);



  useEffect(() => {
    if (saveDraftApp?.length && currentTab === "Draft") {
      let activeApplication = saveDraftApp && saveDraftApp?.filter((item) => item?.status !== "Rejected" && item?.status !== "Franchise Approved")
      setFilterData(activeApplication)
    }
  }, [saveDraftApp]);

  const handleAction = (_id) => {
    const result = applicantData.find((items) => {
      return items._id === _id;
    });
    if (result?.active_step === 8) {
      dispatch(setSelectedApplicationPhlebo(result));
      history.push("/phlebo/review");
    } else {
      dispatch(setSelectedApplication(result));
      history.push(APPLICANT_DETAIL_REVIEW, {
        applicant_review_data: result,
        _id: _id,
      })
    }
  };

  const handleGoChange = (e) => {
    const val = e.target.value;
    setGo(val);
    setCurrentPage(Number(val));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPageAction(pageNumber));
    setCurrentPage(pageNumber);
  };
  const handleGetDraftApplication = () => {
    setDraftApp(!draftApp);
    dispatch(setCurrentTab("Draft"))
    dispatch(getDraftJobs(user?.result?._id, true, "APPLICANT SECOND JOURNEY"));
    dispatch(setCurrentPageAction(1))
  };

  const handleActiveApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"))
    const result = showActiveApplicants()
    setFilterData(result)
    dispatch(setCurrentPageAction(1))
  }

  const handleClosedApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Closed"))
    let closedApplications = showClosedApplicants()
    setFilterData(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleCHApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting CH Approval"))
    let closedApplications = showCHApplication()
    setFilterData(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleBHApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting BH Approval"))
    let closedApplications = showBHApplication()
    setFilterData(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleLegal = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Legal Approval"))
    let closedApplications = showLegalApplication()
    setFilterData(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handlePayment = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Payment"))
    let closedApplications = showPendingPayment()
    setFilterData(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handlePaymentConfirmation = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Payment Confirmation"))
    let closedApplications = showPaymentConfirmation()
    setFilterData(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleActive = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"))
    const result = showAllActive()
    setFilterData(result)
    dispatch(setCurrentPageAction(1))
  }


  const indexOfLastApplicant = storePage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = filterData.slice(
    indexOfFirstApplicant,
    indexOfLastApplicant
  );


  const filterFunction = (filterItem) => {
    if (filterItem === "name") {
      return currentApplicants.sort(function (a, b) {
        if (a.source_data?.first_name < b.source_data?.first_name) {
          return -1;
        }
        if (a.source_data?.first_name > b.source_data?.first_name) {
          return 1;
        }
        return 0;
      });
    }
  };

  return (
    <div>
      <Toaster />
      {applicantData?.length === 0 || applicantData === undefined ? (
        <NoResult text="Please wait...!" showImage={false} />

      ) : (
        <>
                <Dashboard
                  dashboardData={currentApplicants}
                  handleAction={handleAction}
                  filterFunction={filterFunction}
                  handleGetDraftApplication={handleGetDraftApplication}
                  handleActiveApplication={handleActiveApplication}
                  handleClosedApplication={handleClosedApplication}
                  handleCH={handleCHApplication}
                  handleBH={handleBHApplication}
                  handleLegal={handleLegal}
                  handlePayment={handlePayment}
                  handlePaymentConfirmation={handlePaymentConfirmation}
                  handleActive={handleActive}
                  clusterhead={true}
                  draftJobsActive={draftApp}
                  clientTable={true}
                  currentTab={currentTab}
                />

         { 
         currentApplicants?.length !== 0 &&
                <ListPagination
                  applicantsPerPage={applicantsPerPage}
                  setApplicantsPerPage={setApplicantsPerPage}
                  currentPage={storePage ? storePage : currentPage}
                  totalCount={filterData?.length !== 0 ? filterData?.length : applicantData?.length}
                  handleGoChange={handleGoChange}
                  go={go}
                  handlePageChange={handlePageChange}
                />
           }
        </>
      )}
    </div>
  );
};

export default DashboardClient;
