import { useEffect, useRef, useState } from "react";
import "./index.scss";

const Radio = ({ handleRadio, name, value, checked, optionLabel, error='', index }) => {
  const errorCls = error?.length !== 0 ? 'lds-radio__wrapper--error' : '';
  const radioRef = useRef();
  const handleOptionClick = () => {
    const el = radioRef?.current;
    if(el){
      const { name, value } = el;
      handleRadio(name, value);
    }
  }
  return (
    <div onClick={handleOptionClick} className={`lds-radio__wrapper ${errorCls}`}>
      <div className="lds-radio__pretty">
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          className="lds-radio__radio"
          id={`${name}-${index}`}
          ref={radioRef}
        />
        <span className="lds-radio__text"></span>
        <span className="lds-radio__text__label">{optionLabel}</span>
      </div>
    </div>
  );
};
const RadioCheckbox = ({
  name,
  label,
  handleRadioCheckbox,
  options,
  value,
  error,
  ...otherProps
}) => {
  const [add, setAdd] = useState("");
  const handleRadio = (name, value) => {
    setAdd(value);
    handleRadioCheckbox(name, value);
  };
  useEffect(() => {
    if (value) {
      const optionVal = options?.find((item) => { return item.addType === value });
      setAdd(optionVal?.value);
    }
  }, [value]);
  return (
    <>
      <p className="lds-radio__title">{label}</p>
      <div className="lds-radio__block">
        {options.map((option, index) => {
          return (
            <Radio
              key={option.id}
              optionLabel={option.addType}
              value={option.value}
              checked={add === option.value}
              name={name}
              handleRadio={handleRadio}
              label={label}
              error={error}
              index={index}
            />
          );
        })}
      </div>
      {error?.length !== 0 && (
        <span className="lds-radio__error">{error}</span>
      )}
    </>
  );
};

export default RadioCheckbox;
