import { Container, Row, Col } from "react-bootstrap";
import tickMark from "../../../../assets/images/green tick.svg";
import crossMark from "../../../../assets/images/red cross.svg";
import docImage from "../../../../assets/images/document.svg";
import "./index.scss";
import { getBooleanImg } from "../../../../utils/booleanImgHelper";
import HorizontalLine from "../HorizontalLine/index.js"
import editIcon from "../../../../assets/images/Edit_icon.svg";
import { rupeesWithCommas } from "../../../../Helper";
import { useState } from "react";
import { SUCCESS_RESPONSE_LIST } from "../../../../constants/status";
import requestV1 from "../../../../utils/requestV1";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import InputModal from "../../../../lib/components/InputModal";

const partnerInformationHeaders = {
  header: "Partner Information",
  columnOne: {
    titleOne: "Partner Profiling",
    titleTwo: "Security Deposit Amount",
  },
  columnTwo: {
    titleOne: "Agilus Franchisee",
    titleTwo: "Subscription",
  },
  columnThree: {
    titleOne: "Business Potential",
    titleTwo: "Ownership",
  },
  marketing_deposit: "Marketing Deposit",
  rolling_advance: "Rolling Advance",
  // subText: "Tagging BDC Details",
  downloadBDC: "Uploaded BDC .csv file",
};



const PartnerInformation = ({ selectedData, fullInfo, _id }) => {

  const [show, setShow] = useState(false);
  const [src, setSrc] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalDetails, setEditModalDetails] = useState({
    title: "",
    field: "",
    inputText: "",
  });

  let adminView = false;
  const url = window.location.href;
  if (url.includes("super-admin"))
    adminView = true;
  // setAdminView(true)

  // const handleClose = () => {
  //   setSrc("");
  //   setShow(false);
  // };
  const currentActiveStep = useSelector((state)=>state?.clusterHead?.selectedApplicant?.active_step)
  // console.log(selectedData)
  let docTypes=fullInfo?.kycDocs;
  var isExcel = false;
  var userId = fullInfo?._id;
  var originalFileName = Date.now()+".xlsx";
  for(let i=0;i<docTypes?.length;i++){
    if(docTypes[i].document_type == 'tagging_bdc_excel'){
      originalFileName=docTypes[i].originalname;
      isExcel = true;
      break;
    }
  }
  const downloadDoc =() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/downloadUploadedDoc/${userId}/applicant_docs/tagging_bdc_excel`,{
      method:  'GET'
    }).then(response => response.blob())
    .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = originalFileName || Date.now()+".xlsx";
        document.body.appendChild(a); 
        a.click();    
        a.remove();  
    });
  }

  const handleEdit = (fieldName, field_id, fieldValue) => {
    // console.log(fieldName);
    // console.log("field name", fieldName, field_id)
    setShowEditModal(true);
    setEditModalDetails({
      title: fieldName,
      inputText: fieldValue,
      field: field_id,
    });
  };

  const updateSelectedData = (title, field, inputText) => {
    //  console.log("values in data", title, field, inputText)
    setEditModalDetails({
      title: title,
      inputText: inputText,
      field: field,
    });
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateApplicantInfo`;
    // console.log(selectedData)
    const payload = {
      _id: _id,
      field: editModalDetails?.field,
    };

    payload[editModalDetails.field] = editModalDetails?.inputText;
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await requestV1(reqUri, options);
      const { status, data, message } = response;
      // console.log(response)
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        selectedData[editModalDetails.field] = editModalDetails?.inputText;

        // selectedData[field] = input_data
        toast.success("Data updated successfully");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      toast.error("Network Error");
    }
    setShowEditModal(false);
  };

  const { header, columnOne, columnTwo, columnThree, subText, downloadBDC, marketing_deposit, rolling_advance } =
    partnerInformationHeaders;
  return (
    <section className="widget-info" id="partner-info">
      <HorizontalLine />
      <Container fluid={true}>
        <Row>
          <p className="widget-info__title">{header}</p>
        </Row>
        <Row>
          <Col xs={4}>
            <div>
              <p className="widget-info__sub-title">{columnOne.titleOne}</p>
              <p className="widget-info__sub-text">
                {selectedData?.partner_profiling ?? "-"}
              </p>
            </div>
            <div>
              <p className="widget-info__sub-title">{columnTwo.titleTwo}</p>
              {selectedData?.subscription ?? "-"}
            </div>
            <div className="widget-info__sub-titles">
              <span>{marketing_deposit}</span>
              {adminView && currentActiveStep < 6 &&
                  <img
                    className="ml-2"
                    src={editIcon}
                    alt="edit"
                    style={{ cursor: "pointer", width: "14px" }}
                    onClick={() =>
                      handleEdit(
                        "Marketing Deposit",
                        "marketing_deposit",
                        selectedData.marketing_deposit
                      )
                    }
                  />
          }
            </div>
              {selectedData?.marketing_deposit ? <>&#x20B9;{rupeesWithCommas(selectedData?.marketing_deposit)}</> : "-"}
          </Col>
          <Col xs={4}>
            <div>
              <p className="widget-info__sub-title">{columnTwo.titleOne}</p>
              {selectedData?.srl_franchise ?? "-"}
            </div>
            <div>
              <p className="widget-info__sub-title">{columnThree.titleTwo}</p>
              {selectedData?.ownership ?? "-"}
            </div>
            <div className="widget-info__sub-titles">
              <span >{rolling_advance}</span>
              {adminView && currentActiveStep < 6 &&
                  <img
                    className="ml-2"
                    src={editIcon}
                    alt="edit"
                    style={{ cursor: "pointer", width: "14px" }}
                    onClick={() =>
                      handleEdit(
                        "Rolling Deposit",
                        "rolling_advance",
                        selectedData.rolling_advance
                      )
                    }
                  />
                }
                </div>
              {selectedData?.rolling_advance ? <>&#x20B9;{rupeesWithCommas(selectedData?.rolling_advance)}</> : "-"}
          </Col>
          <Col xs={4}>
            <div>
              <p className="widget-info__sub-title">{columnThree.titleOne}</p>
              <p className="widget-info__sub-text">
                {selectedData?.business_potential ?? "-"}
              </p>
            </div>
            {selectedData?.security_deposit ? <div>
              <p className="widget-info__sub-title">{columnOne.titleTwo}</p>
              {selectedData?.security_deposit ? <>&#x20B9;{rupeesWithCommas(selectedData?.security_deposit)}</> : "-"}
            </div>: ""}  
            {showEditModal && (
        <InputModal
          showModal={showEditModal}
          hideModal={() => setShowEditModal(false)}
          title={editModalDetails?.title}
          inputText={editModalDetails?.inputText}
          setEditModalDetails={updateSelectedData}
          field={editModalDetails?.field}
          handleSubmit={(e) => handleEditSubmit(e)}
          buttonSubmitType={"primary"}
          buttonSubmitLabel={"Submit"}
          buttonCloseType="outline"
          buttonCloseLabel="Cancel"
        />
      )}
          </Col>
        </Row>

        <Row>
          {/* <Col xs={4}>
            <p className="widget-info__sub-title">{subText}</p>
            {selectedData?.tagging_bdc?.map((item) => (
              <span className="widget-info__tags_partner">{item}</span>
            ))}
          </Col> */}
            { isExcel? (<Col xs={12}>
            <p className="widget-info__sub-title">{downloadBDC}</p>
            {/* <p className="widget-info__sub-text"> */}
            {/* {selectedData?.tagging_bdc?.map((item) => ( */}
              <span className="widget-info__file_partner">
                <img src={docImage} onClick={downloadDoc}/>
              </span>
            {/* ))} */}
            {/* </p> */}
          </Col>) : (
            <div></div>
          )}
          
        </Row>
        {/* <Row>
          <Col lg={4}>
            <p className="widget-info__sub-title">Complete Address</p>
            <p className="widget-info__sub-text">
              {selectedData?.complete_address}
            </p>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default PartnerInformation;
