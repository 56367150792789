import { CERTIFICATE_UPLOADED, REMOVE_CERTIFICATE } from "./UploadedDocumentsTypes";

export const saveUploadedCertificates = (key, data) => {
    return {
        type: CERTIFICATE_UPLOADED,
        key,
        payload: data,
    };
}

export const removeCertificate = (key) => {
    return {
        type: REMOVE_CERTIFICATE,
        key
    };
}