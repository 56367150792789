export function Hamberger({ stroke }) {
  return (
    <svg
      id="noun-list-4724848"
      xmlns="http://www.w3.org/2000/svg"
      width="34.522"
      height="20.354"
      viewBox="0 0 34.522 30.354"
    >
      <path
        id="Path_15350"
        data-name="Path 15350"
        d="M160.359,116.479a2.862,2.862,0,1,0-2.861-2.862A2.864,2.864,0,0,0,160.359,116.479Zm0-3.138a.277.277,0,0,1,.278.276c0,.3-.556.3-.556,0A.278.278,0,0,1,160.359,113.341Z"
        transform="translate(-157.498 -110.756)"
        fill={stroke}
      />
      <path
        id="Path_15351"
        data-name="Path 15351"
        d="M268.342,125.16H244.629a1.569,1.569,0,1,0,0,3.138h23.713a1.569,1.569,0,0,0,0-3.138Z"
        transform="translate(-235.389 -123.868)"
        fill={stroke}
      />
      <path
        id="Path_15352"
        data-name="Path 15352"
        d="M160.359,253.817a2.861,2.861,0,1,0-2.861-2.861A2.864,2.864,0,0,0,160.359,253.817Zm0-3.139a.277.277,0,0,1,.278.278c0,.306-.556.306-.556,0A.278.278,0,0,1,160.359,250.679Z"
        transform="translate(-157.498 -235.781)"
        fill={stroke}
      />
      <path
        id="Path_15353"
        data-name="Path 15353"
        d="M268.342,262.5H244.629a1.569,1.569,0,1,0,0,3.138h23.713a1.569,1.569,0,1,0,0-3.138Z"
        transform="translate(-235.389 -248.893)"
        fill={stroke}
      />
      <path
        id="Path_15354"
        data-name="Path 15354"
        d="M160.359,391.159A2.862,2.862,0,1,0,157.5,388.3,2.865,2.865,0,0,0,160.359,391.159Zm0-3.139a.277.277,0,0,1,.278.276c0,.306-.556.306-.556,0A.278.278,0,0,1,160.359,388.021Z"
        transform="translate(-157.498 -360.805)"
        fill={stroke}
      />
      <path
        id="Path_15355"
        data-name="Path 15355"
        d="M269.911,401.409a1.568,1.568,0,0,0-1.569-1.569H244.629a1.569,1.569,0,1,0,0,3.138h23.713A1.569,1.569,0,0,0,269.911,401.409Z"
        transform="translate(-235.389 -373.918)"
        fill={stroke}
      />
    </svg>
  );
}
export function FourSquares({stroke}) {
  return (
    <svg
      id="Component_25_1"
      data-name="Component 25 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="32.588"
      height="20.5"
      viewBox="0 0 32.588 29.5"
    >
      <path
        id="Path_15313"
        data-name="Path 15313"
        d="M95.639,299H87.492A3.339,3.339,0,0,0,84,302.161v7.375a3.339,3.339,0,0,0,3.492,3.161h8.147a3.339,3.339,0,0,0,3.492-3.161v-7.375A3.339,3.339,0,0,0,95.639,299ZM96.8,309.536a1.113,1.113,0,0,1-1.164,1.054H87.492a1.113,1.113,0,0,1-1.164-1.054v-7.375a1.113,1.113,0,0,1,1.164-1.054h8.147a1.113,1.113,0,0,1,1.164,1.054Z"
        transform="translate(-84 -283.196)"
        fill={stroke}
      />
      <path
        id="Path_15314"
        data-name="Path 15314"
        d="M380.639,299h-8.147A3.339,3.339,0,0,0,369,302.161v7.375a3.339,3.339,0,0,0,3.492,3.161h8.147a3.339,3.339,0,0,0,3.492-3.161v-7.375A3.339,3.339,0,0,0,380.639,299Zm1.164,10.536a1.113,1.113,0,0,1-1.164,1.054h-8.147a1.113,1.113,0,0,1-1.164-1.054v-7.375a1.113,1.113,0,0,1,1.164-1.054h8.147a1.113,1.113,0,0,1,1.164,1.054Z"
        transform="translate(-351.543 -283.196)"
        fill={stroke}
      />
      <path
        id="Path_15315"
        data-name="Path 15315"
        d="M95.639,14H87.492A3.339,3.339,0,0,0,84,17.161v7.375A3.339,3.339,0,0,0,87.492,27.7h8.147a3.339,3.339,0,0,0,3.492-3.161V17.161A3.339,3.339,0,0,0,95.639,14ZM96.8,24.536a1.113,1.113,0,0,1-1.164,1.054H87.492a1.113,1.113,0,0,1-1.164-1.054V17.161a1.113,1.113,0,0,1,1.164-1.054h8.147A1.113,1.113,0,0,1,96.8,17.161Z"
        transform="translate(-84 -14)"
        fill={stroke}
      />
      <path
        id="Path_15316"
        data-name="Path 15316"
        d="M380.639,14h-8.147A3.339,3.339,0,0,0,369,17.161v7.375a3.339,3.339,0,0,0,3.492,3.161h8.147a3.339,3.339,0,0,0,3.492-3.161V17.161A3.339,3.339,0,0,0,380.639,14ZM381.8,24.536a1.113,1.113,0,0,1-1.164,1.054h-8.147a1.113,1.113,0,0,1-1.164-1.054V17.161a1.113,1.113,0,0,1,1.164-1.054h8.147a1.113,1.113,0,0,1,1.164,1.054Z"
        transform="translate(-351.543 -14)"
        fill={stroke}
      />
    </svg>
  );
}
