import "./App.css";
import { Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Routes from "./routing";
import useScrollTop from "./hooks/useScrollTop";
import BaseAppLoader from "./components/BaseAppLoader";
import ErrorComponent from "./components/ErrorComponent";
import { useSelector } from "react-redux";

function App() {
  useScrollTop();
  const appError = useSelector((state) => {
    return state?.globalReducer?.appError;
  });
  return (
    <>
      <Header />
      <BaseAppLoader />
      {appError ?
         <ErrorComponent/>:
          <>
          <Switch>
            <Routes />
          </Switch>
        </>
      }
      <Footer />
    </>
  );
}

export default App;
