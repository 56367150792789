import "./index.scss";
import DynamicForm from "../DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchKYCFormFields } from "../../redux/AddPartnerDetails/AddPartnerDetailsActions";
import { useHistory, useParams } from 'react-router-dom';

const KycOrOtp = ({ handleProceed,  selectedData, setSelectedData, customAction=undefined }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { workflowId, partnerId } = useParams()

  useEffect(() => {
    dispatch(fetchKYCFormFields())
  }, [dispatch])

  const kycFields = useSelector((state) => {
    return state?.addPartnerDetails?.kycFormFields
  })

  const handleCancelClick = () => {
    history.push(`partner-info/${workflowId}/${partnerId}`)
  }
  return (
    <section>
      <DynamicForm
        formFields={kycFields}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleNextClick={handleProceed}
        partnerKyc={true}
        handleCancelClick={handleCancelClick}
        customAction={customAction}
      />
    </section>
  );
};
export default KycOrOtp;
