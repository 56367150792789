import { useState } from "react";
import Input from "../../lib/components/Input";
import Button from "../../lib/components/Button";
import "./index.scss";
import { useHistory } from "react-router";
import envlop from "../../assets/images/envlop.svg";
import { GLOBAL_LOGIN_ROUTE } from "../../constants/route.constants.js";
const EmailResetSuccess = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push(GLOBAL_LOGIN_ROUTE);
  };
  return (
    <div className="password-success">
      <p>Email has been sent!!</p>
      <div className="password-success-img">
        <img src={envlop} />
      </div>
      <p>
        Check your inbox to find the link to reset your password. In case you
        can't find the email in your inbox, Please check your spam folder.
      </p>
      <div style={{ width: "100%" }}>
        <Button
          label="Login"
          style={{ width: "100%" }}
          onClick={handleRedirect}
        />
      </div>
    </div>
  );
};

const EmailResetPassword = ({ handleSubmit }) => {
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const handleResetLinkSent = () => {
    if (emailAddress.length !== 0) {
      handleSubmit(emailAddress, () => {
        setResetLinkSent(true);
      });
    }
  };

  return (
    <div>
      {resetLinkSent ? (
        <>
          <EmailResetSuccess />
        </>
      ) : (
        <div className="viaemail-reset">
          <p>Forgot Password?</p>
          <span>
            {" "}
            Enter your registered email address and we will email
            <br /> you a link to reset your password
          </span>
          <div className="viaemail-reset-email">
            <Input
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              label="Email Address"
              name="new_password"
              type="email"
            />
          </div>
          <div className="viaemail-reset-captch">
          </div>
          <Button
            label="Send"
            onClick={handleResetLinkSent}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  );
};
export default EmailResetPassword;
