import {
  FETCH_PIN_CODE_FAILURE,
  FETCH_STEP_TWO_APPLICANTS,
  FETCH_PIN_CODE_SUCCESS,
  CLUSTER_SELECTED_DATA,
  DOWNLOAD_WORKFLOW_CSV_SUCCESS
} from "./DashboardActionTypes";
import { toast } from "react-hot-toast"
import { SUCCESS_RESPONSE_LIST } from "../../constants/status";
import requestV1 from '../../utils/requestV1';
import moment from "moment";

export const fetchClusteHeadApplicants = (userId) => async (dispatch) => {
  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ "assigned_to": userId, "active_step": 2,organisation_id: process.env.REACT_APP_ORGANIZATION_ID }),
  };
  try {
    const response = await requestV1(reqUrl, options);
    if (response !== undefined && Object.keys(response).length !== 0) {
      const { status, data, message } = response;
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        dispatch(fetchStepApplicatsData(data));
      } else {
        toast.error(message);
      }
    }
  } catch (Err) {
    toast.error("Network Error");
  }
}
export const fetchStepApplicatsData = (payload) => {
  return {
    type: FETCH_STEP_TWO_APPLICANTS,
    payload: payload,
  };
};

// export const downloadWorkflowCSV = (payload)  => async (dispatch) => {
export const downloadWorkflowCSV = (payload) => async (dispatch) => {
  payload.organisation_id = process.env.REACT_APP_ORGANIZATION_ID
  // console.log("🚀 ~ file: DashboardActions.js ~ line 43 ~ downloadWorkflowCSV ~ payload", payload)
  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/downloadWorkflowReport`
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  try {
    // console.log("form data sending",downloadWorkflowCSV options)
    const response = await requestV1(reqUrl, options, false);
    // console.log("🚀 ~ file: DashboardActions.js ~ line 54 ~ downloadWorkflowCSV ~ response", response)
    const { status,buffer, data, message } = response;
    if (response.status.includes(status)) {
      // dispatch( downloadWorkflowCSV_Success(buffer.data))
      // console.log(buffer)
      //buffer is undefined
      var byteArray = new Uint8Array(buffer.data);
      var blob = new Blob([byteArray], { type: 'text/csv' });
      if (navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob,  "Mis Report " + moment().format("MMM Do YY")+ ".csv");
      } else {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.target = '_blank';
        a.download = "Mis Report " + moment().format("MMM Do YY") +".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
   }
    else {
      toast.error(message)
    }
  }
  catch (err) {
    toast.error("No Data found in this date range")
    // console.log(err);
  }
};

export const fetchPin_codeDetails = (body) => async (dispatch) => {
  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/users/assignUserBasedOnPincode`
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };
  try {
    const response = await requestV1(reqUrl, options, false);
    const { status, data, message } = response;
    if (response.status.includes(status)) {
      dispatch(fetchPin_code_success(data));
    }
    else {
      toast.error(message)
    }
  }
  catch (err) {
    dispatch(fetchPin_code_failure(err));
    toast.error("Network Error")
  }
};

const fetchPin_code_success = (data) => {
  return {
    type: FETCH_PIN_CODE_SUCCESS,
    payload: data,
  };
};
const fetchPin_code_failure = (error) => {
  return {
    type: FETCH_PIN_CODE_FAILURE,
    payload: error,
  };
};
const downloadWorkflowCSV_Success = (data) => {
  return {
    type: DOWNLOAD_WORKFLOW_CSV_SUCCESS,
    payload: data,
  };
};

export const setClusterSelectedData = (key, value) => {
  return {
    type: CLUSTER_SELECTED_DATA,
    key,
    payload: value,
  };
};
export const clearClusterSelectedData = () => {
  return {
    type: "CLEAR_SELECTED_DATA"
  }
}