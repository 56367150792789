import "./index.scss";
import { MdCheckCircleOutline, MdCheckCircle } from "react-icons/md";
import { Row, Col } from "react-bootstrap";

const RadioInput = props => {
  const { label, onChange, value, text, options = [], name, error } = props;
  const errCls = error?.length !== 0 ? "lds__radio-input__option-error" : "";
  //name===subscription below code get called CH-Partner defatil form

  return name === "subscription" ? (
    <div className="lds__radio-input__wrapper">
      <Row>
        <Col lg={12}>
          <p className="lds__radio-input__text">{label}</p>
        </Col>

        <Col lg={12} className="lds__radio-input__buttons">
          <Row>
            {options?.map((_option, index) => {
              const { label } = _option;
              const selected = index === value;
              return (
                <Col lg={6}>
                  <div
                    key={index}
                    onClick={() => {
                      onChange(index, props);
                    }}
                    className={`lds__radio-input__buttons--option ${errCls}`}
                    style={{
                      color: selected ? "#383838" : "#B5B5B5",
                      paddingLeft: "10px",
                    }}
                  >
                    <Row>
                      <Col lg={9}>{label}</Col>
                      <Col lg={3}>
                        {selected ? (
                          <MdCheckCircle
                            style={{
                              fontSize: "18px",
                              color: "#0076BC",
                            }}
                          />
                        ) : (
                          <MdCheckCircleOutline style={{ fontSize: "18px" }} />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      {error?.length !== 0 && (
        <span className="lds__radio-input__error">
          Choose an option to proceed
        </span>
      )}
    </div>
  ) : (
    <div className="lds__radio-input__wrapper">
      <Row>
        <Col lg={6}>
          <p className="lds__radio-input__text">{label}</p>
        </Col>
        <Col lg={6} className="px-0">
          <div className="lds__radio-input__buttons">
            {options?.map((_option, index) => {
              const { label } = _option;
              const selected = index === value;
              return (
                <Col lg={6} className="px-3">
                  <div
                    key={index}
                    onClick={() => {
                      onChange(index, props);
                    }}
                    className={`lds__radio-input__buttons--option ${errCls}`}
                    style={{ color: selected ? "#383838" : "#B5B5B5" }}
                  >
                    <Col lg={9}>{label}</Col>
                    <Col lg={3}>
                      {selected ? (
                        <MdCheckCircle
                          style={{
                            fontSize: "18px",
                            color: "#0076BC",
                          }}
                        />
                      ) : (
                        <MdCheckCircleOutline style={{ fontSize: "18px" }} />
                      )}
                    </Col>
                  </div>
                </Col>
              );
            })}
          </div>
          {error?.length !== 0 && (
            <span className="lds__radio-input__error">
              Choose an option to proceed
            </span>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default RadioInput;
