import KycOrOtp from "../../../components/KYC";
import { useHistory } from "react-router";
import { PARTNER_KYC_OTP } from "../../../constants/route.constants.js/applicant";
import { setPartnerKYCSelectedData } from "../../../redux/PartnerKYC/partnerKYCActions";
import { useDispatch, useSelector } from "react-redux";
import { toast, Toaster } from 'react-hot-toast'
import { aadharGenerateOTP } from '../../../redux/AddPartnerDetails/AddPartnerDetailsActions';
import "./index.scss";

const PartnerKYC = () => {
  let history = useHistory();
  let dispatch = useDispatch()

  const selectedData = useSelector((state) => {
    return state?.partnerKyc?.selectedData
  })

  const handleSuccess = () => {
    history.push(PARTNER_KYC_OTP);

  }
  const handleError = (message) => {
    toast.error(message)
  }

  const handleProceed = () => {
    dispatch(aadharGenerateOTP(selectedData?.aadhaar_number, handleSuccess, handleError))
  };

  const copyCurrentAddress = () => {
    const address = selectedData?.current_address;
    if (address?.length !== 0) {
      dispatch(setPartnerKYCSelectedData('permanent_address', address));
    }
  }

  return (
    <div className="partner-kyc-wrapper">
      <Toaster />
      <KycOrOtp
        handleProceed={handleProceed}
        setSelectedData={setPartnerKYCSelectedData}
        selectedData={selectedData}
        customAction={{ field: 'permanent_address', label: 'Same as current address', handlerFunc: copyCurrentAddress }}
      />
    </div>
  );
};

export default PartnerKYC;
