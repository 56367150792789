/* APPLICANT ONBOARDING JOURNEY */
export const CLIENT_REVIEW = "/review/client";
export const CLIENT_ONBOARDING_SUBMIT = "/submit/onboarding";
export const CLIENT_ONBOARDING = "/client-onboarding";

/* APPLICANT SECOND JOURNEY */
export const CLIENT_LOGIN = "/login/client";
export const CLIENT_OTP = "/otp/client";
export const CLIENT_DASHBOARD_BASIC = "/dashboard/client-basic";
export const CLIENT_REVIEW_BASIC = "/review/client-basic";
export const CLIENT_PARTNER_DOCMUMENTATION = "/partner-documentation";
export const CLIENT_KYC_FORM = "/client/kyc";
export const CLIENT_KYC_OTP = "/client/kyc-otp";
export const PARTNER_KYC_OTP = "/partner/kyc-otp";
export const CLIENT_SUBMIT = "/client/submit";
export const CLIENT_MAKE_PAYMENT = "/submit/second-client"
export const PAYMENT_SCREEN = "/submit/payment-screen"
/* APPLICANT PHLEBO UPDATE JOURNEY */
export const PARTNER_KYC_INFO = "/partner-info";
export const PARTNER_KYC_FORM = "/partner-kyc";
export const PARTNER_KYC_SUCCESS = '/partner-kyc/success';
export const KYC_ERROR_HANDLE = '/KYC-server-error';

/* APPLICANT PHLEBO UPDATE JOURNEY */
