import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ListPagination from "../../../components/ListPagination";
import { CMO_REVIEW } from "../../../constants/route.constants.js/cmo";
import NoResult from "../../../lib/components/NoResult/Index";
import {
  fetchCMOJourneyList,
  selectedApplicantDataCMO,
} from "../../../redux/CMO/CMOJourneyActions";
import { loginInfo } from "../../../redux/Login/LoginActions";
import { setCurrentPageAction, setCurrentTab } from "../../../redux/Pagination/actions";
import { getItemFromLocal } from "../../../utils/storageHelper/localStorage";

import Dashboard from "../../Dashboard";

const CMODashboard = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage, setApplicantsPerPage] = useState(5);
  const [cmoJourneyData, setcmoJourneyData] = useState([]);
  const [go, setGo] = useState();
  const history = useHistory();
  const user = dispatch(loginInfo)?.data
  const currentTab = useSelector((state) => state?.pageReducers?.tab)

  const CMOList = useSelector((state) => {
    return state?.cmoJourney?.applicationList;
  });
  const storePage = useSelector((state) => {
    return state?.pageReducers?.currentPageStore;
  });


  const [draftApp, setDraftApp] = useState(false);



  const showActiveApplicants = () => {
    if (CMOList?.length) {
      return CMOList && CMOList?.filter((item) => item?.status !== "Rejected" && item?.status !== "Franchise Approved")
    }
  }

  const showClosedApplicants = () => {
    if (CMOList?.length) {
      return CMOList && CMOList?.filter((item) => item?.status === "Rejected" || item?.status === "Franchise Approved")
    }
  }

  useEffect(() => {
    dispatch(fetchCMOJourneyList(user?._id || getItemFromLocal('userId')));
  }, []);

  useEffect(() => {
    if (CMOList?.length && currentTab === "Active") {
      let activeApplication = showActiveApplicants()
      setcmoJourneyData(activeApplication)
    }

    if (CMOList?.length && currentTab === "Closed") {
      let closedApplications = showClosedApplicants()
      setcmoJourneyData(closedApplications)
    }
  }, [CMOList]);


  const handleAction = (_id) => {
    const result = CMOList.find((items) => {
      return items._id === _id;
    });
    dispatch(selectedApplicantDataCMO(result));
    history.push(CMO_REVIEW, { _id: _id });
  };

  const handleGoChange = (e) => {
    const val = e.target.value;
    setGo(val);
    setCurrentPage(Number(val));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPageAction(pageNumber));
    setCurrentPage(pageNumber);
  };

  const handleActiveApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"))
    const result = showActiveApplicants()
    setcmoJourneyData(result)
    dispatch(setCurrentPageAction(1))

  }

  const handleClosedApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Closed"))
    let closedApplications = showClosedApplicants()
    setcmoJourneyData(closedApplications)
    dispatch(setCurrentPageAction(1))
  }



  const indexOfLastApplicant = storePage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = cmoJourneyData.slice(
    indexOfFirstApplicant,
    indexOfLastApplicant
  );

  //Filter
  const filterFunction = (filterItem) => {
    if (filterItem === "name") {
      return currentApplicants.sort(function (a, b) {
        if (a.source_data?.first_name < b.source_data?.first_name) {
          return -1;
        }
        if (a.source_data?.first_name > b.source_data?.first_name) {
          return 1;
        }
        return 0;
      });
    }
  };
  return (
    <div>
      <Toaster />
      {CMOList?.length === 0 || CMOList === undefined ? (
        // <h1>Loading...</h1>
         <NoResult text="Please wait...!" showImage={false} />
      ) : (
        <>
               
              <Dashboard
                handleAction={handleAction}
                filterFunction={filterFunction}
                dashboardData={currentApplicants}
                handleActiveApplication={handleActiveApplication}
                handleClosedApplication={handleClosedApplication}
                clusterhead={false}
                currentTab={currentTab}
              />
            { 
             currentApplicants?.length !== 0 &&
                <ListPagination
                  applicantsPerPage={applicantsPerPage}
                  setApplicantsPerPage={setApplicantsPerPage}
                  currentPage={storePage ? storePage : currentPage}
                  totalCount={cmoJourneyData?.length !== 0 ? cmoJourneyData?.length : CMOList?.length}
                  handleGoChange={handleGoChange}
                  go={go}
                  handlePageChange={handlePageChange}
              />
            }
        </>
      )}
    </div>
  );
};

export default CMODashboard;
