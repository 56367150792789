
import "./index.scss";

const OtpInput = ({ state, handleChange, handleSubmit }) => {

  const keyPressed = () => { };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="otp1"
          autoComplete="off"
          className="otpInput"
          value={state.otp1}
          onKeyPress={keyPressed}
          onChange={(e) => handleChange("opt1", e)}
          tabIndex="1"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          type="text"
          name="otp2"
          autoComplete="off"
          className="otpInput"
          value={state.otp2}
          onChange={(e) => handleChange("opt2", e)}
          tabIndex="2"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          type="text"
          name="otp3"
          autoComplete="off"
          className="otpInput"
          value={state.otp3}
          onChange={(e) => handleChange("opt3", e)}
          tabIndex="3"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          type="text"
          name="otp4"
          autoComplete="off"
          className="otpInput"
          value={state.otp4}
          onChange={(e) => handleChange("opt4", e)}
          tabIndex="4"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
        <input
          type="text"
          name="otp5"
          autoComplete="off"
          className="otpInput"
          value={state.otp5}
          onChange={(e) => handleChange("opt5", e)}
          tabIndex="5"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />

        <input
          type="text"
          name="otp6"
          autoComplete="off"
          className="otpInput"
          value={state.otp6}
          onChange={(e) => handleChange("opt6", e)}
          tabIndex="6"
          maxLength="1"
          onKeyUp={(e) => inputfocus(e)}
        />
      </form>
    </div>
  );
};
export default OtpInput;
