

import {
  FETCH_PARTNER_KYC_REQUEST,
  FETCH_PARTNER_KYC_SUCCESS,
  FETCH_PARTNER_KYC_FAILURE,
  PARTNER_KYC_SELECTED_DATA,
  SET_DATA_FROM_PANCARD_PARTNER_KYC,
  SAVE_PARTNER_OTHER_DATA,
  SAVE_PARTNER_ID
} from "./partnerKYCTypes";

const initialState = {
  loading: false,
  partnerKYCFields: [],
  error: "",
  selectedData: {},
  partnerOtherInfo: {},
  partnerId: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNER_KYC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PARTNER_KYC_SUCCESS:
      return {
        loading: false,
        partnerKYCFields: action.payload,
        error: "",
      };
    case FETCH_PARTNER_KYC_FAILURE:
      return {
        loading: false,
        partnerDetailsFields: [],
        error: action.payload,
      };
    case PARTNER_KYC_SELECTED_DATA: {
      return {
        ...state,
        selectedData: { ...state.selectedData, [action.key]: action.payload },
      };
    }
    case SAVE_PARTNER_OTHER_DATA: {
      return {
        ...state,
        partnerOtherInfo: action.payload,
      };
    }
    case SAVE_PARTNER_ID: {
      return {
        ...state,
        partnerId: action.payload,
      };
    }

    case SET_DATA_FROM_PANCARD_PARTNER_KYC:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          pan_number: action.payload.pan_number,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          date_of_birth: action.payload.date_of_birth,
          father_name: action.payload.father_name
        },
      };
    default:
      return state;
  }
};

export default reducer;
