import { useEffect } from "react";
import SubmitResponse from "../../../lib/components/SubmitResponse";
import { resetOnboardingForm } from "../../../redux/OnboardingForm/OnboardingFormActions";
import { useDispatch } from "react-redux";
const SubmitOnboardingForm = () => {
  const dispatch=useDispatch()
  useEffect(()=>{
    dispatch(resetOnboardingForm())
  },[])
  return (
    <SubmitResponse text="Your request has been submitted successfully." 
    secondText= "Our team will get back to you shortly regarding how your request will be taken forward."/>
  );
};

export default SubmitOnboardingForm;
