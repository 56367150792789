import Buttonv1 from "../Buttonv1";
import "./index.scss";

const Button = (props) => {
  const { label, onClick,name, variant, type,style ,loading=false, className } = props
  let classname = type  ? `button ${type}` : `button ${variant}`;
  classname = `${classname} ${className}`;
  //hardcoded color needs to be remooved
  return (
    <Buttonv1 
      {...props}
      label={label}
      loading={loading}
      onClick={onClick}
      className={classname}
      style={style}
      type={type}
      variant={variant}
      name={name}
     />
  );
};

Button.defaultProps = {
  variant: "primary", // reject, outline, ghost
};
export default Button;
