import { useHistory } from "react-router";
import { IT_TEAM_DASHBOARD } from "../../../constants/route.constants.js/itTeam";
import SubmitResponse from "../../../lib/components/SubmitResponse";

const ItTeamSubmit = () => {
  const history=useHistory()
  const responseSubmit=()=>{
    history.push(IT_TEAM_DASHBOARD)
  }
  return (
    <div>
      <SubmitResponse text="Client code added to the application successfully" btnStatus={true} responseSubmit={responseSubmit}/>
    </div>
  );
};

export default ItTeamSubmit;
