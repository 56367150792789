import Dashboard from "../../Dashboard";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchApplicantsList,
  rejectApplication,
} from "../../../redux/BusinessHead/BusinessHeadAction";
import { BUSINESS_HEAD_REVIEW } from "../../../constants/route.constants.js/business_head";
import { useHistory } from "react-router";
import PaginationCMO from "../../../components/Pagination";
import "./index.scss";
import ListPagination from "../../../components/ListPagination";
import { setCurrentPageAction, setCurrentTab } from "../../../redux/Pagination/actions";
import NoResult from "../../../lib/components/NoResult/Index";
import { getItemFromLocal } from "../../../utils/storageHelper/localStorage";
import { loginInfo } from "../../../redux/Login/LoginActions"

const DashboardBH = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedInBusinessHead = dispatch(loginInfo)?.data;
  const currentTab = useSelector((state) => state?.pageReducers?.tab)

  const applicantList = useSelector(
    (state) => state?.businessHead?.applicantList
  );

  const { _id: businessHeadId } = loggedInBusinessHead;

  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage, setApplicantsPerPage] = useState(5);
  const [filterData, setFilterData] = useState([]);
  const [go, setGo] = useState();

  const [applicationsList, setApplicationsList] = useState()
  const [draftApp, setDraftApp] = useState(false);


  const showActiveApplicants = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status !== "Rejected" && item?.status !== "Franchise Approved")
    }
  }

  const showClosedApplicants = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status === "Rejected" || item?.status === "Franchise Approved")
    }
  }

  const showCHApplication = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status === "Awaiting CH Approval")
    }
  }

  const showBHApplication = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status === "Awaiting BH Approval")
    }
  }

  const showPaymentConfirmation = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status === "Awaiting Payment Confirmation")
    }
  }

  const showLegalApplication = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status === "Awaiting Legal Approval")
    }
  }

  const showPendingPayment = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status === "Awaiting Payment")
    }
  }

  const showAllActive = () => {
    if (applicantList?.length) {
      return applicantList && applicantList?.filter((item) => item?.status !== "Rejected" && item?.status !== "Franchise Approved")
    }
  }

  useEffect(() => {
    if (applicantList?.length && currentTab === "Active") {
      let activeApplication = showActiveApplicants()
      setApplicationsList(activeApplication)
    }

    if (applicantList?.length && currentTab === "Closed") {
      let closedApplications = showClosedApplicants()
      setApplicationsList(closedApplications)
    }

    if (applicantList?.length && currentTab === "Awaiting CH Approval") {
      let closedApplications = showCHApplication()
      setApplicationsList(closedApplications)
    }

    if (applicantList?.length && currentTab === "Awaiting BH Approval") {
      let closedApplications = showBHApplication()
      setApplicationsList(closedApplications)
    }

    if (applicantList?.length && currentTab === "Awaiting Legal Approval") {
      let closedApplications = showLegalApplication()
      setApplicationsList(closedApplications)
    }

    if (applicantList?.length && currentTab === "Awaiting Payment") {
      let closedApplications = showPendingPayment()
      setApplicationsList(closedApplications)
    }

    if (applicantList?.length && currentTab === "Awaiting Payment Confirmation") {
      let closedApplications = showPaymentConfirmation()
      setApplicationsList(closedApplications)
    }

    if (applicantList?.length && currentTab === "Active") {
      let activeApplication = showAllActive()
      setApplicationsList(activeApplication)
    }
  }, [applicantList]);



  const handleAction = (_id) => {
    const result = applicantList.find((items) => {
      return items._id === _id;
    });
    history.push(BUSINESS_HEAD_REVIEW, { review_data: result, _id: _id });
  };

  const handleGoChange = (e) => {
    const val = e.target.value;
    setGo(val);
    setCurrentPage(Number(val));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPageAction(pageNumber));
    setCurrentPage(pageNumber);
  };

  const storePage = useSelector((state) => {
    return state?.pageReducers?.currentPageStore;
  });

  const indexOfLastApplicant = storePage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = applicationsList?.slice(
    indexOfFirstApplicant,
    indexOfLastApplicant
  );


  const handleGetDraftApplication = () => {
    // console.log("Draft Jobs")
  };

  const handleActiveApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"))
    const result = showActiveApplicants()
    setApplicationsList(result)
    dispatch(setCurrentPageAction(1))
  }

  const handleClosedApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Closed"))
    let closedApplications = showClosedApplicants()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleCHApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting CH Approval"))
    let closedApplications = showCHApplication()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleBHApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting BH Approval"))
    let closedApplications = showBHApplication()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleLegal = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Legal Approval"))
    let closedApplications = showLegalApplication()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handlePayment = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Payment"))
    let closedApplications = showPendingPayment()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handlePaymentConfirmation = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Awaiting Payment Confirmation"))
    let closedApplications = showPaymentConfirmation()
    setApplicationsList(closedApplications)
    dispatch(setCurrentPageAction(1))
  }

  const handleActive = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"))
    const result = showActiveApplicants()
    setApplicationsList(result)
    dispatch(setCurrentPageAction(1))
  }

  useEffect(() => {
    dispatch(fetchApplicantsList(businessHeadId || getItemFromLocal('userId')));
  }, []);


  const filterFunction = (filterItem) => {
    if (filterItem === "name") {
      return currentApplicants.sort(function (a, b) {
        if (a.source_data?.first_name < b.source_data?.first_name) {
          return -1;
        }
        if (a.source_data?.first_name > b.source_data?.first_name) {
          return 1;
        }
        return 0;
      });
    }
  };
  return (
    ////
    <div>
      {applicantList?.length === 0 ? (
        // <h1>...loading</h1>
        <NoResult text="Please wait...!" showImage={false} />
      ) : (
        <>   
              <Dashboard
                handleAction={handleAction}
                filterFunction={filterFunction}
                dashboardData={currentApplicants}
                handleGetDraftApplication={handleGetDraftApplication}
                handleActiveApplication={handleActiveApplication}
                handleClosedApplication={handleClosedApplication}
                handleCH={handleCHApplication}
                handleBH={handleBHApplication}
                handleLegal={handleLegal}
                handlePayment={handlePayment}
                handlePaymentConfirmation={handlePaymentConfirmation}
                handleActive={handleActive}
                clusterhead={false}
                draftJobsActive={draftApp}
                currentTab={currentTab}
              />

         {currentApplicants?.length !==0 &&
              <ListPagination
                applicantsPerPage={applicantsPerPage}
                setApplicantsPerPage={setApplicantsPerPage}
                currentPage={storePage ? storePage : currentPage}
                totalCount={applicationsList?.length !== 0 ? applicationsList?.length : applicantList?.length}
                handleGoChange={handleGoChange}
                go={go}
                handlePageChange={handlePageChange}
              />
         }
        </>
      )}
    </div>
    ////
  );
};

export default DashboardBH;
