import { useEffect, useState } from "react";
import DynamicForm from "../../../components/DynamicForm";
import { useSelector, useDispatch } from "react-redux";
import { fetchOnboardingFormFields, fetchPinCodeInfo, resetMobileAndOtp, storeClientRefId } from "../../../redux/OnboardingForm/OnboardingFormActions";
import { useHistory } from "react-router-dom";
import { CLIENT_REVIEW } from "../../../constants/route.constants.js/applicant";
import { Toaster } from 'react-hot-toast'
import { setSelectedData } from '../../../redux/OnboardingForm/OnboardingFormActions';
import "./index.scss";
import { resetStore } from "../../../redux/Login/LoginActions";
import { getItemFromLocal, setItemToLocal } from "../../../utils/storageHelper/localStorage";

const FranchiseOnboarding = (props) => {
  const [otherProps, setOtherProps] = useState([]);
  let history = useHistory();

  const formFields = useSelector(
    (state) => state?.onBoadringForm?.formFields?.configuration?.step_1
  );
  const selectedData = useSelector(
    (state) => state?.onBoadringForm?.selectedData
  );
  const otpValidationSucceeded = useSelector(
    (state) => state?.onBoadringForm?.isOtpValidated
  );
  const otpGenerated = useSelector(
    (state) => state?.onBoadringForm?.isOtpGenerated
  );
  const disabledFields = useSelector(
    (state) => state?.globalReducer?.onboarding_form_disabled_fields
  );

  const locality = useSelector((state) => {
    return state?.onBoadringForm?.locality_data
  })

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedData && Object.keys(selectedData)?.length === 0) {
      dispatch(fetchOnboardingFormFields());
    }
  }, []);

  const handleNextClick = () => {
    dispatch(storeClientRefId(props?.match?.params?.id))
    history.push(CLIENT_REVIEW);
  };
  const resetPhone = () => {
    dispatch(resetMobileAndOtp());
  }
  useEffect(() => {
    if (selectedData?.pin_code) {
      dispatch(fetchPinCodeInfo(selectedData?.pin_code, selectedData?.shop_finalized))
    }
  }, [selectedData?.pin_code, selectedData?.shop_finalized])

  const options = locality?.map((ele) => {
    return { label: ele }
  })

  const otp_verified = otpValidationSucceeded && otpValidationSucceeded !== 'init_state';
  const isOtpGenerated = otpGenerated && otpGenerated !== "init_state";
  const formattedSelectedData = { ...selectedData, otp_verified: otp_verified, otp_generated: isOtpGenerated };

  const { id_proof: selectedId } = formattedSelectedData;

  // dispatch(resetStore())
  var ciphertext = getItemFromLocal('userInfoEnc');
  if(ciphertext?.length !== undefined){
     dispatch(resetStore());
  }
  useEffect(() => {
    let tempProps = [...otherProps];
    setOtherProps([...tempProps, { field: "area_locality", options: options }]);
  }, [locality]);

  useEffect(() => {
    let tempProps = [...otherProps];
    if (!selectedId || selectedId === undefined) {
      const id_front_obj = {
        field: "id_proof_front",
        dependent: { field_name: "id_proof", val: "NA" },
      };
      const id_back_obj = {
        field: "id_proof_back",
        dependent: { field_name: "id_proof", val: "NA" },
      };
      setOtherProps([...tempProps, id_front_obj, id_back_obj]);
    } else if (selectedId !== undefined && selectedId?.length !== 0) {
      const id_front_obj = {
        field: "id_proof_front",
        label: `${selectedId} Front Side`,
        dependent: { field_name: "id_proof", val: selectedId },
      };
      const id_back_obj = {
        field: "id_proof_back",
        label: `${selectedId} Back Side`,
        dependent: { field_name: "id_proof", val: selectedId },
      };
      setOtherProps([...tempProps, id_front_obj, id_back_obj]);
    }
  }, [selectedId]);
  
  return (
    <div className="franchise-form-wrapper">
      <Toaster />
      <DynamicForm
        formFields={formFields}
        selectedData={formattedSelectedData}
        setSelectedData={setSelectedData}
        onBoardingForm={true}
        handleNextClick={handleNextClick}
        customAction={{ label: 'Change mobile number', handlerFunc: resetPhone, style: { color: '#0076BC' } }}
        disabledFields={disabledFields}
        otherProps={otherProps}
      />
    </div>
  );
};

export default FranchiseOnboarding;
