import { Navbar, Container } from "react-bootstrap";
import "./index.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
// import srlLogo from "../../assets/images/SRL_Diagnostics_Logo.png";
import agilusLogo from "../../assets/images/Agilus_Diagnostics_Logo.png"
import { useDispatch, useSelector } from "react-redux";
import { resetStore } from "../../redux/Login/LoginActions";
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  LOGO_ROUTE_MAP,
  RESET_PASSWORD_ROUTE,
} from "../../constants/app.constants";
import { CLIENT_ONBOARDING } from "../../constants/route.constants.js/applicant";
import { getItemFromLocal } from "../../utils/storageHelper/localStorage";
import fourSquares from "../../assets/images/FourSquares.svg";
// import hamBerger from "../../assets/images/hamberger.svg";
import { Hamberger } from "../../assets/ImagesAsComponent";
import { FourSquares } from "../../assets/ImagesAsComponent";
import logOutArrow from "../../assets/images/logOutArrow.svg";
import { useState } from "react";
const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const accessToken = localStorage?.getItem("token");
  const userInfoToken = getItemFromLocal("userInfoEnc");
  const currentPath = location?.pathname || "";
  const [hambergerColor,setHambergerColor] = useState("grey")
  const [fourSquaresColor,setfourSquaresColor] = useState("blue")
  // console.log("userInfoToken", userInfoToken?.length);
  const pathCheck = (activePath, matchPath) => {
    return activePath.indexOf(matchPath) !== -1;
  };
  const pathRender =
    !pathCheck(currentPath, "client-onboarding") &&
    !pathCheck(currentPath, LOGIN_ROUTE);

  const navigateToListPage = () => {
    let route = LOGO_ROUTE_MAP[currentPath] || "/";
    if (currentPath.indexOf(CLIENT_ONBOARDING) !== -1) {
      return;
    } else if (
      pathCheck(currentPath, LOGIN_ROUTE) ||
      pathCheck(currentPath, RESET_PASSWORD_ROUTE) ||
      pathCheck(currentPath, FORGOT_PASSWORD_ROUTE)
    ) {
      route = "/login";
    }
    history.push(route);
  };

  return (
    <Navbar fixed="top" expand="lg" className="nav-bar-wrapper">
      <Container fluid={true}>
        <span className="nav-bar-logo" onClick={navigateToListPage}>
          <img src={agilusLogo} className="client-logo" alt="logo" />
        </span>
        {pathRender && accessToken && userInfoToken?.length ? (
          
         
            currentPath.indexOf("super-admin")>=0? 
            <span
            style={{
              width: "10%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
                  <Link to="/dashboard/super-admin" onClick={()=>{setHambergerColor("grey");setfourSquaresColor("blue")}}>
              {/* <img src={fourSquares} style={{ height: "20px" }}></img> */}
              <FourSquares stroke={fourSquaresColor}/>
            </Link>
            <Link to="/dashboard/super-admin-edit" onClick={()=>{setHambergerColor("blue");setfourSquaresColor("grey")}}>
              <Hamberger stroke={hambergerColor} style={{ height: "10px" }} />
              {/* <img src={Hamberger} style={{ height: "20px" }} fill="blue"></img> */}
            </Link>
            <Link
              to="/login"
              onClick={() => dispatch(resetStore())}
              style={{ color: "#B2BEB5", textDecoration: "none" }}
            >
              <img
                src={logOutArrow}
                style={{
                  height: "20px",
                  borderLeft: "1px solid grey",
                  paddingLeft: "10px",
                }}
              ></img>
            </Link>
            </span>:  <span
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Link
              to="/login"
              onClick={() => dispatch(resetStore())}
              style={{ color: "#B2BEB5", textDecoration: "none" }}
            >
              <img
                src={logOutArrow}
                style={{
                  height: "20px",
                  paddingLeft: "10px",
                }}
              ></img>
            </Link></span>
        
        ) : (
          ""
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
