
import toast from "react-hot-toast";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status";
import requestV1 from "../../utils/requestV1";
import { setAppError } from "../App/Action";
import {
  FETCH_PARTNER_FORM_REQUEST,
  FETCH_PARTNER_FORM_SUCCESS,
  FETCH_PARTNER_FORM_FAILURE,
  PARTNER_SELECTED_DATA,
  FETCH_PARTNER_FORM_NOT_REQUEST,
  FETCH_PARTNER_FORM_NOT_SUCCESS,
  FETCH_PARTNER_FORM_NOT_FAILURE
} from "./PartnerFormDetailsTypes";

export const fetchPartnerDetailsFormFields = (shop_finalized) => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/get-shop-finalized-form`;
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await requestV1(reqUri, options)
    const { result, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(fetchPartnerDetailsFormSuccess(response));
    }
    else {
      dispatch(fetchPartnerDetailsFormFailure(message));
    }
  }
  catch (err) {
    dispatch(setAppError(true))
    toast.error("Network Error")
  }
};
export const fetchPartnerDetailsFormRequest = () => {
  return {
    type: FETCH_PARTNER_FORM_REQUEST,
  };
};

export const fetchPartnerDetailsFormSuccess = (data) => {
  return {
    type: FETCH_PARTNER_FORM_SUCCESS,
    payload: data.result,
  };
};

export const fetchPartnerDetailsFormFailure = (error) => {
  return {
    type: FETCH_PARTNER_FORM_FAILURE,
    payload: error,
  };
};

export const setSelectedData = (key, value) => {
  return {
    type: PARTNER_SELECTED_DATA,
    key,
    payload: value,
  };
};


export const fetchPartnerDetailsFormNotFinalized = () => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/get-shop-not-finalized-form`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await requestV1(reqUri, options)
    const { status, message } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(fetchPartnerDetailsFormNotFinalizedSuccess(response));
    }
    else {
      dispatch(fetchPartnerDetailsFormNotFinalizedFailure(message));
    }
  }
  catch (err) {
    dispatch(setAppError(true))
    toast.error("Network Error")
  }
}

export const fetchPartnerDetailsFormNotFinalizedRequest = () => {
  return {
    type: FETCH_PARTNER_FORM_NOT_REQUEST,
  };
};

export const fetchPartnerDetailsFormNotFinalizedSuccess = (data) => {
  return {
    type: FETCH_PARTNER_FORM_NOT_SUCCESS,
    payload: data.result,
  };
};

export const fetchPartnerDetailsFormNotFinalizedFailure = (error) => {
  return {
    type: FETCH_PARTNER_FORM_NOT_FAILURE,
    payload: error,
  };
};
