import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import BankInformation from "../../ReviewDetails/components/BankInformation";
import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";

import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import Clarifications from "../../ReviewDetails/components/Clarification";
import { Col, Container, Row } from "react-bootstrap";
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import Overlay from "../../../lib/components/Overlay";
import Input from "../../../lib/components/Input";
import Textarea from "../../../lib/components/Textarea";
import {
  IT_TEAM_DASHBOARD,
  IT_TEAM_SUBMIT,
} from "../../../constants/route.constants.js/itTeam";
import {
  clarificationITTeam,
  submitClientCode,
  fetchApplicantsList,
} from "../../../redux/ITTeam/ITTeamAction";
import toast, { Toaster } from "react-hot-toast";
import { APPLICATION_STATUSES } from '../../../constants/status.constants';
import { loginInfo } from "../../../redux/Login/LoginActions";
import ActivityTracker from "../../ReviewDetails/components/ActivityTracker";


const ClientCodeOverlay = ({
  showOverlay,
  setShowOverlay,
  setClarificationComment,
  setClientCode,
  reject,
  applicant,
  handleSubmit,
  handleClarification,
  removeHeader
}) => {
  const [overlayData, setOverlayData] = useState();

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const handleAddPartner = () => {
    setShowOverlay(true);
  };

  const handleSubmitOverlay = () => {
    setShowOverlay(false);
    {
      reject
        ? setClarificationComment(overlayData)
        : setClientCode(overlayData);
    }
    {
      reject ? handleClarification(overlayData) : handleSubmit(overlayData);
    }
  };
  const validateClientCodeAndSumit = () => {
    if(overlayData?.length){
      handleSubmitOverlay();
    }
  }
  return (
    <div>
      {reject ? (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Clarification"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType=""
          buttonSubmitLabel="SUBMIT"
          handleCloseOverlay={handleCloseOverlay}
          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea placeholder="Please specify the details of the clarification required" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      ) : (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Enter client code"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType=""
          buttonSubmitLabel="PROCEED"
          handleCloseOverlay={handleCloseOverlay}
          removeHeader={removeHeader}
          handleSubmitOverlay={validateClientCodeAndSumit}
        >
          <Input
            label="Client Code"
            placeholder="Enter Client Code"
            onChange={(e) => setOverlayData(e.target.value)}
          />
        </Overlay>
      )}
    </div>
  );
};

const ItTeamReviewDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState(false);
  const [routePath, setRoutePath] = useState();
  const [reject, setReject] = useState();
  const [clarificationComment, setClarificationComment] = useState();
  const [clientCode, setClientCode] = useState();
  const loggedItTeamUser = dispatch(loginInfo)?.data
  const { _id: itTeamId } = loggedItTeamUser;

  useEffect(() => {
    setRoutePath(location.pathname);
    dispatch(fetchApplicantsList(itTeamId));
  }, []);
  const _id = location?.state?._id;
  const applicantList = useSelector((state) => state?.itTeam?.applicantList);
  const userData = dispatch(loginInfo)?.data
  
  const selectedApplicantIT = location?.state?.review_data;

  const makeChange = () => { };
  const handleSuccess = () => {
    setTimeout(() => {
      history.push(IT_TEAM_SUBMIT);
    }, 1500)
  };
  const handleClarificationSuccess = () => {
    history.push(IT_TEAM_DASHBOARD);
  };
  const handleClarification = (text) => {
    const payload = {
      clarification: text,
      name: loggedItTeamUser.profile?.first_name,
      role: loggedItTeamUser.role_name,
      _id: selectedApplicantIT?._id,
    };
    // dispatch(clarificationITTeam(payload, handleClarificationSuccess, handleError));

    // let formData = new FormData();
    // clarification_history.push(payload);
    const clarificationCommentsHistory = {
      applicationId: selectedApplicantIT?._id,
      ...selectedApplicantIT,
    };
    clarificationCommentsHistory.clarification_history.push(payload);
    // formData.append("data", JSON.stringify(clarificationCommentsHistory));
    dispatch(clarificationITTeam(clarificationCommentsHistory, handleClarificationSuccess, handleError));

  };

  const handleError = (message) => {
    toast.error(message);
  };
  const handleSubmit = (data) => {
    // let formData = new FormData();
    const clientCodeUpdateData = {
      applicationId: selectedApplicantIT?._id,
      ...selectedApplicantIT,
      active_step: 8,
      status: APPLICATION_STATUSES['Awaiting Additional Info']['label'],
      stepHistory: [...selectedApplicantIT?.stepHistory?.map((item) => {
        if (item?.step === selectedApplicantIT?.active_step) {
          item.status = "COMPLETED";
          item.completed_date = new Date().toDateString()
          if (item?.step == 7) {
            item.completedBy = userData._id;
          }
        }
        return item
      }), ...[{
        step: 8,
        status: APPLICATION_STATUSES['Awaiting Additional Info']['label'],
        initated_date: new Date().toDateString(),
      }]]
    };
    clientCodeUpdateData.client_code = data;
    let currentUser = JSON.parse(localStorage.getItem("userInfo"));
    selectedApplicantIT?.approval_history?.push({
      status: "Approved",
      approved_at: new Date().toDateString(),
      approved_by: currentUser?.profile?.first_name + " "+ currentUser?.profile?.last_name,
      role: currentUser?.role_name

  })
    clientCodeUpdateData.approval_history =  selectedApplicantIT?.approval_history
    // formData.append("data", JSON.stringify(clientCodeUpdateData));
    dispatch(submitClientCode(clientCodeUpdateData, handleSuccess, handleError))
  };

  const handleBack = () => {
    history.push(IT_TEAM_DASHBOARD);
  };

  const loadingClarify = useSelector((state) => {
    return state?.globalReducer?.clarifyLoading
  })
  const loading = useSelector((state) => {
    return state?.globalReducer?.submitLoading
  })

  const SlidingNavabrMenu = [
    { id: 1, key: "Basic Information", title: "Basic information" },
    { id: 2, key: "Centre Information", title: "Centre Information" },
    { id: 3, key: "Addition Information", title: "Addition Information" },
    { id: 4, key: "Partner Information", title: "Partner Information" },
    { id: 5, key: "Bank Information", title: "Bank Information" },
    { id: 6, key: "Other Information", title: "Other Information" },
    { id: 7, key: "Remarks", title: "Remarks" },
    { id: 8, key: "Clarification", title: "Clarification" }
  ]
  return (

    <section className="review-details__wrapper">
      <Toaster/>
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <SlidingNavbar selectedData={selectedApplicantIT} />
            <UserInformation
              selectedData={selectedApplicantIT?.source_data} //selectedApplicantChannel?.source_data} not passed
              currentApplicant={selectedApplicantIT}
              _id={_id}
              showAddress={true}
              kycDocs={selectedApplicantIT?.kycDocs}
            />
            <div style={{ marginTop: "50px" }}>
              {Array.isArray(selectedApplicantIT?.partner_details) &&
                selectedApplicantIT?.partner_details?.map((item) => {
                  if (item?.source_data) {
                    return (
                      <UserInformation
                        selectedData={item?.source_data}
                        _id={selectedApplicantIT?._id}
                        partnerId={item?.id}
                        isPartnerData={true}
                        kycDocs={selectedApplicantIT?.kycDocs}
                      />
                    );
                  }
                })}
            </div>
            <CenterInfo selectedData={selectedApplicantIT?.source_data} />
            <AddtionalDetails selectedData={selectedApplicantIT?.source_data} />
            {
              selectedApplicantIT?.source_data?.partner_profiling ?
                <PartnerInformation selectedData={selectedApplicantIT?.source_data} fullInfo={selectedApplicantIT}/> : ''
            }
            {
              selectedApplicantIT?.bank_name ?
                <BankInformation selectedData={selectedApplicantIT} /> : ''
            }
            {
              selectedApplicantIT?.document_signed ?
                <OtherInformation selectedData={selectedApplicantIT} /> : ''
            }
            {
              Array.isArray(selectedApplicantIT?.checkList) && selectedApplicantIT?.checkList ?
                <ChecklistRequirement
                  selectedData={selectedApplicantIT?.checkList}
                />
                : ''
            }
            {
              Array.isArray(selectedApplicantIT?.phleboList) && selectedApplicantIT?.phleboList?.length ?
                <PhleboInformation
                  selectedData={selectedApplicantIT?.phleboList}
                  _id={selectedApplicantIT?._id}
                /> : ''
            }
            {
              selectedApplicantIT?.weekday_start_time ?
                <>
                  <BrandingPhotos _id={selectedApplicantIT?._id} />
                  <ServiceTiming selectedData={selectedApplicantIT} />
                </>
                : ''
            }
            {
              Array.isArray(selectedApplicantIT?.reject_remarks) && selectedApplicantIT?.reject_remarks?.length ?
                <Remarks selectedData={selectedApplicantIT?.reject_remarks} /> : ''
            }
            {
              Array.isArray(selectedApplicantIT?.clarification_history) && selectedApplicantIT?.clarification_history?.length ?
                <Clarifications role="IT Team" selectedData={selectedApplicantIT?.clarification_history} userData={loggedItTeamUser} /> : ''
            }
          <ActivityTracker selectedData={selectedApplicantIT?.approval_history} />

            <div className="layout-button">
              <div>
                <Button label="Back" type="ghost" onClick={handleBack} />
              </div>
              <div>
                <Button
                  label="CLARIFICATION"
                  type="outline"
                  loading={loadingClarify}
                  onClick={() => {
                    setShowOverlay(true);
                    setReject(true);
                  }}
                  id="clarification"
                />
                {
                  selectedApplicantIT?.status === APPLICATION_STATUSES['Awaiting Client Code']['label'] ?
                    <Button
                      label={"ADD CLIENT CODE"}
                      loading={loading}
                      onClick={() => {
                        setShowOverlay(true);
                        setReject(false);
                      }}
                    />
                    : ''
                }
              </div>
            </div>

            {showOverlay && (
              <ClientCodeOverlay
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                setClarificationComment={setClarificationComment}
                setClientCode={setClientCode}
                applicant={selectedApplicantIT}
                reject={reject}
                handleClarification={handleClarification}
                handleSubmit={handleSubmit}
                removeHeader={true}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ItTeamReviewDetails;
