import { useHistory } from "react-router-dom";
import { CLIENT_ONBOARDING_SUBMIT } from "../../../constants/route.constants.js/applicant";
import { useDispatch, useSelector } from "react-redux";
import { submitOnBoardingFranchise } from "../../../redux/OnboardingForm/OnboardingFormActions";
import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import Button from "../../../lib/components/Button";
import { Col, Container, Row } from "react-bootstrap";
import { Toaster } from 'react-hot-toast'
import "./index.scss";
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import { useEffect } from "react";

const ReviewDetailsApplicant = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const selectedData = useSelector(
    (state) => state?.onBoadringForm?.selectedData
  );
  const clientRefId = useSelector(
    (state) => state?.onBoadringForm?.clientRefId
  );
  const kycFiles = useSelector(
    (state) => state?.onBoadringForm?.kycFile
  );
  const clusterHeadAssignment = useSelector(
    (state) => state?.onBoadringForm?.assigned_to
  );
  const PassToSubmit = () => {
    history.push(CLIENT_ONBOARDING_SUBMIT);
  }
  const loading = useSelector((state) => {
    return state?.globalReducer?.submitLoading
  })
  const handleSubmit = () => {
    let params = {
      name: selectedData.first_name,
      email_id: selectedData.email
    }
    dispatch(
      submitOnBoardingFranchise(selectedData, () => {
        history.push(CLIENT_ONBOARDING_SUBMIT);
      }, kycFiles, clusterHeadAssignment, clientRefId)
    );
  };

  const handleMakeChange = () => {
    history.push("/");
  };

  useEffect(()=>{
    if(!Object.keys(selectedData)?.length){
      history.push('/');
    }
  },[]);

  return (
    <section className="review-details__wrapper">
      <Toaster />
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            {/* <SlidingNavbar selectedData={selectedData} /> */}
            <UserInformation selectedData={selectedData} currentApplicant={null}/>
            <CenterInfo selectedData={selectedData} />
            <AddtionalDetails selectedData={selectedData} />
            <div className="layout-button">
              <div></div>
              <div>
                <Button
                  label="MAKE CHANGE"
                  type="outline"
                  onClick={handleMakeChange}
                />
                <Button label="SUBMIT" onClick={handleSubmit} loading={loading} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReviewDetailsApplicant;
