import { getItemFromLocal } from "./storageHelper/localStorage";
import jwt_decode from 'jwt-decode';
import { isTokenFormatValid } from "./JWT/formateValidate";

export const isAuthenticated = (allowedRoles=[]) => {
    const token = getItemFromLocal('token');
    if (token !== {} && token?.length !== 0 && isTokenFormatValid(token)) {
        const _decoded = jwt_decode(token);
        const { exp, role } = _decoded;
        if (exp * 1000 >= Date.now()) {
            if (allowedRoles.includes(role)) {
                return true;
            }
        }
    }
    return false;
}
