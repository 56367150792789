import "./index.scss";

const Separator = ({ label }) => {
  return (
    <div className="lds-seperator">
      <span>{label}</span>
    </div>
  );
};

export default Separator;
