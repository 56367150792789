import { Container, Row, Col } from "react-bootstrap";
import "./index.scss";
import HorizontalLine from "../HorizontalLine/index.js"
import ViewImage from "../../../../components/ViewImage";
import certificate from "../../../../assets/images/certificate.svg";
import Image from "../../../../components/Image";

import React, { useState } from "react";
import Tooltip from "../../../../lib/components/Tooltip";

const PhleboInformation = ({ selectedData, _id }) => {
  const [show, setShow] = useState(false)
  const [src, setSrc] = useState("")
  const [title, setTitle] = useState(false) 
  
  const handleShow = (src, title) => {
    setShow(true)
    // setSrc(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/61cdaae611482d3eb4339875/phlebo_docs/phleboid`)
    setSrc(src)
    setTitle(title)
  }
  const handleClose = () => {
    setShow(false)
  }

  return (
    <section className="other-partner" id="phlebo-info">
      <HorizontalLine />
      <Container fluid={true}>
        <p className="other-partner__header">Phlebo Information</p>

        <div className="other-partner__content">
          <Row>
            <Col>
              <span className="other-partner__text">Name</span>
            </Col>
            <Col>
              <span className="other-partner__text">Qualification</span>
            </Col>
            <Col>
              <span className="other-partner__text">DOB</span>
            </Col>
            <Col>
              <span className="other-partner__text">Experience</span>
            </Col>
          </Row>
          <HorizontalLine />
          {
            Array?.isArray(selectedData) && selectedData?.map((item) => {
              return (
                <Row>
                  <Col>
                    <span className="other-partner__text">{item?.first_name} {item.last_name}</span>
                    <div className="other-partner__text">{item?.mobile_number}</div>
                  </Col>

                  <Col>
                    <div className="other-partner__qualification_hover">
                      <span className="pl-2">
                        <Tooltip
                          onClick={() => handleShow(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedPhleboDocument/${_id}/phlebo_docs/${item?.id}`)}
                          toolTipText={`${item?.qualification}_certificate`}
                          displayText={
                            <Image
                              src={certificate}
                              onClick={() => handleShow(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedPhleboDocument/${_id}/phlebo_docs/${item?.id}`)}
                              alt="certificate"
                            />
                          }
                        />
                      </span>

                      <span className="pl-2">
                        <Tooltip
                          onClick={() => handleShow(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedPhleboDocument/${_id}/phlebo_docs/${item?.id}`)}
                          toolTipText={`${item?.qualification}_certificate`}
                          displayText={item?.qualification}
                        />
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <span className="other-partner__text">{item?.date_of_birth}</span>
                  </Col>
                  <Col>
                    <span className="other-partner__text">{item?.phlebotomy_experience} years</span>
                  </Col>
                </Row>
              )
            })
          }
          <ViewImage show={show} handleClose={handleClose} src={src} />  {/*src={src}*/}
        </div>
      </Container>
    </section>
  );
};

export default PhleboInformation;
