import "./index.scss";
import KYCOTPImage from "../../assets/images/otp.svg";
import OtpNumber from "./OtpNumber";
import { aadharGenerateOTP, aadharVerifyOTP, submitApplicantSecondJourney } from '../../redux/AddPartnerDetails/AddPartnerDetailsActions';
import { PARTNER_KYC_SUCCESS, KYC_ERROR_HANDLE } from "../../constants/route.constants.js/applicant";
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast'
import { useSelector, useDispatch } from "react-redux";
import { subStringHelper } from '../../utils/subStringHelper';
import requestV1 from "../../utils/requestV1.js";

const PartnerKYCOTP = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedData = useSelector(state => state?.partnerKyc?.selectedData)
  const partnerKYC = useSelector(state => state?.onBoadringForm?.kycFile)
  const partnerOtherInfo = useSelector(state => state?.partnerKyc?.partnerOtherInfo)
  const partnerId = useSelector(state => state?.partnerKyc?.partnerId)

  const handleSubmitApplicantSecondJourneySuccess = () => {
    history.push(PARTNER_KYC_SUCCESS);
  }

  const handleSuccess = async() => {
    if (selectedData) {

      delete selectedData?.id_proof_front
      delete selectedData?.applicant_photo
      delete selectedData?.id_proof_back
      delete selectedData?.id_proof_back_base64
      delete selectedData?.id_proof_front_base64
      delete selectedData?.pan
      delete selectedData?.address_proof;
      delete selectedData?.address_proof;

      let partnerIdx = -1;
      let updatedPartnerData = partnerOtherInfo?.partner_details && partnerOtherInfo?.partner_details?.map((item,index) => {
        if (item?.id == localStorage.getItem('partner_id')) {
          partnerIdx = index;
          return {
            ...item,
            kyc_completed: true,
            source_data: {
              ...selectedData,
              aadhaar_number: selectedData? subStringHelper(selectedData.aadhaar_number,8,12):"",
              name_as_per_aadhar: localStorage.getItem('name_as_per_aadhar_applicant') ? localStorage.getItem('name_as_per_aadhar_applicant') : "",
              gender: localStorage.getItem('gender')?localStorage.getItem('gender'):"",
            }
          }
        }
      })

      let formData = new FormData()
      for (let file in partnerKYC) {
        formData.append(file, partnerKYC[file])
      }


      const formattedFullData = {...partnerOtherInfo};
      if(partnerIdx > -1){
        formattedFullData['partner_details'][partnerIdx] = updatedPartnerData[0];
      }

      const secondJourneyPartnerPayload = {
        ...formattedFullData,
        partner_id: localStorage.getItem('partner_id')
      }
      let params = {
        body: JSON.stringify(secondJourneyPartnerPayload)
      }
      const encryptedBodyRes = await requestV1('',params,true,true);
  
      formData.append('data', JSON.stringify(encryptedBodyRes))
      // formData.append("data", JSON.stringify(secondJourneyPartnerPayload))
      dispatch(submitApplicantSecondJourney(formData, handleSubmitApplicantSecondJourneySuccess, handleError))
    }
  }
  const handleError = (message) => {
    toast.error(message)
    history.push(KYC_ERROR_HANDLE);
  }
  const resendOtp = () => {
    const aadhaar = selectedData.aadhaar_number;
    dispatch(aadharGenerateOTP(aadhaar));
  }
  return (
    <div className="kyc__wrapper">
      <Toaster />
      <div>
        <img src={KYCOTPImage} alt="altimage"/>
      </div>
      <div>
        <p className="kyc__header">OTP Verification</p>
        <p className="kyc__sub-header">
          Enter the OTP sent to your phone number registered with Aadhaar
        </p>
        <OtpNumber
          aadharVerifyOTP={aadharVerifyOTP}
          handleSuccess={handleSuccess}
          handleError={handleError}
          resendOtp={resendOtp}
        />
      </div>
    </div>
  );
};

export default PartnerKYCOTP;
