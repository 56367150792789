import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
import { CHANNEL_TEAM_DASHBOARD, CHANNEL_TEAM_REVIEW, CHANNEL_TEAM_SUBMIT } from "../../../constants/route.constants.js/channelTeam";
import OtherPartnerDetails from "../../ReviewDetails/components/OtherPartnerDetails";
import BankInformation from "../../ReviewDetails/components/BankInformation";
import {
  approveApplicationPayment, rejectApplicationChannel
} from "../../../redux/ChannelTeam/ChannelTeamAction";
import { toast, Toaster } from "react-hot-toast";
import { sendReminderToPartner } from "../../../lib/components/api/api.service"
import { APPLICATION_STATUSES } from '../../../constants/status.constants';
import Clarifications from "../../ReviewDetails/components/Clarification";
import * as _ from "underscore"

import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";
import { getStatusBasedBtn } from "../utils/getStatusBasedBtn";
import { loginInfo } from "../../../redux/Login/LoginActions";
import ActivityTracker from "../../ReviewDetails/components/ActivityTracker";

const ReviewOverlay = ({
  showOverlay,
  setShowOverlay,
  setRemarkComment,
  handleReject,
  handleSubmit,
  selectedOption
}) => {
  const [overlayData, setOverlayData] = useState();
  const history = useHistory();

  const dispatch = useDispatch();

  const handleCloseOverlay = () => {
    // history.push(CHANNEL_TEAM_REVIEW);
    setShowOverlay(false);
  };

  const handleAddPartner = () => {
    setShowOverlay(true);
  };

  const handleSubmitOverlay = () => {
    setShowOverlay(false);

    if (selectedOption === "approve") {
      return handleSubmit(overlayData)
    }
    setRemarkComment(overlayData)
    handleReject(overlayData)
  };

  return (
    <>
      <Overlay
        setShowOverlay={setShowOverlay}
        showOverlay={showOverlay}
        title="Remarks"
        buttonCloseType="outline"
        buttonCloseLabel="CANCEL"
        buttonSubmitType={selectedOption === "Reject" ? "red" : ""}
        buttonSubmitLabel={selectedOption === "Reject" ? "REJECT" : "SUBMIT"}
        handleCloseOverlay={handleCloseOverlay}
        handleSubmitOverlay={handleSubmitOverlay}
      >
        <Textarea placeholder="Please specify the details of the remarks (optional)" onChange={(e) => setOverlayData(e.target.value)} />
      </Overlay>
    </>
  );
};

const ChannelTeamReview = () => {
  const location = useLocation();
  const history = useHistory();
  const [routePath, setRoutePath] = useState();
  const [remarkComment, setRemarkComment] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedOption, setSelectedOption] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    setRoutePath(location.pathname);
  }, []);

  const selectedApplicantChannel = useSelector((state) => state?.channelTeam?.selectedApplicantChannel)
  const userData = dispatch(loginInfo)?.data
  const loading = useSelector((state) => {
    return state?.globalReducer?.submitLoading
  })
  const rejectLoading = useSelector((state) => {
    return state?.globalReducer?.rejectLoading
  })

  const { active_step, status } = selectedApplicantChannel
  const handleBack = () => {
    history.push(CHANNEL_TEAM_DASHBOARD)
  }
  const handleReject = (text) => {
    const payload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: selectedApplicantChannel?._id,
    };

    let rejectCommentsHistory = {
      applicationId: selectedApplicantChannel?._id,
      ...selectedApplicantChannel,
      status: APPLICATION_STATUSES['Rejected']['label']
    };
    if (rejectCommentsHistory?.reject_remarks?.length) {
      rejectCommentsHistory?.reject_remarks?.push(payload);
    } else {
      rejectCommentsHistory.reject_remarks = [payload]
    }

    dispatch(rejectApplicationChannel(rejectCommentsHistory, handleSuccess, handleError))
  }

  const handleSuccess = () => {
    history.push(CHANNEL_TEAM_DASHBOARD);
  }

  const handleError = (message) => {
    toast.error(message)
  }

  const handlePaymentSuccess = () => {
    history.push(CHANNEL_TEAM_SUBMIT);
  }

  const makeChange = () => { };

  const handleSubmit = (text) => {
    const createChannelTeamPayload = {
      payment_approved: true,
      ...selectedApplicantChannel
    }

    if (selectedApplicantChannel.source_data.ownership === "Sole proprietorship") {
      createChannelTeamPayload.status = APPLICATION_STATUSES['Awaiting Legal Approval']['label']
      createChannelTeamPayload.active_step = 5.1
      createChannelTeamPayload._id = selectedApplicantChannel?._id
      createChannelTeamPayload.reject_remarks = selectedApplicantChannel?.reject_remarks
      createChannelTeamPayload.stepHistory = [...selectedApplicantChannel?.stepHistory?.map((item) => {
        if (item?.step === selectedApplicantChannel?.active_step) {
          item.status = "COMPLETED";
          item.completed_date = new Date().toDateString()
          if (item?.step == 5) {
            item.completedBy = userData._id;
          }
        }
        return item
      }), ...[{
        step: 5.1,
        status: APPLICATION_STATUSES['Awaiting Legal Approval']['label'],
        initated_date: new Date().toDateString(),
      }]]
    } else {
      createChannelTeamPayload.reject_remarks = selectedApplicantChannel?.reject_remarks
      let kycCompletedApplicants = _.filter(selectedApplicantChannel.partner_details, (each) => {
        return each.kyc_completed === true
      })
      if (kycCompletedApplicants?.length === selectedApplicantChannel.partner_details?.length) {
        createChannelTeamPayload.status = APPLICATION_STATUSES['Awaiting Legal Approval']['label']
        createChannelTeamPayload.active_step = 5.1
        createChannelTeamPayload._id = selectedApplicantChannel?._id
        createChannelTeamPayload.stepHistory = [...selectedApplicantChannel?.stepHistory?.map((item) => {
          if (item?.step === selectedApplicantChannel?.active_step) {
            item.status = "COMPLETED";
            item.completed_date = new Date().toDateString()
            if (item.step == 5) {
              item.completedBy = userData._id;
            }
          }
          return item
        }), ...[{
          initated_date: new Date().toDateString(),
          status: APPLICATION_STATUSES['Awaiting Legal Approval']['label'],
          step: 5.1,
        }]]
      } else {
        createChannelTeamPayload.status = APPLICATION_STATUSES['Awaiting Partner KYC']['label']
        createChannelTeamPayload.active_step = selectedApplicantChannel.active_step
        createChannelTeamPayload._id = selectedApplicantChannel?._id
      }
    }

    const textPayload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: location?.state?.review_data?._id,
    };
    if (text !== undefined) {
      createChannelTeamPayload?.reject_remarks?.push(textPayload);
    }
    let currentUser = JSON.parse(localStorage.getItem("userInfo"));
    selectedApplicantChannel?.approval_history?.push({
      status: "Approved",
      approved_at: new Date().toDateString(),
      approved_by: currentUser?.profile?.first_name + " "+ currentUser?.profile?.last_name,
      role: currentUser?.role_name
  })
    createChannelTeamPayload.approval_history =  selectedApplicantChannel?.approval_history
    dispatch(approveApplicationPayment(createChannelTeamPayload, handlePaymentSuccess, handleError));
  };

  return (
    <section className="review-details__wrapper">
      <Toaster />
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <SlidingNavbar selectedData={selectedApplicantChannel} />
            <UserInformation selectedData={selectedApplicantChannel?.source_data} _id={selectedApplicantChannel?._id} kycDocs={selectedApplicantChannel?.kycDocs} />
            <div style={{ marginTop: "50px" }}>
              {Array.isArray(selectedApplicantChannel?.partner_details) &&
                selectedApplicantChannel?.partner_details?.map((item) => {
                  if (item?.kyc_completed) {
                    return (
                      <UserInformation
                        selectedData={item?.source_data}
                        _id={selectedApplicantChannel?._id}
                        partnerId={item?.id}
                        isPartnerData={true}
                        kycDocs={selectedApplicantChannel?.kycDocs}
                      />
                    );
                  } else {
                    return (
                      <OtherPartnerDetails currentApplicant={selectedApplicantChannel}
                        sendReminderToPartner={sendReminderToPartner}
                      />
                    )
                  }

                })}
            </div>

            <CenterInfo selectedData={selectedApplicantChannel?.source_data} />
            <AddtionalDetails selectedData={selectedApplicantChannel?.source_data} />
            {
              selectedApplicantChannel?.source_data?.partner_profiling ?
                <PartnerInformation selectedData={selectedApplicantChannel?.source_data} fullInfo={selectedApplicantChannel} /> : ''
            }
            {
              selectedApplicantChannel?.bank_name ?
                <BankInformation selectedData={selectedApplicantChannel} /> : ''
            }
            {
              selectedApplicantChannel?.document_signed ?
                <OtherInformation selectedData={selectedApplicantChannel} /> : ''
            }
            {
              Array.isArray(selectedApplicantChannel?.checkList) && selectedApplicantChannel?.checkList ?
                <ChecklistRequirement
                  selectedData={selectedApplicantChannel?.checkList}
                />
                : ''
            }
            {
              Array.isArray(selectedApplicantChannel?.phleboList) && selectedApplicantChannel?.phleboList?.length ?
                <PhleboInformation
                  selectedData={selectedApplicantChannel?.phleboList}
                  _id={selectedApplicantChannel?._id}
                /> : ''
            }
            {
              selectedApplicantChannel?.weekday_start_time ?
                <>
                  <BrandingPhotos _id={selectedApplicantChannel?._id} />
                  <ServiceTiming selectedData={selectedApplicantChannel} />
                </>
                : ''
            }
            {
              Array.isArray(selectedApplicantChannel?.reject_remarks) && selectedApplicantChannel?.reject_remarks?.length ?
                <Remarks role="Channel Team" selectedData={selectedApplicantChannel?.reject_remarks} /> : ''
            }
            {
              Array.isArray(selectedApplicantChannel?.clarification_history) && selectedApplicantChannel?.clarification_history?.length ?
                <Clarifications role="Channel Team" selectedData={selectedApplicantChannel?.clarification_history} userData={userData} /> : ''
            }
            <ActivityTracker selectedData={selectedApplicantChannel?.approval_history} />

            <div className="layout-button">
              <div>
                <Button type="ghost" label="Back" onClick={handleBack} />
              </div>
              <div>
                {
                  getStatusBasedBtn('reject', active_step, status) &&
                  <Button
                    label="REJECT"
                    type="reject"
                    loading={rejectLoading}
                    onClick={() => {
                      setSelectedOption("Reject")
                      setShowOverlay(true)
                    }}
                  />
                }

                {getStatusBasedBtn('rejected', active_step, status) && (
                  <Button label="REJECTED" type="reject" disabled />
                )}

                {
                  (selectedApplicantChannel?.active_step === 5 && !selectedApplicantChannel?.payment_approved) ? //TODO:add this && status !== "Rejected" to fix 
                    <Button label="APPROVE PAYMENT" loading={loading} onClick={() => {
                      setShowOverlay(true)
                      setSelectedOption("approve")
                    }} /> : ''
                }
              </div>

            </div>
            {showOverlay && (
              <ReviewOverlay
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                setRemarkComment={setRemarkComment}
                handleReject={handleReject}
                handleSubmit={handleSubmit}
                selectedOption={selectedOption}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChannelTeamReview;
