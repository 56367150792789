import { loadState } from "../subscribe/persistReduxStore";
import {
  FETCH_PARTNER_DETAILS_REQUEST,
  FETCH_PARTNER_DETAILS_SUCCESS,
  FETCH_PARTNER_DETAILS_FAILURE,
  SET_APPLICANT_SECOND_JOURNEY_SELECTED_DATA,
  STORE_PARTNER_LIST,
  STORE_AADHAR_CLIENT_ID,
  FETCH_APPLICATIONS_REQUEST,
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_APPLICATIONS_FAILURE,
  SET_APPLICANT_DATA,
  SET_SELECTED_APPLICATION,
  FETCH_KYC_INVITATION_REQUEST,
  FETCH_KYC_INVITATION_SUCCESS,
  FETCH_KYC_INVITATION_FAILURE,
  FETCH_KYC_FORM_REQUEST,
  FETCH_KYC_FORM_SUCCESS,
  FETCH_KYC_FORM_FAILURE,
  SAVE_SECOND_JOURNEY_DRAFT_APPLICATIONS
} from "./AddPartnerDetailsTypes";

const { addPartnerDetails = {} } = loadState();
const { loading, formFields, selectedData, error } = addPartnerDetails;

const initialState = {
  loading: false,
  addPartnerDetailsFields: [],
  applications: [],
  error: "",
  selectedData: selectedData || {},
  partnerList: [],
  aadharClientID: '',
  loggedInApplicant: {},
  kycFormFields: [],
  selectedApplication: {},
  aadharClientID: '',
  draftApplications: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //PARTNER DETAILS
    case FETCH_PARTNER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        addPartnerDetailsFields: action.payload,
        error: "",
      };
    case FETCH_PARTNER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        addPartnerDetailsFields: [],
        error: action.payload,
      };
    case SET_APPLICANT_SECOND_JOURNEY_SELECTED_DATA:
      return {
        ...state,
        selectedData: { ...state.selectedData, [action.key]: action.payload },
      };
    case "CLEAR_SECOND_JOURNEY_SELECTED_DATA": {
      return {
        ...state,
        selectedData: {},
      };
    }
    case STORE_PARTNER_LIST:
      return {
        ...state,
        partnerList: action.payload,
        error: "",
      };
    case STORE_AADHAR_CLIENT_ID:
      return {
        ...state,
        aadharClientID: action.payload,
        error: "",
      };

    case FETCH_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: action.payload,
        error: "",
      };

    case FETCH_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        addPartnerDetailsFields: [],
        error: action.payload,
      };
    case SET_APPLICANT_DATA:
      return {
        ...state,
        loggedInApplicant: action.payload,
      };
    case SET_SELECTED_APPLICATION:
      return {
        ...state,
        selectedApplication: action.payload,
      };
    // Mail KYC INVITATION
    case FETCH_KYC_INVITATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_KYC_INVITATION_SUCCESS:
      return {
        loading: false,
        sendMailKycInvitation: action.payload,
        error: "",
      };
    case FETCH_KYC_INVITATION_FAILURE:
      return {
        loading: false,
        sendMailKycInvitation: [],
        error: action.payload,
      };
    case FETCH_KYC_FORM_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case FETCH_KYC_FORM_SUCCESS: {
      return {
        ...state,
        kycFormFields: action.payload,
        error: "",
      }
    }
    case FETCH_KYC_FORM_FAILURE: {
      return {
        ...state,
        loading: false,
        kycFormFields: [],
        error: action.payload,
      }
    }
    case SAVE_SECOND_JOURNEY_DRAFT_APPLICATIONS: {
      return {
        ...state,
        draftApplications: action.payload
      }
    }
    default:
      return state;
  }
};

export default reducer;
