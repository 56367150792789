import { Container, Row, Col } from "react-bootstrap";
import profile from "../../../../assets/images/dummyProfile.jpg";
import "./index.scss";
import HorizontalLine from "../HorizontalLine/index.js"
import { colorPickerFun} from "../../../../Helper";

const data = [
  {
    id: 1,
    name: "Monica",
    role: "Cluster Head",
    remark:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus consectetur ea ullam eligendi ut velit et minima! Pariatur eos aliquam eaque? Quo vitae nam atque vel ut pariatur consequuntur eaque! ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus consectetur ea ullam eligendi ut velit et minima! Pariatur eos aliquam eaque? Quo vitae nam atque vel ut pariatur consequuntur ",
  },
  {
    id: 1,
    name: "Rachel",
    role: "Cluster Head",
    remark:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus consectetur ea ullam eligendi ut velit et minima! Pariatur eos aliquam eaque? Quo vitae nam atque vel ut pariatur consequuntur eaque!",
  },
  {
    id: 1,
    name: "Joey",
    role: "Cluster Head",
    remark:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus consectetur ea ullam eligendi ut velit et minima! Pariatur eos aliquam eaque? Quo vitae nam atque vel ut pariatur consequuntur eaque!",
  },
  {
    id: 1,
    name: "Ross",
    role: "Cluster Head",
    remark:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus consectetur ea ullam eligendi ut velit et minima! Pariatur eos aliquam eaque? Quo vitae nam atque vel ut pariatur consequuntur eaque!",
  },
];


const Remarks = ({ remarkComment, selectedData, role, userData, currentApplicant = null }) => {
  var remarkComments = []

  selectedData && selectedData?.map((comments) => {
    var remarkComment = {
      id: userData?._id,
      name: comments?.name,
      role: comments?.role,
      remark: comments?.remark
    }
    remarkComments.push(remarkComment);
  })


  return (
    <section className="remark-info" id="remarks">
      <HorizontalLine />
      <Container fluid={true}>
        {
          remarkComments && remarkComments?.length ?
            <Row>
              <p className="widget-info__title">Remarks</p>
            </Row> : ''
        }
        {
          remarkComments?.map((item, i) => (
            <Row>
              <Col lg={12}>
                <div className="clarification-info__profile d-flex">
                  <div className="clarification-info__image">
                    <div className="clarification-info__name" style={{ background: colorPickerFun(item?.name[0]) }}>
                      <div className="clarification-info__name_firsttext">
                        {item?.name[0]?.toUpperCase()}
                      </div>
                    </div>
                  </div>
                  <div className="clarification-info__profile-name">
                    <span>{item?.name}</span>
                    <span>{item?.role}</span>
                  </div>
                </div>
                <div className="clarification-info__data" style={{ marginLeft: '5px', paddingTop: '5px' }}>{item.remark}</div>
              </Col>
            </Row>
          ))
        }


        {
          currentApplicant && currentApplicant?.remarks?
            <Row>
              <Col lg={12}>
                <div className="remark-info__profile d-flex">
                  <div className="clarification-info__image">
                    <div className="clarification-info__name" style={{ background: colorPickerFun(userData?.first_name[0]) }}>
                      <div className="clarification-info__name_firsttext">
                        {userData?.first_name[0]?.toUpperCase()}
                      </div>
                    </div>
                  </div>
                  <div className="remark-info__profile-name">
                    <span>{userData?.first_name}</span>
                    <span>{role}</span>
                  </div>
                </div>
                <div className="clarification-info__data" style={{ marginLeft: '5px', paddingTop: '5px' }}>{currentApplicant?.remarks}</div>
              </Col>
            </Row>
            : ''
        }

      </Container>
    </section>
  );
};

export default Remarks;
