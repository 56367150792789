import ReviewDetails from "../../ReviewDetails"; // Don't remove this unused import - Ayush
import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import { Toaster, toast } from 'react-hot-toast'

import { APPLICATION_STATUSES } from "../../../constants/status.constants";
import BankInformation from "../../ReviewDetails/components/BankInformation";
import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";
import {
  SUPER_ADMIN_REVIEW,
  SUPER_ADMIN_DASHBOARD,
  SUPER_ADMIN_DASHBOARD_EDIT,
  RMM_REVIEW,
  RMM_DASHBOARD,
  CLUSTER_HEAD_REVIEW_EDIT
} from "../../../constants/route.constants.js/admin";

import { setAdminSelectedData } from '../../../redux/Admin/DashboardActions'
import Clarifications from '../../ReviewDetails/components/Clarification/index';
import { clearAdminSelectedData } from "../../../redux/Admin/DashboardActions";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
// import { getStatusBasedBtn } from '../utils/getStatusBasedBtn';
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import { loginInfo } from "../../../redux/Login/LoginActions"
import { CLUSTER_HEAD_DASHBOARD } from "../../../constants/route.constants.js/cluster_head";
import ActivityTracker from "../../ReviewDetails/components/ActivityTracker";

var rejectStatus = false;

const ReviewOverlay = ({
  showOverlay,
  setShowOverlay,
  setRemarkComment,
  applicant,
  handleReject,
  selectedOption
}) => {
  const [overlayData, setOverlayData] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  const handleAddPartner = () => {
    setShowOverlay(true);
  };
  const handleSuccessClarify = () => {
    history.push(SUPER_ADMIN_DASHBOARD)
  }

  const userData = dispatch(loginInfo)?.data;
  // const handleSubmitOverlay = () => {
  //   setShowOverlay(false);
  //   setRemarkComment(overlayData);
  //   if (rejectStatus === true) {
  //     dispatch(clarifyApplication(applicant, userData, overlayData, handleSuccessClarify))
  //   } else {
  //     handleReject(overlayData)
  //     // dispatch(rejectApplication(applicant, overlayData));
  //   }
  // };
  return (
    <div>
      {!rejectStatus && (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Remarks"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType={selectedOption === "Reject" ? "red" : ""}
          buttonSubmitLabel={selectedOption === "Reject" ? "REJECT" : "SUBMIT"}
          handleCloseOverlay={handleCloseOverlay}
        >
          <Textarea placeholder="Please specify the details of the remarks" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      )}

      {rejectStatus && (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Clarification"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType=""
          buttonSubmitLabel="SUBMIT"
          handleCloseOverlay={handleCloseOverlay}

        >
          <Textarea placeholder="Please specify the details of the clarification required" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      )}
    </div>
  );
};


const ReviewDetailsAdmin = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch()

  const [applicant, setApplicants] = useState()
  const [showOverlay, setShowOverlay] = useState(false);
  const [remarkComment, setRemarkComment] = useState();
  const _id = location?.state?._id
  const [selectedOption, setSelectedOption] = useState('')

  const applicants = useSelector((state) => state?.superAdmin?.applicantsList)
  const [routePath, setRoutePath] = useState()
  const selectedData = useSelector((state) => state?.superAdmin?.selectedData);
  // console.log("selected data", selectedData)
  const selectedApplicant = useSelector((state) => state.superAdmin.selectedApplicant)
  const userData = dispatch(loginInfo)?.data;

  const completedData = { ...selectedData, ...selectedApplicant.source_data }
  //Reject Loading 
  const rejectLoading = useSelector((state) => state?.globalReducer?.rejectLoading)
  const loading = useSelector((state) => state?.globalReducer?.submitLoading)

  const clarifyLoading = useSelector((state) => state?.globalReducer?.clarifyLoading)
  const kycFiles = useSelector(
    (state) => state?.onBoadringForm?.kycFile
  );
  useEffect(() => {
    setApplicants({ ...applicants?.find((item) => item._id === _id) })
    
  }, [])

  useEffect(() => {
    setRoutePath(location.pathname);
  }, []);
  useEffect(() => {
    if (selectedApplicant && Object.keys(selectedApplicant)?.length) {
      if (selectedApplicant?.is_draft) {

        if (selectedApplicant?.source_data?.shop_finalized.toLowerCase() === "no") {
          dispatch(setAdminSelectedData('place_rented', selectedApplicant?.place_rented))
          dispatch(setAdminSelectedData('floor', selectedApplicant?.floor))
          dispatch(setAdminSelectedData('size_in_sqft', selectedApplicant?.size_in_sqft))
          dispatch(setAdminSelectedData('capacity', selectedApplicant?.capacity))
          dispatch(setAdminSelectedData('washroom_available', selectedApplicant?.washroom_available))
          dispatch(setAdminSelectedData('parking_available', selectedApplicant?.parking_available))
          dispatch(setAdminSelectedData('address', selectedApplicant?.address))
        }

        dispatch(setAdminSelectedData('partner_profiling', selectedApplicant?.partner_profiling))
        dispatch(setAdminSelectedData('srl_franchise', selectedApplicant?.srl_franchise))
        dispatch(setAdminSelectedData('business_potential', selectedApplicant?.business_potential))
        dispatch(setAdminSelectedData('security_deposit', selectedApplicant?.security_deposit))
        dispatch(setAdminSelectedData('subscription', selectedApplicant?.subscription))
        dispatch(setAdminSelectedData('ownership', selectedApplicant?.ownership))
        dispatch(setAdminSelectedData('tagging_bdc', selectedApplicant?.tagging_bdc))
        dispatch(setAdminSelectedData('remarks', selectedApplicant?.remarks))
      }
    }
  }, [])

  // const adPartnerDetails = () => {
  //   history.push(CLUSTER_HEAD_PARTNER_FORM, { _id: _id })
  // };

  const handleError = (error) => {
    toast.error(error)
  }

  // const handleSuccess = (message) => {
  //   // toast.success(success)
  //   dispatch(clearAdminSelectedData())

  //   history.push(CLUSTER_HEAD_SUBMIT, message)

  // }
  // const handleReject = (text) => {
  //   const payload = {
  //     remark: text,
  //     name: userData?.profile?.first_name,
  //     role: userData?.role_name,
  //     _id: selectedApplicant?._id,
  //   };

  //   // let formData = new FormData();
  //   const rejectCommentsHistory = {
  //     applicationId: selectedApplicant?._id,
  //     ...selectedApplicant,
  //     status: APPLICATION_STATUSES['Rejected']['label']
  //   };
  //   rejectCommentsHistory?.reject_remarks?.push(payload);

  //   dispatch(startRejectApplicant(rejectCommentsHistory, userData, handleSuccess, handleError))
  // };
  // const makeChange = () => {
  //   history.push(CLUSTER_HEAD_PARTNER_FORM, { formData: true, _id: _id })
  // };

  const handleBack = () => {
    if(routePath.includes("/rmm")){
      history.push(RMM_DASHBOARD)

    }else if(routePath.includes("/cluster-head")){
      history.push(CLUSTER_HEAD_DASHBOARD)
    }else{
      history.push(SUPER_ADMIN_DASHBOARD_EDIT)

    }
  }
  // const handleSubmit = () => {
  //   setApplicants({ ...applicants?.find((item) => item?._id === _id) })
  //   let applicantId = { ...applicants?.find((item) => item?._id === _id) }
  //   dispatch(submitSecondStep(applicantId, selectedData, kycFiles, userData, handleError, handleSuccess))
  //   //   const _id= data1?._id
  // };

  const handleCancel = () => {
    dispatch(clearAdminSelectedData())
    history.push(SUPER_ADMIN_DASHBOARD)
  }
  const handleRejectPopUp = () => {
    setShowOverlay(true);
    setSelectedOption('Reject')

    rejectStatus = false;
  }

  const handleClarificationPopUp = () => {
    setShowOverlay(true);
    rejectStatus = true;
    setSelectedOption('Clarification')
  }
  const { active_step, status } = selectedApplicant;
  const getPageData = () => {
    switch (routePath) {
      case CLUSTER_HEAD_REVIEW_EDIT:
        return (
          <section className="review-details__wrapper">
            <Container fluid={true}>
              <Row>
                <Col lg={12}>
                  <SlidingNavbar selectedData={selectedApplicant} />
                  <UserInformation
                    selectedData={selectedApplicant?.source_data}
                    currentApplicant={null}
                    _id={_id}
                    kycDocs={selectedApplicant?.kycDocs}
                    editButton = {true}
                  />
                  <div style={{ marginTop: "50px" }}>
                    {Array.isArray(selectedApplicant?.partner_details) &&
                      selectedApplicant?.partner_details?.map((item) => {
                        if (item?.source_data) {
                          return (
                            <UserInformation
                              selectedData={item?.source_data}
                              _id={selectedApplicant?._id}
                              partnerId={item?.id}
                              isPartnerData={true}
                              kycDocs={selectedApplicant?.kycDocs}
                            />
                          );
                        }
                      })}
                  </div>
                  <CenterInfo selectedData={selectedApplicant?.source_data} id={selectedApplicant._id} />
                  <AddtionalDetails selectedData={selectedApplicant?.source_data} />
                  {
                    selectedApplicant?.source_data?.partner_profiling ?
                      <PartnerInformation
                        selectedData={selectedApplicant?.source_data}
                      /> : ''
                  }
                  {
                    selectedApplicant?.bank_name ?
                      <BankInformation selectedData={selectedApplicant} /> : ''
                  }
                  {
                    (selectedApplicant?.document_id &&selectedApplicant?.docket_id) ?
                      <OtherInformation selectedData={selectedApplicant} /> : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.checkList) && selectedApplicant?.checkList ?
                      <ChecklistRequirement
                        selectedData={selectedApplicant?.checkList}
                      />
                      : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.phleboList) && selectedApplicant?.phleboList?.length ?
                      <PhleboInformation
                        selectedData={selectedApplicant?.phleboList}
                        _id={selectedApplicant?._id}
                      /> : ''
                  }
                  {
                    selectedApplicant?.weekday_start_time ?
                      <>
                        <BrandingPhotos _id={selectedApplicant?._id} />
                        <ServiceTiming selectedData={selectedApplicant} />
                      </>
                      : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.reject_remarks) && selectedApplicant?.reject_remarks?.length ?
                      <Remarks role="Super Admin" userData={userData?.profile} selectedData={selectedApplicant?.reject_remarks} currentApplicant={null} /> : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.clarification_history) && selectedApplicant?.clarification_history?.length ?
                      <Clarifications role="Super Admin" clarificaitonComments={selectedApplicant?.clarification_history} selectedData={selectedApplicant?.clarification_history} userData={userData} />
                      : ''
                  }
                              <ActivityTracker selectedData={selectedApplicant?.approval_history} />

                  <div className="layout-button">
                    <Button label="Back" onClick={handleBack} type="ghost" />
                 
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        );
      case SUPER_ADMIN_REVIEW:
        return (
          <section className="review-details__wrapper">
            <Container fluid={true}>
              <Row>
                <Col lg={12}>
                  <SlidingNavbar selectedData={selectedApplicant} />
                  <UserInformation
                    selectedData={selectedApplicant?.source_data}
                    currentApplicant={null}
                    _id={_id}
                    kycDocs={selectedApplicant?.kycDocs}
                    editButton = {true}
                  />
                  <div style={{ marginTop: "50px" }}>
                    {Array.isArray(selectedApplicant?.partner_details) &&
                      selectedApplicant?.partner_details?.map((item) => {
                        if (item?.source_data) {
                          return (
                            <UserInformation
                              selectedData={item?.source_data}
                              _id={selectedApplicant?._id}
                              partnerId={item?.id}
                              isPartnerData={true}
                              kycDocs={selectedApplicant?.kycDocs}
                            />
                          );
                        }
                      })}
                  </div>
                  <CenterInfo selectedData={selectedApplicant?.source_data} id={selectedApplicant._id} />
                  <AddtionalDetails selectedData={selectedApplicant?.source_data} />
                  {
                    selectedApplicant?.source_data?.partner_profiling ?
                      <PartnerInformation
                        selectedData={selectedApplicant?.source_data}
                        _id={selectedApplicant?._id}
                      /> : ''
                  }
                  {
                    selectedApplicant?.bank_name ?
                      <BankInformation selectedData={selectedApplicant} /> : ''
                  }
                  {
                    selectedApplicant?.document_signed ?
                      <OtherInformation selectedData={selectedApplicant} /> : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.checkList) && selectedApplicant?.checkList ?
                      <ChecklistRequirement
                        selectedData={selectedApplicant?.checkList}
                      />
                      : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.phleboList) && selectedApplicant?.phleboList?.length ?
                      <PhleboInformation
                        selectedData={selectedApplicant?.phleboList}
                        _id={selectedApplicant?._id}
                      /> : ''
                  }
                  {
                    selectedApplicant?.weekday_start_time ?
                      <>
                        <BrandingPhotos _id={selectedApplicant?._id} />
                        <ServiceTiming selectedData={selectedApplicant} />
                      </>
                      : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.reject_remarks) && selectedApplicant?.reject_remarks?.length ?
                      <Remarks role="Super Admin" userData={userData?.profile} selectedData={selectedApplicant?.reject_remarks} currentApplicant={null} /> : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.clarification_history) && selectedApplicant?.clarification_history?.length ?
                      <Clarifications role="Super Admin" clarificaitonComments={selectedApplicant?.clarification_history} selectedData={selectedApplicant?.clarification_history} userData={userData} />
                      : ''
                  }
                   <ActivityTracker selectedData={selectedApplicant?.approval_history} />

                  <div className="layout-button">
                    <Button label="Back" onClick={handleBack} type="ghost" />
                 
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        );
      case RMM_REVIEW:       return (
        <section className="review-details__wrapper">
          <Container fluid={true}>
            <Row>
              <Col lg={12}>
                <SlidingNavbar selectedData={selectedApplicant} />
                <UserInformation
                  selectedData={selectedApplicant?.source_data}
                  currentApplicant={null}
                  _id={_id}
                  kycDocs={selectedApplicant?.kycDocs}
                  editButton = {true}
                />
                <div style={{ marginTop: "50px" }}>
                  {Array.isArray(selectedApplicant?.partner_details) &&
                    selectedApplicant?.partner_details?.map((item) => {
                      if (item?.source_data) {
                        return (
                          <UserInformation
                            selectedData={item?.source_data}
                            _id={selectedApplicant?._id}
                            partnerId={item?.id}
                            isPartnerData={true}
                            kycDocs={selectedApplicant?.kycDocs}
                          />
                        );
                      }
                    })}
                </div>
                <CenterInfo selectedData={selectedApplicant?.source_data} id={selectedApplicant._id} />
                <AddtionalDetails selectedData={selectedApplicant?.source_data} />
                {
                  selectedApplicant?.source_data?.partner_profiling ?
                    <PartnerInformation
                      selectedData={selectedApplicant?.source_data}
                    /> : ''
                }
                {
                  selectedApplicant?.bank_name ?
                    <BankInformation selectedData={selectedApplicant} /> : ''
                }
                {
                  selectedApplicant?.document_signed ?
                    <OtherInformation selectedData={selectedApplicant} /> : ''
                }
                {
                  Array.isArray(selectedApplicant?.checkList) && selectedApplicant?.checkList ?
                    <ChecklistRequirement
                      selectedData={selectedApplicant?.checkList}
                    />
                    : ''
                }
                {
                  Array.isArray(selectedApplicant?.phleboList) && selectedApplicant?.phleboList?.length ?
                    <PhleboInformation
                      selectedData={selectedApplicant?.phleboList}
                      _id={selectedApplicant?._id}
                    /> : ''
                }
                {
                  selectedApplicant?.weekday_start_time ?
                    <>
                      <BrandingPhotos _id={selectedApplicant?._id} />
                      <ServiceTiming selectedData={selectedApplicant} />
                    </>
                    : ''
                }
                {
                  Array.isArray(selectedApplicant?.reject_remarks) && selectedApplicant?.reject_remarks?.length ?
                    <Remarks role="Super Admin" userData={userData?.profile} selectedData={selectedApplicant?.reject_remarks} currentApplicant={null} /> : ''
                }
                {
                  Array.isArray(selectedApplicant?.clarification_history) && selectedApplicant?.clarification_history?.length ?
                    <Clarifications role="Super Admin" clarificaitonComments={selectedApplicant?.clarification_history} selectedData={selectedApplicant?.clarification_history} userData={userData} />
                    : ''
                }
              <ActivityTracker selectedData={selectedApplicant?.approval_history} />

                <div className="layout-button">
                  <Button label="Back" onClick={handleBack} type="ghost" />
               
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      );      
      default:
        return <div></div>;
    }
  };
  return <div>
    <Toaster />
    {getPageData()}
    {showOverlay && (
      <ReviewOverlay
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
        setRemarkComment={setRemarkComment}
        applicant={location?.state?.review_data}
        selectedOption={selectedOption}
      />
    )}
  </div>;
};

export default ReviewDetailsAdmin;
