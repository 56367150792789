import { RoleConstants } from "../../../../constants/app.constants";

const { BUSINESS_HEAD, CHANNEL_TEAM, IT_TEAM, CMO } = RoleConstants;
export const sliderNavTabList = [
  { id: 1, key: "Basic Info", title: "Basic Information ", elId: "basic-info", key: 'all' },
  { id: 2, key: "Center Info", title: "Center Information", elId: "center-info", key: 'all' },
  { id: 3, key: "Addition Info", title: "Addition Information", elId: "additional-info", key: 'all' },
  { id: 4, key: "Partner Info", title: "Partner Information", elId: "partner-info", key: 'partner_profiling' },
  { id: 5, key: "Bank Info", title: "Bank Information", elId: "bank-info", key: 'bank_name' },
  { id: 6, key: "Other Info", title: "Other Information", elId: "other-info", key: 'document_signed' },
  { id: 7, key: "Checklist Info", title: "Checklist Requirement Information", elId: "checklist-info", key: 'checkList' },
  { id: 8, key: "Phlebo Info", title: "Phlebo Information", elId: "phlebo-info", key: 'phleboList' },
  { id: 9, key: "Branding Photos", title: "Branding Photos", elId: "branding-photos", key: 'weekday_start_time' },
  { id: 10, key: "Timings Info", title: "Service Timings and location Information", elId: "timings-info", key: 'weekday_start_time' },
  { id: 11, key: "Remarks", title: "Remarks", elId: "remarks", key: 'reject_remarks' },
  { id: 12, key: "Clarification", title: "Clarification", elId: "clarification-section", key: 'clarification_history' }
]