import "./index.scss";

const ListDivider = () => {
    return (
        <div className="lds-list-divider--wrapper">
          <div className="lds-list-divider--line"></div>
        </div>
    );
};

export default ListDivider;
