import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router";
import axios from 'axios';
import { Helpers } from "../../Helper";
import Login from "../../components/Login";
import { signIn } from "../../redux/Login/LoginActions";
import { UserRouteMap } from "../../constants/app.constants";
import { Toaster } from "react-hot-toast";
import { loginInfo } from "../../redux/Login/LoginActions"

const GlobalLogin = () => {
    const [ip_address, setIpAddress] = useState('')
    const [browser_name, setBrowserName] = useState('')

    const dispatch = useDispatch();
    const history = useHistory();
    const cb = (data) => {
        const { role_name } = data;
        history.push(UserRouteMap[role_name]);
    }
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIpAddress(res.data.IPv4)
    }
    useEffect(() => {
        const data = Helpers.get_browser_name()
        getData()
        setBrowserName(data)
    }, [])

    const handleLogin = (data) => {
        const createAuthPayload = {
            username: data.username,
            password: data.password,
            tokenId: "12345",
            fingerprint: "12345",
            params: {
                ipInfo: ip_address,
                browserName: browser_name
            }
        }
        dispatch(signIn(cb, createAuthPayload));
    }

    return (
        <Fragment>
            <Toaster />
            <Login handleLogin={handleLogin} />
        </Fragment>
    )
}

export default GlobalLogin;