import { useEffect, useState } from "react";
import DynamicForm from "../../../../components/DynamicForm";
import { useSelector, useDispatch } from "react-redux";
import { fetchPartnerDetailsFormFields } from "../../../../redux";
import { useHistory } from "react-router-dom";
import { CLUSTER_HEAD_REVIEW_FORM, CLUSTER_HEAD_DASHBOARD } from "../../../../constants/route.constants.js/cluster_head";
import { useLocation } from "react-router";
// import { fetchPin_codeDetails } from "../../../../redux/Dashboard/DashboardActions";
import { clearClusterSelectedData, setClusterSelectedData } from '../../../../redux/Dashboard/DashboardActions.js';
import { Toaster, toast } from 'react-hot-toast'
import { saveDraft } from '../../../../redux/ClusterHead'
import { fetchPartnerDetailsFormNotFinalized } from "../../../../redux/PartnerFormDetails/PartnerFormDetailsActions";
import './index.scss'
const PartnerDetailsForm = () => {
  let history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const _id = location?.state?._id
  const formData = location?.state?.formData
  const [make, setMakeChange] = useState(formData)
  const [fromCluster, setFromCluster] = useState(true)

  const selectedData = useSelector((state) => state?.clusterHead?.selectedData);
  const selectedApplicant = useSelector((state) => state?.clusterHead?.selectedApplicant)
  const formFields = useSelector((state) => state?.partnerDetailsForm?.partnerDetailsFields);
  const FormFieldsNotFinalized=useSelector((state)=>state?.partnerDetailsForm?.partnerDetailsNotFields)
  


  useEffect(() => {
    if (selectedApplicant?.source_data?.shop_finalized?.toLowerCase() === "yes") {
      dispatch(fetchPartnerDetailsFormFields("yes"));
    }
    else {
      dispatch(fetchPartnerDetailsFormNotFinalized());
    }
  }, []);

  const toggleFunction = () => {
    setFromCluster(!fromCluster)
  }


  const handleNextClick = (data) => {
    const source_data = {}
    const newData = { ...selectedData, _id: _id }
    history.push(CLUSTER_HEAD_REVIEW_FORM, { review_data: { source_data }, _id: _id, forData: "partnerform" })
  };

  const handleSuccess = (message) => {
    toast.success(message)
    history.push(CLUSTER_HEAD_DASHBOARD)
  }

  const handleError = (message) => {
    toast.error(message)
  }
  const handleCancelClick=()=>{
   dispatch( clearClusterSelectedData())
   history.push(CLUSTER_HEAD_DASHBOARD)
  }
  const handleSaveDraft = () => {
    const payload = {
      ...selectedApplicant,
      ...selectedData,
      is_draft: true,
      organisation_id: selectedApplicant.organisation_id
    }
    dispatch(saveDraft(payload, handleSuccess, handleError))
  }
  return (
    <div className="parter-details-container">
      <Toaster />
      {selectedApplicant?.source_data?.shop_finalized?.toLowerCase() === "yes"
        ?
        <DynamicForm
          formFields={formFields}
          make={make}
          selectedData={selectedData}
          setSelectedData={setClusterSelectedData}
          shop_finalized={true}
          toggleFunction={toggleFunction}
          handleNextClick={handleNextClick}
          handleSaveDraft={handleSaveDraft}
          handleCancelClick={handleCancelClick}
        />
        :
          <DynamicForm
            formFields={FormFieldsNotFinalized}
            make={make}
            selectedData={selectedData}
            setSelectedData={setClusterSelectedData}
            shop_not_finalized={true}
            toggleFunction={toggleFunction}
            handleNextClick={handleNextClick}
            handleSaveDraft={handleSaveDraft}
            handleCancelClick={handleCancelClick}
          />
      }
    </div>
  );
};

export default PartnerDetailsForm;