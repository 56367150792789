import {
  FETCH_PARTNER_FORM_REQUEST,
  FETCH_PARTNER_FORM_SUCCESS,
  FETCH_PARTNER_FORM_FAILURE,
  FETCH_PARTNER_FORM_NOT_REQUEST,
  FETCH_PARTNER_FORM_NOT_SUCCESS,
  FETCH_PARTNER_FORM_NOT_FAILURE,
  PARTNER_SELECTED_DATA
} from "./PartnerFormDetailsTypes";

const initialState = {
  loading: false,
  partnerDetailsFields: [],
  partnerDetailsNotFields:[],
  error: "",
  selectedData: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNER_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PARTNER_FORM_SUCCESS:
      return {
        loading: false,
        partnerDetailsFields: action.payload,
        error: "",
      };
    case FETCH_PARTNER_FORM_FAILURE:
      return {
        loading: false,
        partnerDetailsFields: [],
        error: action.payload,
      };
      case FETCH_PARTNER_FORM_NOT_REQUEST:{
        return {
          ...state,
          loading: true,
        };
      }
      case FETCH_PARTNER_FORM_NOT_SUCCESS:{
         return{

         loading: false,
         partnerDetailsNotFields:action.payload,
          error: "",
         }
      }
      case FETCH_PARTNER_FORM_NOT_FAILURE:{
        return {
          loading: false,
          partnerDetailsNotFields: [],
          error: action.payload,
        };
      }
      case PARTNER_SELECTED_DATA:{
        return {
          ...state,
          selectedData: { ...state.selectedData, [action.key]: action.payload },
        };
      }
    default:
      return state;
  }
};

export default reducer;
