import axios from "axios";
import { GET_GEO_LOCATION_ENDPOINT } from "../../constants/api.constants.js";
import {
  APPLICANT_LIST_REQUEST,
  APPLICANT_LIST_SUCCESS,
  APPLICANT_LIST_FAILURE,
  GET_GEO_LOCATION_REQUEST,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_FAILURE,
} from "./ITTeamTypes";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status.js";
import { toast } from 'react-hot-toast'
import { setClarificationLoading, setSubmitLoading } from "../App/Action.js";
import requestV1 from "../../utils/requestV1.js";

//Fetch Applicants
export const fetchApplicantsList = (itTeamId) => async (dispatch) => {
  const Url = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ active_step: 7, organisation_id: process.env.REACT_APP_ORGANIZATION_ID })
  };
  try {
    dispatch(fetchApplicantsListRequest())
    const response = await requestV1(Url, options)
    const { data, status, message } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(fetchApplicantsListSuccess(data));
    } else {
      toast.error(data?.message)
      dispatch(fetchApplicantsListSuccess([]));

    }
  }
  catch (err) {
    toast.error("Network Error")
    dispatch(fetchApplicantsListFailure(err.message));
  };
};

export const fetchApplicantsListRequest = () => {
  return {
    type: APPLICANT_LIST_REQUEST,
  };
};

export const fetchApplicantsListSuccess = (data) => {
  return {
    type: APPLICANT_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchApplicantsListFailure = () => {
  return {
    type: APPLICANT_LIST_FAILURE,
  };
};


// Get Geolocation data
export const getGeoLocaitonData = () => async (dispatch) => {
  const reqUri = GET_GEO_LOCATION_ENDPOINT;
  dispatch(getGeoLocaitonDataRequest());
  axios
    .get(reqUri)
    .then((response) => {
      dispatch(getGeoLocaitonDataSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getGeoLocaitonDataFailure(err.message));
    });
};

export const getGeoLocaitonDataRequest = () => {
  return {
    type: GET_GEO_LOCATION_REQUEST,
  };
};

export const getGeoLocaitonDataSuccess = (data) => {
  return {
    type: GET_GEO_LOCATION_SUCCESS,
    payload: data,
  };
};

export const getGeoLocaitonDataFailure = () => {
  return {
    type: GET_GEO_LOCATION_FAILURE,
  };
};


export const clarificationITTeam = (payload, handleClarificationSuccess, handleError) => async (dispatch, getState) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };

  try {
    dispatch(setClarificationLoading(true))
    const response = await requestV1(reqUri, options)
    const { message, data, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleClarificationSuccess(message)
      dispatch(setClarificationLoading(false))
    } else {
      handleError(data?.message)
      dispatch(setClarificationLoading(false))
    }
  } catch (error) {
    handleError("Network Error")
    dispatch(setClarificationLoading(false))
  }
};

//submit client code
export const submitClientCode = (payload, handleSuccess, handleError) => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };
  try {
    dispatch(setSubmitLoading(true))
    const response = await requestV1(reqUri, options)
    const { data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleSuccess()
      toast.success(message)
      dispatch(setSubmitLoading(false))
    } else {
      handleError(message)
      dispatch(setSubmitLoading(false))
    }
  } catch (error) {
    handleError("Network Error")
    dispatch(setSubmitLoading(false))
  }
}