import { useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import dots from "../../assets/images/threeDots.svg";
import EditPartnerList from "./EditPartnerList";

import certificate from "../../assets/images/certificate.svg";
import Image from "../../components/Image";
import { useSelector } from 'react-redux';

import Tooltip from "../../lib/components/Tooltip";
import ViewImage from "../ViewImage";

const TableContent = ({ partnerList, handleDelete, editPartnerList, phleboOnboarding, saveUploadedCertificates }) => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({});
  const [show, setShow] = useState(false)
  const [src, setSrc] = useState("")

  const certificateList = useSelector(state => state.uploadedDocument.certificates)

  const handleCloseOverlay = () => {
    setOpen(false);
  };

  const handleShow = (src, index) => {

    setShow(true)
    setSrc(src[index]?.preview)
    // setSrc(src)
    // setTitle(title)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleOpen = (id) => {
    setOpen(true);
    const result = partnerList.find((ele) => {
      return ele.id === id;
    });
    setDetails(result);
  };

  const showPartnerList = () => {
    return (
      <Table responsive className="add-list--table-container">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email Address</th>
            <th>Mobile Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {partnerList.map((ele, i) => {
            return (
              <tr key={ele.id}>
                <td>{ele.name}</td>
                <td>{ele.email}</td>
                <td>{ele.mobile}</td>
                <td>
                  <div className="dropdown">
                    <img src={dots} alt="dots" />
                    <div className="dropdown-content">
                      <span to="#" className="link" onClick={() => handleOpen(ele.id)}>
                        Edit
                      </span><br />
                      <span to="#" className="link" onClick={() => handleDelete(ele.id)}>
                        Delete
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>

        {open && (
          <EditPartnerList
            closeEdit={handleCloseOverlay}
            id={details.id}
            name={details.name}
            mobile={details.mobile}
            email={details.email}
            showOverlay={open}
            editPartnerList={editPartnerList}
            handleCloseOverlay={handleCloseOverlay}
            overlayHeader={"Edit Details"}
          />
        )}
      </Table>
    )
  }

  const showPhleboList = () => {
    return (
      <>
        <Table responsive className="table-list">
          <thead>
            <tr >
              <th>User Details</th>
              <th>Qualification</th>
              <th>DOB</th>
              <th>Experience</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {partnerList.map((ele, i) => {
              return (
                <tr key={ele.id} className="table-list-contents">
                  <td>{ele.first_name}<div className="table-list-mobile">+91-{ele.mobile_number}</div></td>
                  <td>
                    <Image
                      src={certificate}
                      onClick={() => handleShow(Object.values(certificateList), i)}
                      alt="certificate"
                    />
                    <span className="pl-2">
                      <Tooltip
                        onClick={() => handleShow(Object.values(certificateList), i)}
                        toolTipText={`${ele.qualification}_certificate`}
                        displayText={ele.qualification}
                      />
                    </span>
                  </td>
                  {/* <td>{ele.qualification}</td> */}
                  {/* Add Qualification svg file here <img src={qualification}/> */}
                  <td>{ele.date_of_birth}</td>
                  <td>{ele.phlebotomy_experience} Year</td>
                  <td>
                    <div className="dropdown">
                      <img src={dots} alt="dots" />
                      <div className="dropdown-content">
                        <Link to="#" className="link" onClick={() => handleOpen(ele.id)}>
                          Edit
                        </Link><br />
                        <Link to="#" className="link" onClick={() => handleDelete(ele.id)}>
                          Delete
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
          {open && (
            <EditPartnerList
              closeEdit={handleCloseOverlay}
              id={details.id}
              first_name={details.first_name}
              last_name={details.last_name}
              mobile_number={details.mobile_number}
              qualification={details.qualification}
              date_of_birth={details.date_of_birth}
              phlebotomy_experience={details.phlebotomy_experience}
              showOverlay={open}
              editPartnerList={editPartnerList}
              handleCloseOverlay={handleCloseOverlay}
              overlayHeader={"Edit Details"}
              phleboOnboarding={phleboOnboarding}
              saveUploadedCertificates={saveUploadedCertificates}
            />
          )}
        </Table>
        <ViewImage show={show} handleClose={handleClose} src={src} />
      </>
    )
  }

  return (
    <>
      {
        phleboOnboarding ? showPhleboList() : showPartnerList()
      }
    </>
  );
};
export default TableContent;
