export const FETCH_BUSINESS_HEAD_REQUEST = "FETCH_BUSINESS_HEAD_REQUEST";
export const FETCH_BUSINESS_HEAD_SUCCESS = "FETCH_BUSINESS_HEAD_SUCCESS";
export const FETCH_BUSINESS_HEAD_FAILURE = "FETCH_BUSINESS_HEAD_FAILURE";

//Step two applicats data

export const FETCH_STEP_TWO_APPLICANTS = "FETCH_STEP_TWO_APPLICANTS";
export const DASH_BOARD_SELECTED_DATA = "DASH_BOARD_SELECTED_DATA";

//Pin_code Constant

export const FETCH_PIN_CODE_SUCCESS = "FETCH_PIN_CODE_SUCCESS";

export const FETCH_PIN_CODE_FAILURE = "FETCH_PIN_CODE_FAILURE";
export const CLUSTER_SELECTED_DATA = "CLUSTER_SELECTED_DATA";
export const SAVE_DRAFT_JOBS = "SAVE_DRAFT_JOBS";

//To Download workflow

export const DOWNLOAD_WORKFLOW_CSV_SUCCESS = "DOWNLOAD_WORKFLOW_CSV_SUCCESS";
