export const getTime = (data) => {
    const date = new Date(data);
    let hours = date.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12;
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let seconds = date.getSeconds();
    seconds = seconds < 10 ? '0'+seconds : seconds;
    const ampm = hours >= 12 ? 'PM' : 'AM';
    return `${hours}:${minutes}:${seconds} ${ampm}`
}