import { BUSINESS_HEAD_DASHBOARD } from "./route.constants.js/business_head";
import { CLUSTER_HEAD_DASHBOARD } from "./route.constants.js/cluster_head";
import { RMM_DASHBOARD, RMM_REVIEW, SUPER_ADMIN_DASHBOARD } from "./route.constants.js/admin";
import { CMO_DASHBOARD } from "./route.constants.js/cmo";
import { CHANNEL_TEAM_DASHBOARD } from './route.constants.js/channelTeam';
import { LEGAL_TEAM_DASHBOARD } from './route.constants.js/legalTeam'
import { IT_TEAM_DASHBOARD } from './route.constants.js/itTeam'
import { CLIENT_DASHBOARD_BASIC } from "./route.constants.js/applicant";
import { SUPER_ADMIN_DASHBOARD_EDIT } from "./route.constants.js/admin";
export const WORKFLOW_ID = "625f72d90e76479b6182b586"; //mongo doc id for srl workflow config

export const CLIENT_ALLOWED_STEPS = [4, 8, 5.1];

export const UserRouteMap = {
    "Cluster Head": CLUSTER_HEAD_DASHBOARD,
    "Business Head": BUSINESS_HEAD_DASHBOARD,
    "Channel Team": CHANNEL_TEAM_DASHBOARD,
    "Legal Team": LEGAL_TEAM_DASHBOARD,
    "IT Team": IT_TEAM_DASHBOARD,
    "CMO": CMO_DASHBOARD,
    "Super Admin": SUPER_ADMIN_DASHBOARD,
    "RMM": RMM_DASHBOARD
};

export const RETAILS_APP_USER_TYPE = "retailApp";


export const weekdayTimeSlotsAM = [
    { label: "12:00 AM" },
    { label: "01:00 AM" },
    { label: "02:00 AM" },
    { label: "03:00 AM" },
    { label: "04:00 AM" },
    { label: "05:00 AM" },
    { label: "06:00 AM" },
    { label: "07:00 AM" },
    { label: "08:00 AM" },
    { label: "09:00 AM" },
    { label: "10:00 AM" },
    { label: "11:00 AM" },
]

export const weekdayTimeSlotsPM = [
    { label: "12:00 PM" },
    { label: "01:00 PM" },
    { label: "02:00 PM" },
    { label: "03:00 PM" },
    { label: "04:00 PM" },
    { label: "05:00 PM" },
    { label: "06:00 PM" },
    { label: "07:00 PM" },
    { label: "08:00 PM" },
    { label: "09:00 PM" },
    { label: "10:00 PM" },
    { label: "11:00 PM" },
]

export const weekendTimeSlotsAM = [
    { label: "12:00 AM" },
    { label: "12:30 AM" },
    { label: "01:00 AM" },
    { label: "01:30 AM" },
    { label: "02:00 AM" },
    { label: "02:30 AM" },
    { label: "03:00 AM" },
    { label: "03:30 AM" },
    { label: "04:00 AM" },
    { label: "04:30 AM" },
    { label: "05:00 AM" },
    { label: "05:30 AM" },
    { label: "06:00 AM" },
    { label: "06:30 AM" },
    { label: "07:00 AM" },
    { label: "07:30 AM" },
    { label: "08:00 AM" },
    { label: "08:30 AM" },
    { label: "09:00 AM" },
    { label: "09:30 AM" },
    { label: "10:00 AM" },
    { label: "10:30 AM" },
    { label: "11:00 AM" },
    { label: "11:30 AM" },
]

export const weekendTimeSlotsPM = [
    { label: "12:00 PM" },
    { label: "12:30 PM" },
    { label: "01:00 PM" },
    { label: "01:30 PM" },
    { label: "02:00 PM" },
    { label: "02:30 PM" },
    { label: "03:00 PM" },
    { label: "03:30 PM" },
    { label: "04:00 PM" },
    { label: "04:30 PM" },
    { label: "05:00 PM" },
    { label: "05:30 PM" },
    { label: "06:00 PM" },
    { label: "06:30 PM" },
    { label: "07:00 PM" },
    { label: "07:30 PM" },
    { label: "08:00 PM" },
    { label: "08:30 PM" },
    { label: "09:00 PM" },
    { label: "09:30 PM" },
    { label: "10:00 PM" },
    { label: "10:30 PM" },
    { label: "11:00 PM" },
    { label: "11:30 PM" },
];

export const RoleConstants = {
    CLUSTER_HEAD: "Cluster Head",
    BUSINESS_HEAD: "Business Head",
    CHANNEL_TEAM: "Channel Team",
    LEGAL_TEAM: "Legal Team",
    IT_TEAM: "IT Team",
    CMO: "CMO",
    CLIENT : "Application User",
    SUPER_ADMIN: "Super Admin",
    RMM: "RMM"
};

export const LOGO_ROUTE_MAP = {
    //cluster head routes
    '/dashboard/cluster-head': CLUSTER_HEAD_DASHBOARD,
    '/review/cluster-head': CLUSTER_HEAD_DASHBOARD,
    '/partner-details-form': CLUSTER_HEAD_DASHBOARD,
    '/review/cluster-head-form': CLUSTER_HEAD_DASHBOARD,
    '/submit/cluster-head': CLUSTER_HEAD_DASHBOARD,
    //business head routes
    '/dashboard/business-head': BUSINESS_HEAD_DASHBOARD,
    '/review/business-head': BUSINESS_HEAD_DASHBOARD,
    '/submit/business-head': BUSINESS_HEAD_DASHBOARD,
    //APPLICANT routes
    '/dashboard/client-basic': CLIENT_DASHBOARD_BASIC,
    '/review/client-basic': CLIENT_DASHBOARD_BASIC,
    '/partner-documentation': CLIENT_DASHBOARD_BASIC,
    '/client/kyc': CLIENT_DASHBOARD_BASIC,
    '/client/kyc-otp': CLIENT_DASHBOARD_BASIC,
    '/submit/second-client': CLIENT_DASHBOARD_BASIC,
    //phlebo
    '/phlebo/onboarding': CLIENT_DASHBOARD_BASIC,
    '/phlebo/checklist': CLIENT_DASHBOARD_BASIC,
    '/phlebo/review': CLIENT_DASHBOARD_BASIC,
    '/phlebo/submit': CLIENT_DASHBOARD_BASIC,
    //Channel team routes
    '/dashboard/channel-team': CHANNEL_TEAM_DASHBOARD,
    '/review/channel-team': CHANNEL_TEAM_DASHBOARD,
    '/submit/channel-team': CHANNEL_TEAM_DASHBOARD,
    //Legal team routes
    '/dashboard/legal-team': LEGAL_TEAM_DASHBOARD,
    '/review/legal-team': LEGAL_TEAM_DASHBOARD,
    '/submit/legal-team': LEGAL_TEAM_DASHBOARD,
    //IT team routes
    '/dashboard/it-team': IT_TEAM_DASHBOARD,
    '/review/it-team': IT_TEAM_DASHBOARD,
    '/submit/it-team': IT_TEAM_DASHBOARD,
    //CMO routes
    '/dashboard/cmo': CMO_DASHBOARD,
    '/review/cmo': CMO_DASHBOARD,
    '/submit/cmo': CMO_DASHBOARD,
    //cluster head routes
    '/dashboard/super-admin': SUPER_ADMIN_DASHBOARD,
    '/review/super-admin': SUPER_ADMIN_DASHBOARD,
    '/dashboard/super-admin-edit':SUPER_ADMIN_DASHBOARD_EDIT,
    '/dashboard/rmm': RMM_DASHBOARD,
    '/review/rmm':RMM_REVIEW
};

export const LOGIN_ROUTE = '/login';

export const RESET_PASSWORD_ROUTE = '/reset-password';

export const FORGOT_PASSWORD_ROUTE = '/forgot-password';

export const PHLEBO_MODAL_HEADER_CONSTANT = "Add Phlebo Details";
