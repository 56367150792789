import Input from "../../lib/components/Input";
import Button from "../../lib/components/Button";
import PasswordTick from "../../assets/images/passwordTick.svg";
import success from "../../assets/images/success.svg";

import { useState } from "react";
import { useHistory } from "react-router";
import "./index.scss";
import { GLOBAL_LOGIN_ROUTE } from "../../constants/route.constants.js";
import {Toaster ,toast }from "react-hot-toast";
const PasswordResetSuccess = () => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push(GLOBAL_LOGIN_ROUTE); //Redirect where you want to go
  };
  return (
    <div className="password-success">
      <p>Password Reset Successful!</p>
      <div className="password-success-img">
        <img src={success}  alt="success"/>
      </div>
      <p>
        You have successfully reset your password. Please login <br />
        to your account using the new password.
      </p>
      <div style={{ width: "100%" }}>
        <Button
          label="Login"
          style={{ width: "100%" }}
          onClick={handleRedirect}
        />
      </div>
    </div>
  );
};

const PasswordReset = ({ handleSubmit }) => {
  const [newPassword, setNewPassowrd] = useState("");
  const [reNewPassword, setRePassword] = useState("");
  const [isResetTrue, setIsResetTrue] = useState(false);

  const [lCase,setLCase]=useState(false)
  const [uCase,setUCase]=useState(false)
  const [nCase,setNCase]=useState(false)
  const [eCase,setECase]=useState(false)
  const [sCase,setSCase]=useState(false)

  const handleNewPassword=(e)=>{
    const attr=e.target.value
    setNewPassowrd(attr)
    const lowerCase=new RegExp('^(?=.*[a-z]$)') //lower Case  1
    const upperCase=new RegExp('^(?=.*[A-Z]$)') //Upper Case  1
    const numberCase=new RegExp('^(?=.*[0-9]$)') //number case 1
    const eightChar=new RegExp('^(?=.{8,})') // Eight char
    const specialCase =new RegExp('^(?=.*[!@#$%^&*]$)') // sppecial char 1
    if(lowerCase.test(attr)){
      setLCase(true)
    }
    if(upperCase.test(attr)){
      setUCase(true)
    }
    if(numberCase.test(attr)){
      setNCase(true)
    }
    if(specialCase.test(attr)){
      setSCase(true)
    }
    if(eightChar.test(attr)){
      setECase(true)
    }
    if(attr.length===0){
      setSCase(false)
      setUCase(false)
      setLCase(false)
      setECase(false)
      setNCase(false)
      setRePassword("")
    }
  }
  const handleResetPassword = () => {
    if (newPassword?.length !== 0 && reNewPassword?.length !== 0) {
      const data = {
        newPassword: newPassword,
        reNewPassword: reNewPassword,
      };
      if(newPassword !== reNewPassword ){
          return toast.error("Password do not match") 
      }
      handleSubmit(data, () => {
        setIsResetTrue(true);
      });
    }
    else{
      toast.error("Fields can't be empty")
    }
  };
  return (
    <>
    <Toaster/>
      {isResetTrue ? (
        <>
          <PasswordResetSuccess />
        </>
      ) : (
        <>
          <p>Reset Your Password</p>
          <Input
            label="New Password"
            name="new_password"
            type="password"
            value={newPassword}
            onChange={handleNewPassword}
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }}
             onCopy={(e)=>{
              e.preventDefault()
              return false
            }}
          />
          <Input
            label="Re-enter New Password"
            name="password"
            type="password"
            disabled={eCase && nCase && uCase && lCase && sCase ?false :true}
            title="Please enter correct password"
            value={reNewPassword}
            onChange={(e) => setRePassword(e.target.value)}
            onPaste={(e)=>{
              e.preventDefault()
              return false;
            }}
             onCopy={(e)=>{
              e.preventDefault()
              return false
            }}
          />
          <div className="password-validation">
            <div className="password-validation-one-set">
              <span>
                {eCase ? (
                  <img
                    src={PasswordTick}
                    className="password-validation-correct"
                    alt="validation-correct"
                  />
                ) : (
                  <img
                    src={PasswordTick}
                    className="password-validation-wrong"
                    alt="validation-wrong"
                  />
                )}
                At least 8 characters
              </span>
              <span>
                {nCase ? (
                  <img src={PasswordTick} className="password-validation-correct"   alt="validation-correct" />
                ) : (
                  <img src={PasswordTick} className="password-validation-wrong"   alt="validation-wrong"/>
                )
                }
                Numbers
              </span>
            </div>
            <div className="password-validation-two-set">
              <span>
                {uCase ? (
                     <img src={PasswordTick} className="password-validation-correct"   alt="validation-correct" />
                  )
                  :
                  <img src={PasswordTick} className="password-validation-wrong"   alt="validation-wrong" />    
                 }
                  Uppercase characters
              </span>
              <span>
                {lCase ? (
                     <img src={PasswordTick} className="password-validation-correct" alt="validation-correct" />
                  )
                  :(
                  <img src={PasswordTick} className="password-validation-wrong" alt="validation-wrong" />    
                  )
                }
                Lowercase characters
              </span>
            </div>
            <div className="password-validation-three-set">
               {sCase ?
                  <img src={PasswordTick} className="password-validation-correct"  alt="validation-correct"/> 
                :
                <img src={PasswordTick}  className="password-validation-wrong" alt="validation-wrong"/>
               }
               <span>Special characters including ! @ # $ %  ^ * and  &amp; </span>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              label="Reset Password"
              style={{ width: "100%" }}
              onClick={handleResetPassword}
            />
          </div>
        </>
      )}
    </>
  );
};
export default PasswordReset;
