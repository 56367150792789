import ReviewDetails from "../../ReviewDetails"; // Don't remove this unused import - Ayush
import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import { Toaster, toast } from 'react-hot-toast'

import { APPLICATION_STATUSES } from "../../../constants/status.constants";
import BankInformation from "../../ReviewDetails/components/BankInformation";
import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";
import {
  CLUSTER_HEAD_REVIEW,
  CLUSTER_HEAD_PARTNER_FORM,
  CLUSTER_HEAD_REVIEW_FORM,
  CLUSTER_HEAD_REVIEW_EDIT,
  CLUSTER_HEAD_SUBMIT,
  CLUSTER_HEAD_DASHBOARD,
} from "../../../constants/route.constants.js/cluster_head";

import { clarifyApplication, startRejectApplicant, submitSecondStep } from '../../../redux/ClusterHead'
import { setClusterSelectedData } from '../../../redux/Dashboard/DashboardActions'
import Clarifications from '../../ReviewDetails/components/Clarification/index';
import { clearClusterSelectedData } from "../../../redux/Dashboard/DashboardActions";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
import { getStatusBasedBtn } from '../utils/getStatusBasedBtn';
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import { loginInfo } from "../../../redux/Login/LoginActions"
import ActivityTracker from "../../ReviewDetails/components/ActivityTracker";

var rejectStatus = false;

const ReviewOverlay = ({
  showOverlay,
  setShowOverlay,
  setRemarkComment,
  applicant,
  handleReject,
  selectedOption
}) => {
  const [overlayData, setOverlayData] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  const handleAddPartner = () => {
    setShowOverlay(true);
  };
  const handleSuccessClarify = () => {
    history.push(CLUSTER_HEAD_DASHBOARD)
  }

  const userData = dispatch(loginInfo)?.data;
  const handleSubmitOverlay = () => {
    setShowOverlay(false);
    setRemarkComment(overlayData);
    if (rejectStatus === true) {
      dispatch(clarifyApplication(applicant, userData, overlayData, handleSuccessClarify))
    } else {
      handleReject(overlayData)
      // dispatch(rejectApplication(applicant, overlayData));
    }
  };
  return (
    <div>
      {!rejectStatus && (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Remarks"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType={selectedOption === "Reject" ? "red" : ""}
          buttonSubmitLabel={selectedOption === "Reject" ? "REJECT" : "SUBMIT"}
          handleCloseOverlay={handleCloseOverlay}
          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea placeholder="Please specify the details of the remarks" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      )}

      {rejectStatus && (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Clarification"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType=""
          buttonSubmitLabel="SUBMIT"
          handleCloseOverlay={handleCloseOverlay}

          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea placeholder="Please specify the details of the clarification required" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      )}
    </div>
  );
};


const ReviewDetailsCH = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch()

  const [applicant, setApplicants] = useState()
  const [showOverlay, setShowOverlay] = useState(false);
  const [remarkComment, setRemarkComment] = useState();
  const _id = location?.state?._id
  const [selectedOption, setSelectedOption] = useState('')

  const applicants = useSelector((state) => state?.clusterHead?.applicantsList)
  const [routePath, setRoutePath] = useState()
  const selectedData = useSelector((state) => state?.clusterHead?.selectedData);
  const selectedApplicant = useSelector((state) => state.clusterHead.selectedApplicant)
  const userData = dispatch(loginInfo)?.data;

  const completedData = { ...selectedData, ...selectedApplicant.source_data }
  //Reject Loading 
  const rejectLoading = useSelector((state) => state?.globalReducer?.rejectLoading)
  const loading = useSelector((state) => state?.globalReducer?.submitLoading)

  const clarifyLoading = useSelector((state) => state?.globalReducer?.clarifyLoading)
  const kycFiles = useSelector(
    (state) => state?.onBoadringForm?.kycFile
  );
  useEffect(() => {
    setApplicants({ ...applicants?.find((item) => item._id === _id) })
  }, [])

  useEffect(() => {
    setRoutePath(location.pathname);
  }, []);
  useEffect(() => {
    if (selectedApplicant && Object.keys(selectedApplicant)?.length) {
      if (selectedApplicant?.is_draft) {

        if (selectedApplicant?.source_data?.shop_finalized.toLowerCase() === "no") {
          dispatch(setClusterSelectedData('place_rented', selectedApplicant?.place_rented))
          dispatch(setClusterSelectedData('floor', selectedApplicant?.floor))
          dispatch(setClusterSelectedData('size_in_sqft', selectedApplicant?.size_in_sqft))
          dispatch(setClusterSelectedData('capacity', selectedApplicant?.capacity))
          dispatch(setClusterSelectedData('washroom_available', selectedApplicant?.washroom_available))
          dispatch(setClusterSelectedData('parking_available', selectedApplicant?.parking_available))
          dispatch(setClusterSelectedData('address', selectedApplicant?.address))
        }

        dispatch(setClusterSelectedData('partner_profiling', selectedApplicant?.partner_profiling))
        dispatch(setClusterSelectedData('srl_franchise', selectedApplicant?.srl_franchise))
        dispatch(setClusterSelectedData('business_potential', selectedApplicant?.business_potential))
        dispatch(setClusterSelectedData('security_deposit', selectedApplicant?.security_deposit))
        dispatch(setClusterSelectedData('marketing_deposit', selectedApplicant?.marketing_deposit))
        dispatch(setClusterSelectedData('rolling_advance', selectedApplicant?.rolling_advance))
        dispatch(setClusterSelectedData('subscription', selectedApplicant?.subscription))
        dispatch(setClusterSelectedData('ownership', selectedApplicant?.ownership))
        dispatch(setClusterSelectedData('tagging_bdc', selectedApplicant?.tagging_bdc))
        dispatch(setClusterSelectedData('remarks', selectedApplicant?.remarks))
      }
    }
  }, [])

  const adPartnerDetails = () => {
    history.push(CLUSTER_HEAD_PARTNER_FORM, { _id: _id })
  };

  const handleError = (error) => {
    toast.error(error)
  }

  const handleSuccess = (message) => {
    // toast.success(success)
    dispatch(clearClusterSelectedData())

    history.push(CLUSTER_HEAD_SUBMIT, message)

  }
  const handleReject = (text) => {
    const payload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: selectedApplicant?._id,
    };

    // let formData = new FormData();
    const rejectCommentsHistory = {
      applicationId: selectedApplicant?._id,
      ...selectedApplicant,
      status: APPLICATION_STATUSES['Rejected']['label']
    };
    rejectCommentsHistory?.reject_remarks?.push(payload);

    dispatch(startRejectApplicant(rejectCommentsHistory, userData, handleSuccess, handleError))
  };
  const makeChange = () => {
    history.push(CLUSTER_HEAD_PARTNER_FORM, { formData: true, _id: _id })
  };

  const handleBack = () => {
    history.push(CLUSTER_HEAD_DASHBOARD)
  }
  const handleSubmit = () => {
    setApplicants({ ...applicants?.find((item) => item?._id === _id) })
    let applicantId = { ...applicants?.find((item) => item?._id === _id) }
    dispatch(submitSecondStep(applicantId, selectedData, kycFiles, userData, handleError, handleSuccess))
    //   const _id= data1?._id
  };

  const handleCancel = () => {
    dispatch(clearClusterSelectedData())
    history.push(CLUSTER_HEAD_DASHBOARD)
  }
  const handleRejectPopUp = () => {
    setShowOverlay(true);
    setSelectedOption('Reject')

    rejectStatus = false;
  }

  const handleClarificationPopUp = () => {
    setShowOverlay(true);
    rejectStatus = true;
    setSelectedOption('Clarification')
  }
  const { active_step, status } = selectedApplicant;
  const getPageData = () => {
    switch (routePath) {
      case CLUSTER_HEAD_REVIEW:
        return (
          <section className="review-details__wrapper">
            <Container fluid={true}>
              <Row>
                <Col lg={12}>
                  <SlidingNavbar selectedData={selectedApplicant} />
                  <UserInformation
                    selectedData={selectedApplicant?.source_data}
                    currentApplicant={null}
                    _id={_id}
                    kycDocs={selectedApplicant?.kycDocs}
                  />
                  <div style={{ marginTop: "50px" }}>
                    {Array.isArray(selectedApplicant?.partner_details) &&
                      selectedApplicant?.partner_details?.map((item) => {
                        if (item?.source_data) {
                          return (
                            <UserInformation
                              selectedData={item?.source_data}
                              _id={selectedApplicant?._id}
                              partnerId={item?.id}
                              isPartnerData={true}
                              kycDocs={selectedApplicant?.kycDocs}
                            />
                          );
                        }
                      })}
                  </div>
                  <CenterInfo selectedData={selectedApplicant?.source_data} />
                  <AddtionalDetails selectedData={selectedApplicant?.source_data} />
                  {
                    selectedApplicant?.source_data?.partner_profiling ?
                      <PartnerInformation
                        selectedData={selectedApplicant?.source_data}
                      /> : ''
                  }
                  {
                    selectedApplicant?.bank_name ?
                      <BankInformation selectedData={selectedApplicant} /> : ''
                  }
                  {
                    selectedApplicant?.document_signed ?
                      <OtherInformation selectedData={selectedApplicant} /> : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.checkList) && selectedApplicant?.checkList ?
                      <ChecklistRequirement
                        selectedData={selectedApplicant?.checkList}
                      />
                      : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.phleboList) && selectedApplicant?.phleboList?.length ?
                      <PhleboInformation
                        selectedData={selectedApplicant?.phleboList}
                        _id={selectedApplicant?._id}
                      /> : ''
                  }
                  {
                    selectedApplicant?.weekday_start_time ?
                      <>
                        <BrandingPhotos _id={selectedApplicant?._id} />
                        <ServiceTiming selectedData={selectedApplicant} />
                      </>
                      : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.reject_remarks) && selectedApplicant?.reject_remarks?.length ?
                      <Remarks role="Cluster Head" userData={userData?.profile} selectedData={selectedApplicant?.reject_remarks} currentApplicant={null} /> : ''
                  }
                  {
                    Array.isArray(selectedApplicant?.clarification_history) && selectedApplicant?.clarification_history?.length ?
                      <Clarifications role="Cluster Head" clarificaitonComments={selectedApplicant?.clarification_history} selectedData={selectedApplicant?.clarification_history} userData={userData} />
                      : ''
                  }
                  <ActivityTracker selectedData={selectedApplicant?.approval_history} />

                  <div className="layout-button">
                    <Button label="Back" onClick={handleBack} type="ghost" />
                    <div>
                      {
                        getStatusBasedBtn('reject', active_step, status) &&
                        <Button
                          label={"REJECT"}
                          type="reject"
                          loading={rejectLoading}
                          onClick={handleRejectPopUp}
                        />
                      }
                      {
                        getStatusBasedBtn('clarification', active_step, status) &&
                        <Button
                          label="CLARIFICATION"
                          loading={clarifyLoading}
                          type="outline"
                          onClick={() => handleClarificationPopUp()}
                          id="clarification"
                        />
                      }
                      {
                        applicant.status === APPLICATION_STATUSES['Awaiting CH Approval']['label'] ?
                          <Button
                            label="ADD PARTNER DETAILS"
                            onClick={adPartnerDetails}
                          />
                          : ""
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        );
      case CLUSTER_HEAD_REVIEW_FORM:
        return (
          <section className="review-details__wrapper">
            <Container fluid={true}>
              <Row>
                <Col lg={12}>
                  <UserInformation
                    selectedData={completedData}
                    currentApplicant={null}
                    _id={_id}
                    kycDocs={selectedApplicant?.kycDocs}
                  />
                  <CenterInfo selectedData={completedData} />
                  <AddtionalDetails selectedData={completedData} />
                  <PartnerInformation selectedData={completedData} currentApplicant={null} />
                  <Remarks role="Cluster Head" userData={userData?.profile} selectedData={selectedApplicant?.reject_remarks} currentApplicant={selectedData} />
                  {
                    getStatusBasedBtn('clarification', active_step, status) &&
                    <Clarifications role="Cluster Head" clarificaitonComments={selectedApplicant?.clarification_history} selectedData={selectedApplicant?.clarification_history} userData={userData} />
                  }
                   <ActivityTracker selectedData={selectedApplicant?.approval_history} />

                  <div className="layout-button">
                    <div>
                      <Button label="Cancel" type="ghost" onClick={handleCancel} />
                    </div>
                    <div>
                      <Button
                        is_disabled={false}
                        label="MAKE CHANGE"
                        type="outline"
                        onClick={makeChange}
                      />
                      <Button label="SUBMIT" onClick={handleSubmit} loading={loading} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        );
      default:
        return <div></div>;
    }
  };
  return <div>
    <Toaster />
    {getPageData()}
    {showOverlay && (
      <ReviewOverlay
        showOverlay={showOverlay}
        setShowOverlay={setShowOverlay}
        setRemarkComment={setRemarkComment}
        applicant={location?.state?.review_data}
        handleReject={handleReject}
        selectedOption={selectedOption}
      />
    )}
  </div>;
};

export default ReviewDetailsCH;
