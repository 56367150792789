import { loadState } from "../subscribe/persistReduxStore";
import {
  FETCH_ONBOARDING_FORM_REQUEST,
  FETCH_ONBOARDING_FORM_SUCCESS,
  FETCH_ONBOARDING_FORM_FAILURE,
  UPDATE_ONBOARDING_FORM_DATA,
  SET_SELECTED_DATA,
  UPLOAD_PAN_SUCCESS,
  UPLOAD_KYC_SUCCESS,
  GENERATE_OTP_SUCCESS,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_REQUEST,
  SET_DATA_FROM_PANCARD,
  RESET_ONBOARDING_FORM_DATA,
  FORM_ERROR,
  PHONE_VALIDATION,
  RESET_MOBILE_AND_OTP,
  SET_DISABLED_FIELDS,
  SAVE_LOCALITY
} from "./OnboardingFormTypes";

let onBoadringForm = {};
const { loading, formFields, selectedData, error } = onBoadringForm;
const initialState = {
  validated: false,
  loading: loading || false,
  formFields: formFields || [],
  selectedData: selectedData || {},
  error: error || "",
  kycUploadSuccessData: {},
  isOtpGenerated: "init_state",
  isOtpValidated: "init_state",
  otpValidationInProgress: false,
  kycFile: {},
  uploadedDocs: {},
  assigned_to: '',
  locality_data:[]
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONBOARDING_FORM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ONBOARDING_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        formFields: action.payload,
        error: "",
      };
    case FETCH_ONBOARDING_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        formFields: [],
        error: action.payload,
      };
    //needs change
    case UPDATE_ONBOARDING_FORM_DATA:
      return {
        ...state,
        formFields: {
          ...state.formFields,
          configuration: { step_1: action.payload },
        },
      };
    case SET_SELECTED_DATA:
      return {
        ...state,
        selectedData: { ...state.selectedData, [action.key]: action.payload },
      };
    case "SET_FILE_OBJECT":
      return {
        ...state,
        kycFile: { ...state.kycFile, [action.key]: action.isRemove ? null : action.payload },
      };
    case "SET_UPLOADED_DOCS":
      let newState = { ... state };
      let tempDocs = { ...newState.uploadedDocs };
      if(action?.isRemove){
        delete tempDocs[action.key];
      }else{
        tempDocs = { ...tempDocs, [action.key]: action.payload};
      }
      return {
        ...state,
        uploadedDocs: tempDocs,
      };
    case "ASSIGNED_TO_CLUSTER_HEAD":
      return {
        ...state,
        assigned_to: action.payload,
      };
    case SET_DATA_FROM_PANCARD:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          pan_number: action.payload.pan_number,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          date_of_birth: action.payload.date_of_birth,
          father_name: action.payload.father_name
        },
      };
    case UPLOAD_PAN_SUCCESS:
      return {
        ...state,
        selectedData: { ...selectedData, ...action.payload },
      };
    case UPLOAD_KYC_SUCCESS:
      return {
        ...state,
        kycUploadSuccessData: { kycUploadSuccessData: action.payload },
      };
    case GENERATE_OTP_SUCCESS:
      return {
        ...state,
        isOtpGenerated: action.payload,
      };
    case VALIDATE_OTP_REQUEST:
      return {
        ...state,
        otpValidationInProgress: true,
      };
    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        isOtpValidated: action.payload,
        otpValidationInProgress: false,
      };
    case RESET_ONBOARDING_FORM_DATA:
      return {
        ...state,
        selectedData: {},
        isOtpGenerated: "init_state",
        isOtpValidated: "init_state",
      };

    case FORM_ERROR:
      return {
        ...state,
        error: { ...action.payload }
      }
    case "PHONE_VALIDATION":
      return {
        ...state,
        validation: action.payload
      }
    case "STORE_CLIENT_REF_ID":
      return {
        ...state,
        clientRefId: action.payload
      }
    case RESET_MOBILE_AND_OTP:
      return {
        ...state,
        selectedData: {...selectedData, mobile_number: ""},
        isOtpGenerated: "init_state",
        isOtpValidated: "init_state"
      }
    case SAVE_LOCALITY:
      return{
        ...state,
        locality_data:action.payload
      }  
    default:
      return state;
  }
};

export default reducer;
