import Button from "../Button";
import { Modal } from "react-bootstrap";
import "./index.scss";
import HorizontalLine from "../../../pages/ReviewDetails/components/HorizontalLine";

const Overlay = ({
  showOverlay,
  title,
  buttonCloseType,
  buttonCloseLabel,
  buttonSubmitType,
  buttonSubmitLabel,
  handleCloseOverlay,
  handleSubmitOverlay,
  ...props
}) => {
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showOverlay}
        onHide={handleCloseOverlay}
      >
        <form onSubmit={handleSubmitOverlay}>
          {props.removeHeader?"": <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
            <p onClick={handleCloseOverlay} className="lds-overlay__close">
              &times;
            </p>
          </Modal.Header>}
          <Modal.Body>{props.children}</Modal.Body>
           {props.removeHeader ? "":<HorizontalLine />}
          <Modal.Footer>
            <Button
              label={buttonCloseLabel}
              type={buttonCloseType}
              onClick={handleCloseOverlay}
            />

            <Button
              label={buttonSubmitLabel}
              type={buttonSubmitType}
              variant={buttonSubmitLabel === "REJECT" ? "" : "primary"}
              onClick={handleSubmitOverlay}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default Overlay;