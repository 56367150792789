import Login from "../../../components/Login";
import { CLIENT_DASHBOARD_BASIC } from "../../../constants/route.constants.js/applicant";
import { SUCCESS_RESPONSE_LIST } from "../../../constants/status";
import { generateOTPToMobile, validateMobileOTP } from "../../../lib/components/api/api.service";
import { setApplicantData } from "../../../redux/AddPartnerDetails/AddPartnerDetailsActions";

import { useHistory } from "react-router-dom";
import { toast, Toaster } from 'react-hot-toast'
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from '../../../redux/Login/LoginActions'
import { setOtpLoader } from "../../../redux/App/Action";
import { setItemToLocal } from "../../../utils/storageHelper/localStorage";

const LoginClient = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const sendOtp = async (mobile, cb) => { 
    try {
      dispatch(setOtpLoader(true))
      let result = await generateOTPToMobile(mobile);
      const { data,status,message } = result;
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        dispatch(setOtpLoader(false))
        cb();
      }
      else{
        dispatch(setOtpLoader(false))
      }
    } catch (error) {
      toast.error("Network Error")
      dispatch(setOtpLoader(false))
    }
  }

  const verifyOtp = async (otp, mobile) => {
    try {
      dispatch(setOtpLoader(true))
      let response = await validateMobileOTP(otp, mobile);
      const {message,result,status}=response
      dispatch(loginSuccess(response))
      if (result && SUCCESS_RESPONSE_LIST.includes(status)) {
        setItemToLocal('token', result?.token);
        setItemToLocal("userId", result?._id)
        dispatch(setApplicantData(result))
        dispatch(setOtpLoader(false))
        history.push(CLIENT_DASHBOARD_BASIC);
      } else {
        dispatch(setOtpLoader(false))
        toast.error(message);
      }
    } catch (error) {
      dispatch(setOtpLoader(false))
      toast.error('Some error occured!!!');
    }
  }
  const loading=useSelector((state)=>{
    return state?.globalReducer?.otpLoading
  })
  return (
    <Fragment>
      <Toaster />
      <Login
        otp_login={true}
        loading={loading}
        sendOtp={sendOtp}
        verifyOtp={verifyOtp}
      />
    </Fragment>
  );
};

export default LoginClient;
