/**
 * Set data to local storage
 * @param {*} key 
 * @param {*} val 
 */
export const setItemToLocal = (key, val) => {
    const serialized = JSON.stringify(val);
    localStorage.setItem(key, serialized);
}

/**
 * get data from local storage
 * @param {*} key 
 * @param {*} val 
 * @returns 
 */
export const getItemFromLocal = (key) => {
    const serialized = localStorage.getItem(key);
    return serialized && serialized !== 'undefined' && JSON.parse(serialized) || {};
}