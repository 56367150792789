import Button from "../Button";
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import "./index.scss";

function InputModal({
  showModal,
  hideModal,
  title,
  handleSubmit,
  buttonCloseType,
  buttonSubmitType,
  buttonCloseLabel,
  buttonSubmitLabel,
  setEditModalDetails,
  field,
  inputText,
  errorMsg
}) {
  // const [inputText, setInputText] = useState(setEditModalDetails || "");
  // console.log("values received", title, field, inputText)
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={hideModal}
    >
      <Modal.Header className="border-0">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="pl-3 fs-16px"
        >
          Edit {title}
        </Modal.Title>
        <p onClick={hideModal} className="lds-overlay__close">
          &times;
        </p>
      </Modal.Header>
      <Modal.Body className="justify-content-center input-modal-body">
        <span className="input-title">{title}</span>
        <textarea
          className="text_input"
          defaultValue={inputText}
          onChange={(e) => {
            // console.log("e.target.value", e.target.value)
            setEditModalDetails(title, field, e.target.value)
  }
          }
        ></textarea>
        <span style={{color: 'red'}}>{errorMsg}</span>
      </Modal.Body>
      <Modal.Footer className="pb-5 pl-0 justify-content-evenly">
        <Button
          label={buttonCloseLabel}
          type={buttonCloseType}
          onClick={hideModal}
        />

        <Button
          label={buttonSubmitLabel}
          type={buttonSubmitType}
          variant={buttonSubmitLabel === "REJECT" ? "" : "primary"}
          onClick={(event) => handleSubmit(event,  field,inputText)}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default InputModal;
