import "./index.scss";

const FormSubHeader = ({ label }) => {
  return (
    <div className="form-sub-header">
      <p>{label}</p>
    </div>
  );
};

export default FormSubHeader;
