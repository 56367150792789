import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import OtpInput from "./OtpInput";
import Timer from "./Timer";
import { useDispatch, useSelector } from "react-redux";

const OtpNumber = ({ aadharVerifyOTP, handleError, handleSuccess, resendOtp }) => {
  const [state, setState] = useState({
    value: "",
    opt1: "",
    opt2: "",
    opt3: "",
    opt4: "",
    opt5: "",
    opt6: "",
  });
  const [resendActive, setResendActive] = useState(false);
  const handleChange = (valu1, e) => {
    setState({ ...state, [valu1]: e.target.value });
  };

  const aadharClientId = useSelector((state) => state?.addPartnerDetails?.aadharClientID)

  const dispatch = useDispatch()

  const handleSubmit = () => {
    let OTPValue = state.opt1 + state.opt2 + state.opt3 + state.opt4 + state.opt5 + state.opt6
    const payload = {
      "otp": OTPValue,
      "client_id": aadharClientId ? aadharClientId : localStorage.getItem('aadhar_client_id'),
      "type": "verify"
    }
    dispatch(aadharVerifyOTP(payload, handleSuccess, handleError))
  }
  const otpResend = () => {
    setResendActive(false);
    resendOtp();
  }
  return (
    <div className="otp-wrapper">
      {!resendActive && (
        <p>
          <small>Resend otp in</small> <Timer onTimerEnd={()=>{setResendActive(true)}}/>
        </p>
      )}
      <div className="opt-wrapper__boxes">
        <OtpInput state={state} handleChange={handleChange} />
      </div>
      <div className="otp-wrapper__resend">
        <span className="otp-wrapper__resendlink" onClick={otpResend}>
          Resend OTP
        </span>
      </div>
      <Button className="otp-wrapper__verify" onClick={handleSubmit}>Verify</Button>
    </div>
  );
};
export default OtpNumber;
