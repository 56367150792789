import {
  CMO_JOURNEY_LIST_FAILURE,
  CMO_JOURNEY_LIST_SUCCESS,
  CMO_JOURNEY_LIST_REQUEST,
  SELECTED_APPLICANT_CMO
} from "./CMOJourneyTypes";

const initialState = {
  loading: false,
  applicationList: [],
  // CMOJourneyApproval: {},
  // CMOJourneyRejection: {},
  error: "",
  selectedApplicantCMO: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CMO_JOURNEY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CMO_JOURNEY_LIST_SUCCESS: {
      return {
        ...state,
        applicationList: action.payload,
      };
    }
    case CMO_JOURNEY_LIST_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case SELECTED_APPLICANT_CMO: {
      return {
        ...state,
        selectedApplicantCMO: action.payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
