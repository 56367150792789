import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
import { LEGAL_TEAM_DASHBOARD, LEGAL_TEAM_REVIEW, LEGAL_TEAM_SUBMIT } from "../../../constants/route.constants.js/legalTeam";
import OtherPartnerDetails from "../../ReviewDetails/components/OtherPartnerDetails";
import BankInformation from "../../ReviewDetails/components/BankInformation";
import {
  approveApplicationPayment, rejectApplicationLegal,   clarificationLegalTeam,
} from "../../../redux/LegalTeam/LegalTeamAction";
import { toast, Toaster } from "react-hot-toast";
import { sendReminderToPartner } from "../../../lib/components/api/api.service"
import { APPLICATION_STATUSES } from '../../../constants/status.constants';
import Clarifications from "../../ReviewDetails/components/Clarification";
import * as _ from "underscore"

import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";
import { getStatusBasedBtn } from "../utils/getStatusBasedBtn";
import { loginInfo } from "../../../redux/Login/LoginActions";
import ActivityTracker from "../../ReviewDetails/components/ActivityTracker";

const ReviewOverlay = ({
  showOverlay,
  setShowOverlay,
  setRemarkComment,
  handleReject,
  reject,
  overlayData,
  handleCloseOverlay,
  handleSubmit,
  selectedOption,
  setClarificationComment,
  handleClarification,
  setOverlayData,
  clarificationLegalTeam
  
}) => {
  
  const history = useHistory();

  const dispatch = useDispatch();
  

  const handleAddPartner = () => {
    setShowOverlay(true);
  };
  
  const handleSubmitOverlay = () => {
    setShowOverlay(false);

    if (selectedOption === "approve") {
      return handleSubmit(overlayData)
    }
    {
      reject
        ? setClarificationComment(overlayData)
        : setRemarkComment(overlayData)
    }
    {
      reject ? handleClarification(overlayData) : handleReject(overlayData);
    }
  };

  return (
    <>
    {reject ? ( <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Clarification"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType=""
          buttonSubmitLabel="SUBMIT"
          handleCloseOverlay={handleCloseOverlay}
          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea placeholder="Please specify the details of the clarification required" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>): (
      <Overlay
        setShowOverlay={setShowOverlay}
        showOverlay={showOverlay}
        title="Remarks"
        buttonCloseType="outline"
        buttonCloseLabel="CANCEL"
        buttonSubmitType={selectedOption === "Reject" ? "red" : ""}
        buttonSubmitLabel={selectedOption === "Reject" ? "REJECT" : "SUBMIT"}
        handleCloseOverlay={handleCloseOverlay}
        handleSubmitOverlay={handleSubmitOverlay}
      >
        <Textarea placeholder="Please specify the details of the remarks (optional)" onChange={(e) => setOverlayData(e.target.value)} />
      </Overlay>
    )
}
    </>
  );
};

const LegalTeamReview = () => {
  const location = useLocation();
  const history = useHistory();
  const [routePath, setRoutePath] = useState();
  const [remarkComment, setRemarkComment] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedOption, setSelectedOption] = useState('')
  const [reject, setReject] = useState();
  const [overlayData, setOverlayData] = useState();
  const [clarificationComment, setClarificationComment] = useState();

  const dispatch = useDispatch();
  const loggedLegalUser = dispatch(loginInfo)?.data


  const handleClarificationSuccess = () => {
    history.push(LEGAL_TEAM_DASHBOARD);
  };
  const handleClarification = (text) => {
    const payload = {
      clarification: text,
      name: loggedLegalUser.profile?.first_name,
      role: loggedLegalUser.role_name,
      _id: selectedApplicantLegal?._id,
    };
    // dispatch(clarificationLegalTeam(payload, handleClarificationSuccess, handleError));

    // let formData = new FormData();
    // clarification_history.push(payload);
    const clarificationCommentsHistory = {
      applicationId: selectedApplicantLegal?._id,
      ...selectedApplicantLegal,
    };
    clarificationCommentsHistory.clarification_history.push(payload);
    // formData.append("data", JSON.stringify(clarificationCommentsHistory));
    dispatch(clarificationLegalTeam(clarificationCommentsHistory, handleClarificationSuccess, handleError));

  };
  const handleCloseOverlay = () => {
    // history.push(LEGAL_TEAM_REVIEW);
    setShowOverlay(false);
  };
  useEffect(() => {
    setRoutePath(location.pathname);
  }, []);

  const selectedApplicantLegal = useSelector((state) => state?.legalTeam?.selectedApplicantLegal)
  const userData = dispatch(loginInfo)?.data
  const loading = useSelector((state) => {
    return state?.globalReducer?.submitLoading
  })
  const rejectLoading = useSelector((state) => {
    return state?.globalReducer?.rejectLoading
  })
  const loadingClarify = useSelector((state) => {
    return state?.globalReducer?.clarifyLoading
  })
  const { active_step, status } = selectedApplicantLegal
  const handleBack = () => {
    history.push(LEGAL_TEAM_DASHBOARD)
  }

  const handleReject = (text) => {

    const payload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: selectedApplicantLegal?._id,
    };

    let rejectCommentsHistory = {
      applicationId: selectedApplicantLegal?._id,
      ...selectedApplicantLegal,
      status: APPLICATION_STATUSES['Rejected']['label']
    };
    if (rejectCommentsHistory?.reject_remarks?.length) {
      rejectCommentsHistory?.reject_remarks?.push(payload);
    } else {
      rejectCommentsHistory.reject_remarks = [payload]
    }

    dispatch(rejectApplicationLegal(rejectCommentsHistory, handleSuccess, handleError))
  
  }

  const handleSuccess = () => {
    history.push(LEGAL_TEAM_DASHBOARD);
  }

  const handleError = (message) => {
    toast.error(message)
  }

  const handlePaymentSuccess = () => {
    toast.success("Approved")
    history.push(LEGAL_TEAM_DASHBOARD);
  }

  const makeChange = () => { };
  const handleSubmit = (text) => {

    // const createLegalTeamPayload = {
    //   payment_approved: true,
    //   user_id: userData?._id,
    //   ...selectedApplicantLegal
    // }

    // if (selectedApplicantLegal.source_data.ownership === "Sole proprietorship") {
    //   // createLegalTeamPayload.status = APPLICATION_STATUSES['Out For Signature']['label']
    //   createLegalTeamPayload.active_step = 6
    //   createLegalTeamPayload._id = selectedApplicantLegal?._id
    //   createLegalTeamPayload.reject_remarks = selectedApplicantLegal?.reject_remarks
    //   createLegalTeamPayload.stepHistory = [...selectedApplicantLegal?.stepHistory?.map((item) => {
    //     if (item?.step === selectedApplicantLegal?.active_step) {
    //       item.status = "COMPLETED";
    //       item.completed_date = new Date().toDateString()
    //       if (item?.step == 5.1) {
    //         item.completedBy = userData._id;
    //       }
    //     }
    //     return item
    //   }), ...[{
    //     step: 6,
    //     // status: APPLICATION_STATUSES['Out For Signature']['label'],
    //     initated_date: new Date().toDateString(),
    //   }]]
    // } else {
    //   createLegalTeamPayload.reject_remarks = selectedApplicantLegal?.reject_remarks
    //   let kycCompletedApplicants = _.filter(selectedApplicantLegal.partner_details, (each) => {
    //     return each.kyc_completed === true
    //   })
    //   if (kycCompletedApplicants.length === selectedApplicantLegal.partner_details.length) {
    //     // createLegalTeamPayload.status = APPLICATION_STATUSES['Out For Signature']['label']
    //     createLegalTeamPayload.active_step = 6
    //     createLegalTeamPayload._id = selectedApplicantLegal?._id
    //     createLegalTeamPayload.stepHistory = [...selectedApplicantLegal?.stepHistory?.map((item) => {
    //       if (item?.step === selectedApplicantLegal?.active_step) {
    //         item.status = "COMPLETED";
    //         item.completed_date = new Date().toDateString()
    //         if (item.step == 5.1) {
    //           item.completedBy = userData._id;
    //         }
    //       }
    //       return item
    //     }), ...[{
    //       initated_date: new Date().toDateString(),
    //       // status: APPLICATION_STATUSES['Out For Signature']['label'],
    //       step: 6,
    //     }]]
    //   } else {
    //     createLegalTeamPayload.status = APPLICATION_STATUSES['Awaiting Partner KYC']['label']
    //     createLegalTeamPayload.active_step = selectedApplicantLegal.active_step
    //     createLegalTeamPayload._id = selectedApplicantLegal?._id
    //   }
    // }

    // const textPayload = {
    //   remark: text,
    //   name: userData?.profile?.first_name,
    //   role: userData?.role_name,
    //   _id: location?.state?.review_data?._id,
    // };
    // if (text !== undefined) {
    //   createLegalTeamPayload?.reject_remarks?.push(textPayload);
    // }
     const textPayload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: location?.state?.review_data?._id,
    };
    const createLegalTeamPayload = {
      user_id: userData?._id,
      _id : selectedApplicantLegal?._id,
      remarks: textPayload
    }
    let currentUser = JSON.parse(localStorage.getItem("userInfo"));
    selectedApplicantLegal?.approval_history?.push({
      status: "Approved",
      approved_at: new Date().toDateString(),
      approved_by: currentUser?.profile?.first_name + " "+ currentUser?.profile?.last_name,
      role: currentUser?.role_name
  })
  createLegalTeamPayload.approval_history =  selectedApplicantLegal?.approval_history
    dispatch(approveApplicationPayment(createLegalTeamPayload, handlePaymentSuccess, handleError));
  };

  return (
    <section className="review-details__wrapper">
      <Toaster />
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <SlidingNavbar selectedData={selectedApplicantLegal} />
            <UserInformation selectedData={selectedApplicantLegal?.source_data} _id={selectedApplicantLegal?._id} 
              kycDocs={selectedApplicantLegal?.kycDocs}
          />
            <div style={{ marginTop: "50px" }}>
              {Array.isArray(selectedApplicantLegal?.partner_details) &&
                selectedApplicantLegal?.partner_details?.map((item) => {
                  if (item?.kyc_completed) {
                    return (
                      <UserInformation
                        selectedData={item?.source_data}
                        _id={selectedApplicantLegal?._id}
                        partnerId={item?.id}
                        isPartnerData={true}
                        kycDocs={selectedApplicantLegal?.kycDocs}
                      />
                    );
                  } else {
                    return (
                      <OtherPartnerDetails currentApplicant={selectedApplicantLegal}
                        sendReminderToPartner={sendReminderToPartner}
                      />
                    )
                  }

                })}
            </div>

            <CenterInfo selectedData={selectedApplicantLegal?.source_data} />
            <AddtionalDetails selectedData={selectedApplicantLegal?.source_data} />
            {
              selectedApplicantLegal?.source_data?.partner_profiling ?
                <PartnerInformation selectedData={selectedApplicantLegal?.source_data} fullInfo={selectedApplicantLegal} /> : ''
            }
            {
              selectedApplicantLegal?.bank_name ?
                <BankInformation selectedData={selectedApplicantLegal} /> : ''
            }
            {
              selectedApplicantLegal?.document_signed ?
                <OtherInformation selectedData={selectedApplicantLegal} /> : ''
            }
            {
              Array.isArray(selectedApplicantLegal?.checkList) && selectedApplicantLegal?.checkList ?
                <ChecklistRequirement
                  selectedData={selectedApplicantLegal?.checkList}
                />
                : ''
            }
            {
              Array.isArray(selectedApplicantLegal?.phleboList) && selectedApplicantLegal?.phleboList?.length ?
                <PhleboInformation
                  selectedData={selectedApplicantLegal?.phleboList}
                  _id={selectedApplicantLegal?._id}
                /> : ''
            }
            {
              selectedApplicantLegal?.weekday_start_time ?
                <>
                  <BrandingPhotos _id={selectedApplicantLegal?._id} />
                  <ServiceTiming selectedData={selectedApplicantLegal} />
                </>
                : ''
            }
            {
              Array.isArray(selectedApplicantLegal?.reject_remarks) && selectedApplicantLegal?.reject_remarks?.length ?
                <Remarks role="Legal Team" selectedData={selectedApplicantLegal?.reject_remarks} /> : ''
            }
            {
              Array.isArray(selectedApplicantLegal?.clarification_history) && selectedApplicantLegal?.clarification_history?.length ?
                <Clarifications role="Legal Team" selectedData={selectedApplicantLegal?.clarification_history} userData={userData} /> : ''
            }
                  <ActivityTracker selectedData={selectedApplicantLegal?.approval_history} />

            <div className="layout-button">
              <div>
                <Button type="ghost" label="Back" onClick={handleBack} />
              </div>
              <div>
                <Button
                  label="CLARIFICATION"
                  type="outline"
                  loading={loadingClarify}
                  onClick={() => {
                    setShowOverlay(true);
                    setSelectedOption("Clarification")
                    setReject(true);
                  }}
                  id="clarification"
                />
              </div>
              <div>
                {
                  getStatusBasedBtn('reject', active_step, status) &&
                  <Button
                    label="REJECT"
                    type="reject"
                    loading={rejectLoading}
                    onClick={() => {
                      setReject(false)
                      setSelectedOption("Reject")
                      setShowOverlay(true)
                    }}
                  />
                }

                {getStatusBasedBtn('rejected', active_step, status) && (
                  <Button label="REJECTED" type="reject" />
                )}

{
                  (selectedApplicantLegal?.status === "Awaiting Legal Approval" ) ? //TODO:add this && status !== "Rejected" to fix 
                    <Button label="Reject"  type="reject" loading={loading} onClick={() => {
                      setShowOverlay(true)
                      setSelectedOption("reject")
                    }} /> : ''
                }
                {
                  (selectedApplicantLegal?.status === "Awaiting Legal Approval" ) ? //TODO:add this && status !== "Rejected" to fix 
                    <Button label="APPROVE" loading={loading} onClick={() => {
                      setShowOverlay(true)
                      setSelectedOption("approve")
                    }} /> : ''
                }
              </div>

            </div>
            {showOverlay && (
              <ReviewOverlay
                showOverlay={showOverlay}
                setShowOverlay={setShowOverlay}
                setRemarkComment={setRemarkComment}
                handleReject={handleReject}
                reject={reject}
                overlayData={overlayData}
                handleCloseOverlay={handleCloseOverlay}
                handleSubmit={handleSubmit}
                selectedOption={selectedOption}
                setClarificationComment={setClarificationComment}
                handleClarification={handleClarification}
                setOverlayData={setOverlayData}
                clarificationLegalTeam={clarificationLegalTeam}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LegalTeamReview;
