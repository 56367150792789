
import {
	ADD_PHLEBO_FORM,
	SET_SELECTED_DATA_PHLEBO,
	SAVE_PHLEBO_LIST,
	FETCH_QUERY_LIST,
	UPDATE_CHECKLIST,
	SET_SELECTED_PHLEBO_APPLICATION,
	CLEAR_PHLEBO_SELECTED_DATA
} from "./PhleboOnboardingTypes";

const initialState = {
	loading: false,
	formFields: {},
	error: "",
	selectedData: {},
	phleboList: [],
	partnerOtherInfo: {},
	checkList: [],
	selectedPhleboApplication: {}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_PHLEBO_FORM:
			return {
				...state,
				formFields: action.payload,
				loading: true,
			};
		case SET_SELECTED_DATA_PHLEBO:
			return {
				...state,
				selectedData: { ...state.selectedData, [action.key]: action.payload },
			};
		case SAVE_PHLEBO_LIST:
			return {
				...state,
				phleboList: action.payload
			}
		case FETCH_QUERY_LIST:
			return {
				...state,
				checkList: action.payload
			}
		case UPDATE_CHECKLIST:
			return {
				...state,
				checkList: action.payload
			}
		case SET_SELECTED_PHLEBO_APPLICATION:
			return {
				...state,
				selectedPhleboApplication: action.payload
			}
		case CLEAR_PHLEBO_SELECTED_DATA:{
			return{
				...state,
				phleboList:{},
				selectedData:{}
			}
		}
		default:
			return state;
	}
}



export default reducer;
