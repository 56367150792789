import axios from "axios";
import {
  LEGAL_TEAM_ENDPOINT,
  LEGAL_TEAM_REJECT_ENDPOINT,
  LEGAL_TEAM_APPROVE_ENDPOINT,
  GET_GEO_LOCATION_ENDPOINT
} from "../../constants/api.constants.js/index.js";
import {
  APPLICANT_LIST_REQUEST,
  APPLICANT_LIST_SUCCESS,
  APPLICANT_LIST_FAILURE,
  LEGAL_TEAM_REJECTION_REQUEST,
  LEGAL_TEAM_REJECTION_SUCCESS,
  LEGAL_TEAM_REJECTION_FAILURE,
  LEGAL_TEAM_APPROVAL_REQUEST,
  LEGAL_TEAM_APPROVAL_SUCCESS,
  LEGAL_TEAM_APPROVAL_FAILURE,
  GET_GEO_LOCATION_REQUEST,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_FAILURE,
  SELECTED_APPLICANT_CHANNEL
} from "./LegalTeamTypes";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status.js";
import config_mock from "./config_mock.json";
import { toast } from 'react-hot-toast'
import { setRejectLoading, setSubmitLoading, setClarificationLoading } from "../App/Action.js";
import requestV1 from "../../utils/requestV1.js";

//Fetch Applicants
export const fetchApplicantsList = (legalTeamId) => async (dispatch) => {
  const reqUrl = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ active_step: 5.1, organisation_id: process.env.REACT_APP_ORGANIZATION_ID })
  };

  try {
    const response = await requestV1(reqUrl, options)
    const { data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(fetchApplicantsListSuccess(data));
    } else {
      toast.error(data?.message)
      dispatch(fetchApplicantsListSuccess([]));
    }
  }
  catch (err) {
    toast.error("Network Error")
    dispatch(fetchApplicantsListSuccess(config_mock));
    dispatch(fetchApplicantsListFailure(err.message));
  }
};

export const fetchApplicantsListRequest = () => {
  return {
    type: APPLICANT_LIST_REQUEST,
  };
};

export const fetchApplicantsListSuccess = (data) => {
  return {
    type: APPLICANT_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchApplicantsListFailure = () => {
  return {
    type: APPLICANT_LIST_FAILURE,
  };
};

export const rejectApplicationRequest = () => {
  return {
    type: LEGAL_TEAM_REJECTION_REQUEST,
  };
};

export const rejectApplicationSuccess = (data) => {
  return {
    type: LEGAL_TEAM_REJECTION_SUCCESS,
    payload: data,
  };
};

export const rejectApplicationFailure = () => {
  return {
    type: LEGAL_TEAM_REJECTION_FAILURE,
  };
};

export const rejectApplicationLegal = (payload, handleSuccess, handleError) => async (dispatch, getState) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };

  try {
    dispatch(setRejectLoading(true))
    const response = await requestV1(reqUri, options)
    const { data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleSuccess()
      dispatch(setRejectLoading(false))
    } else {
      handleError(data?.message)
      dispatch(setRejectLoading(false))
    }
  }
  catch (error) {
    handleError("Network Error")
    dispatch(setRejectLoading(false))
  }
};

//APPROVE_PAYMENT
export const approveApplicationPayment = (payload, handleSuccess, handleError) => async (dispatch, getState) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/legalApproval`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };

  try {
    dispatch(setSubmitLoading(true))
    const response = await requestV1(reqUri, options)
    const { status, message } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleSuccess()
      dispatch(setSubmitLoading(false))
    } else {
      handleError(message)
      dispatch(setSubmitLoading(false))
    }
  }
  catch (error) {
    handleError("Network Error")
    dispatch(setSubmitLoading(false))
  }
};



export const approveApplicationRequest = () => {
  return {
    type: LEGAL_TEAM_APPROVAL_REQUEST,
  };
};

export const approveApplicationSuccess = (data) => {
  return {
    type: LEGAL_TEAM_APPROVAL_SUCCESS,
    payload: data,
  };
};

export const approveApplicationFailure = () => {
  return {
    type: LEGAL_TEAM_APPROVAL_FAILURE,
  };
};

export const getGeoLocaitonData = () => async (dispatch) => {
  const reqUri = GET_GEO_LOCATION_ENDPOINT;
  dispatch(getGeoLocaitonDataRequest());
  axios
    .get(reqUri)
    .then((response) => {
      dispatch(getGeoLocaitonDataSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getGeoLocaitonDataFailure(err.message));
    });
};

export const getGeoLocaitonDataRequest = () => {
  return {
    type: GET_GEO_LOCATION_REQUEST,
  };
};

export const getGeoLocaitonDataSuccess = (data) => {
  return {
    type: GET_GEO_LOCATION_SUCCESS,
    payload: data,
  };
};

export const getGeoLocaitonDataFailure = () => {
  return {
    type: GET_GEO_LOCATION_FAILURE,
  };
};

export const setSelectedApplicant = (data) => {
  return {
    type: SELECTED_APPLICANT_CHANNEL,
    payload: data
  };
};



export const clarificationLegalTeam = (payload, handleClarificationSuccess, handleError) => async (dispatch, getState) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };

  try {
    dispatch(setClarificationLoading(true))
    const response = await requestV1(reqUri, options)
    const { message, data, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleClarificationSuccess(message)
      dispatch(setClarificationLoading(false))
    } else {
      handleError(data?.message)
      dispatch(setClarificationLoading(false))
    }
  } catch (error) {
    handleError("Network Error")
    dispatch(setClarificationLoading(false))
  }
};

