import { useSelector, useDispatch } from "react-redux";
import SubmitResponse from "../../../lib/components/SubmitResponse";
import { submitApplicantSecondJourney } from '../../../redux/AddPartnerDetails/AddPartnerDetailsActions';
import { CLIENT_DASHBOARD_BASIC } from '../../../constants/route.constants.js/applicant'
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast'
import { axisPayment, axisPaymentResult } from '../../../lib/components/api/api.service';
import { useState } from 'react';
import { SUCCESS_RESPONSE_LIST } from "../../../constants/status";
import { PAYMENT_SCREEN } from "../../../constants/route.constants.js/applicant";
const Razorpay = require('razorpay');

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}


const     SubmitDetails = () => {
  const history = useHistory()

  const [redirect, setRedirect] = useState(false)

  const selectedApplication = useSelector(
    (state) => state?.addPartnerDetails?.selectedApplication
  );
  const responseSubmit = () => {

    history.push(PAYMENT_SCREEN)


    // const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')
    // if (!res) {
    //   alert('Razorpay SDK failed to load.')
    //   return
    // }
    // console.log("selected application",selectedApplication?._id)
    // let params = {
    //   id: selectedApplication?._id,
    //   security_deposit: selectedApplication?.source_data?.rolling_advance,
    //   payment_type: "rolling_advance"
    // }
    // let response = axisPayment(params)
    // response.then((data) => {
    //   const options = {
    //     key: process.env.REACT_APP_PAYMENT_KEY,
    //     currency: "INR",
    //     amount: selectedApplication?.source_data?.rolling_advance + "00",
    //     order_id: data.data?.result?.order_id,
    //     name: 'Agilus Diagnostics',
    //     description: '',
    //     handler: function (resp) {
    //       let params = {
    //         id: selectedApplication?._id,
    //         org_name: resp.org_name,
    //         initiated_at: data.data?.result?.initiated_at,
    //         gateway_response: resp,
    //         payment_type: "rolling_advance"
    //       }
    //       // razorpay_order_id: response.razorpay_order_id,
    //       // razorpay_signature: response.razorpay_signature,
    //       // console.log("respo", response)
    //       // if (SUCCESS_RESPONSE_LIST?.includes(response?.status)) {
    //      let paymentResponse =  axisPaymentResult(params);
    //      paymentResponse.then((payResponse)=>{
    //       // console.log("response", payResponse)
    //       if (SUCCESS_RESPONSE_LIST?.includes(payResponse?.status)) {
    //         selectedApplication.payment_status = payResponse.result.payment_status
    //         history.push(PAYMENT_SCREEN)
    //         setRedirect(true)
    //       }
    //      })
    //     // }
         
    //     },
    //     prefill: {
    //       name: selectedApplication?.source_data?.first_name + " " + selectedApplication?.source_data?.last_name,
    //       email: selectedApplication?.source_data?.email,
    //       contact: selectedApplication?.source_data?.mobile_number
    //     },
    //     image: "/static/media/Agilus_Diagnostics_Logo.png",
    //     callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
    //     notes: {
    //       "address": "Razorpay Corporate Office"
    //     },
    //     theme: {
    //       "color": "#3399cc"
    //     }
    //   }

    //   const paymentObject = new window.Razorpay(options)
    //   paymentObject.open()
    // })
    // handleSubmit()
  }

  const handleRedirect = () => {
    history.push(CLIENT_DASHBOARD_BASIC)
  }

  return (
    <>
      <Toaster />
      {
        redirect ?
          <SubmitResponse
            payment={true}
            btnStatus={true}
            responseSubmit={handleRedirect}
          />
          :
          <SubmitResponse
            text="You have successfully finished the KYC process"
            secondText="Now you can proceed to make the payment of the security deposit"
            btnStatus={true}
            btnLabel="make payment"
            responseSubmit={responseSubmit}
          />
      }
    </>
  );
};

export default SubmitDetails;
