import axios from 'axios'
import { SUCCESS_RESPONSE_LIST } from '../../constants/status';
import {
	ADD_PHLEBO_FORM,
	SET_SELECTED_DATA_PHLEBO,
	SAVE_PHLEBO_LIST,
	FETCH_QUERY_LIST,
	UPDATE_CHECKLIST, SET_SELECTED_PHLEBO_APPLICATION, CLEAR_PHLEBO_SELECTED_DATA
} from "./PhleboOnboardingTypes";
import { GET_PHLEBO_CHECKLIST_ENDPOINT } from '../../constants/api.constants.js';
import { setAppError, setSubmitLoading } from '../App/Action';
import toast from 'react-hot-toast';
import requestV1 from '../../utils/requestV1';

export const fetchAddPhleboFormFields = () => {
	return (dispatch) => {
		axios.get('/data/addPhleboOnboarding.json')
			.then((response) => {
				dispatch(fetchAddPhleboFormFieldsSuccess(response.data))
			})
	}
}

export const submitPhleboData = (payload, handleSuccess, handleError) => async (dispatch) => {
	const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
	const options = {
		method: 'POST',
		body: payload
	};
	try {
		const response = await requestV1(reqUri, options, false, true)
		const { status, data, message } = response

		if (SUCCESS_RESPONSE_LIST.includes(status)) {
			dispatch(setSubmitLoading(false))
			handleSuccess()
		}
		else {
			dispatch(setSubmitLoading(false))
			handleError(message)
		}
	}
	catch (err) {
		dispatch(setSubmitLoading(false))
		handleError("Network Error")

	}
}

export const setSelectedApplicationPhlebo = (data) => {
	return {
		type: SET_SELECTED_PHLEBO_APPLICATION,
		payload: data,
	};
}

export const fetchAddPhleboFormFieldsSuccess = (data) => {
	return {
		type: ADD_PHLEBO_FORM,
		payload: data,
	};
}

export const setSelectedDataPhlebo = (key, value) => {
	return {
		type: SET_SELECTED_DATA_PHLEBO,
		key,
		payload: value,
	};
};

export const savePhleboList = (data) => {
	return {
		type: SAVE_PHLEBO_LIST,
		payload: data,
	};
}
export const fetchQueryList = async (dispatch) => {
	const reqUri = GET_PHLEBO_CHECKLIST_ENDPOINT;
	const options = {
		method: 'POST',
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const response = await requestV1(reqUri, options, true)
		const { status, message, result } = response;
		if (SUCCESS_RESPONSE_LIST.includes(status)) {
			const { fieldsList } = result;
			dispatch(fetchQueryListSuccess(fieldsList))
		}
		else {
			toast.error(message)
		}
	}
	catch (err) {
		dispatch(setAppError(true))
		toast.error("Network Error")
	}
}

export const fetchQueryListSuccess = (data) => {
	return {
		type: FETCH_QUERY_LIST,
		payload: data
	}
}

export const updateCheckList = (data) => {
	//update checklits here
	return {
		type: UPDATE_CHECKLIST,
		payload: data
	}
}
export const clearPhleboSelectedData = () => {
	return {
		type: CLEAR_PHLEBO_SELECTED_DATA
	}
}