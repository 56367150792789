import { useEffect, useState } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import CloudUpload from "../../../../assets/images/cloud_upload.svg";
import DocumentUploaded from "../components/documentUploaded";

const DocumentProgress = ({
  fileName,
  fileSize,
  uploaded,
  fileRejectionReason,
  cancelClick,
  error,
  removeFiles
}) => {
  let [completed, setCompleted] = useState(0);

  useEffect(() => {
    setInterval(() => {
      if (completed < 99) {
        setCompleted((completed += 4));
      }
    }, 100);
  }, []);


  const removeUploadedFile = () => {
    removeFiles();
    setCompleted(0);
  }

  if(uploaded){
    return (
      <DocumentUploaded fileName={fileName} fileSize={fileSize} error={error} removeUploadedFile={removeUploadedFile} />
    )
  }

  return (
    <>
      {completed !== 100 ? (
        <section className="document-progress__wrapper">
          <Container>
            <Row className="align-items-center">
              <Col xs={1}>
                <div className="document-progress__image">
                  <img src={CloudUpload} alt="" />
                </div>
              </Col>
              <Col xs={11}>
                <div className="document-progress__display-flex">
                  <span className="document-progress__filename">
                    {fileName}
                  </span>
                  <span
                    className="document-progress__cancel"
                    onClick={cancelClick}
                  >
                    Cancel
                  </span>
                </div>
                <div className="document-progress__progress-bar">
                  <ProgressBar variant="#0676ED" now={completed} />
                </div>
                <div className="document-progress__display-flex document-progress__uploading">
                  <span>Uploading...</span>
                  <span>{completed}%</span>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        <DocumentUploaded fileName={fileName} fileSize={fileSize} error={error} removeUploadedFile={removeUploadedFile} />
      )}
    </>
  );
};

export default DocumentProgress;
