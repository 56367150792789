import {Row,Col,Container} from 'react-bootstrap'
import Loginimage from "../../assets/images/AdminLogin.svg";
import { forgotPasswordRequest } from '../../redux/Auth/Action';
import EmailResetPassword from './EmailResetPassword';

const ForgotPassword=()=>{
    return(
        <section className="login__wrapper">
      <Container fluid={true}>
        <Row>
          <Col lg={6}>
            <div className="login__content-left">
              <h5>Expand the Franchise</h5>
              <p>Over 1000+ successful businesses started here</p>
              <div className="login__content-left--image">
                <img src={Loginimage} alt="" />
              </div>
            </div>
          </Col>
          <Col lg="6">
              <EmailResetPassword handleSubmit={forgotPasswordRequest}/>
             </Col>
        </Row>
      </Container>
    </section>
    )
}
export default ForgotPassword