import { Container, Row, Col } from "react-bootstrap";
import tickMark from "../../../../assets/images/green tick.svg";
import crossMark from "../../../../assets/images/red cross.svg";
import "./index.scss";
import Image from "../../../../components/Image";
import { useLocation,useHistory } from "react-router-dom";
import editIcon from "../../../../assets/images/Edit_icon.svg";
import InputModal from "../../../../lib/components/InputModal";

import HorizontalLine from "../HorizontalLine/index.js";
import ViewImage from "../../../../components/ViewImage";
import { useState,useRef } from "react";
import uploadImg from "../../../../assets/images/cloud_upload.svg";
import requestV1 from "../../../../utils/requestV1";
import { SUCCESS_RESPONSE_LIST } from "../../../../constants/status";
import {toast, Toaster} from 'react-hot-toast'
import { SUPER_ADMIN_DASHBOARD_EDIT } from "../../../../constants/route.constants.js/admin";
import { useSelector } from "react-redux";
const BankInformationHeaders = {
  header: "Bank Information",
  columnOne: {
    titleOne: "Bank Name",
    titleTwo: "Cheque",
    titleThree: "Complete Address"
  },
  columnTwo: {
    titleOne: "IFSC Code",
    titleTwo: "Account No.",
  },
  columnThree: {
    titleOne: "Beneficiary Name",
    titleTwo: "Amount Paid",
  },
  security_deposit_subtext: "Security Deposit Transaction ID",
  marketing_deposit_subtext: "Marketing Deposit Transaction ID",
  rolling_advance_subtext: "Rolling Advance Transaction ID"
};
const PartnerInformation = ({ selectedData }) => {
  // console.log(selectedData)
  const { header, columnOne, columnTwo, columnThree, security_deposit_subtext, marketing_deposit_subtext,rolling_advance_subtext } =
    BankInformationHeaders;
  const {
    _id,
    bank_name,
    ifsc_code,
    beneficiary_name,
    account_number,
    payment_status,
    marketing_payment_status,
    rolling_payment_status,
    source_data,
    payment_approved,
    rolling_transaction_id,
    marketing_transaction_id
  } = selectedData;
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalDetails, setEditModalDetails] = useState({
    title: "",
    field: "",
    inputText: "",
  });

  const history = useHistory()
  const [title, setTitle] = useState("");
  let adminView = false;
  const url = window.location.href;
  if (url.includes("super-admin"))
    adminView = true;

  const handleShow = (src, title) => {
    setShow(true);
    setSrc(src);
    setTitle(title);
  };

  const currentActiveStep = useSelector((state)=>state?.clusterHead?.selectedApplicant?.active_step)

  const handleClose = () => {
    setShow(false);
  };
  const inputRef = useRef(null);
  const inputRefAadhar = useRef(null);
  const handleClickAadhar = (e) => {
    // 👇️ open file input box on click of other element
    inputRefAadhar.current.click();
  };

  const handleEdit = (fieldName, field_id, fieldValue) => {
    // console.log("field name", fieldName, field_id)
    setShowEditModal(true);
    setEditModalDetails({
      title: fieldName,
      inputText: fieldValue,
      field: field_id,
    });
  };

  const updateSelectedData = (title, field, inputText) => {
    //  console.log("values in data", title, field, inputText)
    setEditModalDetails({
      title: title,
      inputText: inputText,
      field: field,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    // console.log("123456",selectedData)
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateApplicantInfo`;
    // console.log(selectedData)
    const payload = {
      _id: _id,
      field: editModalDetails?.field,
    };
    payload[editModalDetails.field] = editModalDetails?.inputText;
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await requestV1(reqUri, options);
      const { status, data, message } = response;
      // console.log(response)
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        selectedData[editModalDetails.field] = editModalDetails?.inputText;

        // selectedData[field] = input_data
        toast.success("Data updated successfully");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      toast.error("Network Error");
    }
    setShowEditModal(false);
  };

  const handleFileChange = async(event,_id, selectedData, docType) => {
    // console.log("type-----", d)
    event.preventDefault();
    let fileObj = event?.target?.files && event?.target?.files[0];
    let formData = new FormData();
    // TODO: Hit API here
    if (!fileObj) {
      return;
    }
    // console.log(selectedData)
    // fileObj["fieldname"] = docType;
    // fileObj.filetype = type
    // console.log(fileObj)
    // for (let file in fileObj) {
    //   // formData.append(file, kycFiles[file])
    // }
    // console.log(selectedApplicantData()) 
    formData.append("file", fileObj);
    let payload = {
      organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
      workflow_id: _id,
      field: docType
    }
    let params = {
      body: JSON.stringify(payload)
    }
    // console.log(params)
    const encryptedBodyRes = await requestV1('',params,true,true);
  
    formData.append('data', JSON.stringify(encryptedBodyRes))
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/updateDocuments`
    const options = {
      method: 'POST',
      body: formData
    };
  
    try {
  
      const response = await requestV1(reqUri, options, false, false)
      const { status, message } = response
      // history.push(SUPER_ADMIN_DASHBOARD_EDIT)

      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        // handleSuccess()
        // console.log("heree")
        history.push(SUPER_ADMIN_DASHBOARD_EDIT)
      } else {
        toast.error(message)
        // handleError(message)
      }
      // await axios.post(reqUri, payload)
      //   .then((response) => {
      //     if (SUCCESS_RESPONSE_LIST.includes(response.data.status)) {
      //       handleSuccess()
      //     } else {
      //       handleError(response?.data?.message)
      //     }
      //   })
    } catch (error) {
      // handleError("Network Error")
    }
  };
  return (
    <section className="widget-info" id="bank-info">
      <HorizontalLine />
      <Container fluid={true}>
        <Row>
          <p className="widget-info__title">{header}</p>
        </Row>
        <Row>
          <Col lg={4}>
            <div>
              <p className="widget-info__sub-title">{columnOne.titleOne}</p>
              <p className="widget-info__sub-text">{bank_name}</p>
            </div>
            <div className="image-cheque">
              <p className="widget-info__sub-title">{columnOne.titleTwo}</p>
              <div style={{display : 'flex'}}>
                <Image
                  className="widget-info__cheque-img"
                  onClick={() =>
                    handleShow(
                      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/cheque`,
                      ""
                    )
                  }
                  src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/cheque`}
                />
                <div>
                   {adminView &&  <><input
                      ref={inputRefAadhar}
                      type="file"
                      onChange={(e)=>handleFileChange(e, _id, selectedData, "cheque")}
                      accept="image/*"
                      style={{ display: "none" }}
                    ></input>
                    {currentActiveStep < 6 &&
                    <button
                      onClick={handleClickAadhar}
                      style={{ borderWidth: "0px", background: "none" }}
                    >
                      <img src={uploadImg} style={{ height: "30px" ,width : "15px" }} />
                      </button>
}
                      </>}
                </div>
              </div>
            </div>
            <div>
            <p className="widget-info__sub-title">{columnOne.titleThree}</p>
              <p className="widget-info__sub-text">{selectedData?.complete_address ?? ""}</p>
            </div>
         { source_data?.security_deposit?  
         <div>
            <p className="widget-info__sub-title">{security_deposit_subtext}</p>
            {payment_status?.transaction_id}
            </div>: ""}
          </Col>
          <Col lg={4}>
            <div>
              <p className="widget-info__sub-title">{columnTwo.titleOne}</p>
              {ifsc_code}
            </div>
            <div>
              <p className="widget-info__sub-title">{columnTwo.titleTwo}</p>
              {account_number}
            </div>
          <div style={{marginTop: "20px"}}>
          <span className="widget-info__sub-titles">{marketing_deposit_subtext}</span>
          {adminView &&
                  <img
                    className="ml-2"
                    src={editIcon}
                    alt="edit"
                    style={{ cursor: "pointer", width: "14px" }}
                    onClick={() =>
                      handleEdit(
                        "Marketing Deposit Transaction ID",
                        "marketing_transaction_id",
                         selectedData?.marketing_transaction_id
                      )
                    }
                  />
          }
           </div>
           <div>
            {marketing_transaction_id}
          </div>
          </Col>
          
          <Col lg={4}>
            <div>
              <p className="widget-info__sub-title">{columnThree.titleOne}</p>
              <p className="widget-info__sub-text">{beneficiary_name}</p>
            </div>
            {source_data?.security_deposit ?<div>
              <p className="widget-info__sub-title">{columnThree.titleTwo}</p>
              {source_data?.security_deposit} {payment_approved && <> <img src={tickMark} /> </>}
            </div>: ""}
           
              <div style={{marginTop: "10px"}}>
          <span className="widget-info__sub-titles">{rolling_advance_subtext}</span>
          {adminView  &&
                  <img
                    className="ml-2"
                    src={editIcon}
                    alt="edit"
                    style={{ cursor: "pointer", width: "14px" }}
                    onClick={() =>
                      handleEdit(
                        "Rolling Advance Transaction ID",
                        "rolling_transaction_id",
                        selectedData.rolling_transaction_id
                      )
                    }
                  />
          }
          </div>
          <div>
            {rolling_transaction_id}
          </div>
          </Col>
        </Row>
        <Row>
          <Col>
      
          </Col>
          <Col>
      
           
          </Col>
        </Row>
        
        <ViewImage show={show} src={src} handleClose={handleClose} />
      </Container>
      {showEditModal && (
     <InputModal
     showModal={showEditModal}
     hideModal={() => setShowEditModal(false)}
     title={editModalDetails?.title}
     inputText={editModalDetails?.inputText}
     setEditModalDetails={updateSelectedData}
     field = {editModalDetails?.field}
     handleSubmit={(e)=>handleEditSubmit(e)}
     buttonSubmitType={"primary"}
     buttonSubmitLabel={"Submit"}
     buttonCloseType="outline"
     buttonCloseLabel="Cancel"
   />
      )}
    </section>
  );
};

export default PartnerInformation;
