import axios from "axios";
import {
  FETCH_PARTNER_DETAILS_REQUEST,
  FETCH_PARTNER_DETAILS_SUCCESS,
  FETCH_PARTNER_DETAILS_FAILURE,
  SET_APPLICANT_SECOND_JOURNEY_SELECTED_DATA,
  STORE_PARTNER_LIST,
  STORE_AADHAR_CLIENT_ID,
  FETCH_APPLICATIONS_REQUEST,
  FETCH_APPLICATIONS_SUCCESS,
  FETCH_APPLICATIONS_FAILURE,
  SET_APPLICANT_DATA,
  SET_SELECTED_APPLICATION,
  FETCH_KYC_FORM_REQUEST,
  FETCH_KYC_FORM_SUCCESS,
  FETCH_KYC_FORM_FAILURE,
  SAVE_SECOND_JOURNEY_DRAFT_APPLICATIONS
} from "./AddPartnerDetailsTypes";
import { WORKFLOW_ID } from "../../constants/app.constants";
import {
  FETCH_KYC_INVITATION_REQUEST,
  FETCH_KYC_INVITATION_SUCCESS,
  FETCH_KYC_INVITATION_FAILURE
} from "./AddPartnerDetailsTypes";

import { SUCCESS_RESPONSE_LIST } from "../../constants/status";
import { setAppError, setSubmitLoading } from "../App/Action";
import requestV1 from "../../utils/requestV1";
import toast from "react-hot-toast";
import { getItemFromLocal } from "../../utils/storageHelper/localStorage";


export const fetchAddPartnerDetailsFormFields = () => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/get-second-journey-form`;
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },

  };
  try {
    const response = await requestV1(reqUri, options)
    const formFields = {
      configuration: {
        step_1: {
          module: response?.module,
          title: response?.title,
          fields: response?.fields,
        },
      },
    };
    dispatch(fetchAddPartnerDetailsFormSuccess(formFields));
  }
  catch (err) {
    dispatch(setAppError(true))
    toast.error("Network Error")
  }
};

export const aadharGenerateOTP = (aadharNumber, handleSuccess = undefined, handleError = undefined) => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_KYC_ENDPOINT}/digitalkyc/validateAadharNumber`
  const payload = {
    "id_number": aadharNumber,
    "type": "generateOTP",
    "customer_id": "jdu2ejf99e"
  }
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };

  try {
    dispatch(setSubmitLoading(true))
    const response = await requestV1(reqUri, options)
    const { status_code, data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status_code)) {  
      localStorage.setItem('aadhar_client_id', data?.client_id)
      dispatch(setSubmitLoading(false))
      dispatch(saveAadharClientId(data?.client_id))
      if (handleSuccess) {
        handleSuccess()
      }
    } else {
      dispatch(setSubmitLoading(false))
      if (handleError) {
        handleError(message)
      }
    }
    // await axios.post(reqUri, payload)
    //   .then((response) => {
    //     if (SUCCESS_RESPONSE_LIST.includes(response.data.status_code)) {
    //       localStorage.setItem('aadhar_client_id', response?.data?.data?.client_id)
    //       dispatch(setSubmitLoading(false))
    //       dispatch(saveAadharClientId(response?.data?.data?.client_id))
    //       if (handleSuccess) {
    //         handleSuccess()
    //       }
    //     } else {
    //       dispatch(setSubmitLoading(false))
    //       if (handleError) {
    //         handleError(response?.data?.message)
    //       }
    //     }
    //   })
  } catch (error) {
    dispatch(setSubmitLoading(false))
    handleError("Network Error")
  }
}

export const aadharVerifyOTP = (payload, handleSuccess, handleError) => async () => {
  const reqUri = `${process.env.REACT_APP_KYC_ENDPOINT}/digitalkyc/validateAadharNumber`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };
  try {
    const response = await requestV1(reqUri, options)
    localStorage.setItem('name_as_per_aadhar_applicant', undefined);
    localStorage.setItem('gender', undefined);
    const { data, message, status_code } = response
    if (SUCCESS_RESPONSE_LIST.includes(status_code)) {
      localStorage.setItem('name_as_per_aadhar_applicant', data?.full_name)
      localStorage.setItem('gender', data?.gender);
      handleSuccess();
    } else if (status_code == 422) {
      handleError(message)
    }
    else {
      handleError(message)
    }
    // await axios.post(reqUri, payload)
    //   .then((response) => {
    //     localStorage.setItem('name_as_per_aadhar_applicant', undefined);
    //     localStorage.setItem('gender', undefined);
    //     if (response.data?.status_code == 200) {
    //       localStorage.setItem('name_as_per_aadhar_applicant', response?.data?.data?.full_name)
    //       localStorage.setItem('gender', response?.data?.data?.gender);
    //       handleSuccess();
    //     } else if (response.data?.status_code == 422) {
    //       handleError(response?.data?.message)
    //     }
    //     else {
    //       handleError(response?.data?.message)
    //     }
    //   })
  } catch (error) {
    handleError("Network Error")
  }
}

export const submitApplicantSecondJourney = (payload, handleSuccess, handleError) => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    body: payload
  };

  try {

    const response = await requestV1(reqUri, options, false, true)
    const { status, message } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleSuccess()
    } else {
      toast.error(message)
      handleError(message)
    }
    // await axios.post(reqUri, payload)
    //   .then((response) => {
    //     if (SUCCESS_RESPONSE_LIST.includes(response.data.status)) {
    //       handleSuccess()
    //     } else {
    //       handleError(response?.data?.message)
    //     }
    //   })
  } catch (error) {
    handleError("Network Error")
  }
}

export const secondJourneyDrafts = (data) => {
  return {
    type: SAVE_SECOND_JOURNEY_DRAFT_APPLICATIONS,
    payload: data
  }
}

export const fetchAddPartnerDetailsFormRequest = () => {
  return {
    type: FETCH_PARTNER_DETAILS_REQUEST,
  };
};

export const fetchAddPartnerDetailsFormSuccess = (data) => {
  return {
    type: FETCH_PARTNER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchAddPartnerDetailsFormFailure = (error) => {
  return {
    type: FETCH_PARTNER_DETAILS_FAILURE,
    payload: error,
  };
};

export const setApplicantSecondJourneySelectedData = (key, value) => {
  return {
    type: SET_APPLICANT_SECOND_JOURNEY_SELECTED_DATA,
    key,
    payload: value,
  };
};

export const clearApplicantSecondJourneySelectedData = (key, value) => {
  return {
    type: "CLEAR_SECOND_JOURNEY_SELECTED_DATA",
    key,
    payload: value,
  };
};


export const savePartnerList = (data) => {
  return {
    type: STORE_PARTNER_LIST,
    payload: data
  }
}

export const fetchApplications = () => async (dispatch, getState) => {
  const applicantId = getState().addPartnerDetails?.loggedInApplicant?._id || getItemFromLocal('userId');
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user_id: applicantId, organisation_id: process.env.REACT_APP_ORGANIZATION_ID})
  }
  try {
    const response = await requestV1(reqUri, options)
    const { data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(fetchApplicationsSuccess(data));
    }
    else {
      toast.error("Network Error")
      // dispatch(fetchApplicantsListFailure(err.message));
    }
  }
  catch (err) {
    toast.error("Network Error")
  }
};

export const fetchApplicationsRequest = () => {
  return {
    type: FETCH_APPLICATIONS_REQUEST,
  };
};

export const fetchApplicationsSuccess = (data) => {
  return {
    type: FETCH_APPLICATIONS_SUCCESS,
    payload: data,
  };
};


export const saveAadharClientId = (data) => {
  return {
    type: STORE_AADHAR_CLIENT_ID,
    payload: data
  }
}

export const sendMailKycInvitation = () => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/kycInvitation`;
  dispatch(sendMailKycInvitationRequest());
  try {
    const result = await axios.post(reqUri);
    const { fields, module, title } = result;
    const formFields = {
      configuration: {
        step_1: {
          module: module,
          title: title,
          fields: fields,
        },
      },
    };
    dispatch(sendMailKycInvitationSuccess(formFields));
  } catch (error) {
    dispatch(setAppError(true))
    dispatch(sendMailKycInvitationFailure(error));
  }
};

export const sendMailKycInvitationRequest = () => {
  return {
    type: FETCH_KYC_INVITATION_REQUEST,
  };
};

export const sendMailKycInvitationSuccess = (data) => {
  return {
    type: FETCH_KYC_INVITATION_SUCCESS,
    payload: data,
  };
};

export const fetchApplicationsFailure = (error) => {
  return {
    type: FETCH_APPLICATIONS_FAILURE,
    payload: error,
  };
};

export const setApplicantData = (data) => {
  return {
    type: SET_APPLICANT_DATA,
    payload: data,
  };
};

export const setSelectedApplication = (data) => {
  return {
    type: SET_SELECTED_APPLICATION,
    payload: data,
  };
};
export const sendMailKycInvitationFailure = (error) => {
  return {
    type: FETCH_KYC_INVITATION_FAILURE,
    payload: error,
  };
};

export const fetchKYCFormFields = () => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/get-second-journey-kyc`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },

  }
  try {
    const response = await requestV1(reqUri, options)
    const { result, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(fetchKYCFormSuccess(result));
    }
    else {
      dispatch(fetchKYCFormFailure(message));
    }
  }
  catch (err) {
    dispatch(setAppError(true))
    toast.error("Network Error")
  }
};

export const fetchKYCFormRequest = () => {
  return {
    type: FETCH_KYC_FORM_REQUEST,
  };
};

export const fetchKYCFormSuccess = (data) => {
  return {
    type: FETCH_KYC_FORM_SUCCESS,
    payload: data,
  };
};

export const fetchKYCFormFailure = (error) => {
  return {
    type: FETCH_KYC_FORM_FAILURE,
    payload: error,
  };
};