import axios from "axios";
import CryptoJS from "crypto-js";
import toast from "react-hot-toast";
import { SUCCESS_RESPONSE_LIST } from "../../../constants/status";
import { setSubmitLoading } from "../../../redux/App/Action";
import requestV1 from "../../../utils/requestV1";

 
function encryptRequestAndDecryptResponse(method, endPoint, apiBody){
  console.log("process.env.REACT_APP_BASE_URL",process.env.REACT_APP_BASE_URL);
  
  var randomId, encryptedAPIParams;
  let options = { method: method };
  // fetch from config var
  var cipherKey='Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh';
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/generic/getRandomId`)
    .then((response) => {
      randomId=response.data.tokenId;
      // Decrypt randomId
      var randomIdDecrypted  = (CryptoJS.AES.decrypt(randomId, cipherKey)).toString(CryptoJS.enc.Utf8);
      // Encrypt apiBody param
      var apiBodyEncrypted = CryptoJS.AES.encrypt(JSON.stringify(apiBody), cipherKey).toString();
      randomIdDecrypted = btoa(randomIdDecrypted)
      encryptedAPIParams = {
        api_body: apiBodyEncrypted,
        token_id: randomIdDecrypted
      }
      axios.post(endPoint,encryptedAPIParams).then((EncryptedResponse)=>{
        let decreptedResponse = (CryptoJS.AES.decrypt(EncryptedResponse, cipherKey)).toString(CryptoJS.enc.Utf8);
        return decreptedResponse;
      }).catch((error)=>{
      });
    })
    .catch((error) => {
      return null;
    });
}

const generateOTPToMobile=async(mob)=> {
  let apiBody = {
    "login_otp_mode": "mobile",
    "phoneNumber": mob,
    "organisation_id": process.env.REACT_APP_ORGANIZATION_ID,
    "type": "login",
    "loginType": "retailApp"
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/users/generateOTPToMobile`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiBody)
  }; 
    try{
      const response=await requestV1(url,options)
      return response 
    }
    catch(err){
      toast.error("Network Error")
    }
  // return axios.post(url, apiBody).catch((e) => Promise.reject(e.response.data));
}


const validateMobileOTP=async(otp, mob) =>{
  let apiBody = {
    "login_otp_mode": "mobile",
    "phoneNumber": mob,
    "organisation_id": process.env.REACT_APP_ORGANIZATION_ID,
    "type": "login",
    "loginType": "retailApp",
    "otp": otp
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/users/validateMobileOTP`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(apiBody)
  }; 
  try{
    const response=await requestV1(url,options)
    return response 
  }
  catch(err){
    toast.error("Network Error")
  }

  // return axios.post(url, apiBody).catch((e) => Promise.reject(e.response.data));
}

export const sendReminderToPartner=(email,name) =>async (dispatch)=>{

  let params = {
    name:name,
    email:email
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/sendReminderToPartner`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params)
  };
  try{
     dispatch(setSubmitLoading(true))

    const response=await requestV1(url,options)
    const {message,status,data}=response
    if(SUCCESS_RESPONSE_LIST.includes(status)){
      toast.success(message)
      dispatch(setSubmitLoading(false))
    }  
    else{
      toast.error(message)
      dispatch(setSubmitLoading(false))
    }  
  }
  catch(err){
    dispatch(setSubmitLoading(false))
    toast.error(err.message)
  }
  
  //  return (dispatch)=>{
  //    dispatch(setSubmitLoading(true))
  //   const url = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/sendReminderToPartner`;
  //   axios.post(url,params)
  //    .then((res)=>{
  //       const status=res.data.status

  //       if(SUCCESS_RESPONSE_LIST.includes(status)){
  //         dispatch(setSubmitLoading(false))
  //         toast.success(res.data.message)
  //       }  
  //       else{
  //         dispatch(setSubmitLoading(false))
  //         toast.error(res.data.message)
  //       }

  //    })   
  //    .catch((err)=>{
  //     dispatch(setSubmitLoading(false))
  //      toast.error(err.message)
  //    })
  //  }
   
  // return axios.post(url, params).catch((e) => Promise.reject(e.response));
}

function kycInvitation() {
  let params = {
    name:"Rishika kaliat"
  }
  const url = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/kycInvitation`;
  return axios.post(url, params).catch((e) => Promise.reject(e.response.data));
}

async function axisPayment(params) {
  const url = `${process.env.REACT_APP_BASE_URL}/api/axisPayment/initiatePaymentStatus`;
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  }
  try{
    let response = await requestV1(url, options)
    return response
  }catch(err){
    Promise.reject(err)
  }
}

async function axisPaymentResult(params) {
  const url = `${process.env.REACT_APP_BASE_URL}/api/axisPayment/paymentStatus`;
  let options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  }
  try{
    let response = await requestV1(url, options)
    // console.log(response)
    return response
  }catch(err){
    Promise.reject(err)
  }
}

export { encryptRequestAndDecryptResponse, generateOTPToMobile, validateMobileOTP, kycInvitation, axisPaymentResult, axisPayment };
