import Pagination from "react-bootstrap/Pagination";
import "bootstrap/dist/css/bootstrap.css";
import { usePagination, threeDot } from "./usePagination";
import "./index.scss";

const PaginationCMO = ({
  onPageChange,
  currentPage,
  totalCount,
  pageSize,
  siblingCount = 1,
}) => {
  const paginationRange = usePagination({
    //Givea number of pages

    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }
  const onNext = () => {
    onPageChange(currentPage + 1);
  };
  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  const onFirst = () => {
    onPageChange(1);
  };
  const onLast = () => {
    onPageChange(paginationRange?.[paginationRange?.length - 1]);
  };
  let lastPage = paginationRange?.[paginationRange?.length - 1];

  return (
      <Pagination>
        <Pagination.First onClick={onFirst} />
        <Pagination.Prev disabled={currentPage === 1} onClick={onPrevious} />
        {paginationRange?.map((pageNumber) => {
          if (pageNumber === threeDot) {
            return <Pagination.Ellipsis key={pageNumber} />;
          }
          return (
            <Pagination.Item
              active={currentPage === pageNumber}
              key={pageNumber}
              onClick={() => onPageChange(pageNumber)}
              className="pagination-number"
            >
              {pageNumber}
            </Pagination.Item>
          );
        })}
        <Pagination.Next disabled={currentPage === lastPage} onClick={onNext} />
        <Pagination.Last onClick={onLast} />
      </Pagination>
  );
};
export default PaginationCMO;
