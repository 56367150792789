import Image from "../Image";
import "./index.scss";

const Avatar = props => {
  return (
    <span className="lds-avatar">
      <Image {...props} />
    </span>
  );
};

export default Avatar;
