import { useLocation, useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserInformation from "../../ReviewDetails/components/UserInfo";
import CenterInfo from "../../ReviewDetails/components/CenterInformation";
import AddtionalDetails from "../../ReviewDetails/components/AdditionalDetails";
import PartnerInformation from "../../ReviewDetails/components/PartnerInformation";
import OtherInformation from "../../ReviewDetails/components/OtherInformation";
import ChecklistRequirement from "../../ReviewDetails/components/ChecklistRequirement";
import PhleboInformation from "../../ReviewDetails/components/PhleboInformation";
import BrandingPhotos from "../../ReviewDetails/components/BrandingPhotos";
import ServiceTiming from "../../ReviewDetails/components/ServiceTiming";
import BankInformation from "../../ReviewDetails/components/BankInformation";

import Button from "../../../lib/components/Button";
import Remarks from "../../ReviewDetails/components/Remarks";
import { Col, Container, Row } from "react-bootstrap";
import Overlay from "../../../lib/components/Overlay";
import Textarea from "../../../lib/components/Textarea";
import { BUSINESS_HEAD_DASHBOARD, BUSINESS_HEAD_SUBMIT } from "../../../constants/route.constants.js/business_head";
import {
  rejectApplication,
  approveApplication,
  clarifyApplication
} from "../../../redux/BusinessHead/BusinessHeadAction";
import { Toaster, toast } from "react-hot-toast";
import { APPLICATION_STATUSES } from '../../../constants/status.constants';
import Clarifications from '../../ReviewDetails/components/Clarification/index';
import { getStatusBasedBtn } from '../utils/getStatusBasedBtn';
import SlidingNavbar from "../../ReviewDetails/components/SlidingNavabr";
import { loginInfo } from "../../../redux/Login/LoginActions"
import ActivityTracker from "../../ReviewDetails/components/ActivityTracker";

var rejectStatus = false;

const ReviewOverlay = ({
  showOverlay,
  setShowOverlay,
  setRemarkComment,
  applicant,
  handleReject,
  handleSubmit,
  selectedOption
}) => {
  const [overlayData, setOverlayData] = useState();
  const history = useHistory();

  const dispatch = useDispatch();
  const userData = dispatch(loginInfo)?.data;
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  const handleAddPartner = () => {
    setShowOverlay(true);
  };

  const handleSuccessClarify = () => {
    history.push(BUSINESS_HEAD_DASHBOARD)
  }

  const handleSubmitOverlay = () => {
    setShowOverlay(false);
    setRemarkComment(overlayData);
    if (selectedOption === "Clarification") {
      dispatch(clarifyApplication(applicant, userData, overlayData, handleSuccessClarify))
    }
    if (selectedOption === "Reject") {
      handleReject(overlayData)
      // dispatch(rejectApplication(applicant, overlayData));
    }
    if (selectedOption === "Approve") {
      handleSubmit(overlayData)
    }

  };
  return (
    <div>
      {(selectedOption === "Reject" || selectedOption === "Approve") && (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Remarks"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType={selectedOption === "Reject" ? "red" : ""}
          buttonSubmitLabel={selectedOption === "Reject" ? "REJECT" : "SUBMIT"}
          handleCloseOverlay={handleCloseOverlay}
          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea
            placeholder={selectedOption === "Approve" ? "Please specify the details of the remarks (optional)" : "Please specify the details of the remarks"}
            onChange={(e) => setOverlayData(e.target.value)}
          />
        </Overlay>
      )}

      {selectedOption === "Clarification" && (
        <Overlay
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          title="Clarification"
          buttonCloseType="outline"
          buttonCloseLabel="CANCEL"
          buttonSubmitType=""
          buttonSubmitLabel="SUBMIT"
          handleCloseOverlay={handleCloseOverlay}
          handleSubmitOverlay={handleSubmitOverlay}
        >
          <Textarea placeholder="Please specify the details of the clarification required" onChange={(e) => setOverlayData(e.target.value)} />
        </Overlay>
      )}
    </div>

  );
};

const ReviewDetailsBH = () => {
  const location = useLocation();
  const history = useHistory();
  const [showOverlay, setShowOverlay] = useState(false);
  const [routePath, setRoutePath] = useState();
  const [remarkComment, setRemarkComment] = useState();
  const [selectedOption, setSelectedOption] = useState('')

  const dispatch = useDispatch();

  useEffect(() => {
    setRoutePath(location.pathname);
  }, []);

  const { active_step, status } = location?.state?.review_data
  //TODO- Add a reducer which saves this data and use the data from the redux store
  const selectedApplicantBH = location?.state?.review_data

  const userData = dispatch(loginInfo)?.data;
  const _id = location?.state?._id
  const loading = useSelector((state) => {
    return state?.globalReducer?.submitLoading
  })
  const rejectLoader = useSelector((state) => {
    return state?.globalReducer?.rejectLoading
  })
  const clarifyLoader = useSelector((state) => {
    return state?.globalReducer?.clarifyLoading
  })

  const handleSuccess = (success) => {
    history.push(BUSINESS_HEAD_SUBMIT, success);
  }

  const handleBack = () => {
    history.push(BUSINESS_HEAD_DASHBOARD)
  }
  const handleRejectPopUp = () => {
    setShowOverlay(true);
    rejectStatus = false;
    setSelectedOption('Reject')
  }
  const handleClarificationPopUp = () => {
    setShowOverlay(true);
    rejectStatus = true;
    setSelectedOption('Clarification')
  }

  const handleApprovePopUp = () => {
    setShowOverlay(true);
    setSelectedOption('Approve')
  }



  const handleError = (message) => {
    toast.error(message);
  };

  const handleSubmit = (text) => {
    const textPayload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: location?.state?.review_data?._id,
    };

    const payload = {
      ...location?.state?.review_data,
    }

    if (text !== undefined) {
      payload?.reject_remarks?.push(textPayload);
    }
    dispatch(approveApplication(payload, userData?._id, handleSuccess));
  };

  const handleReject = (text) => { //reject
    const payload = {
      remark: text,
      name: userData?.profile?.first_name,
      role: userData?.role_name,
      _id: location?.state?.review_data?._id,
    };

    // let formData = new FormData();
    const rejectCommentsHistory = {
      applicationId: location?.state?.review_data?._id,
      ...location?.state?.review_data,
      status: APPLICATION_STATUSES['Rejected']['label']
    };
    rejectCommentsHistory?.reject_remarks?.push(payload);
    // formData.append("data", JSON.stringify(rejectCommentsHistory));
    dispatch(rejectApplication(rejectCommentsHistory,userData, handleSuccess, handleError));
  }

  return (
    <section className="review-details__wrapper">
      <Toaster />
      <Container fluid={true}>
        <Row>
          <Col lg={12}>
            <SlidingNavbar selectedData={selectedApplicantBH} />

            <UserInformation
              selectedData={selectedApplicantBH?.source_data}
              _id={_id}
              kycDocs={selectedApplicantBH?.kycDocs}
            />
            <div style={{ marginTop: "50px" }}>
              {Array.isArray(selectedApplicantBH?.partner_details) &&
                selectedApplicantBH?.partner_details?.map((item) => {
                  if (item?.source_data) {
                    return (
                      <UserInformation
                        selectedData={item?.source_data}
                        _id={selectedApplicantBH?._id}
                        partnerId={item?.id}
                        isPartnerData={true}
                        kycDocs={selectedApplicantBH?.kycDocs}
                      />
                    );
                  }
                })}
            </div>
            <CenterInfo selectedData={selectedApplicantBH?.source_data} />
            <AddtionalDetails selectedData={selectedApplicantBH?.source_data} />
            {
              selectedApplicantBH?.source_data?.partner_profiling ?
                <PartnerInformation selectedData={selectedApplicantBH?.source_data} fullInfo={selectedApplicantBH}/> : ''
            }
            {
              selectedApplicantBH?.bank_name ?
                <BankInformation selectedData={selectedApplicantBH} /> : ''
            }
            {
              selectedApplicantBH?.document_signed ?
                <OtherInformation selectedData={selectedApplicantBH} /> : ''
            }
            {
              Array.isArray(selectedApplicantBH?.checkList) && selectedApplicantBH?.checkList ?
                <ChecklistRequirement
                  selectedData={selectedApplicantBH?.checkList}
                />
                : ''
            }
            {
              Array.isArray(selectedApplicantBH?.phleboList) && selectedApplicantBH?.phleboList?.length ?
                <PhleboInformation
                  selectedData={selectedApplicantBH?.phleboList}
                  _id={selectedApplicantBH?._id}
                /> : ''
            }
            {
              selectedApplicantBH?.weekday_start_time ?
                <>
                  <BrandingPhotos _id={selectedApplicantBH?._id} />
                  <ServiceTiming selectedData={selectedApplicantBH} />
                </>
                : ''
            }
            {
              Array.isArray(selectedApplicantBH?.reject_remarks) && selectedApplicantBH?.reject_remarks?.length ?
                <Remarks role="Business Head" selectedData={selectedApplicantBH?.reject_remarks} /> : ''
            }
            {
              Array.isArray(selectedApplicantBH?.clarification_history) && selectedApplicantBH?.clarification_history?.length ?
                <Clarifications role="Business Head" selectedData={selectedApplicantBH?.clarification_history} userData={userData} /> : ''
            }
           <ActivityTracker selectedData={selectedApplicantBH?.approval_history} />

            <div className="layout-button">
              <div>
                <Button label="Back" type="ghost" onClick={handleBack} />
              </div>
              <div>
                {getStatusBasedBtn('reject', active_step, status) && <Button
                  label="REJECT"
                  type="reject"
                  loading={rejectLoader}
                  onClick={() => handleRejectPopUp()}
                />}

                {getStatusBasedBtn('rejected', active_step, status) && (
                  <Button label="REJECTED" type="reject" disabled />
                )}

                {
                  getStatusBasedBtn('clarification', active_step, status) &&
                  <Button
                    label="CLARIFICATION"
                    loading={clarifyLoader}
                    type="outline"
                    onClick={() => handleClarificationPopUp()}
                    id="clarification"
                  />
                }
                {
                  getStatusBasedBtn('approve', active_step, status) &&
                  <Button label="APPROVE" onClick={handleApprovePopUp} loading={loading} />
                }


              </div>
            </div>
            {showOverlay && (
              <ReviewOverlay
                showOverlay={showOverlay}
                applicant={selectedApplicantBH}
                selectedOption={selectedOption}
                setShowOverlay={setShowOverlay}
                setRemarkComment={setRemarkComment}
                handleReject={handleReject}
                handleSubmit={handleSubmit}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReviewDetailsBH;
