import {
  SET_REJECT_LOADING,
  SET_SUBMIT_LOADING,
  LOGIN_LOADING,
  SET_CLEARIFICATION_LOADING,
  PAGE_LOADING,
  SET_DISABLED_FIELDS,
  SET_OTP_LOADING,
  SET_APP_ERROR
} from "./Types";

const initialState = {
  rejectLoading: false,
  clarifyLoading: false,
  submitLoading: false,
  loginLoading: false,
  pageLoading:false,
  otpLoading:false,
  onboarding_form_disabled_fields: [],
  appError:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBMIT_LOADING:
      return {
        ...state,
        submitLoading: action.payload,
      };
    case SET_REJECT_LOADING:
      return {
        ...state,
        rejectLoading: action.payload,
      };
    case SET_CLEARIFICATION_LOADING: {
      return {
        ...state,
        clarifyLoading: action.payload,
      };
    }
    case LOGIN_LOADING: {
      return {
        ...state,
        loginLoading: action.payload,
      };
    }
    case PAGE_LOADING:{
      return {
        ...state,
        pageLoading:action.payload
      }
    }
    case SET_DISABLED_FIELDS:
      const _type = action.action_type;
      const fields = action.payload;
      let _disabled = [...state.onboarding_form_disabled_fields];
      if (_type === "add") {
        for (let key in fields) {
          const item = fields[key];
          if (!_disabled.includes(item)) {
            _disabled.push(item);
          }
        }
      } else {
        _disabled = _disabled.filter((_item) => { return !fields.includes(_item) });
      }
      return {
        ...state,
        onboarding_form_disabled_fields: _disabled
      }
      case SET_OTP_LOADING:{
        return{
          ...state,
          otpLoading:action.payload
        }
      }
      case SET_APP_ERROR:
        return{
          ...state,
          appError:action.payload
        }
    default:
      return state;
  }
};

export default reducer;
