import "./index.scss";
import { useEffect } from "react";
import AddRemark from "../../../components/AddRemark";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPhleboSelectedData,
  fetchQueryList,
  updateCheckList,
} from "../../../redux/PhleboOnboarding/PhleboOnboardingActions";

import { useHistory } from "react-router";
import { CLIENT_DASHBOARD_BASIC } from "../../../constants/route.constants.js/applicant";
import { PHLEBO_ONBOARDING } from "../../../constants/route.constants.js/phlebo";
import { Toaster, toast } from "react-hot-toast";
import { saveDraft } from "../../../redux/ClusterHead";
import DynamicForm from "../../../components/DynamicForm";
import HorizontalLine from "../../ReviewDetails/components/HorizontalLine";
import Button from "../../../lib/components/Button";

const RequirementChecklist = () => {
  let checklistError = {};

  const checkList = useSelector(state => state?.phleboOnboarding?.checkList);
  const selectedPhleboApplication = useSelector(
    state => state?.phleboOnboarding?.selectedPhleboApplication
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!selectedPhleboApplication?.is_draft) {
      dispatch(fetchQueryList);
    }
  }, []);

  const handleListUpdate = (mainIdx, listIdx, val, is_remark = false) => {
    let tempList = [...checkList];
    tempList[mainIdx]["list"][listIdx][is_remark ? "remark" : "selected"] = val;
    dispatch(updateCheckList(tempList));
  };

  const errorHandle = checkList => {
    if (checkList) {
      return checkList.map(ele => {
        return ele.list.find(listItem => {
          if (listItem?.selected === undefined) {
            checklistError.error = "All fields are required";
          }
        });
      });
    } else {
      checklistError.error = "";
    }
  };

  const handlePrevNext = (prev = false, cancelProp) => {
    if (cancelProp) {
      const route = prev ? CLIENT_DASHBOARD_BASIC : PHLEBO_ONBOARDING;
      if (route === CLIENT_DASHBOARD_BASIC) {
        dispatch(clearPhleboSelectedData());
        return history.push(route);
      }
    }
    errorHandle(checkList);
    if (checklistError?.error) {
      toast.error(checklistError?.error);
    } else {
      const route = prev ? CLIENT_DASHBOARD_BASIC : PHLEBO_ONBOARDING;
      history.push(route);
    }
  };

  const handleSuccess = message => {
    toast.success(message);
    history.push(CLIENT_DASHBOARD_BASIC);
  };

  const handleError = message => {
    toast.error(message);
  };

  const handleSaveDraft = () => {
    const payload = {
      checkList: checkList,
      ...selectedPhleboApplication,
      is_draft: true,
    };
    dispatch(saveDraft(payload, handleSuccess, handleError));
  };

  const handleCancel = () => {
    history.push(CLIENT_DASHBOARD_BASIC);
  };

  return (
    <section className="requirement-checklist">
      <Toaster />
      <p className="requirement-checklist__sub-header">Getting Started</p>
      <p className="requirement-checklist__header">
        Hey, welcome to Agilus Diagnosis Franchise! You're Few steps away from
        establishing your own successful business. Fill the below checklist to
        proceed further.
      </p>
      <HorizontalLine className="phlebo-checklist-separator" />
      {checkList?.map((item, mainIdx) => {
        return (
          <div className="requirement-checklist__list">
            <div className="requirement-checklist__list_header">
              {item?.header}
            </div>
            <div>
              {item?.list?.map((listItem, listIdx) => (
                <AddRemark
                  text={listItem.label}
                  value={listItem.selected}
                  remark={listItem.remark}
                  handleSelect={val => {
                    handleListUpdate(mainIdx, listIdx, val);
                  }}
                  updateRemark={val => {
                    handleListUpdate(mainIdx, listIdx, val, true);
                  }}
                />
              ))}
            </div>
          </div>
        );
      })}

      <div className="layout-button partner-documentation--action-btn">
        <div onClick={() => handlePrevNext(true, true)}>
          <span className="partner-details-form__cancel_btn">CANCEL</span>
        </div>
        <div>
          <Button label="Draft" type="outline" onClick={handleSaveDraft} />
          <Button label="next" onClick={() => handlePrevNext(false)} />
        </div>
      </div>
    </section>
  );
};

export default RequirementChecklist;
