import { Fragment, useEffect, useState } from "react";
import Button from "../../lib/components/Button";
import { useDispatch } from "react-redux";

import TableContent from "./TableContent";
import AddListContainer from "./AddListContainer";
import { savePartnerList } from "../../redux/AddPartnerDetails/AddPartnerDetailsActions";
import { savePhleboList } from "../../redux/PhleboOnboarding/PhleboOnboardingActions";
import { setFileObjects, setUploadedDocs } from "../../redux/OnboardingForm/OnboardingFormActions";
import { removeCertificate } from "../../redux/UploadedDocuments/UploadedDocumentsActions";



const AddList = ({
  buttonText,
  showAddPartnerButton,
  formFields,
  overlayHeader,
  phleboOnboarding,
  saveUploadedCertificates,
  certificate,
  listData = []
}) => {
  const [partnerList, setPartnerList] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);


  useEffect(() => {
    if (listData && listData?.length !== 0) {
      setPartnerList(listData);
    }
  }, [])
  //
  const dispatch = useDispatch();

  const handleAddPartner = () => {
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  //
  const addPartnerItem = formData => {
    const data = [...partnerList, formData];
    setPartnerList(data);
    if (phleboOnboarding) {
      dispatch(savePhleboList(data));
    } else {
      dispatch(savePartnerList(data));
    }
  };

  const handleDelete = id => {
    const newArray = partnerList.filter((ele, i) => {
      return ele.id !== id;
    });
    setPartnerList(newArray);
    if (phleboOnboarding) {
      let phleboId = `phlebo_certificate_${id}`

      dispatch(setFileObjects(phleboId, null, true));
      dispatch(setUploadedDocs(phleboId, {}, true));
      dispatch(removeCertificate(phleboId));

      dispatch(savePhleboList(newArray));
    } else {
      dispatch(savePartnerList(newArray));
    }
  };
  const editPartnerList = editedData => {
    const result = partnerList.map(ele => {
      if (ele.id === editedData.id) {
        return { ...ele , ...editedData };
      } else {
        return { ...ele };
      }
    });
    setPartnerList(result);
    if (phleboOnboarding) {
      dispatch(savePhleboList(result));
    } else {
      dispatch(savePartnerList(result));
    }
  };
  return (
    <Fragment>
      <div className="add-partner">
        {partnerList.length === 0 ? (
          <div>
            <div className="add-partner-button">
              {showAddPartnerButton && (
                <p className="add-partner-button-label">
                  Other Partner details
                </p>
              )}
              <Button
                className="add-partner--btn"
                label={buttonText}
                onClick={handleAddPartner}
              />
            </div>
          </div>
        ) : (
          <div className="add-partner-afterList">
            {phleboOnboarding ? (
              <p className="add-partner-button-label">Phlebo Onboarding {partnerList?.length ? `( ${partnerList?.length} Phlebos)` : ''}</p>
            ) : (
              <p className="add-partner-button-label">Other Partner details</p>
            )}
            <div className="add-partner-plus">
              <span onClick={handleAddPartner}>+</span>
            </div>
          </div>
        )}
      </div>

      {showOverlay && (
        <AddListContainer
          setShowOverlay={setShowOverlay}
          showOverlay={showOverlay}
          addPartnerItem={addPartnerItem}
          overlayHeader={overlayHeader}
          buttonText={buttonText}
          handleCloseOverlay={handleCloseOverlay}
          formFields={formFields}
          phleboOnboarding={phleboOnboarding}
          certificate={certificate}
          saveUploadedCertificates={saveUploadedCertificates}
        />
      )}
      {partnerList?.length !== 0 && (
        <TableContent
          handleDelete={handleDelete}
          partnerList={partnerList}
          editPartnerList={editPartnerList}
          phleboOnboarding={phleboOnboarding}
          saveUploadedCertificates={saveUploadedCertificates}
        />
      )}
    </Fragment>
  );
};
export default AddList;
