import { APPLICATION_STATUSES } from '../../../constants/status.constants';

export const getStatusBasedBtn = (type, step, status) => {
    if (type === "reject" && status === APPLICATION_STATUSES['Awaiting Payment Confirmation']['label']) {
        if (step === 5.1) return true
    }
    if (type === 'clarification' && status !== APPLICATION_STATUSES['Rejected']['label']) {
        return true
    }
    if (type === 'approve' && status === APPLICATION_STATUSES['Awaiting Payment Confirmation']['label']) {
        if (step === 5.1) return true
    }
    if (type === 'rejected') {
        if (step === 5.1 && status === APPLICATION_STATUSES['Rejected']['label']) return true
    }
    //TODO:if(type ==="reject" && status === APPLICATION_STATUSES["Awaiting Clarification"]["label"]) return true
}