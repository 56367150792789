
import {
    CERTIFICATE_UPLOADED, REMOVE_CERTIFICATE,
} from "./UploadedDocumentsTypes";

const initialState = {
    certificates: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CERTIFICATE_UPLOADED:
            return {
                ...state,
                certificates: { ...state.certificates, [action.key]: action.payload },
            }
        case REMOVE_CERTIFICATE:
            let tempCertificates = { ...state?.certificates };
            delete tempCertificates[action.key];
            return {
                ...state,
                certificates: tempCertificates
            }
        default:
            return state
    }
}

export default reducer