export const  Helpers={
    get_browser_name: () => {
      var sBrowser,
        sUsrAg = navigator.userAgent;

      // The order matters here, and this may report false positives for unlisted browsers.

      if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
      } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
      } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
      } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
      } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
      } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
      } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
      } else {
        sBrowser = "unknown";
      }
     return sBrowser
    },
  };

export const panHide=(panNumber)=>{
  if(panNumber){
      const first = panNumber?.slice(0, 2);
      const last = panNumber?.slice(panNumber.length - 2);
      let mid = "";
      for (let i = 2; i < panNumber?.length - 2; i++) {
        mid += "*";
      }
      const result = first + "" + mid + "" + last;
      return result
  }
  else{
    return "No Pan found"
  }
}

export const nameWrapperFunc=(firstName,lastName)=>{
  const first=firstName.slice(0,8)
  const last=lastName.slice(0,2)
  return `${first} ${last}`
}

export const rupeesWithCommas=(x)=> {
  const vals = x.toString().split('.');
  return vals[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + ( vals.length == 2 ? '.' + vals[1] : '' )
}

//Unique Bg color 
export const colorPickerFun=(firstCharacter)=>{
  const uniqueColor=[
    {a:"#2596be"},{b:"#febe66"},{c:"#76b5c5"},{d:"#5e1608"},{e:"#2BCE48"},{f:"#4363d8"},{g:"#bfef45"},
    {h:"#f58231"},{i:"#E2E2E2"},{j:"#1CBE4E"},{k:"#C4451C"},{l:"#DEA0FD"},{m:"#FA0087"},{n:"#325A9B"},
    {o:"#FEAF16"},{p:"#F8A19F"},{q:"#90AD1C"},{r:"#F6222E"},{s:"#1CFFCE"},{t:"#2ED9FF"},{u:"#B10DA1"},
    {v:"#C075A6"},{w:"#FC1CBF"},{x:"#B00068"},{y:"#FBE426"},{z:"#FE00FA"}
  ]    
  const result=uniqueColor.find((char)=>{
    return char[firstCharacter.toLowerCase()]
  }) 
  if(Object.keys(result).length !==0){
    return Object.values(result)
  }
}

// Not in used
// export const getRandomColor=() =>{
//   var letters = '0123456789ABCDEF';
//   var color = '#';
//   for (var i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)];
//   }
//   return color;
// }
