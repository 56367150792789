import { useEffect, useState } from "react";
import "./index.scss";
import { Container, Row, Col } from "react-bootstrap";
import Input from "../../lib/components/Input";
import Button from "../../lib/components/Button";
import Loginimage from "../../assets/images/AdminLogin.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PasswordLogin from "./PasswordLogin";
import OTPLogin from "./OTPLogin";

const Login = (props) => {
  const initialObject = {
    username: "",
    password: ""
  }
  const [user, setUser] = useState(initialObject);
  const { handleLogin, otp_login = false } = props;
  let location = useLocation();
  const dispatch = useDispatch();

  const role = useSelector((state) => state);

  const loading = useSelector((state) => {
    return state?.globalReducer?.loginLoading
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmitLogin = () => {
    handleLogin(user)
  }
  let history = useHistory();

  return (
    <section className="login__wrapper">
      <Container fluid={true}>
        <Row>
          <Col lg={6}>
            <div className="login__content-left">
              <h5>Expand the Franchise</h5>
              <p>Over 1000+ successful businesses started here</p>
              <div className="login__content-left--image">
                <img src={Loginimage} alt="" />
              </div>
            </div>
          </Col>
          {otp_login ? (
            <OTPLogin {...props} />
          ) : (
            <PasswordLogin
              handleChange={handleChange}
              user={user}
              handleSubmitLogin={handleSubmitLogin}
              loading={loading}
            />
          )}
        </Row>
      </Container>
    </section>
  );
};
export default Login;
