import React from 'react'
import './index.scss'

const Tooltip = (props) => {
    const { toolTipText, displayText, onClick} = props
    return (    
        <span className="tooltip-container">
            {displayText} 
            <span className="tooltip-text" onClick={onClick}> {toolTipText}</span>
        </span>
    )
}

export default Tooltip
