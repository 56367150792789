import { SUCCESS_RESPONSE_LIST } from "../../constants/status";
import { toast } from 'react-hot-toast'
import {
  FETCH_PARTNER_KYC_REQUEST,
  FETCH_PARTNER_KYC_SUCCESS,
  FETCH_PARTNER_KYC_FAILURE,
  PARTNER_KYC_SELECTED_DATA,
  SET_DATA_FROM_PANCARD_PARTNER_KYC,
  SAVE_PARTNER_OTHER_DATA,
  SAVE_PARTNER_ID
} from "./partnerKYCTypes";
import requestV1 from "../../utils/requestV1";
import { setAppError } from "../App/Action";

export const fetchPartnerKYCFields = () => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/get-partner-kyc`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: {}
  };
  try {
    const response = await requestV1(reqUri, options)
    const { message, status, result } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      const formFields = {
        configuration: {
          step_1: {
            module: result?.module,
            title: result?.title,
            fields: result?.fields,
          },
        },
      };
      dispatch(fetchPartnerKYCSuccess(formFields))
    }
    else {
      dispatch(fetchPartnerKYCFailure(message));
    }
  }
  catch (err) {
    dispatch(setAppError(true))
    toast.error("Network Error")
  }
};

export const fetchCurrentApplication = (workflowId) => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getCurrentApplication`;
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ _id: workflowId })
  };
  try {
    const response = await requestV1(reqUri, options)
    // console.log(response)
    const { status, message, data } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(savePartnerWorkflowDetails(data))
    }
    else {
      toast.error(message)
      dispatch(fetchPartnerKYCFailure(message));
    }
  }
  catch (err) {
    toast.error("Netowork Error")
  }
};

const fetchPartnerKYCRequest = () => {
  return {
    type: FETCH_PARTNER_KYC_REQUEST,
  };
};

const fetchPartnerKYCSuccess = (data) => {
  return {
    type: FETCH_PARTNER_KYC_SUCCESS,
    payload: data,
  };
};

const fetchPartnerKYCFailure = (error) => {
  return {
    type: FETCH_PARTNER_KYC_FAILURE,
    payload: error,
  };
};

export const setPartnerKYCSelectedData = (key, value) => {
  return {
    type: PARTNER_KYC_SELECTED_DATA,
    key,
    payload: value,
  };
};

export const setExtractedPanCardDataForPartnerKYC = (data) => {
  return {
    type: SET_DATA_FROM_PANCARD_PARTNER_KYC,
    payload: data,
  };
};

export const savePartnerWorkflowDetails = (data) => {
  return {
    type: SAVE_PARTNER_OTHER_DATA,
    payload: data,
  };
};

export const savePartnerId = (id) => {
  return {
    type: SAVE_PARTNER_ID,
    payload: id,
  };
};