import { useEffect } from "react";
import { useState } from "react";
import "./index.scss";
import { sliderNavTabList } from "./tablist.constants";

const SlidingNavbar = ({ selectedData }) => {
  const [menu, setMenu] = useState([]);
  const [activeTabId, setActiveTabId] = useState("");
  // const userData = useSelector(state => state?.signIn?.userData)

  useEffect(() => {
    // const userAllowedTabs = sliderNavTabList?.filter((_tab) => { return _tab?.roles?.includes(userData?.role_name) });
    const userAllowedTabs = sliderNavTabList?.filter(_tab => {
      if (_tab?.key === "all") return _tab;
      else if (
        _tab?.key === "reject_remarks" ||
        _tab?.key === "clarification_history"
      ) {
        if (selectedData[_tab?.key]?.length) return _tab;
      } else if (
        selectedData[_tab?.key] ||
        selectedData?.source_data[_tab?.key]
      )
        return _tab;
    });
    if (userAllowedTabs?.length !== 0) {
      setMenu(userAllowedTabs);
      setActiveTabId(userAllowedTabs[0]?.elId);
    }
  }, []);

  const handleTabClick = tab => {
    const { elId } = tab;
    const el = elId && document.getElementById(elId);
    if (elId && el) {
      var headerOffset = 140;
      var elementPosition = el.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window?.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let oldValue = 0;
    let newValue = 0;
    let direction = "";
    window.addEventListener("scroll", () => {
      newValue = window.pageYOffset;
      if (oldValue > newValue) {
        direction = "up";
      } else if (oldValue > newValue) {
        direction = "down";
      }
      oldValue = newValue;
      for (let key in menu) {
        const item = menu[key];
        let { elId } = item;
        const tabEl = document.getElementById(elId);
        const currentY = tabEl?.getBoundingClientRect().top;
        const slidingNavEl = document.getElementById("section-slider");
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          elId = menu[menu.length - 1]?.elId;
          setActiveTabId(elId);
        }
        if (slidingNavEl && currentY > 0 && currentY < 200) {
          setActiveTabId(elId);
          if (direction === "up") {
            slidingNavEl.scrollLeft -= 50;
          } else {
            slidingNavEl.scrollLeft += 50;
          }
        }
      }
    });
  }, [menu]);

  return (
    <div className="sliding-navbar__mainwrapper">
      <div
        className="sliding-navbar__wrapper hide-scrollbar"
        id="section-slider"
      >
        {menu?.length !== 0 &&
          menu?.map(item => {
            const activeCls =
              activeTabId === item?.elId
                ? "sliding-navbar__content--active"
                : "";
            return (
              <section className={`sliding-navbar__content ${activeCls}`}>
                <span key={item.key} onClick={() => handleTabClick(item)}>
                  {item.title}
                </span>
              </section>
            );
          })}
      </div>
    </div>
  );
};
export default SlidingNavbar;
