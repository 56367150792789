import { Fragment, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import listArrow from "../../assets/images/listArrow.svg";
import FilterImg from "../../assets/images/SRL.svg";
import DownloadImg from "../../assets/images/Icon_material-file-download.svg"
import calendar2 from "../../assets/images/calendar2.svg"
import Dropdown from 'react-bootstrap/Dropdown';

import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";
import "./index.scss";

import { getTime } from "../../utils/dateTimeHelper";
import { CLIENT_ALLOWED_STEPS } from "../../constants/app.constants";
import { APPLICATION_STATUSES } from "../../constants/status.constants";
import { panHide, nameWrapperFunc } from "../../Helper";
import Tooltip from "../../lib/components/Tooltip";
import NoResult from "../../lib/components/NoResult/Index";
import { useSelector } from "react-redux";
import Avatar from "../../components/Avatar";
import ListDivider from "../../components/ListDivider";
import { useDispatch } from "react-redux";
import { loginInfo } from "../../redux/Login/LoginActions";
import Filter from "../../lib/components/Filter";
import Input from "../../lib/components/Input";
import Textarea from "../../lib/components/Textarea";
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { downloadWorkflowCSV } from "../../redux/Dashboard/DashboardActions"; 
import { RoleConstants } from "../../constants/app.constants";
const { SUPER_ADMIN } = RoleConstants;
const { Terminated } = APPLICATION_STATUSES;

const data = {
  header: [
    { key: "name", label: "name", col: 3 },
    { key: "pan", label: "pan", col: 2 },
    { key: "location", label: "location", col: 3 },
    { key: "status", label: "status", col: 3 },
    { key: "action", label: "action", col: 1 },
  ],
};

const clientTableHeaders = [
  { key: "", label: "Location", col: 3 },
  { key: "", label: "Submitted On", col: 4 },
  { key: "", label: "Status", col: 4 },
  { key: "action", label: "Action", col: 1 },
];

const HandleColumnHeader = ({ clientTable = false }) => {
  const columns = clientTable ? clientTableHeaders : data?.header;
  const getColSize = (array, index) => {
    if (index === 0 || index === array.length - 1) {
      return 3
    }
    else {
      return 2
    }
  }
  return (
    <Row>
      {columns?.map((item,index) => (
        <Col xs={item?.col} lg={getColSize(columns,index)}>
          <div className="dashboard__column-header">
            <h6 className="u-opacity-6">{item.label}</h6>
          </div>
        </Col>
      ))}
    </Row>
  );
};

const getClientColumnContent = (
  _id,
  source_data,
  handleAction,
  created_at,
  status,
  active_step,
  isLastItem
) => {
  const activeStatusConfig = APPLICATION_STATUSES[status] || { label: status };
  const { label, color } = activeStatusConfig;
  return (
    <div>
      <div className="dashboard__content-row">
        <Row>
          <Col xs={4} sm={3}>
            <div className="dashboard__name-content-user">
              <div>
                <h5>{source_data?.area_locality}</h5>
                <span>{source_data?.district},{source_data?.state}</span>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={4}>
            <p>{new Date(created_at).toDateString()}</p>
            <p>
              <small>{getTime(created_at)}</small>
            </p>
          </Col>
          <Col xs={4} sm={4}>
            <div
              className="statustag statustag-clarification"
              style={{ background: color }}
            >
              <span>{label}</span>
            </div>
          </Col>
          <Col xs={4} sm={1}>
            <div
              className="dashboard__link"
              style={{ textDecoration: "none" }}
              onClick={() => handleAction(_id)}
            >
              {/* active step logic needs to be moved to page level */}
              <span>
                {CLIENT_ALLOWED_STEPS.includes(active_step)
                  ? "proceed"
                  : "view"}
              </span>
              <img src={listArrow} alt="redirect arrow" />
            </div>
          </Col>
        </Row>
        {!isLastItem && <ListDivider />}
      </div>
    </div>
  );
};

const HandleColumnContent = ({
  _id,
  source_data,
  handleAction,
  handleTerminate,
  created_at,
  status,
  clientTable = false,
  active_step,
  user,
  isLastItem,
  userRole
}) => {
  if (clientTable) {
    return getClientColumnContent(
      _id,
      source_data,
      handleAction,
      created_at,
      status,
      active_step,
      isLastItem
    );
  }
  const activeStatusConfig = APPLICATION_STATUSES[status] || { label: status };

  const { label, color, listAction } = activeStatusConfig;
  const { role_name } = user;

  let action = listAction?.[role_name] || "View";

  const ColoredLine = () => <hr className="list-line" />;
  return (
    <div>
      <div className="dashboard__content-row">
        <Row>
          <Col xs={4} sm={3} lg={3}>
            <div className="dashboard__name-content">
              <Avatar
                className="dashboard__name-content--profile-pic"
                src={`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/viewUploadedDocument/${_id}/applicant_docs/applicant_photo`}
                alt="profile image"
              />
              <div>
                <h5>
                  {source_data?.first_name?.length >= 9 ||
                    source_data?.last_name?.length >= 8 ? (
                    <>
                      <Tooltip
                        toolTipText={`${source_data?.first_name} ${source_data?.last_name}`}
                        displayText={nameWrapperFunc(
                          source_data?.first_name,
                          source_data?.last_name
                        )}
                      />
                    </>
                  ) : (
                    <>
                      {source_data?.first_name} {source_data?.last_name}
                    </>
                  )}
                </h5>
                <p>{new Date(created_at).toDateString()}</p>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={2} lg={2}>{panHide(source_data?.pan_number)}</Col>
          <Col xs={4} sm={3} lg={2}>
            <div className="u-opacity-6">
              <div>
                <span>{source_data?.area_locality} </span>
                <p>
                  {source_data?.district}, {source_data?.state}
                </p>
              </div>
            </div>
          </Col>
          <Col xs={3} sm={3} lg={2}>
            <div className={`statustag`} style={{ background: color }}>
              <span>{label}</span>
            </div>
          </Col>
          {userRole === SUPER_ADMIN && status !== Terminated?.label ? (
            <Col xs={12} md={3}>
          <div className="d-flex align-items-center">
          <div>
            <Button className="" variant="outline-danger" style={{ textDecoration: "none" }} onClick={() => handleTerminate(_id)}>
            <span className="text-capitalize">
                  Terminate
                </span>
            </Button>
                
              </div>
            <div
              className="dashboard__link ms-3"
              style={{ textDecoration: "none" }}
              onClick={() => handleAction(_id)}
            >
              <span>
                {action}
              </span>
              <img src={listArrow} alt="redirect arrow" />
            </div>
          </div>
            </Col>
          ):(
              <Col xs={12} md={3}>
                <div
                  className="dashboard__link ms-3 d-flex align-items-center"
                  style={{ textDecoration: "none" }}
                  onClick={() => handleAction(_id)}
                >
                  <span>
                    {action}
                    <img src={listArrow} alt="redirect arrow" />
                  </span>

                </div>
              </Col>
          )}
          {/* <Col xs={1} md={1} lg={3}>
            <div
              className="dashboard__link"
              style={{ textDecoration: "none" }}
              onClick={() => handleAction(_id)}
            >
              <span>
                {action}
              </span>
              <img src={listArrow} alt="redirect arrow" />
            </div>
          </Col> */}
          {!isLastItem && <ListDivider />}
        </Row>
      </div>
    </div>
  );
};

export const DownloadWorkflowsOverlay = ({
  showOverlay,
  setShowOverlay,
  dateOptions,
  handleSubmit,
}) => {
  
  const [selectedOption, setSelectedOption] = useState();
  
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const [optionSelected, setOptionSelected] = useState();
  const [isActive, setIsActive] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleClick = async (e) => {
    setOptionSelected("default")
    await setIsActive(e?.target?.id);
  };
  

  const handleSubmitOverlay = () =>{
    var formData = {
      type: optionSelected,
      filterValue:
        optionSelected == "default"
          ? isActive && isActive.replace(/ /g, "").toLowerCase()
          : [
              new Date(startDate).toISOString(),
              new Date(endDate).toISOString(),
            ],
    };
    handleSubmit(formData);
  }


  return (
    <div>
      <Filter
        setShowOverlay={setShowOverlay}
        showOverlay={showOverlay}
        title="Download report"
        buttonCloseType="outline"
        buttonCloseLabel="CANCEL"
        buttonSubmitType="button"
        handleCloseOverlay={handleCloseOverlay}
        buttonSubmitLabel="DOWNLOAD"
        handleSubmitOverlay={(e)=>handleSubmitOverlay(e)}
      >
        <div className="d-flex flex-column justify-content-between pd-left-1">
          <div className="d-block d-lg-flex justify-content-between">
            {dateOptions?.map((item, index) => (
              <span className={item?.name == isActive ? "label label-primary" : "label label-default"} onClick={(e)=>handleClick(e)} id={item?.name}> {item?.name} </span>
            ))}
          </div>
          <div>
            <div>
              <div className="pt-4 pb-1 fw-light"> Or Choose date range </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
            <DateRangePicker
              startDate={startDate}
              startDateId="s_id"
              endDate={endDate}
              endDateId="e_id"
              minDate={new Date("02-01-2020")}
              onDatesChange={({ startDate, endDate }) => { setOptionSelected("custom"); setIsActive(undefined); setStartDate(startDate); setEndDate(endDate); }}
              focusedInput={focusedInput}
              isOutsideRange={() => false}
              onFocusChange={e => setFocusedInput(e)}
              displayFormat="DD/MM/YYYY"
            />
            <img src={calendar2} className="position-absolute  flex-column right-30px" />
            {/* <div className="mt-3 mb-1">Start Date: {startDate && startDate.format('ll')}</div>
            <div>End Date: {endDate && endDate.format('ll')}</div> */}
            </div>
          </div>
        </div>
      </Filter>
      
    </div>
  );
};

const Dashboard = ({
  handleAction,
  handleTerminate,
  dashboardData,
  filterFunction,
  clientTable = false,
  handleGetDraftApplication,
  handleActiveApplication,
  handleClosedApplication,
  currentTab,
  handleCH,
  handleBH,
  handlePayment,
  handleLegal,
  handlePaymentConfirmation,
  handleActive,
  clusterhead, //Using clusterhead as a variable to show the draft button conditionally - Rohit (name can be changed)
}) => {
  const dispatch = useDispatch()
  const user = dispatch(loginInfo)
  const [filter, setFilter] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userRole = useSelector(state => state?.signIn?.userData?.role_name);

  let options=[{
    name:"Last 7 days",
    isSelected: false
  },
  {
    name:"Last 15 days",
    isSelected: false
  },
  {
    name:"Last month",
    isSelected: false
  },
  {
    name:"All",
    isSelected: false
  }]
  const [dateOptions, setDateOptions] = useState(options)
  const [filterdata, showFilterData] = useState(false)
  const [wfconfigId, setWfConfigId] = useState('')

  const handleOption = e => {
    filterFunction(e.target.value);
  };
  const wfc_id = useSelector((state) => state?.superAdmin?.applicantsList)

  const handleSubmitOverlay = (filterData)  => {
    const user = dispatch(loginInfo)?.data
    filterData.wfconfigId = wfconfigId
    filterData.role= user?.role_name;
    filterData.user_id=user?._id;
    dispatch(downloadWorkflowCSV(filterData))
    
  }
  useEffect(() => {
    if(wfc_id) {
      setWfConfigId(wfc_id[0]?.workflow_configuration_id)
    }
  }, [wfc_id])

  const handlegoback = () => {
    handleClose(true)
  }
  return (
    <section className="dashboard__wrapper">
      <div>
        <div>
          <div className="dashboard__page-header d-flex justify-content-between align-items-center">
            <div>
              <span>Applications</span>
              <Link
                to="#"
                style={
                  currentTab === "Active"
                    ? { color: "#0076BC", textDecoration: "none" }
                    : { textDecoration: "none" }
                }
                onClick={() => {
                  handleActiveApplication();
                }}
              >
                Active
              </Link>
              <Link
                to="#"
                style={
                  currentTab === "Closed"
                    ? { color: "#0076BC", textDecoration: "none" }
                    : { textDecoration: "none" }
                }
                onClick={() => {
                  handleClosedApplication();
                }}
              >
                Closed
              </Link>
              {clusterhead && (
                <Link
                  to="#"
                  style={
                    currentTab === "Draft"
                      ? { color: "#0076BC", textDecoration: "none" }
                      : { textDecoration: "none" }
                  }
                  onClick={() => {
                    handleGetDraftApplication();
                  }}
                >
                  Draft
                </Link>
              )}

              {/* {
                  showDraft()
                } */}
            </div>
            <div>
              
                <div className="filter-icon relative">
                  {user?.type && (
                    <div
                      className="absolute"
                      onClick={() => {
                        // console.log("clicked")
                        showFilterData(true)
                      }}
                    >
                      {/* <Dropdown
                      drop={'down'}
                      >
                      <Dropdown.Toggle  variant="" id="dropdown-basic">
                      <img className="filter-dropdown" src={FilterImg} alt="" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{fontFamily: "sans-serif"}}>
                      <Dropdown.Item onClick={handleCH}>Awaiting CH Approval</Dropdown.Item>
                      <Dropdown.Item onClick={handleBH}>Awaiting BH Approval</Dropdown.Item>
                      <Dropdown.Item onClick={handleLegal}>Awaiting Legal Approval</Dropdown.Item>
                      <Dropdown.Item onClick={handlePayment}>Awaiting Payment</Dropdown.Item>
                      <Dropdown.Item onClick={handlePaymentConfirmation}>Awaiting Payment Confirmation</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}

         <img onClick={handleShow} className="filter-dropdown" src={FilterImg} alt="" />
        
        <Offcanvas show={show} placement="end" onHide={handleClose}>
          <Offcanvas.Header className="filter-header" closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
                    STATUS <br></br><br></br>
                    <Button onClick={handleCH} className="mb-2 me-3" variant="outline-primary">Awaiting CH Approval</Button>{' '}
                    <Button onClick={handleBH} className="mb-2 me-3" variant="outline-primary">Awaiting BH Approval</Button>{' '}<br></br>
                    <Button onClick={handleLegal} className="mb-2 me-3" variant="outline-primary">Awaiting Legal Approval</Button>{' '}
                    <Button onClick={handlePayment} className="mb-2 me-3" variant="outline-primary">Awaiting Payment</Button>{' '}<br></br>
                    <Button onClick={handlePaymentConfirmation} className="mb-2 me-3" variant="outline-primary">Awaiting Payment Confirmation</Button>{' '}<br></br>
                    <Button onClick={handleActive} className="mb-2 me-3" variant="outline-primary">All Applications</Button>{' '}<br></br>
          <div className="offcanvas-btn-div">
          <Button onClick={handlegoback} className="offcanvas-btn" variant="primary">Apply</Button>{' '}
          </div>
         </Offcanvas.Body>
         </Offcanvas>
                    </div>
                  )}
                </div>

              <div className="filter-icon">
                {user?.type && (
                  <div
                    onClick={() => {
                      // console.log("clicked")
                      setShowOverlay(true);
                      setFilter(true);
                    }}
                  >
                    <img className="p-2" src={DownloadImg} alt="" />
                    {/* <img className="p-2" src={FilterImg} alt=""/> */}
                  </div>
                )}
                {filter && (
                  <DownloadWorkflowsOverlay
                    showOverlay={showOverlay}
                    setShowOverlay={setShowOverlay}
                    dateOptions={dateOptions}
                    filter={filter}
                    handleSubmit={(data) => handleSubmitOverlay(data)}
                    removeHeader={true}
                  />
                )}
              </div>
            </div>
            {/* code for filter */}
            {/* <div className="dropdown">
                <img className="menu-btn-filter" src={filter} />
                <div className="dropdown-content">
                  <Link to="#" className="link">
                    Status
                  </Link><br />
                  <Link to="#" className="link" >
                    Name
                  </Link>
                </div>
              </div> */}
            {/* <select value={option} onChange={handleOption}>
                <option value="">filter</option>
                <option value="name">Name</option>
              </select> */}
          </div>
        </div>
      </div>
      <Row>
        <div>
          <Col lg={12}>
            {dashboardData === undefined || dashboardData?.length !== 0 &&
              <HandleColumnHeader clientTable={clientTable} />
            }
          </Col>
          <Col lg={12}>
            {(dashboardData && Object.keys(dashboardData)?.length === 0) ||
              dashboardData === undefined ? (
              <NoResult />
            ) : (
              dashboardData?.map((ele, index) => {
                return (
                  <Fragment>
                    <HandleColumnContent
                      key={ele._id}
                      handleAction={handleAction}
                      handleTerminate={handleTerminate}
                      {...ele}
                      clientTable={clientTable}
                      user={user}
                      isLastItem={
                        dashboardData && dashboardData?.length - 1 === index
                      }
                      userRole= {userRole}
                    />
                  </Fragment>
                );
              })
            )}
          </Col>
        </div>
      </Row>
    </section>
  );
};

export default Dashboard;
