import "./index.scss";

const HandleKYC = ({ text, secondText }) => {  
  

  return (
    <div className="lds-err-response">
      <p className="lds-err-response-ptext">
        {text}
      </p>
      <p className="lds-err-response-secondText">
        {secondText}
      </p>
    </div>
  );
};
export default HandleKYC;
