import React from 'react'
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';

const RedirectUser = () => {
    const history = useHistory()

    useEffect(() => {
        const generateRandomId = uuidv4();
        history.push(`/client-onboarding/${generateRandomId}`)
    }, [])

    return (
        <div></div>
    )
}

export default RedirectUser
