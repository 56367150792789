import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { CLIENT_DASHBOARD_BASIC } from "../../../constants/route.constants.js/applicant"
import SubmitResponse from "../../../lib/components/SubmitResponse"
import { clearPhleboSelectedData } from "../../../redux/PhleboOnboarding/PhleboOnboardingActions"

const SubmitPhlebo = () => {
    const history = useHistory()
    const dispatch=useDispatch()
    const responseSubmit = () => {
        dispatch(clearPhleboSelectedData())
        history.push(CLIENT_DASHBOARD_BASIC)
    }
    return (
        <div>
            <SubmitResponse text="You have successfully submitted all the information" tq="Thank You!" btnStatus={true} responseSubmit={responseSubmit} />
        </div>
    )
}
export default SubmitPhlebo