import { useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import { LOGO_ROUTE_MAP } from "../constants/app.constants";
import { CLUSTER_HEAD_DASHBOARD } from "../constants/route.constants.js/cluster_head";
import { isAuthenticated } from "../utils/tokenHelper";
import useAuth from "./Auth";
const partnerKycKey = "partnerKyc";
export default function PrivateRoute({ component: Component, ...restProps }) {
  const { roles, path, selectorKey, dataKey, reduxCheck=true } = restProps;
  const history = useHistory();
  const data = useSelector(state => {
    return selectorKey ? state[selectorKey][dataKey] : {};
  });
  const renderComponent = props => {
    let redirectRoute = LOGO_ROUTE_MAP[path];
    if (reduxCheck && redirectRoute !== path && !Object.keys(data).length) {
      if (selectorKey === partnerKycKey) {
        const partnerId = localStorage.getItem("partner_id");
        const workflowId = localStorage.getItem("workflow_id");
        redirectRoute = `/partner-info/${workflowId}/${partnerId}`;
      }
      history.push(redirectRoute);
    } else {
      return <Component {...props} />;
    }
  };
  return (
    <Route
      {...restProps}
      render={props =>
        isAuthenticated(roles) ? (
          renderComponent(props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
}
