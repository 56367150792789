export const validate = (fields, selected_data, restData = {}) => {
    let errs = {};
    let err = false;
    const { onBoardingForm } = restData;
    for (let key in fields) {
        const _field = fields[key];
        const { required, name, label, pattern, dependent, error_messages } = _field;
        const selectedVal = selected_data[name];
        if (name && name?.length !== 0) {
            if (required) {
                if (dependent?.field_name) {
                    if (dependent?.val == selected_data[dependent?.field_name]) {
                        if (!selectedVal || selectedVal?.length === 0 || selectedVal === undefined) {
                            errs[name] = { err: `**${label} is required` };
                            // return;
                        }
                    }
                } else if (!selectedVal || selectedVal?.length === 0 || selectedVal === undefined) {
                    errs[name] = { err: error_messages?.required_error || `This field is required` };
                    // return;
                }
            }
            // if (pattern && pattern?.length !== 0 && !errs.hasOwnProperty(name)) {
            //     var regex_pattern = new RegExp(pattern);
            //     if (!regex_pattern.test(selectedVal)) {
            //         errs[name] = { err: `${label} is invalid` };
            //     }
            // }
        }
        /**
         * Custom field validations
         */
        if (onBoardingForm && name === "mobile_number" && !errs[name] && !selected_data?.otp_verified) {
            errs[name] = { err: "Phone number not verified" }
        }
    }
    return errs;
}