import { useEffect,useState } from "react";
import { getItemFromLocal } from "../utils/storageHelper/localStorage";
import jwt_decode from 'jwt-decode';

function useAuth() {
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(()=>{
        const token = getItemFromLocal('token');
        const _decoded = jwt_decode(token);
        const currentDate = new Date();
        if (_decoded.exp * 1000 >= currentDate.getTime()) {
            setAuthenticated(true);
          }
    },[]);
    return [authenticated];
}

export default useAuth;