import { useSelector } from "react-redux"
import PageLoader from '../../components/PageLoader'

const BaseAppLoader=()=>{

    const loading=useSelector((state)=>{
        return state?.globalReducer?.pageLoading
    })
    
    return(
        <>
           <PageLoader loading={loading}/>      
        </>
    )
}
export default BaseAppLoader