import { Container, Spinner } from 'react-bootstrap'
import './index.scss'
const PageLoader=({loading,label=""})=>{

    return (
        <Container fluid={true} >
            <div className='page-loader-wrapper'>
                {
                    loading && (
                        <div className="page-loader-text">
                           <Spinner animation="border"/>
                            <span>{label}</span>
                       </div>
                    )
                }
            </div>
        </Container>
        
    )
}
export default PageLoader