import "./index.scss";
import { Container, Row, Col } from "react-bootstrap";
import tickMark from "../../../../assets/images/form-green tick- selected.svg";
import crossMark from "../../../../assets/images/red cross.svg";
import { getBooleanImg } from "../../../../utils/booleanImgHelper";
import HorizontalLine from "../HorizontalLine/index.js"

const data = [
  {
    customer: [
      {
        id: 1,
        text: "braod band Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam, sequi.",
        flag: "yes",
      },
      {
        id: 1,
        text: "cc should",
        flag: "no",
        remark: "some random text here",
      },
      {
        id: 1,
        text: "home collection bag",
        flag: "no",
        remark: "some random text here",
      },
    ],
  },
  {
    accomodation: [
      {
        id: 1,
        text: "braod band",
        flag: "yes",
        remark: "some random text here",
      },
      {
        id: 1,
        text: "cc should",
        flag: "no",
      },
    ],
  },
  {
    safety: [
      {
        id: 1,
        text: "braod band",
        flag: "yes",
        remark: "some random text here",
      },
      {
        id: 1,
        text: "cc should",
        flag: "no",
      },
    ],
  },
  {
    satffing: [
      {
        id: 1,
        text: "braod band",
        flag: "yes",
        remark: "some random text here",
      },
      {
        id: 1,
        text: "cc should",
        flag: "no",
      },
      {
        id: 1,
        text: "home collection bag",
        flag: "yes",
        remark: "some random text here",
      },
    ],
  },
];

const getFlag = (flag) => {
  if (flag === "yes") {
    return (
      <div className="checklist__flag-image">
        <img src={tickMark} />
      </div>
    );
  } else if (flag === "no") {
    return (
      <div className="checklist__flag-image">
        <img src={crossMark} />
      </div>
    );
  }
};

const ChecklistRequirement = ({ selectedData }) => {

  return (
    <section className="checklist" id="checklist-info">
      <HorizontalLine />
      <Container fluid={true}>
        <Row>
          <p className="widget-info__title">
            Checklist Requirement Information
          </p>
        </Row>
        <Row>
          {
            selectedData?.map((item, index) => {
              return (
                <Col lg={6}>
                  <p className="checklist__content-header">{item.header}</p>

                  {item.list.map((value) => (
                    value.hasOwnProperty('selected') ?
                      <div className="checklist__content-wrapper">
                        <span className="d-flex align-center">
                          {getBooleanImg(value.selected)}
                          <span
                            className={
                              !value.selected
                                ? "checklist__content checklist__content-flag-no"
                                : "checklist__content"
                            }
                          >
                            {value.label}
                          </span>
                        </span>
                        {value.remark && (
                          <>
                            <p className="checklist__remark-title">Remarks</p>
                            <span className="checklist__remark">{value.remark}</span>
                          </>
                        )}
                      </div> : ''
                  ))}
                </Col>
              )
            })
          }
          {/* <Col lg={6}>
            <p className="checklist__content-header">Customer Delight</p>

            {data[0].customer.map((item) => (
              <div className="checklist__content-wrapper">
                <span className="d-flex align-center">
                  {getFlag(item.flag)}
                  <span
                    className={
                      item.flag === "no"
                        ? "checklist__content checklist__content-flag-no"
                        : "checklist__content"
                    }
                  >
                    {item.text}
                  </span>
                </span>
                {item.remark && (
                  <>
                    <p className="checklist__remark-title">Remarks</p>
                    <span className="checklist__remark">{item.remark}</span>
                  </>
                )}
              </div>
            ))}
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default ChecklistRequirement;
