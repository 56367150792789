import DynamicForm from "../../../components/DynamicForm";
import Button from "../../../lib/components/Button";
import { PARTNER_KYC_FORM } from "../../../constants/route.constants.js/applicant";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPartnerKYCFields, setPartnerKYCSelectedData, fetchCurrentApplication, savePartnerId } from "../../../redux/PartnerKYC/partnerKYCActions";
import { useParams } from 'react-router-dom';

const PartnerKYCInfo = () => {
  let history = useHistory();
  let dispatch = useDispatch();
  const [otherProps, setOtherProps] = useState([]);

  let { workflowId, partnerId } = useParams();

  useEffect(() => {
    dispatch(fetchPartnerKYCFields())
    dispatch(fetchCurrentApplication(workflowId))
    // if (partnerId) {
    dispatch(savePartnerId(partnerId))
    localStorage.setItem('partner_id', partnerId);
    localStorage.setItem('workflow_id', workflowId);
    // }
  }, [])

  const formFields = useSelector((state) => {
    return state?.partnerKyc?.partnerKYCFields?.configuration?.step_1
  })
  const selectedData = useSelector((state) => {
    return state?.partnerKyc?.selectedData || {}
  })

  const disabledFields = useSelector(
    (state) => state?.globalReducer?.onboarding_form_disabled_fields
  );

  const handleNext = () => {
    history.push(PARTNER_KYC_FORM);
  };
  const { id_proof: selectedId } = selectedData;
  useEffect(() => {
    let tempProps = [...otherProps];
    if (!selectedId || selectedId === undefined) {
      const id_front_obj = {
        field: "id_proof_front",
        dependent: { field_name: "id_proof", val: "NA" },
      };
      const id_back_obj = {
        field: "id_proof_back",
        dependent: { field_name: "id_proof", val: "NA" },
      };
      setOtherProps([...tempProps, id_front_obj, id_back_obj]);
    } else if (selectedId !== undefined && selectedId?.length !== 0) {
      const id_front_obj = {
        field: "id_proof_front",
        label: `${selectedId} Front Side`,
        dependent: { field_name: "id_proof", val: selectedId },
      };
      const id_back_obj = {
        field: "id_proof_back",
        label: `${selectedId} Back Side`,
        dependent: { field_name: "id_proof", val: selectedId },
      };
      setOtherProps([...tempProps, id_front_obj, id_back_obj]);
    }
  }, [selectedId]);

  return (
    <div style={{ paddingBottom: "100px" }}>
      <DynamicForm
        formFields={formFields}
        selectedData={selectedData}
        setSelectedData={setPartnerKYCSelectedData}
        partnerInfo={true}
        handleNextClick={handleNext}
        disabledFields={disabledFields}
        otherProps={otherProps}
      />
      {/* <div className="d-flex justify-between" style={{ padding: "0 80px" }}>
        <Button label="CANCEL" type="ghost" />
        <Button label="NEXT" onClick={handleNext} />
      </div> */}
    </div>
  );
};

export default PartnerKYCInfo;
