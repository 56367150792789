import "./index.scss";

const TagList = ({ tagList, removeHandle }) => {
 
 
  return (
    <div className="tag-list__wrapper">
      {tagList.length !== 0 &&
        tagList?.map((ele, i) => (
          <div className="tag-list__content">
            <span className="tag-list__list">{ele}</span>
            <span
              key={i}
              className="tag-list__cross"
              onClick={() => removeHandle(i)}
            >
              &times;
            </span>
          </div>
        ))}
    </div>
  );
};
export default TagList;
