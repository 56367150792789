import Button from "../../lib/components/Button";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";

const FormButtons = props => {
  const {
    partnerDetails,
    checklist,
    onBoardingForm,
    shop_finalized,
    shop_not_finalized,
    toggleFunction,
    handleNextClick,
    handleNextValue,
    handleSaveDraft,
    partnerKyc,
    partnerInfo,
    handleCancelClick,
    partner_documentation,
  } = props;
  const handleButtonClick = () => {
    handleNextClick();
  };
  const loading = useSelector(state => {
    return state?.globalReducer?.submitLoading;
  });
  return (
    <div>
      {onBoardingForm && (
        <Row>
          <div className="dynamic-form__submit-button">
            <Button label="next" onClick={handleButtonClick} />
          </div>
        </Row>
      )}
      {partnerDetails && (
        <div className="dynamic-form__submit-button">
          <Button label="Draft" type="outline" onClick={handleSaveDraft} />
          <Button label="next" onClick={handleButtonClick} />
        </div>
      )}
      {partnerInfo && (
        <div className="dynamic-form__submit-button">
          {/* <Button label="Draft" type="outline" onClick={handleSaveDraft} /> */}
          <Button label="next" onClick={handleButtonClick} />
        </div>
      )}
      {partnerKyc && (
        <div className="layout-button">
          <div onClick={handleCancelClick}>
            <span className="partner-details-form__cancel_btn">CANCEL</span>
          </div>
          <Button
            label="PROCEED"
            onClick={handleButtonClick}
            loading={loading}
          />
        </div>
      )}
      {shop_finalized && (
        <div className="layout-button">
          <div onClick={handleCancelClick}>
            <span className="partner-details-form__cancel_btn">CANCEL</span>
          </div>
          <div>
            <Button label="Draft" type="outline" onClick={handleSaveDraft} />
            <Button label="next" onClick={handleButtonClick} />
          </div>
        </div>
      )}
      {shop_not_finalized && (
        <div className="layout-button">
          <div>
            <Button label="CANCEL" type="ghost" onClick={handleCancelClick} />
          </div>
          <div>
            <Button label="Draft" type="outline" onClick={handleSaveDraft} />
            <Button label="next" onClick={handleButtonClick} />
          </div>
        </div>
      )}
      {checklist && (
        <div className="layout-button partner-documentation--action-btn">
          <div onClick={handleCancelClick}>
            <span className="partner-details-form__cancel_btn">CANCEL</span>
          </div>
          <div>
            <Button label="Draft" type="outline" onClick={handleSaveDraft} />
            <Button label="next" onClick={handleButtonClick} />
          </div>
        </div>
      )}
      {partner_documentation && (
        <div className="layout-button partner-documentation--action-btn">
          <div onClick={handleCancelClick}>
            <span className="partner-details-form__cancel_btn">CANCEL</span>
          </div>
          <div>
            <Button label="Draft" type="outline" onClick={handleSaveDraft} />
            <Button label="next" onClick={handleButtonClick} />
          </div>
        </div>
      )}
    </div>
  );
};
export default FormButtons;
