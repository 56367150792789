import { useState } from "react";
import Dashboard from "../../Dashboard";
import { useHistory } from "react-router";
import { SUPER_ADMIN_REVIEW } from "../../../constants/route.constants.js/admin";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import upArrow from "../../../assets/images/ic-arrow-up.svg";
import downArrow from "../../../assets/images/ic-arrow-down.svg";
import { BsDownload } from "react-icons/bs";
import { DownloadWorkflowsOverlay } from "../../../pages/Dashboard";
import {
  fetchAdminApplicants,
  selectedApplicantData,
} from "../../../redux/Admin/DashboardActions";
// import {
//   selectedApplicantData,
//   getDraftJobs,
// } from "../../../redux/ClusterHead/index";
import "./index.scss";
import ListPagination from "../../../components/ListPagination";
import { Toaster, toast } from "react-hot-toast";
import {
  setCurrentPageAction,
  setCurrentTab,
} from "../../../redux/Pagination/actions";
import { loginInfo } from "../../../redux/Login/LoginActions";
import NoResult from "../../../lib/components/NoResult/Index";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  DropdownButton,
  Dropdown,
  ButtonGroup,
  InputGroup,
  ButtonToolbar,
  Form,
} from "react-bootstrap";
import localStorage from "redux-persist/es/storage";
import { downloadWorkflowCSV } from "../../../redux/Dashboard/DashboardActions";

const DashboardAdmin = () => {
  const stats = {
    franchise_request: {
      application_data: {
        application_under_progress: "820",
        accepted_requests: "422",
        rejected_request: "22",
      },

      deposits: {
        recived_amount: "5,94,820",
        balance_amount: "94,820",
      },
    },

    status_count: {
      awating_from_channel_team: "100",
      awating_response_from_kyc: "78",
      clarification: "100",
      awating_approval_from_bh: "78",
      payment_approval: "34",
      out_for_signature: "32",
      phlebo_onboarding: "100",
      awating_approval_from_cmo: "56",
    },
    client_details: {
      total_count: 100,
      prev_total_count: 80000,
      ownership: {
        proporiter: 534752,
        partnership: 52,
      },
    },
  };

  const [graphData, setGraphData] = useState([]);
  const history = useHistory();
  const applicants = useSelector((state) => state?.superAdmin?.applicantsList);
  const saveDraftApp = useSelector((state) => state?.superAdmin?.saveDraftJobs);
  const storePage = useSelector(
    (state) => state?.pageReducers?.currentPageStore
  );
  const currentTab = useSelector((state) => state?.pageReducers?.tab);
  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage, setApplicantsPerPage] = useState(5);
  const [go, setGo] = useState();

  const [applicationsList, setApplicationsList] = useState();
  const [draftApp, setDraftApp] = useState(false);

  const dispatch = useDispatch();
  const user = dispatch(loginInfo)?.data;

  const [cities, setCities] = useState([]);
  const [currentCity, setCurrentCity] = useState("all");
  const [ownershipCount, setOwnershipCount] = useState([]);
  const [depositValuePending, setDepositValuePending] = useState(0);
  const [depositValueReceived, setDepositValueReceived] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [statusCount, setStatusCount] = useState({});
  const [timeFrame, setTimeFrame] = useState("last 7 days");
  const [lastUpdated, setLastUpdated] = useState("");
  const [filter, setFilter] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  
  const [wfconfigId, setWfConfigId] = useState('')
  const wfc_id = useSelector((state) => state?.superAdmin?.applicantsList)

  let options = [
    {
      name: "Last 7 days",
      isSelected: false,
    },
    {
      name: "Last 15 days",
      isSelected: false,
    },
    {
      name: "Last month",
      isSelected: false,
    },
    {
      name: "All",
      isSelected: false,
    },
  ];
  const [dateOptions, setDateOptions] = useState(options);

  const handleSubmitOverlay = (filterData) => {
    // console.log(filterData);
    const user = dispatch(loginInfo)?.data;
    filterData.wfconfigId = wfconfigId
    filterData.role = user?.role_name;
    filterData.user_id = user?._id;
     dispatch(downloadWorkflowCSV(filterData));
    // console.log(response);
  };

  useEffect(() => {
    if(wfc_id) {
      setWfConfigId(wfc_id[0]?.workflow_configuration_id)
    }
  }, [wfc_id])

  const showActiveApplicants = () => {
    if (applicants?.length) {
      return (
        applicants &&
        applicants?.filter(
          (item) =>
            item?.status !== "Rejected" && item?.status !== "Franchise Approved"
        )
      );
    }
  };

  const showClosedApplicants = () => {
    if (applicants?.length) {
      return (
        applicants &&
        applicants?.filter(
          (item) =>
            item?.status === "Rejected" || item?.status === "Franchise Approved"
        )
      );
    }
  };

  useEffect(() => {
    dispatch(fetchAdminApplicants());
  }, []);

  useEffect(() => {
    if (applicants?.length && currentTab === "Active") {
      let activeApplication = showActiveApplicants();
      setApplicationsList(activeApplication);
    }

    if (applicants?.length && currentTab === "Closed") {
      let closedApplications = showClosedApplicants();
      setApplicationsList(closedApplications);
    }
  }, [applicants]);

  useEffect(() => {
    if (saveDraftApp?.length && currentTab === "Draft") {
      let activeApplication =
        saveDraftApp &&
        saveDraftApp?.filter(
          (item) =>
            item?.status !== "Rejected" && item?.status !== "Franchise Approved"
        );
      setApplicationsList(activeApplication);
    }
  }, [saveDraftApp]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllCities`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
      }),
    })
      .then((res) => res.json())
      .then((res) => setCities(res.data));
  }, []);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getApplicationCounts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
          city: "all",
          // dateFilter: "last2month",
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        res.statusCount.statusCount.forEach((each) => {
          let id = each._id
            .toLowerCase()
            .split(" ")
            .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
          id = id.replace(/-/g, "");
          setStatusCount({ ...statusCount }, (statusCount[id] = each.count));
        });
        setDepositValuePending(res.depositValuePending.total);
        setDepositValueReceived(res.depositValueReceived.total);
        setOwnershipCount(res.ownerShipCount.ownershipCount);
        setTotalCount(res.totalCount.total);
        let today = new Date();
        let time = today.getHours() + ":" + today.getMinutes();
        setLastUpdated(time);
      });
  }, []);
  useEffect(() => {
    // Fetch Graph Data
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/graphStatUpdate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
          city: `${currentCity}`,
          dateFilter: "last2months",
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        let months = [
          "",
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "Sept",
          "October",
          "November",
          "December",
        ];
        let finalData = [];
        res.data.forEach((each, index) => {
          let obj = {
            name: months[each._id],
            requests: each.total,
          };
          finalData.push(obj);
          if (index === res.data.length - 1) {
            setGraphData(finalData);
          }
        });
      });
  }, [currentCity]);

  const handleAction = (_id) => {
    const result = applicants.find((items) => {
      return items?._id === _id;
    });
    dispatch(selectedApplicantData(result));
    history.push(SUPER_ADMIN_REVIEW, { _id: _id });
  };

  const handleGoChange = (e) => {
    const val = e.target.value;
    setGo(val);
    setCurrentPage(Number(val));
  };

  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPageAction(pageNumber));
    setCurrentPage(pageNumber);
  };

  const handleGetDraftApplication = () => {
    setDraftApp(!draftApp);
    dispatch(setCurrentTab("Draft"));
    // dispatch(getDraftJobs(user?.result?._id, false, 'ADMIN'));
    dispatch(setCurrentPageAction(1));
  };

  const handleActiveApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Active"));
    const result = showActiveApplicants();
    setApplicationsList(result);
    dispatch(setCurrentPageAction(1));
  };
  

  const handleClosedApplication = () => {
    setDraftApp(false);
    dispatch(setCurrentTab("Closed"));
    let closedApplications = showClosedApplicants();
    setApplicationsList(closedApplications);
    dispatch(setCurrentPageAction(1));
  };

  const indexOfLastApplicant = storePage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = applicationsList?.slice(
    indexOfFirstApplicant,
    indexOfLastApplicant
  );

  const filterFunction = (filterItem) => {
    if (filterItem === "name") {
      return currentApplicants.sort(function (a, b) {
        if (a.source_data?.first_name < b.source_data?.first_name) {
          return -1;
        }
        if (a.source_data?.first_name > b.source_data?.first_name) {
          return 1;
        }
        return 0;
      });
    }
  };

  return (
    <div>
      <Toaster />

      {applicants?.length === 0 || applicants === undefined ? (
        <NoResult text="Please wait...!" showImage={false} />
      ) : (
        <Container className="mb-4">
          <Container className="parent mb-3">
            <Row>
              <Col lg={3} className="clientDetails pt-3 pb-3">
                <Row>
                  <span style={{ fontSize: "50px" }}>{totalCount || 0}</span>
                  <div>
                    <p style={{ fontSize: "20px" }}>Existing Franchisees</p>
                  </div>
                </Row>
                <Row>
                  {stats.client_details.total_count >=
                  stats.client_details.prev_total_count ? (
                    <div>
                      <span className="text-success pe-2 flex-grow-1  pt-1">
                        {Math.round(
                          ((totalCount -
                            stats.client_details.prev_total_count) /
                            stats.client_details.prev_total_count) *
                            100
                        )}
                        %<img src={upArrow}></img>
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="text-danger pe-2 flex-grow-1  pt-1">
                        {
                          /* {Math.abs(
                          Math.round(
                            ((stats.client_details.total_count -
                              stats.client_details.prev_total_count) /
                              stats.client_details.prev_total_count) *
                              100
                          )
                        )} */ 80
                        }
                        %<img src={downArrow}></img>
                      </span>
                      <span className="text-muted" style={{ fontSize: "11px" }}>
                        than last month
                      </span>
                    </div>
                  )}
                </Row>
              </Col>

              <Col lg={8}>
                <div>
                  <Row>
                    <Col lg={9} sm={6} className="mt-1 mb-1">
                      <DropdownButton
                        title={currentCity}
                        id="bg-nested-dropdown"
                        variant="outline-primary"
                        size="sm"
                        onSelect={(e) => setCurrentCity(e)}
                      >
                        {cities?.map((each) => {
                          return (
                            <Dropdown.Item eventKey={each}>
                              {each}
                            </Dropdown.Item>
                          );
                        })}
                      </DropdownButton>
                      <span
                        className="text-muted ms-3"
                        style={{ fontSize: "11px", paddingLeft: "70px" }}
                      >
                        last updated on today {lastUpdated}
                      </span>
                    </Col>
                    <Col
                      lg={2}
                      sm={3}
                      md={{}}
                      style={{ visibility: "hidden" }}
                      className="mt-1"
                    >
                      <div className="download_duration ">
                        <DropdownButton
                          title={timeFrame}
                          id="bg-nested-dropdown"
                          variant="outline-primary"
                          size="sm"
                        >
                          <Dropdown.Item
                            onClick={() => setTimeFrame("last 6 month")}
                            id="last6month"
                          >
                            Last 6 Months
                          </Dropdown.Item>
                          <Dropdown.Item
                            id="last1year"
                            onClick={() => setTimeFrame("last 1 year")}
                          >
                            Last 1 Year
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            id="last2year"
                            onClick={() => setTimeFrame("last 2 year")}
                          >
                            All
                          </Dropdown.Item> */}
                        </DropdownButton>
                      </div>
                    </Col>
                    <Col lg={1} sm={3} className="mt-1 mb-1">
                      <ButtonGroup>
                        <div className="px-auto">
                          <Button variant="outline-primary" size="sm">
                            <BsDownload
                              onClick={() => {
                                setShowOverlay(true);
                                setFilter(true);
                              }}
                            />
                          </Button>
                          {filter && (
                            <DownloadWorkflowsOverlay
                              showOverlay={showOverlay}
                              setShowOverlay={setShowOverlay}
                              dateOptions={dateOptions}
                              filter={filter}
                              handleSubmit={(filter)=>handleSubmitOverlay(filter)}
                              removeHeader={true}
                            />
                          )}
                        </div>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </div>
                <ResponsiveContainer width="100%" height={150} className="pt-2">
                  <LineChart data={graphData}>
                    <Line
                      strokeWidth={2}
                      type="monotone"
                      dataKey="requests"
                      stroke="#E49B0F"
                    />
                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                    <YAxis axisLine={false} tickLine={false} width={30} />
                    <Tooltip wrapperStyle={{ outline: "none" }} />
                  </LineChart>
                </ResponsiveContainer>
              </Col>
              {/* </div> */}
            </Row>
          </Container>

          <Container className="pb-3">
            <Row>
              <Col lg={3} className=" mt-1 ps-4">
                <Row className="statRow">
                  <Col className="pt-2">
                    <h5>{ownershipCount[1]?.count}</h5>
                    <div>
                      <p style={{ fontSize: "15px" }}>Proporitership</p>
                    </div>
                  </Col>
                  <Col className="text-danger pt-3 ms-2">
                    <span style={{ fontSize: "11px" }}>
                      04% <img className="arrow" src={downArrow}></img>
                    </span>
                    <div>
                      <p className="text-muted" style={{ fontSize: "11px" }}>
                        than last month
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={3} className="mt-1 ps-4">
                <Row className="statRow">
                  <Col className="pt-2">
                    <h5>{ownershipCount[1]?.count}</h5>
                    <div>
                      <p style={{ fontSize: "15px" }}>Partnership</p>
                    </div>
                  </Col>
                  <Col className="text-danger pt-3 ms-2">
                    <span style={{ fontSize: "11px" }}>
                      04% <img className="arrow" src={downArrow}></img>
                    </span>
                    <div>
                      <p className="text-muted" style={{ fontSize: "11px" }}>
                        than last month
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={3} className=" mt-1 ps-4">
                <Row className="statRow">
                  <Col className="pt-2">
                    <h5>0</h5>
                    <div>
                      <p style={{ fontSize: "15px" }}>Company</p>
                    </div>
                  </Col>
                  <Col className="text-danger pt-3 ms-2">
                    <span style={{ fontSize: "11px" }}>
                      04% <img className="arrow" src={downArrow}></img>
                    </span>
                    <div>
                      <p className="text-muted" style={{ fontSize: "11px" }}>
                        than last month
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={3} className=" mt-1 ps-4">
                <Row className="statRow">
                  <Col className="pt-2">
                    <h5>0</h5>
                    <div>
                      <p style={{ fontSize: "15px" }}>LLP</p>
                    </div>
                  </Col>
                  <Col className="text-danger pt-3 ms-2 mt-1">
                    <span style={{ fontSize: "11px" }}>
                      04% <img className="arrow" src={downArrow}></img>
                    </span>
                    <div>
                      <p className="text-muted" style={{ fontSize: "11px" }}>
                        than last month
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container className="statRow">
            <Row>
              <Col lg={4}>
                <Row className="franchiseStat mt-4 ms-2 me-2 mb-3">
                  <Col lg={10}>
                    <p className="pt-2">Franchise requests recieved</p>
                    <p
                      className="text-muted"
                      style={{ fontSize: "11px", marginTop: "-12px" }}
                    >
                      Application under Progress
                    </p>
                  </Col>
                  <Col lg={2}>
                    <p className="pt-2">{totalCount}</p>
                  </Col>
                  {/* <hr className="solidLine"></hr> */}
                  <div
                    className="border-top mb-3 ms-3"
                    style={{ width: "90%" }}
                  ></div>
                  <Row>
                    <Col lg={6} className="border-right mb-2">
                      <p className="" style={{ fontSize: "12px" }}>
                        Accepted Requests
                      </p>
                      <p style={{ marginTop: "-12px" }}>
                        {
                          stats.franchise_request.application_data
                            .accepted_requests
                        }
                      </p>
                      <p
                        className="text-muted"
                        style={{ fontSize: "11px", marginTop: "-12px" }}
                      >
                        <span className="text-success">
                          {" "}
                          54% <img className="arrow" src={upArrow}></img>
                        </span>
                        than last month
                      </p>
                    </Col>

                    <Col lg={6}>
                      <p className="" style={{ fontSize: "12px" }}>
                        Rejected Requests
                      </p>
                      <p style={{ marginTop: "-12px" }}>
                        {
                          stats.franchise_request.application_data
                            .rejected_request
                        }
                      </p>
                      <p
                        className="text-muted"
                        style={{ fontSize: "11px", marginTop: "-12px" }}
                      >
                        <span className="text-danger">
                          {" "}
                          11% <img className="arrow" src={downArrow}></img>
                        </span>{" "}
                        than last month
                      </p>
                    </Col>
                    {/* </div> */}
                  </Row>
                </Row>

                <Row className="depositStat mt-4 mb-4 ms-2 me-2">
                  <Col lg={7}>
                    <p style={{ color: "#343130" }} className="pt-2">
                      Deposits
                    </p>
                    <p
                      className="text-muted"
                      style={{ fontSize: "11px", marginTop: "-12px" }}
                    >
                      Deposits Paid by 789 Clients
                    </p>
                  </Col>
                  <Col lg={5}>
                    <p className="pt-2">&#8377; {depositValueReceived}</p>
                  </Col>

                  <div
                    className="border-top ms-3"
                    style={{ width: "90%" }}
                  ></div>
                  <Row>
                    <Col lg={7}>
                      <p style={{ color: "#343130" }} className="pt-2">
                        Projections
                      </p>
                      <p
                        className="text-muted"
                        style={{ fontSize: "11px", marginTop: "-12px" }}
                      >
                        Will Recieve when 44 clients were approved by BH
                      </p>
                    </Col>
                    <Col lg={5}>
                      <p className="pt-2">&#8377; {depositValuePending}</p>
                    </Col>
                  </Row>
                </Row>
              </Col>

              <Col lg={8}>
                <Row className="mt-2 mb-3">
                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyChannelTeams">
                        <div id="upperCardBodyChannelTeams">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.awaitingApprovalCh || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">
                              Awaiting Approval from Channel Team
                            </p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyResponseKyc">
                        <div id="upperCardBodyResponseKyc">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.awaitingKyc || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">
                              Awaiting Response from KYC
                            </p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyClarification">
                        <div id="upperCardBodyClarification">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.clarification || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">Clarification</p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyApprovalFromBh">
                        <div id="upperCardBodyApprovalFromBh">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.awaitingApprovalBh || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">
                              Awating Approval From Business Head
                            </p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-2 mb-3">
                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyPaymentApproval">
                        <div id="upperCardBodyPaymentApproval">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.paymentConfirmation || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">Payment Approval</p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyOutForSignature">
                        <div id="upperCardBodyOutForSignature">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.outForSignature || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">Out For Signature</p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyPhleboOnboarding">
                        <div id="upperCardBodyPhleboOnboarding">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.awaitingAdditionalInfo || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">Phlebo Onboarding</p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col lg={3}>
                    <Card
                      className="card"
                      style={{ border: "none", borderRadius: "20px" }}
                    >
                      <Card.Body id="cardBodyAwaitingApprovalFromCmo">
                        <div id="upperCardBodyAwaitingApprovalFromCmo">
                          <div className="countCard">
                            <Card.Title>
                              <h5>{statusCount.awaitingCmoApproval || 0}</h5>
                            </Card.Title>
                          </div>
                        </div>
                        <div className="lowerCardBody">
                          <Card.Text>
                            <p className="classText">
                              Awating Approval From CMO
                            </p>
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </div>
  );
};
export default DashboardAdmin;
