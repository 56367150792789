import "./index.scss";
// import AddList from "../../components/AddList";
import { Container, Row, Col } from "react-bootstrap";
import DragAndDrop from "../../../lib/components/DragAndDrop";
import Button from "../../../lib/components/Button";
import { useState, useEffect } from "react";
import TimePicker from "../../../lib/components/TimePicker";
import AddList from "../../../components/AddList";
import { clearPhleboSelectedData, fetchAddPhleboFormFields, setSelectedDataPhlebo, submitPhleboData } from '../../../redux/PhleboOnboarding/PhleboOnboardingActions'
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { useHistory } from 'react-router-dom';
import { saveUploadedCertificates } from '../../../redux/UploadedDocuments/UploadedDocumentsActions'
import Dropdown from "../../../lib/components/Dropdown";
import Input from "../../../lib/components/Input";
import { weekdayTimeSlotsAM, weekdayTimeSlotsPM, weekendTimeSlotsAM, weekendTimeSlotsPM } from '../../../constants/app.constants'
import { APPLICATION_STATUSES } from "../../../constants/status.constants";
import { CLIENT_DASHBOARD_BASIC } from "../../../constants/route.constants.js/applicant";
import requestV1 from "../../../utils/requestV1";

const PhleboOnboarding = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [errList, setErrList] = useState({});

  const dispatch = useDispatch()
  const history = useHistory()

  const formFields = useSelector(state => state.phleboOnboarding.formFields?.configuration?.step_1)
  const selectedData = useSelector((state) => state?.phleboOnboarding?.selectedData);
  const phleboList = useSelector((state) => state?.phleboOnboarding?.phleboList);
  const checkList = useSelector((state) => state?.phleboOnboarding?.checkList);
  const selectedPhleboApplication = useSelector((state) => state?.phleboOnboarding?.selectedPhleboApplication);
  const brandPhotos = useSelector(state => state?.onBoadringForm?.kycFile)
  const loading = useSelector((state) => state?.globalReducer?.submitLoading)
  
  useEffect(() => {
    dispatch(fetchAddPhleboFormFields())
  }, [])

  const handleUpload = (name, fileImage) => {
    let tempErrList = { ...errList };
    delete tempErrList[name];
    setErrList(tempErrList);
    dispatch(setSelectedDataPhlebo(name, fileImage))
  }

  const handleSuccess = () => {
    history.push("/phlebo/submit")
  }

  const handleError = (message) => {
    toast.error(message)
  }

  const requiredFields = ['weekday_start_time', 'weekday_end_time', 'weekend_start_time', 'weekend_end_time', 'front_display', 'phlebotomy_room', 'reception', 'waiting_area','longitude','latitude']

  const handleSubmit = async () => {
    let error = false;
    let tempErrs = { ...errList };
    requiredFields?.map(item => {
      if (!selectedData.hasOwnProperty(item)) {
        tempErrs[item] = 'This field is required';
        return error = true
      }
    });

    setErrList(tempErrs);

    if (error) {
      toast.error("All fields are required")
    } else if (!phleboList?.length) {
      toast.error("Please add your phlebo details")
    } else {
      let formData = new FormData()
      for (let file in brandPhotos) {
        formData.append(file, brandPhotos[file])
      }

      const createPhleboPayload = {
        applicationId: selectedPhleboApplication?._id,
        ...selectedData,
        phleboList,
        checkList,
        ...selectedPhleboApplication,
        active_step: 9,
        status: APPLICATION_STATUSES['Awaiting CMO Approval']['label'],
        stepHistory: [...selectedPhleboApplication?.stepHistory?.map((item) => {
          if (item?.step === selectedPhleboApplication?.active_step) {
            item.status = "COMPLETED";
            item.completed_date = new Date().toDateString()
          }
          return item
        }), ...[{
          step: 9,
          status: "PENDING",
          initated_date: new Date().toDateString(),
        }]]
      }

      delete createPhleboPayload?.front_display
      delete createPhleboPayload?.phlebotomy_room
      delete createPhleboPayload?.reception
      delete createPhleboPayload?.waiting_area

      let params = {
        body: JSON.stringify(createPhleboPayload)
      }
      const encryptedBodyRes = await requestV1('', params, true, true);
      formData.append('data', JSON.stringify(encryptedBodyRes))
      dispatch(submitPhleboData(formData, handleSuccess, handleError))
    }
  }

  const timeChange = (name, value) => {
    let tempErrList = { ...errList };
    delete tempErrList[name];
    setErrList(tempErrList);
    dispatch(setSelectedDataPhlebo(name, value))
  }

  const [state, setState] = useState();
  const [formErr, setFormErr] = useState({});
  // const stateErrRef = useRef();

  // stateErrRef.current = { ...formErr };

  // const clearFieldError = (fields, isList) => {
  //   fields = isList ? fields : [fields];
  //   let err = { ...stateErrRef?.current };
  //   for (let key in fields) {
  //     const _field = fields[key];
  //     if (err[_field] !== undefined) {
  //       delete err[_field];
  //     }
  //   }
  //   setFormErr(err);
  // };

  const handleChange = e => {
    const { name, value } = e.target;
    let tempErrList = { ...errList };
    delete tempErrList[name];
    setErrList(tempErrList);
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
    dispatch(setSelectedDataPhlebo(name, value));
  };

  const handleCancel = () => {
    dispatch(clearPhleboSelectedData())
    history.push(CLIENT_DASHBOARD_BASIC)
  }
  const getErrByField = (field) => {
    const error = errList[field] ? errList[field] : '';
    return error;
  }
  return (
    <section className="phlebo-onboarding">
      <Toaster />
      <Container>
        <Row>
          {phleboList?.length === 0 && <p className="phlebo-onboarding__title">Phlebo Onboarding</p>}
          <AddList
            buttonText="Add Phlebo"
            formFields={formFields}
            overlayHeader="Add Phlebo Details"
            showOverlay={showOverlay}
            setShowOverlay={setShowOverlay}
            phleboOnboarding={true}
            saveUploadedCertificates={saveUploadedCertificates}
            certificate={true}
            listData={phleboList}
          />
          <p className="phlebo-onboarding__title" style={{ marginTop: "20px" }}>
            Service Timings and Location Details
          </p>
          <div className="phlebo-list">
            <Col className="pl-0">
              <Dropdown
                label={"Weekdays(Mon-Sat)"}
                name="weekday_start_time"
                options={weekdayTimeSlotsAM}
                onChange={timeChange}
                error={getErrByField('weekday_start_time')}
              />
            </Col>
            <span className="time_separator">to</span>
            <Col>
              <Dropdown
                label={"."}
                name="weekday_end_time"
                options={weekdayTimeSlotsPM}
                onChange={timeChange}
                labelHidden={true}
                error={getErrByField('weekday_end_time')}
              />
            </Col>
            <Col>
              <Dropdown
                label={"Weekend(Sun)"}
                name="weekend_start_time"
                options={weekendTimeSlotsAM}
                onChange={timeChange}
                error={getErrByField('weekend_start_time')}
              />
            </Col>
            <span className="time_separator">to</span>
            <Col>
              <Dropdown
                label={"."}
                name="weekend_end_time"
                options={weekendTimeSlotsPM}
                onChange={timeChange}
                labelHidden={true}
                error={getErrByField('weekend_end_time')}
              />
            </Col>
          </div>
          <div className="phlebo-list">
          <Col className="pl-0">
              <Input
                label={"Longitude"}
                name="longitude"
                onChange={handleChange}
                error={getErrByField('longitude')}
              />
            </Col>
            <Col>
              <Input
                label={"Latitude"}
                name="latitude"
                onChange={handleChange}
                error={getErrByField('latitude')}
              />
            </Col>
          </div>
          <p className="phlebo-onboarding__title" style={{ marginTop: "50px" }}>
            Branding Photos Upload
          </p>
          <Col lg={6}>
            <DragAndDrop handleUpload={handleUpload} label="Front Display" name="front_display" error={getErrByField('front_display')} enums ={["*"]}/>
            <DragAndDrop handleUpload={handleUpload} label="Reception" name="reception" error={getErrByField('reception')}  enums ={["*"]}/>
          </Col>
          <Col lg={6}>
            <DragAndDrop handleUpload={handleUpload} label="Phlebotomy Room" name="phlebotomy_room" error={getErrByField('phlebotomy_room')}  enums ={["*"]}/>
            <DragAndDrop handleUpload={handleUpload} label="Waiting Area" name="waiting_area" error={getErrByField('waiting_area')}  enums ={["*"]}/>
          </Col>
          <div className="phlebo-onboarding__button-wrapper">
            <div onClick={handleCancel}>
              <span className="phlebo-onboarding__button-wrapper__cancel_btn">CANCEL</span>
            </div>
            <Button label="SUBMIT" loading={loading} onClick={handleSubmit} />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default PhleboOnboarding;
