import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";

const AppRoute = (props) => {
    const { privateRoute } = props;
    if (privateRoute) {
        return <PrivateRoute {...props} />
    }
    return <Route {...props} />
}

const Routing = (props) => {
    return (
        <Switch>
            {routes.map((route, index) => {
                return (
                    <AppRoute
                        {...props}
                        privateRoute={route.private}
                        {...route}
                        key={index}
                    />
                )
            })}
        </Switch>
    )
}

export default Routing;