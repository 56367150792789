import { Col, Container, Row } from "react-bootstrap";
import "./index.scss";
import { getBooleanImg } from "../../../../utils/booleanImgHelper";
import HorizontalLine from "../HorizontalLine/index.js";

const AdditionalDetails = ({ selectedData }) => {

  return (
    <section className="additional-details" id="additional-info">
      <HorizontalLine />
      <Container fluid={true}>
        <p className="additional-details__header">Additional Information</p>
        <Row>
          <Col lg={4}>
            <p className="additional-details__sub-title">
              Already owns Agilus center
            </p>
          </Col>
          <Col lg={8}>
            <p className="additional-details__title">
              {selectedData?.existing_franchisee_name ? selectedData?.existing_franchisee_name : getBooleanImg("No")}
            </p>
            <p className="additional-details__sub-text">
              {selectedData?.existing_franchisee_address ? selectedData?.existing_franchisee_address : ""}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <p className="additional-details__sub-title">
              Franchisee of other labs
            </p>
          </Col>
          <Col lg={8}>
            <p className="additional-details__title">
              {selectedData?.other_franchisee_name ? selectedData?.other_franchisee_name : getBooleanImg("No")}

              {/* {other_franchisee_name && "No" || selectedData?.other_franchisee_name} */}
            </p>
            <p className="additional-details__sub-text">
              {selectedData?.other_franchisee_address ? selectedData?.other_franchisee_address : ""}
            </p>
          </Col>
        </Row>

        <Row>
          <Col lg={4}>
            <p className="additional-details__sub-title">
              Relative have Existing Agilus Centre
            </p>
          </Col>
          <Col lg={8}>
            <p className="additional-details__title">{getBooleanImg(selectedData?.family_franchisee)}</p>
          </Col>
        </Row>

      </Container>
    </section>
  );
};

export default AdditionalDetails;
