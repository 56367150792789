import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.scss";
import Button from '../../lib/components/Button'
import { useLocation,useHistory } from "react-router-dom";
import { LOGO_ROUTE_MAP } from "../../constants/app.constants";
import { CLIENT_ONBOARDING } from "../../constants/route.constants.js/applicant";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAppError } from "../../redux/App/Action";

const ErrorComponent = () => {
  const location = useLocation()
  const history = useHistory();
  const currentPath = location.pathname || ''
  const dispatch=useDispatch();

  const navigateToListPage=()=>{
   let route = LOGO_ROUTE_MAP[currentPath] || '/';
    if (currentPath.indexOf(CLIENT_ONBOARDING) !== -1) {
      window.location.reload();
      return;
    }
    dispatch(setAppError(false));
    history.push(route);
  }

  return (
    <Container className="lds-error-container">
      <Row>
        <Col>
          <div className="lds-error-wrapper">
            <span>Something went wrong...!<span className="lds-error-wrapper--smile">&#128549;</span> Please try again later</span>
          </div>
        </Col>
        <Button type="ghost" label={ currentPath.indexOf(CLIENT_ONBOARDING) !== -1 ?"reload" : "back"} onClick={navigateToListPage}/>
      </Row>
    </Container>
  );
};
export default ErrorComponent;
