import PaginationCMO from "./Pagination";
import { useState } from "react";
import "./index.scss";

const ListPagination = ({
  applicantsPerPage,
  handleGoChange,
  go,
  handlePageChange,
  totalCount,
  setApplicantsPerPage,
  currentPage,
}) => {
  const [toggleGo, setToggleGo] = useState(false);
  return (
    <div className="pagination--wrapper">
      {totalCount >applicantsPerPage &&  <>
      <p className="page">
        {applicantsPerPage}/page
        <select
          className="pagination--wrapper-page"
          value={applicantsPerPage}
          onChange={(e) => setApplicantsPerPage(e.target.value)}
        >
          <option value="" hidden> </option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15"> 15</option>
        </select>
      </p>
      <div className="pagination">
        <PaginationCMO
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={applicantsPerPage}
          onPageChange={handlePageChange}
        />
      </div>

      <p className="go">
        Page No | &nbsp;
        <span
          className="pagination--wrapper-go"
          onClick={() => setToggleGo(!toggleGo)}
        >
          Go
        </span>
        {toggleGo && (
          <input
            type="text"
            className="pagination--wrapper-go-input"
            value={go}
            onChange={handleGoChange}
          />
        )}
      </p>
      </>
      }
    </div>
  );
};
export default ListPagination;
