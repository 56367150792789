const key = "Redux_STATE";

export const loadState = () => {
	try {
		const serialized = localStorage.getItem(key);
		return JSON.parse(serialized) || {};
	} catch (err) {
		return undefined;
	}
};

export const saveState = state => {
	
	try {
		const serialized = JSON.stringify(state);
		localStorage.setItem(key, serialized);
	} catch (err) {
	}
};
