import KycOrOtp from "../../../components/KYC";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CLIENT_KYC_OTP, CLIENT_MAKE_PAYMENT } from "../../../constants/route.constants.js/applicant";
import { setApplicantSecondJourneySelectedData, submitApplicantSecondJourney } from '../../../redux/AddPartnerDetails/AddPartnerDetailsActions'
import { aadharGenerateOTP } from '../../../redux/AddPartnerDetails/AddPartnerDetailsActions'
import { toast, Toaster } from 'react-hot-toast'
import './index.scss'

// import {sendPartnerKycInvitation} from "../../../lib/components/api/api.service"
const ClientKYC = () => {
  let history = useHistory();
  let dispatch = useDispatch()

  const partnerList = useSelector((state) => state?.addPartnerDetails?.partnerList);

  const selectedData = useSelector(state => state?.addPartnerDetails?.selectedData)
  const partnerData = useSelector(state => state?.addPartnerDetails?.partnerList)
  const selectedApplication = useSelector(state => state?.addPartnerDetails?.selectedApplication)
  const kycFile = useSelector(state => state?.onBoadringForm?.kycFile)



  const handleCancel = () => { };

  const handleSuccess = () => {
    history.push(CLIENT_KYC_OTP);
  }
  const handleError = (message) => {
    toast.error(message)
  }

  const handleProceed = () => {
    dispatch(aadharGenerateOTP(selectedData?.aadhaar_number, handleSuccess, handleError));
  };
  const copyCurrentAddress = () => {
    const address = selectedData?.current_address;
    if (address?.length !== 0) {
      dispatch(setApplicantSecondJourneySelectedData('permanent_address', address));
    }
  }
  return (
    <div className="kyc-client-container">
      <Toaster />
      <KycOrOtp
        handleProceed={handleProceed}
        selectedData={selectedData}
        setSelectedData={setApplicantSecondJourneySelectedData}
        customAction={{ field: 'permanent_address', label: 'Same as current address', handlerFunc: copyCurrentAddress }}
      />
    </div>
  );
};

export default ClientKYC;
