import footer from "../../assets/images/SignDesk.svg";
import "./index.scss";
const Footer = () => {
  return (
    <footer className="footer">
      <span>Powered by</span>
      <div className="footer__image">
        <img src={footer} alt="footerImg" />
      </div>
    </footer>
  );
};

export default Footer;
