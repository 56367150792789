export const APPLICANT_LIST_REQUEST = "APPLICANT_LIST_REQUEST";
export const APPLICANT_LIST_SUCCESS = "APPLICANT_LIST_SUCCESS";
export const APPLICANT_LIST_FAILURE = "APPLICANT_LIST_FAILURE";

export const CHANNEL_TEAM_APPROVAL_REQUEST = "CHANNEL_TEAM_APPROVAL_REQUEST";
export const CHANNEL_TEAM_APPROVAL_SUCCESS = "CHANNEL_TEAM_APPROVAL_SUCCESS";
export const CHANNEL_TEAM_APPROVAL_FAILURE = "CHANNEL_TEAM_APPROVAL_FAILURE";

export const CHANNEL_TEAM_REJECTION_REQUEST = "CHANNEL_TEAM_REJECTION_REQUEST";
export const CHANNEL_TEAM_REJECTION_SUCCESS = "CHANNEL_TEAM_REJECTION_SUCCESS";
export const CHANNEL_TEAM_REJECTION_FAILURE = "CHANNEL_TEAM_REJECTION_FAILURE";

export const CHANNEL_TEAM_CLARIFICATION_REQUEST = "CHANNEL_TEAM_CLARIFICATION_REQUEST";
export const CHANNEL_TEAM_CLARIFICATION_SUCCESS = "CHANNEL_TEAM_CLARIFICATION_SUCCESS";
export const CHANNEL_TEAM_CLARIFICATION_FAILURE = "CHANNEL_TEAM_CLARIFICATION_FAILURE";

export const GET_GEO_LOCATION_REQUEST = "GET_GEO_LOCATION_REQUEST";
export const GET_GEO_LOCATION_SUCCESS = "GET_GEO_LOCATION_SUCCESS";
export const GET_GEO_LOCATION_FAILURE = "GET_GEO_LOCATION_FAILURE";

export const SELECTED_APPLICANT_CHANNEL = "SELECTED_APPLICANT_CHANNEL";
