import { Row, Col, Container } from 'react-bootstrap'
import NoResultImg from "../../../assets/images/No_result.svg";
import "./index.scss";

const NoResult = ({ text = "Sorry! No Record Found", showImage = true }) => {
  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <div className="no-result">
            {showImage ?
              <>
                <div className='no-result-img'>
                  <img src={NoResultImg} />
                  <div className='no-result-placeholder-text'>{text}</div>
                </div>
              </>
              :
              <p className='no-result-loader-text'>{text}</p>
            }
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NoResult;
