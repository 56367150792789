import Loginimage from "../../assets/images/AdminLogin.svg";
import { Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router"; 
import PasswordReset from "./PasswordReset";
import { resetPasswordRequest } from "../../redux/Auth/Action";
import CryptoJS from "crypto-js";

const ResetPassword = ({ }) => {
  const params = useParams();
  const { userId, token} = params;
  const handleSubmit = (data, cb) => {
    let { newPassword, reNewPassword } = data;
    let cipherKey = 'Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh';

    newPassword = CryptoJS.AES.encrypt(newPassword, cipherKey).toString();
    reNewPassword = CryptoJS.AES.encrypt(reNewPassword, cipherKey).toString();
    const finalData = {newPassword: newPassword, confirmPassword: reNewPassword, userId, token };
    resetPasswordRequest(finalData, cb);
  };

  return (
    <section className="login__wrapper">
      <Container fluid={true}>
        <Row>
          <Col lg={6}>
            <div className="login__content-left">
              <h5>Expand the Franchise</h5>
              <p>Over 1000+ successful businesses started here</p>
              <div className="login__content-left--image">
                <img src={Loginimage} alt="" />
              </div>
            </div>
          </Col>
          <Col lg="6">
              <PasswordReset handleSubmit={handleSubmit} />
          
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default ResetPassword;
