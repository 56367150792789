import {
  FETCH_PIN_CODE_FAILURE,
  DASH_BOARD_SELECTED_DATA,
  FETCH_STEP_TWO_APPLICANTS,
  FETCH_PIN_CODE_SUCCESS,
  CLUSTER_SELECTED_DATA,
  SAVE_DRAFT_JOBS
} from "./DashboardActionTypes";

import { SELECTED_APPLICANT_FROM_DASHBOARD } from '../ClusterHead/ClusterHeadTypes'

const initialState = {
  applicantsList: [],
  pin_code_details: {},
  selectedData: {},
  selectedApplicant: {},
  saveDraftJobs: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STEP_TWO_APPLICANTS:
      return {
        ...state,
        applicantsList: action.payload,
      };
    case CLUSTER_SELECTED_DATA: {
      return {
        ...state,
        selectedData: { ...state.selectedData, [action.key]: action.payload },
      };
    }

    case "CLEAR_SELECTED_DATA": {
      return {
        ...state,
        selectedData: {},
      };
    }
    case FETCH_PIN_CODE_SUCCESS: {
      return {
        ...state,
        pin_code_details: action.payload,
      };
    }
    case FETCH_PIN_CODE_FAILURE: {
      return {
        ...state,
        pin_code_details: action.payload,
      };
    }
    case SELECTED_APPLICANT_FROM_DASHBOARD: {
      return {
        ...state,
        selectedApplicant: action.payload,
      };
    }
    case SAVE_DRAFT_JOBS: {
      return {
        ...state,
        saveDraftJobs: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
