export const FETCH_ONBOARDING_FORM_REQUEST = "FETCH_ONBOARDING_FORM_REQUEST";
export const FETCH_ONBOARDING_FORM_SUCCESS = "FETCH_ONBOARDING_FORM_SUCCESS";
export const FETCH_ONBOARDING_FORM_FAILURE = "FETCH_ONBOARDING_FORM_FAILURE";
export const UPDATE_ONBOARDING_FORM_DATA = "UPDATE_ONBOARDING_FORM_DATA";
export const SET_SELECTED_DATA = "SET_SELECTED_DATA";
export const UPLOAD_PAN_REQUEST = "UPLOAD_PAN_REQUEST";
export const UPLOAD_PAN_SUCCESS = "UPLOAD_PAN_SUCCESS";
export const UPLOAD_KYC_SUCCESS = "UPLOAD_PAN_SUCCESS";
export const UPLOAD_PAN_FAILURE = "UPLOAD_PAN_REQUEST";
export const SUBMIT_ONBOARD_FORM_REQUEST = "SUBMIT_ONBOARD_FORM_REQUEST";
export const SUBMIT_ONBOARD_FORM_SUCCESS = "SUBMIT_ONBOARD_FORM_SUCCESS";
export const SUBMIT_ONBOARD_FORM_FAILURE = "SUBMIT_ONBOARD_FORM_FAILURE";

export const GENERATE_OTP_REQUEST = "GENERATE_OTP_REQUEST";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAILURE = "GENERATE_OTP_FAILURE";


export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";
export const RESET_MOBILE_AND_OTP = "RESET_MOBILE_AND_OTP";


export const SET_DATA_FROM_PANCARD = "SET_DATA_FROM_PANCARD";

export const RESET_ONBOARDING_FORM_DATA = "RESET_ONBOARDING_FORM_DATA";

export const SET_PINCODE_ALLOCATION = "SET_PINCODE_ALLOCATION";

export const VALIDATE_AADHAR = "VALIDATE_AADHAR";
export const VALIDATE_VOTER_ID = "VALIDATE_VOTER_ID";
export const VALIDATE_DRIVING_LICENSE = "VALIDATE_DRIVING_LICENSE";
export const VALIDATE_PASSPORT = "VALIDATE_PASSPORT";

export const FORM_ERROR = "FORM_ERROR";
export const PHONE_VALIDATION = "PHONE_VALIDATION";
export const VALIDATE_ID_PROOF = "VALIDATE_ID_PROOF";

export const SAVE_LOCALITY="SAVE_LOCALITY"