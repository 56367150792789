import "./index.scss";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  DropdownButton,
  Dropdown,
  ButtonGroup,
  InputGroup,
  ButtonToolbar,
  Form,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import crown from "../../assets/images/Crown.svg";
import leftHandCashImg from "../../assets/images/leftHandCash.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { CLIENT_DASHBOARD_BASIC } from "../../constants/route.constants.js/applicant"; 
import { axisPayment, axisPaymentResult } from "../../lib/components/api/api.service";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status";
import { setSelectedApplication } from "../../redux/AddPartnerDetails/AddPartnerDetailsActions";
export default function PaymentScreen() {
  const [security, setSecurity] = useState(false);
  const [market, setMarket] = useState(false);
  const [rolling, setRolling] = useState(false);
  const [showSkipPayment, setSkipPayment] = useState(false)
  const [autoSkip, setAutoSkip] = useState(false)
  const location = useLocation();

  const history = useHistory();
  const selectedApplication = useSelector(
    (state) => state?.addPartnerDetails?.selectedApplication
  );
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  const skipToDashboard = ()=>{
    history.push(CLIENT_DASHBOARD_BASIC)
  }
  const responseSubmit = (type) => {
    // console.log(selectedApplication)
    const res = loadScript('https://checkout.razorpay.com/v1/checkout.js')
    if (!res) {
      alert('Razorpay SDK failed to load.')
      return
    }
    let params = {
      id:selectedApplication?._id,
      payment_type: type
    }
    if(type === "security_deposit"){
      params.security_deposit = selectedApplication?.source_data?.security_deposit + "00"
    }else if(type === "marketing_deposit"){
      params.security_deposit = selectedApplication?.source_data?.marketing_deposit + "00"
    }else{
      params.security_deposit = selectedApplication?.source_data?.rolling_advance + "00"

    }
    // console.log(params)
    let response = axisPayment(params)
    response.then((data) => {
      const options = {
        key: process.env.REACT_APP_PAYMENT_KEY,
        currency: "INR",
        amount: params.security_deposit,
        order_id: data.data?.result?.order_id,
        name: 'Agilus Diagnostics',
        description: '',
        handler: function (resp) {
          let params = {
            id:selectedApplication?._id,
            org_name: resp.org_name,
            initiated_at: data.data?.result?.initiated_at,
            gateway_response: resp,
            payment_type: type
          }
          // razorpay_order_id: response.razorpay_order_id,
          // razorpay_signature: response.razorpay_signature,
          axisPaymentResult(params).then((paymentResp)=>{
          // console.log({ paymentResp })
          if (SUCCESS_RESPONSE_LIST?.includes(paymentResp?.status)) {
            setSelectedApplication(paymentResp?.result)
            if(type === "marketing_deposit"){
              setMarket(true)
            }else if(type === "rolling_advance"){
              setRolling(true)
              setSkipPayment(true)
            }

            if( 
              paymentResp?.result?.rolling_payment_status?.status === "completed"
                
                && ( paymentResp?.result?.marketing_payment_status?.status === "completed" 
                  || selectedApplication.source_data.marketing_deposit === "0" 
                  )
              ){
              setTimeout(() => {
                skipToDashboard()
              }, 3000)
            }
          }
        })
        },
        prefill: {
          name:selectedApplication?.source_data?.first_name + " " +selectedApplication?.source_data?.last_name,
          email:selectedApplication?.source_data?.email,
          contact:selectedApplication?.source_data?.mobile_number
        },
        image: "/static/media/Agilus_Diagnostics_Logo.png",
        callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
        notes: {
          "address": "Razorpay Corporate Office"
        },
        theme: {
          "color": "#3399cc"
        }
      }

      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    })
    // handleSubmit()
  }
   
  useEffect(()=>{
    // console.log(selectedApplication)
    // if(selectedApplication?.payment_status?.status === "completed"){
    //   setSecurity(true)
    // } 
    if(selectedApplication?.marketing_payment_status?.status === "completed"){
      setMarket(true)
    }
    if(selectedApplication?.rolling_payment_status?.status === "completed"){
      // setSkipPayment(true)
      setRolling(true)
    }
  },[selectedApplication])
  return (
    <Container id="container" style={{ width: "70%" }} className="pt-4">
      <div
        style={{ height: "2rem", width: "98%", margin: "auto" }}
        className="d-flex justify-content-between pt-2 square border-bottom"
      >
        {/* <div style={{ color: "#0076BC" }}>Successfully paid</div> */}
        {/* {
          showSkipPayment ? (
        <div style={{ color: "#0076BC", cursor: "pointer" }} onClick={()=>{skipToDashboard()}}>Skip Payment</div>
          ):(<div></div>) } */}
      </div>
      <Row className="pt-2">
      {rolling ? (
          <Col lg={4} style={{ padding: "10px" }}>
            <Card
              className="blueCard"
              style={{
                height: "25rem",
                borderWidth: "0px",
                borderStyle: "none",
                borderColor: "white",
                background: "#0076BC 0% 0% no-repeat padding-box",
              }}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-content-baseline">
                <img src={crown} alt="rolling_advance" style={{ height: "48px" }}></img>
                <p
                  className="pt-2 cashValue"
                  style={{
                    textAlign: "center",
                    color: "white",
                    font: "normal normal 600 17px/30px Montserrat",
                  }}
                >
                  Successfully paid ₹{selectedApplication?.source_data.rolling_advance} as Rolling deposit
                </p>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <Col lg={4} style={{ padding: "10px" }}>
            <Card
              style={{
                height: "25rem",
                borderWidth: "0px",
                borderStyle: "none",
                borderColor: "white",
              }}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-content-baseline">
                <img src={leftHandCashImg} alt="rolling_advance" style={{ maxHeight: "7rem" }}></img>
                <p className="pt-4 cashValue" style={{ textAlign: "center" }}>
                ₹{selectedApplication?.source_data.rolling_advance}
                </p>
                <p className="pt-2 cashValue" style={{ textAlign: "center" }}>
                  Rolling Advance
                </p>

                {(selectedApplication?.source_data?.rolling_advance !== undefined && selectedApplication?.source_data?.rolling_advance !== "0" ) ? 

                <p className="pt-2" style={{ textAlign: "center" }}>
                  Pay instantly for faster approval
                </p> : "" }
              </Card.Body>
              {(selectedApplication?.source_data?.rolling_advance !== undefined && selectedApplication?.source_data?.rolling_advance !== "0" ) ? 

              <Button
                style={{
                  marginBottom: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  widht: "100%",
                }}
                onClick={()=>{responseSubmit("rolling_advance")}}

              >
                PAY NOW
              </Button> : ""}
            </Card>
          </Col>
     )}
{market ? (
          <Col lg={4} style={{ padding: "10px" }}>
            <Card
              className="blueCard"
              style={{
                height: "25rem",
                borderWidth: "0px",
                borderStyle: "none",
                borderColor: "white",
                background: "#0076BC 0% 0% no-repeat padding-box",
              }}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-content-baseline">
                <img src={crown} alt="marketing_deposit" style={{ height: "48px" }}></img>
                <p
                  className="pt-2 cashValue"
                  style={{
                    textAlign: "center",
                    color: "white",
                    font: "normal normal 600 17px/30px Montserrat",
                  }}
                >
                  Successfully paid ₹{selectedApplication?.source_data.marketing_deposit} as Marketing deposit
                </p>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <Col lg={4} style={{ padding: "10px" }}>
            <Card
              style={{
                height: "25rem",
                borderWidth: "0px",
                borderStyle: "none",
                borderColor: "white",
              }}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-content-baseline">
                <img src={leftHandCashImg} alt="marketing_deposit" style={{ maxHeight: "7rem" }}></img>
                <p className="pt-4 cashValue" style={{ textAlign: "center" }}>
                  ₹{selectedApplication?.source_data.marketing_deposit}
                </p>
                <p className="pt-2 cashValue" style={{ textAlign: "center" }}>
                  Marketing deposit
                </p>
                {(selectedApplication?.source_data?.marketing_deposit !== undefined && selectedApplication?.source_data?.marketing_deposit !== "0" ) ? 

                <p className="pt-2" style={{ textAlign: "center" }}>
                  Pay instantly for faster approval
                </p> : ""}
              </Card.Body>
              {(selectedApplication?.source_data?.marketing_deposit !== undefined && selectedApplication?.source_data?.marketing_deposit !== "0" ) ? 
              <Button
                style={{
                  marginBottom: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  widht: "100%",
                }}
                onClick={()=>{responseSubmit("marketing_deposit")}}

              >
                PAY NOW
              </Button> :""}
              
            </Card>
          </Col>
)} 
      {/* {security? (
          <Col lg={4} style={{ padding: "10px" }}>
            <Card
              className="blueCard"
              style={{
                height: "25rem",
                borderWidth: "0px",
                borderStyle: "none",
                borderColor: "white",
                background: "#0076BC 0% 0% no-repeat padding-box",
              }}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-content-baseline">
                <img src={crown} style={{ height: "48px" }}></img>
                <p
                  className="pt-2 cashValue"
                  style={{
                    textAlign: "center",
                    color: "white",
                    font: "normal normal 600 17px/30px Montserrat",
                  }}
                >
                  Successfully paid ₹{selectedApplication?.source_data.security_deposit} as security deposit
                </p>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <Col lg={4} style={{ padding: "10px" }}>
            <Card
              style={{
                height: "25rem",
                borderWidth: "0px",
                borderStyle: "none",
                borderColor: "white",
              }}
            >
              <Card.Body className="d-flex flex-column justify-content-center align-content-baseline">
                <img src={leftHandCashImg} style={{ maxHeight: "7rem" }}></img>
                <p className="pt-4 cashValue" style={{ textAlign: "center" }}>
                  ₹{selectedApplication?.source_data.security_deposit}
                </p>
                <p className="pt-2 cashValue" style={{ textAlign: "center" }}>
                  Security deposit (Not applicable)
                </p>
                <p className="pt-2" style={{ textAlign: "center" }}>
                  Pay instantly for faster approval
                </p>
              </Card.Body> */}
              {/* <Button
                style={{
                  marginBottom: "0px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  widht: "100%",
                }}
                onClick={()=>{responseSubmit("security_deposit")}}
              >
                PAY NOW
              </Button> */}
            {/* </Card>
          </Col>
        )} */}
       
        
      </Row>
    </Container>
  );
}
