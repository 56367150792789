import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import TagList from "./TagList";
import "./index.scss";

const AddTag = ({ label, handleAddTag, name, error, value }) => {
  const [tag, setTag] = useState([]);
  const [tagValue, setTagValue] = useState("");

  useEffect(()=>{
    if(value && value.length){
      setTag(value);
    }
  },[]);

  const handleChange = e => {
    const attr = e.target.value;
    setTagValue(attr);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (tagValue.replace(/\s/g, "").length) {
      const newArray = [...tag, tagValue];
      setTag(newArray);
      setTagValue("");
      handleAddTag(name, newArray);
    }
  };

  const removeHandle = index => {
    const newArray = tag.filter((ele, i) => {
      return i !== index;
    });
    setTag(newArray);
    handleAddTag(name, newArray);
  };
  const errActive = error && error?.length !== 0;
  const errCls = errActive ? "add-tag__error" : "";
  return (
    <Row>
      <Col lg={12}>
        <div className="add-tag__wrapper">
          <p className="add-tag__label">{label}</p>
          <div className={`add-tag__content ${errCls}`}>
            <TagList tagList={tag} removeHandle={removeHandle} />
            <form onSubmit={handleSubmit}>
              <input
                className={
                  tag.length === 0
                    ? "add-tag__input"
                    : "add-tag__input add-tag__input-padding"
                }
                type="text"
                placeholder={tag.length === 0 ? "Add Tags" : "+ Add more..."}
                value={tagValue}
                onChange={handleChange}
                title="Hit enter to add"
              />
            </form>
          </div>

          {errActive && (
            <label className="lds-form-group--error-label">{error}</label>
          )}
        </div>
      </Col>
    </Row>
  );
};
export default AddTag;
