import { APPLICATION_STATUSES } from '../../../constants/status.constants';

export const getStatusBasedBtn = (type, step, status) => {
    if (type === "reject" && status === APPLICATION_STATUSES['Awaiting CH Approval']['label']) {
        if (step === 2) return true
    }
    if (type === 'clarification') {
        if (parseInt(step) >= 3 || status === APPLICATION_STATUSES['Awaiting Clarification']['label']) {
            return true
        }
    }
    // if (type === 'approve' && status === APPLICATION_STATUSES['Awaiting BH Approval']['label']) {
    //     if (step === 3) return true
    // }
    // if (type === 'rejected') {
    //     if (step === 3 && status === APPLICATION_STATUSES['Rejected']['label']) return true
    // }
}