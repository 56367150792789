import { Modal } from "react-bootstrap";
import profile from "../../assets/images/dummyProfile3.jpg";
import "./index.scss";

const ViewPDF = ({ handleClose, show, label, src, fullscreen=false }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fullscreen={fullscreen}
      >
        <Modal.Header closeButton />
        <Modal.Body className="my-body">
          {src ? (
            <iframe
              style={{ height: "80vh", width: "100%", textAlign: "center" }}
              src={src}
            />
          ) : (
            <iframe src={profile} style={{ height: "400px", width: "auto" }} />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ViewPDF;
