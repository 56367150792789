import redCross from "../assets/images/red cross.svg";
import greenTick from "../assets/images/green tick.svg";

const trueOptions = ['True', 'true', 'yes', 'Yes', true];
export const getBooleanImg = (val) => {
    if (trueOptions.includes(val)) {
        return (
            <img src={greenTick} />
        )
    }
    return <img src={redCross} />
}