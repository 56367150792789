//dashboard data action
//Write pagination code in to cmo/dashboard
import { SUCCESS_RESPONSE_LIST } from "../../constants/status.js";
import {
  CMO_JOURNEY_LIST_FAILURE,
  CMO_JOURNEY_LIST_SUCCESS,
  CMO_JOURNEY_LIST_REQUEST,
  SELECTED_APPLICANT_CMO
} from "./CMOJourneyTypes";
import { toast } from 'react-hot-toast'
import { setClarificationLoading, setRejectLoading, setSubmitLoading } from '../App/Action.js';
import requestV1 from '../../utils/requestV1.js';

// Dashboard
export const fetchCMOJourneyList = (userId) => async (dispatch) => {

  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getAllApplications`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ active_step: 9, organisation_id: process.env.REACT_APP_ORGANIZATION_ID })
  };
  try {
    const response = await requestV1(reqUri, options)
    const { data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      // dispatch(fetchApplicantsListSuccess(config_mock));
      dispatch(fetchCMOJourneyListSuccess(data));
    } else {
      toast.error(data?.message)
      dispatch(fetchCMOJourneyListSuccess([]));
      dispatch(fetchCMOJourneyListFailure(data?.message))
    }
  }
  catch (err) {
    toast.error("network Error")
  }
};

export const rejectApplicationCMO = (payload, handleSuccess, handleError) => async (dispatch, getState) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  }
  try {
    dispatch(setRejectLoading(true))
    const response = await requestV1(reqUri, options)

    const { data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleSuccess(message)
      dispatch(setRejectLoading(false))
    } else {
      dispatch(setRejectLoading(false))
      handleError(data?.message)
    }
  }
  catch (error) {
    dispatch(setRejectLoading(false))
    handleError("Network Error")
  }

};

export const clarifyApplicationCMO = (payload, handleSuccess, handleError) => async (dispatch, getState) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  };

  try {
    dispatch(setClarificationLoading(true))
    const response = await requestV1(reqUri, options)
    const { message, data, status } = response

    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleSuccess(message)
      dispatch(setClarificationLoading(false))
    } else {
      handleError(message)
      dispatch(setClarificationLoading(false))
    }
  }
  catch (error) {
    handleError("Network Error")
    dispatch(setClarificationLoading(false))
  }
};

export const approveApplicationCMO = (payload, handleSuccess, handleError) => async (dispatch, getState) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/update-application`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload)
  }
  try {
    dispatch(setSubmitLoading(true))
    const response = await requestV1(reqUri, options)
    const { data, message, status } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      handleSuccess(message)
      dispatch(setSubmitLoading(false))
    } else {
      handleError(message)
      dispatch(setSubmitLoading(false))
    }
  }
  catch (error) {
    handleError("Network Error")
    dispatch(setSubmitLoading(false))
  }
};

const fetchCMOJourneyListRequest = () => {
  return {
    type: CMO_JOURNEY_LIST_REQUEST
  }
}
const fetchCMOJourneyListSuccess = (data) => {
  return {
    type: CMO_JOURNEY_LIST_SUCCESS,
    payload: data
  }
}

const fetchCMOJourneyListFailure = (error) => {
  return {
    type: CMO_JOURNEY_LIST_FAILURE,
    payload: error
  }
}

export const selectedApplicantDataCMO = (data) => {
  return {
    type: SELECTED_APPLICANT_CMO,
    payload: data
  }
}