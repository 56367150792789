import { Container, Row, Col } from "react-bootstrap";
import "./index.scss";
import document from "../../../../assets/images/document.svg";
import HorizontalLine from "../HorizontalLine/index.js"
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import ViewPDF from "../../../../components/ViewPDF";
import requestV1 from "../../../../utils/requestV1";
import { SUCCESS_RESPONSE_LIST } from "../../../../constants/status";
import { loginInfo } from "../../../../redux/Login/LoginActions";

const OtherInformation = ({ selectedData }) => {
  const [title, setTitle] = useState("");
  const [src, setSrc] = useState("");
  const dispatch = useDispatch()

  const [show, setShow] = useState(false);
  const handleShow = (src, title) => {
    setShow(true);
    setSrc(src);
    setTitle(title);
  };
  const handleClose = () => {
    setShow(false);
  };
  const userData = dispatch(loginInfo)?.data
  const viewSignedDocument = async () => {
    let reqUri = `${process.env.REACT_APP_BASE_URL}/api/workflow/encryptURL`
    let params = {
      "_id": selectedData?.document_id + '/' + userData._id + '/' + (new Date().getTime().toString())
    } 
    const options={
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    }; 
    try{
       const response =await requestV1(reqUri,options)
       const {status,encrypt}=response
       if(SUCCESS_RESPONSE_LIST.includes(status)){
        handleShow(`${process.env.REACT_APP_PREVIEW_URL}/api/workflow/viewDocumentEncryptedUrl/` + encrypt + '/SRL')
       }
    }
    catch(err){
      // console.log(err)
    }
  }


  return (
    <section className="other-info" id="other-info">
      <HorizontalLine />
      <Container fluid={true}>
        <Row>
          <p className="widget-info__title">Other Information</p>
        </Row>
        <Row>
          <Col lg={5}>
            <p className="widget-info__sub-title">Agreement</p>
            <div className="other-info__document d-flex" onClick={() => viewSignedDocument()} >
              <img src={document} />
              <div className="other-info__text-wrapper">
                <span className="other-info__text" >Agreement</span>
                <span className="other-info__sub-text">Signed</span>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            {selectedData?.client_code && selectedData?.client_code?.length !==0 && <>
              <p className="widget-info__sub-title">Client Code</p>
              <p className="widget-info__sub-text">{(selectedData?.client_code ? selectedData?.client_code : selectedData?.clientCode)}</p>
            </>}
          </Col>
        </Row>
        <ViewPDF show={show} src={src} handleClose={handleClose} fullscreen={true} />
      </Container>
    </section>
  );
};

export default OtherInformation;
