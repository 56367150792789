import SubmitResponse from "../../../lib/components/SubmitResponse";
import { useHistory } from "react-router";
import { LEGAL_TEAM_DASHBOARD } from "../../../constants/route.constants.js/legalTeam";

  
const SubmitLegalTeam = () => {
  const history=useHistory()
  const responseSubmit=()=>{
    history.push(LEGAL_TEAM_DASHBOARD)
  }
  return <SubmitResponse text="Application Approved" btnStatus={true} responseSubmit={responseSubmit}/>;
};

export default SubmitLegalTeam;
