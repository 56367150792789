import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Input from "../../lib/components/Input";
import Button from "../../lib/components/Button";
import greenTick from "../../assets/images/form-green tick- selected.svg";
import redCross from "../../assets/images/form-cross- selected.svg";
import formTick from "../../assets/images/form- tick.svg";
import formCross from "../../assets/images/form-cross.svg";
import "./index.scss";

const AddRemarkForm = (props) => {
  const [remark, setRemark] = useState();
  const [showRemark, setShowRemark] = useState(false);

  const { updateRemark, remarks } = props;

  useEffect(() => {
    if (remarks) {
      setShowRemark(true)
      setRemark(remarks)
    }
  }, [remarks])

  const handleInput = (e) => {
    const remark = e.target.value;
    setRemark(remark);
  };



  return (
    <div className="add-remark-form__wrapper">
      {showRemark ? (
        <>
          <p className="add-remark-form__title">Remarks</p>
          <p
            onClick={() => {
              props.setShowInput(false);
              props.setNo(false);
              updateRemark("");
            }}
            className="add-remark-form__remove-remark"
          >
            Remove Remark
          </p>
          <p className="add-remark-form__show-remark">{remark}</p>
        </>
      ) : (
        <div className="add-remark-form__content">
          <Row>
            <Col lg={8}>
              <Input
                label="Remark"
                type="text"
                placeholder="Add remark"
                fieldName="remark"
                onChange={(e) => handleInput(e)}
                style={{ margin: "0px" }}
              />
            </Col>
            <Col lg={4}>
              <div className="add-remark-form__button-wapper">
                <Button
                  label="Done"
                  onClick={() => {
                    if (remark) {
                      setShowRemark(!showRemark);
                      updateRemark(remark);
                    }
                  }}
                  className="add-remark-form__button-wapper--btn"
                />
                <Button
                  onClick={() => {
                    props.setShowInput(false);
                    props.setNo(false);
                  }}
                  label="cancel"
                  type="outline"
                  className="add-remark-form__button-wapper--btn"
                />
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

const AddRemark = ({ text, value, remark, handleSelect, updateRemark }) => {
  const [yes, setYes] = useState(false);
  const [no, setNo] = useState();
  const [showInput, setShowInput] = useState(false);
  const [yesSrc, setYesSrc] = useState(formTick);
  const [noSrc, setNoSrc] = useState(formCross);

  useEffect(() => {
    if (value !== undefined) {
      if (value) {
        setYes(true)
      } else {
        setNo(true)
        setShowInput(true)
      }
    }
  }, [value])

  return (
    <>
      <section className="add-remark__wrapper">
        <Row>
          <Col lg={10}>
            <p className="add-remark__text">{text}</p>
          </Col>
          <Col lg={2}>
            <div className="add-remark__actions">
              <div
                className="add-remark__image"
                onClick={() => {
                  setYes(true);
                  setNo(false);
                  setShowInput(false);
                  handleSelect(true);
                  updateRemark("");
                }}
                onMouseEnter={() => { setYesSrc(greenTick) }}
                onMouseLeave={() => { setYesSrc(formTick) }}
              >
                {yes ? <img src={greenTick} /> : <img src={yesSrc} />}
              </div>
              <div
                className="add-remark__image"
                onClick={() => {
                  setNo(true);
                  setYes(false);
                  handleSelect(false);
                  setShowInput(true);
                }}
                onMouseEnter={() => { setNoSrc(redCross) }}
                onMouseLeave={() => { setNoSrc(formCross) }}
              >
                {no ? <img src={redCross} /> : <img src={noSrc} />}
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {showInput && <AddRemarkForm setShowInput={setShowInput} setNo={setNo} updateRemark={updateRemark} remarks={remark} />}
    </>
  );
};
export default AddRemark;
