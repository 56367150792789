import toast from "react-hot-toast";
import { FORGOT_PASSWORD_ENDPOINT, RESET_PASSWORD_ENDPOINT } from "../../constants/api.constants.js";
import { RETAILS_APP_USER_TYPE } from "../../constants/app.constants.js";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status.js";
import request from "../../utils/request";
import requestV1 from "../../utils/requestV1.js";

export const forgotPasswordRequest = async (email, cb, errCb = () => { }) => {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ userType: RETAILS_APP_USER_TYPE, username: email }),
    };
    const reqUri = FORGOT_PASSWORD_ENDPOINT;
    try {
        const result = await requestV1(reqUri, options);
        const { status } = result;
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
            cb();
        }
    } catch (error) {
        errCb();
    }
}

export const resetPasswordRequest = async (data, cb) => {
    const { newPassword, confirmPassword, userId, token } = data;

    const payload = {
        newPassword,
        confirmPassword,
        userId,
        token
    };
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    };
    const reqUri = RESET_PASSWORD_ENDPOINT;
    try {
        const result = await requestV1(reqUri, options);
        const { status ,message} = result;
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
            cb();
        }
        else{
            toast.error(message)
        }
    } catch (error) {
    }
}