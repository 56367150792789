import HandleKYC from "../../lib/components/HandleKYC";

const KYCHandle = () => {
  
  return (
    <div>
      <HandleKYC text="Oops! Some servers are down" secondText="We’re doing our best to cheer them up. Please try again after sometime." />
    </div>
  );
};

export default KYCHandle;
