export const APPLICANT_LIST_REQUEST = "APPLICANT_LIST_REQUEST";
export const APPLICANT_LIST_SUCCESS = "APPLICANT_LIST_SUCCESS";
export const APPLICANT_LIST_FAILURE = "APPLICANT_LIST_FAILURE";

export const BUSINESS_HEAD_APPROVAL_REQUEST = "BUSINESS_HEAD_APPROVAL_REQUEST";
export const BUSINESS_HEAD_APPROVAL_SUCCESS = "BUSINESS_HEAD_APPROVAL_SUCCESS";
export const BUSINESS_HEAD_APPROVAL_FAILURE = "BUSINESS_HEAD_APPROVAL_FAILURE";

export const BUSINESS_HEAD_REJECTION_REQUEST = "BUSINESS_HEAD_REJECTION_REQUEST";
export const BUSINESS_HEAD_REJECTION_SUCCESS = "BUSINESS_HEAD_REJECTION_SUCCESS";
export const BUSINESS_HEAD_REJECTION_FAILURE = "BUSINESS_HEAD_REJECTION_FAILURE";

export const BUSINESS_HEAD_CLARIFICATION_REQUEST = "BUSINESS_HEAD_CLARIFICATION_REQUEST";
export const BUSINESS_HEAD_CLARIFICATION_SUCCESS = "BUSINESS_HEAD_CLARIFICATION_SUCCESS";
export const BUSINESS_HEAD_CLARIFICATION_FAILURE = "BUSINESS_HEAD_CLARIFICATION_FAILURE";