import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_INFO,
} from "./LoginActionTypes";

const initialState = {
  loggedIn: true,
  userData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.data
      };
    case LOGIN_INFO:
      return {
        ...state,
        userData: action.data
      }
    case LOGIN_FAILURE:
      return {};
    // case LOGOUT:
    //   return {};
    default:
      return state;
  }
};

export default reducer;
