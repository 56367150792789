import SubmitResponse from "../../../lib/components/SubmitResponse";

import { useHistory, useLocation } from "react-router";
import { CLUSTER_HEAD_DASHBOARD } from "../../../constants/route.constants.js/cluster_head";
const SubmitClusterHead = () => {
  const location = useLocation();
  const rejected = location?.state
  const history = useHistory()
  const responseSubmit = () => {
    history.push(CLUSTER_HEAD_DASHBOARD)
  }
  return (
    <>
      {rejected == "rejected" ?
        <SubmitResponse text="Franchise request has been rejected" btnStatus={true} responseSubmit={responseSubmit} />
        :
        <SubmitResponse text="Franchise request has been updated successfully" btnStatus={true} responseSubmit={responseSubmit} />
      }
    </>
  );
};

export default SubmitClusterHead;
